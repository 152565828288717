import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useTheme } from '@material-ui/styles';
import React from 'react';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleClubSearch } from '../reducers/clubs';
import { handleProspectClubChange } from '../reducers/user';
import { parser } from '../utils/parser';
import AutoSelect from './auto_select';

const ClubDialog = ({open, clubDetail, selectedUser, clubs, toggleDialog, handleClubSearch, handleProspectClubChange}) => {
    const [editClub, showEditClub] = React.useState(false)
    const [newClub, setNewClub] = React.useState()
    const [ selectedRole, setSelectedRole ] = React.useState()
    React.useEffect(() => {
        setSelectedRole(selectedUser ? selectedUser.role : null)
    }, [selectedUser]);
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const handleConfirm = () => {
        setNewClub(null)
        showEditClub(false)
        setSelectedRole(null)
        toggleDialog(null)
    }
    const searchClubs = name => value => {
        if (value && value.length > 1) {
          handleClubSearch(value)
        }
    }
    const updateClub = name => value => {
        if (!value || !value.value) { return }
        setNewClub(value)
    }
    const saveClub = (event) => {
        event.preventDefault()
        handleProspectClubChange(selectedUser.email, newClub.value)
        handleConfirm()
    }
    const cancelEdit = () => {
        setNewClub(null)
        showEditClub(false)
    }
    if (!clubDetail) { return <div /> }
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(null)}
            fullScreen={fullScreen}
            >
            <DialogTitle>
                <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                    {editClub ? 'Edit Primary Club' : clubDetail.name}
                    {(selectedRole === 'applicant' || !selectedRole) && 
                    <IconButton onClick={() => {showEditClub(!editClub)}}>
                        <EditIcon />
                    </IconButton>}
                </div>
            </DialogTitle>
            <DialogContent style={{minWidth: 400, minHeight: 500}}> 
                {editClub ?
                    <div>
                        <AutoSelect
                            textFieldProps={{
                                label: 'Select new primary club',
                            }}
                            fullWidth
                            name={'club'}
                            options={parser.formatClubs(clubs)}
                            selectedValue={newClub}
                            handleAutoSelectTextChange={searchClubs}
                            handleAutoSelectChange={updateClub} />
                        <div style={{ marginTop: 24, display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                            <Button onClick={saveClub} style={{marginRight: 8}} disabled={!newClub} variant="contained">Save</Button>
                            <Button onClick={cancelEdit} variant="contained">Cancel</Button>
                        </div>    
                    </div>
                :   <ReactJson 
                    displayObjectSize={false}
                    displayDataTypes={false}
                    enableClipboard={false} 
                    name="club" 
                    src={clubDetail} /> 
                }
            </DialogContent>
            <DialogActions>
                {!editClub && <Button variant="contained" onClick={handleConfirm}>
                    OK
                </Button>}
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
      clubs: state.clubs.clubList
    }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleClubSearch,
        handleProspectClubChange
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(ClubDialog)
