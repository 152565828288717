import { InputAdornment, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

import { useDebounce } from '../utils/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  }
}));

const SearchBar = ({placeholderText, onTextInput, initialText}) => {
    const classes = useStyles();
    const [ text, setText ] = React.useState(initialText || '')
    const handleText = (event) => {
      setText(event.target.value)
    }

    const debouncedText = useDebounce(text, 500);

    React.useEffect(() => {
      onTextInput(text)
    }, [debouncedText]) // eslint-disable-line

    return (
      <TextField
        id="search"
        name="search"
        label={placeholderText || "Type Member name here to search"}
        value={text}
        classes={{
          root: classes.root
        }}
        style={{marginTop: 8}}
        onChange={handleText}
        margin="normal"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className={classes.iconButton} aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }} />
    // <Paper className={classes.root}>
    //     <InputBase
    //         className={classes.input}
    //         placeholder={ placeholderText || "Type Member name here to search" }
    //         onChange={handleText}
    //         value={text}
    //     />
    //     <IconButton className={classes.iconButton} aria-label="search">
    //         <SearchIcon />
    //     </IconButton>
    // </Paper>
    );
}

export default SearchBar