import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { handlePasswordReset } from '../../reducers/auth'
import PasswordResetView from './passwordResetView'
import { paths } from '../../utils/paths'

const qs = require('query-string')

class PasswordResetContainer extends React.Component {
  render () {
    try {
      const queryString = this.props.location.search
      const token = qs.parse(queryString).token
      const email = this.props.match.params.email
      if (!token || !email) { throw new Error('Bad Path') }
      return <PasswordResetView {...this.props} email={email} token={token} />
    } catch (error) {
      console.log('PWD RESET ERROR', error.message)
      return <Redirect to={paths.root} />
    }

  }
}

const mapStateToProps = (state) => {
    return { auth: {...state.auth} }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handlePasswordReset
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(PasswordResetContainer)
