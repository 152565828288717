import { Button, Dialog, DialogActions, DialogContent, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, IconButton, List, ListItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PhotoEditorDialog from '../../components/photoEditorDialog';
import { handleForgotPassword, handleGetPasswordResetUrl } from '../../reducers/auth';
import { handleCoverPhotoSubmit } from '../../reducers/members';
import { handleUpdateUser } from '../../reducers/user';
import { isApplicantOrMemberReview } from '../../utils/profiles';
import OnboardingContainer from '../onboarding/onboardingContainer';
import ProfileView from '../profile/profileView';


const MemberProfileDialog = ({open, profile, toggleDialog, sendReset, passwordResetUrl, handleForgotPassword, handleGetPasswordResetUrl, handleDeleteUser, handleCoverPhotoSubmit}) => {
    const [ openCoverPhoto, toggleCoverPhotoDialog ] = React.useState(false)
    const deleteClick = () => {
        if (window.confirm('Are you sure you want to delete ' + (!profile.preferred_email ? profile.email : profile.preferred_email) + '?')) {
            handleDeleteUser(profile.email)
            toggleDialog(null)
        }
    }
    const handleUpdateCoverPhoto = (file) => {
        if (!file) { return }
        handleCoverPhotoSubmit(profile.email, profile.role, file, true)
    }
    const sendPasswordReset = () => {
        handleForgotPassword(profile.email, true)
    }
    const getPasswordResetUrl = () => {
        handleGetPasswordResetUrl(profile.email)
    }

    const parseProfileValue = (key, value) => {
        if (value === null) { return '' }
        switch (key) {
            case 'address':
                return `${value.address_1 || ''} ${value.city || ''}, ${value.state || ''} ${value.zip || ''}`
            case 'about_me':
                return value.replace(/,/g, ', ')
            case 'areas_of_interest':
            case 'played_with_confirmed':
                return value.join(', ')
            case 'club_memberships':
            case 'share_clubs':
                return `${value.length} ${value.length === 1 ? 'club' : 'clubs'}: ` + value.map(club => {
                    return club.name + (club.address && club.address.city && club.address.state ? ` (${club.address.city}, ${club.address.state})`: '')
                }).join(', ')
            case 'courses_played':
            case 'course_wishlist':
            case 'favorite_courses':
                return `${value.length} ${value.length === 1 ? 'course' : 'courses'}: ` + value.map(club => {
                    return club.course_name + ' course at ' + club.club_name + (club.state ? ` (${club.state})`: '')
                }).join(', ')
            case 'graduate_degree':
            case 'postgraduate_degree':
                return (JSON.parse(value)).label
            default:
                return value.toString()
        }
    }
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(null)}
            maxWidth='lg'
            fullWidth
        >
            <DialogContent>
                {profile && <PhotoEditorDialog
                        open={openCoverPhoto}
                        toggleDialog={toggleCoverPhotoDialog}
                        imagePath={profile.cover_photo}
                        handleSave={handleUpdateCoverPhoto}
                    />}
                <div style={{display: 'flex', margin: '16px 0'}}>
                    {profile && isApplicantOrMemberReview(profile) && 
                        <Button onClick={() => toggleCoverPhotoDialog(true)} style={{marginRight: 16}} variant='contained'>Set Cover Photo</Button>
                    }
                    <Button disabled={sendReset} onClick={() => sendPasswordReset()} style={{marginRight: 16}} variant={sendReset ? 'outlined' : 'contained'}>{sendReset ? 'Email sent to ' + profile.email : 'Send Password Reset Email'}</Button>
                    <Button disabled={sendReset} onClick={() => getPasswordResetUrl()} variant='contained'>Get Password Reset URL</Button>
                </div>
                <div>
                    {passwordResetUrl && <Typography variant="body1">{decodeURIComponent(passwordResetUrl)}</Typography>}
                </div>
                <div style={{display: 'flex', margin: 0, justifyContent: 'space-between', alignItems: 'top'}}>
                    <div style={{width: '90%', margin: '8px 0'}}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="subtitle1">More Detail</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <List>
                                    {profile && profile.email && Object.keys(profile).sort().map((key, idx) => {
                                        return <ListItem key={idx}>
                                            <div style={{alignSelf: 'flex-start', fontWeight: 'bold', minWidth: 150}}>{key}:</div>
                                            <div style={{marginLeft: 8, textAlign: 'left'}}>
                                                {parseProfileValue(key, profile[key])}
                                            </div>
                                        </ListItem> 
                                    })}
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div>
                        <IconButton onClick={() => deleteClick()}><DeleteIcon /></IconButton>
                    </div>
                </div>
                {profile && isApplicantOrMemberReview(profile) ? <OnboardingContainer /> : <ProfileView profile={profile} />}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => toggleDialog(null)}>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        sendReset: state.auth.sendReset,
        passwordResetUrl: state.auth.passwordResetUrl,
        temporaryUser: state.user.temporaryUser
    }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleCoverPhotoSubmit,
        handleForgotPassword,
        handleGetPasswordResetUrl,
        handleUpdateUser,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(MemberProfileDialog);
