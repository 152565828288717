import { Card, CardContent, CardHeader, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PostCardActions from '../../components/postCardActions';
import PostCommentActions from '../../components/postCommentActions';
import UserAvatar from '../../components/userAvatar';
import {
  handleDeletePost,
  handleSubmitComment,
  handleGetComments,
  handlePostLike,
} from '../../reducers/posts';
import { paths } from '../../utils/paths';
import {GeneralPost} from './generalPost';
import GeneralPostForm from './generalPostForm';
import PlayGolfForm from './playGolfForm';
import PlayGolfPost from './playGolfPost';
import TravelForm from './travelForm';
import TravelPost from './travelPost';

const useStyles = makeStyles(theme => ({
  main: {
    margin: '8px 0',
    padding: '4px 16px',
    backgroundColor: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: 8
    },
  },
  cardContent: {
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      padding: 8
    }
  },
  cardActions: {
    padding: 0,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
  owner: {
    color: theme.palette.link.main,
    cursor: 'pointer',
  },
  submitDate: {
    marginLeft: 'auto',
    color: '#777'
  },
}))

const DashboardFeedItem = ({
  history,
  post,
  groups,
  groupDetail,
  userDetail,
  membersVisible,
  handleSubmitComment,
  handleDeletePost,
  handleGetComments,
  handlePostLike
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false);
  
  const [editPost, setEditPost] = React.useState();

  const group = groups.find(x => x.id === post.group_id)
  const visibleMemberList = (membersVisible || []).map(x => x.username)
  const likedPost = (post?.likes || []).map(like => like?.member_email).includes(userDetail.email)
  function handleExpandClick() {
    if (!expanded && !post.comments?.length && post.comment_count > 0) {
      handleGetComments(post.id);
    }

    setExpanded(!expanded);
  }
  
  function handleDeleteClick() {
    if (window.confirm('Are you sure you want to remove this item?')) {
      handleDeletePost(post)
    }
  }
  
  return (
    <Card elevation={2} className={classes.main}>
      <CardHeader
          style={{borderBottom: 'solid 1px #ccc', padding: '8px 0'}}
          avatar={post.is_admin || post?.play_golf || (userDetail.role === 'member' && visibleMemberList.includes(post.username)) ? 
            <div style={{cursor: 'pointer'}} onClick={() => history.push(`${paths.profile}/` + post.username)}>
              <UserAvatar profileImage={post.profile_image} />
            </div>
            : <div>
              <UserAvatar profileImage={visibleMemberList.includes(post.username) && post.profile_image} />
            </div>
          }
          action={ <PostCardActions
              post={post}
              userDetail={userDetail}
              likedPost={likedPost}
              setEditPost={setEditPost}
              handlePostLike={handlePostLike}
              handleDeleteClick={handleDeleteClick} /> }
          title={<div style={{display: 'flex'}}>
            {post.is_admin || post?.play_golf || ((userDetail.role === 'member' || userDetail.referrer === post.username) && visibleMemberList.includes(post.username)) ? <React.Fragment>
              <Link className={classes.owner} variant="subtitle1" onClick={() => history.push(`${paths.profile}/` + post.username)}>{post.first_name + ' ' + post.last_name}</Link>
              {post.username === userDetail.username && userDetail.privacy_setting === 'anonymous' && 
                  <Typography variant="body2" style={{fontSize: 11, paddingLeft: 8, alignSelf: 'center'}}><Link style={{cursor: 'pointer'}} onClick={() => history.push(paths.profile)}>(Anonymous)</Link></Typography>}
              </React.Fragment>
            : 
              <Typography variant="subtitle1">{visibleMemberList.includes(post.username) ? (post.first_name + ' ' + post.last_name) : 'Anonymous'}</Typography>
            }
            </div>
          }
          subheader={!groupDetail && post.group_id && group ? 
            <span>Private Post. Only visible to <Link className={classes.owner} variant="subtitle2" onClick={() => history.push(`${paths.group}/${group.url}`)}>{group.name}</Link></span> : (post.play_golf ? 'Private Post: Only visible to those invited' : null) }
      />
      <CardContent className={classes.cardContent}>
        {post.play_golf && post.id === post.play_golf.post_id &&
          <div>
            {editPost 
              ? <PlayGolfForm post={post} closeEdit={() => setEditPost(false)} />
              : <PlayGolfPost post={post} userDetail={userDetail} memberList={membersVisible} />
            }
          </div>
        }
        {post.travel && post.id === post.travel.post_id &&
          <div>
            {editPost 
              ? <TravelForm post={post} closeEdit={() => setEditPost(false)} />
              : <TravelPost post={post} />
            }
          </div>
        } 
        {!post.play_golf && !post.travel && (editPost
            ? <GeneralPostForm post={post} closeEdit={() => setEditPost(false)} />
            : <GeneralPost post={post} />
          )
        }
          
      </CardContent>
      <PostCommentActions
        post={post}
        userDetail={userDetail}
        expanded={expanded}
        visibleMemberList={visibleMemberList}
        history={history}
        handleExpandClick={handleExpandClick}
        handleSubmitComment={handleSubmitComment}
        setExpanded={setExpanded}
        likedPost={likedPost}
        handlePostLike={handlePostLike}
        />
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    memberList: state.members.memberList,
    membersVisible: state.members.membersVisible,
    groups: state.groups.groups,
    groupDetail: state.groups.groupDetail,
    updatedPost: state.posts.updatedPost,
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleSubmitComment,
    handleDeletePost,
    handleGetComments,
    handlePostLike,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatch)(DashboardFeedItem))
