import { Button, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import UserAvatar from './userAvatar';

const useStyles = makeStyles((theme) => ({
  member_list: {
    color: theme.palette.profile.dark,
    padding: '4px 0',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 240,
  },
  groupItem: {
    display: 'flex',
    fontSize: 12,
    alignItems: 'center',
    minWidth: 210,
    padding: '1px 6px',
    backgroundColor: '#f0f0f0',
    margin: 2,
    color: theme.palette.profile.dark,
  },
}));
const AudienceList = ({ fullWidth, list, handleRemove }) => {
  const classes = useStyles();
  return (
    <div className={classes.member_list}>
      {list.map((item, idx) => {
        const golfer = {
          label: item.label || `${item.first_name} ${item.last_name}`,
          value: item.value || item.email || item.member_email,
          logo: item.logo || item.profile_image,
        };
        return (
          <div
            key={idx}
            className={classes.groupItem}
            style={{ width: fullWidth ? '100%' : '32%' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <div style={{ marginRight: 8, marginTop: 2 }}>
                <UserAvatar profileImage={golfer.logo} width={18} />
              </div>
              <Typography
                variant='subtitle1'
                component='div'
                style={{ fontSize: 12 }}
              >
                {golfer.label}
                {item.address && item.address.city && item.address.state ? (
                  <span
                    style={{ paddingLeft: 24 }}
                  >{`(${item.address.city}, ${item.address.state})`}</span>
                ) : (
                  ''
                )}
                {item.club_name ? (
                  <Typography
                    variant='body2'
                    component='div'
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      fontSize: 10,
                    }}
                  >
                    {item.club_name}
                  </Typography>
                ) : (
                  <></>
                )}
              </Typography>
            </div>
            <Button
              onClick={() => handleRemove(golfer.value)}
              style={{ marginLeft: 'auto', padding: '2px 0' }}
            >
              <DeleteIcon />
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default AudienceList;
