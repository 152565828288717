import { createTheme } from '@material-ui/core/styles';

export default createTheme({
    palette: {
        primary: { dark: '#11274B', main: '#2b4061', light: 'rgb(236, 242, 251)', extraLight: 'rgb(247, 251, 254)' },
        secondary: { dark: '#cd9a37', main: '#F3BD48' },
        // background: {
        //     default: '#11274B',
        //     paper: '#FFF'
        // },
        profile: { dark: '#162649', main: '#757F93', light: "#9CADBA", background: '#EAEDF1'},
        lightGray: { main: "#F3F5F8"},
        success: { main: '#00FF00'},
        warning: { main: '#FF0000'},
        textOnPrimary: { main: '#FFF'},
        link: { main: "#2783D4"},
        highlight: { main: '#cee4e5'}
    },
    widths: {
        drawerWidth: 150
    },
    overrides: {
        MuiButton: {
            contained: {
                minWidth: 88,
                padding: '3px 16px',
                backgroundColor: '#2b4061',
                color: '#FFF',
                fontWeight: 500,
                borderRadius: 30,
                '&:hover': {
                    backgroundColor: '#cd9a37',
                }
            },
            outlined: {
                padding: '3px 12px',
                borderRadius: 30
            },
            textSecondary: {
                backgroundColor: '#11274B',
                color: '#FFF',
                '&:hover': {
                    border: 'solid 1px #11274B',
                    color: '#11274B',
                }
            }
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: '#FFF',
                color: '#11274B',
                border: 'solid 3px #11274B'
            },
            action: {
                color: '#11274B'
            },
        },
        MuiToolbar: {
            regular: {
                minHeight: 64,
            }
        }
    },
    typography: {
        fontFamily: [
            'Sentinel-Black',
            'Roboto',
            'sans-serif'
        ].join(','),
        secondaryFont: 'Roboto',
        h1: {
            color: 'inherit',
            fontSize: '107.23px',
            letterSpacing: '-1.5px'
        },
        h2: {
            color: 'inherit',
            fontSize: '60px',
            letterSpacing: '-0.5px',
            fontFamily: 'Roboto',
            fontWeight: '300'
        },
        h3: {
            color: 'inherit',
            fontSize: '53.61px',
            letterSpacing: '0px'
        },
        h4: {
            color: 'inherit',
            fontSize: '37.98px',
            letterSpacing: '0.25px'
        },
        h5: {
            color: 'inherit',
            fontSize: '26.81px',
            letterSpacing: '0.25px'
        },
        h6: {
            color: 'inherit',
            fontSize: '22.34px',
            letterSpacing: '0.25px'
        },
        body1: {
            color: 'inherit',
            fontSize: '16px',
            letterSpacing: '0.5px',
            fontFamily: 'Roboto',
            fontWeight: '300'
        },
        body2: {
            color: 'inherit',
            fontSize: '14px',
            letterSpacing: '0.25px',
            fontFamily: 'Roboto',
            fontWeight: '300'
        },
        subtitle1: {
            color: 'inherit',
            fontSize: '17.87px',
            letterSpacing: '0.15px'
        },
        subtitle2: {
            color: 'inherit',
            fontSize: '14px',
            letterSpacing: '0.1px',
            fontFamily: 'Roboto',
            fontWeight: '400'
        },
        button: {
            color: 'inherit',
            fontSize: '15.64px',
            letterSpacing: '1.25px',
            fontFamily: 'Roboto',
            fontWeight: '300',
            textTransform: 'none'
        },
        caption: {
            color: 'inherit',
            fontSize: '12px',
            fontFamily: 'Roboto',
            fontWeight: '300',
            textTransform: 'none'
        },
        overline: {
            color: 'inherit',
            fontSize: '12px',
            letterSpacing: '2px',
            fontFamily: 'Roboto',
            fontWeight: '400'
        }
    }
});
