import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cls from 'classnames';
import { Button, Chip, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MountainIcon from '@material-ui/icons/Photo';
import { handleSubmitPost, handleUpdatePost } from '../../reducers/posts';
import PostImages from '../dashboard/postImages';
import ImageGridList from '../dashboard/imageGridList';
import TaggableInput from '../../components/taggableInput';
import { MAX_IMAGES } from '../../utils/common';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 16,
    border: 'solid 1px #ccc',
    backgroundColor: '#FFF',
    borderRadius: 4,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentBox: {
    paddingBottom: 20,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  inputBox: {
    width: '100%',
  },
  input: {
    flex: 1,
    '&::placeholder': {
      fontSize: 14,
      color: '#999',
      fontStyle: 'italic',
    },
  },
  chip: {
    padding: 12,
    marginRight: 8,
    fontFamily: theme.typography.secondaryFont,
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&.disabled': {
      color: '#ccc',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  chipIcon: {
    color: theme.palette.common.white,
    '&.disabled': {
      color: '#ccc',
    },
  },
  button: {
    width: '100%',
  },
  postButton: {
    maxWidth: 300,
    width: '100%',
    margin: 'auto',
  },
  tagWith: {
    border: 'solid 1px #CCC',
    padding: 8,
    justifyContent: 'center',
    backgroundColor: '#e3e8f5',
    borderRight: 0,
    fontWeight: 500,
  },
}));

const AdminPostForm = ({
  sender_email,
  message,
  post,
  onSubmit,
  postError,
  handleSubmitPost,
  handleUpdatePost,
}) => {
  const classes = useStyles();
  const [submitError, setSubmitError] = React.useState();
  const [postText, setPostText] = React.useState('');
  const [showImageDialog, setImageDialog] = React.useState(false);
  const [media, setMedia] = React.useState([]);
  const textInputRef = React.useRef(null);
  React.useEffect(() => {
    if (post) {
      setPostText(post.body_text);
      setMedia(post.photos);
    }
  }, [post]);
  const handlePost = (e) => {
    if ((!postText || postText.trim().length < 1) && media.length < 1) {
      return;
    }
    if (post && post.id) {
      const newPost = {
        ...post,
        body_text: postText,
        is_admin: true,
        photos: media.filter((x) => typeof x === 'string'),
      };
      handleUpdatePost(
        newPost,
        media.filter((x) => typeof x !== 'string'),
        true
      );
    } else {
      if (!sender_email || !message) {
        return;
      }
      handleSubmitPost(postText, media, null, sender_email, message, null, true);
    }
    setSubmitError('');
    setPostText('');
    setMedia([]);
    onSubmit();
  };
  const handleFilesAdded = (files) => {
    setSubmitError('');
    setMedia(media.concat(files));
  };
  const handleFileDeleted = (filename) => {
    let files = media.filter(
      (item) =>
        (typeof item === 'string' && item !== filename) ||
        (typeof item === 'object' && item.name !== filename)
    );
    setSubmitError('');
    setMedia(files);
  };
  const onTextChange = (ev, value) => {
    setSubmitError('');
    setPostText(value);
  };
  return (
    <Paper elevation={post ? 0 : 2} className={classes.main}>
      <div className={classes.contentBox} style={{ flexGrow: 1 }}>
        <PostImages
          open={showImageDialog}
          toggleDialog={setImageDialog}
          handleConfirm={handleFilesAdded}
        />
        <div
          className={classes.inputBox}
          onClick={() =>
            textInputRef && textInputRef.current
              ? textInputRef.current.focus()
              : {}
          }
        >
          <TaggableInput
            textInputRef={textInputRef}
            value={postText}
            onChange={onTextChange}
            placeholder={
              "Post questions or comments for other members here. (Mention people using '@')"
            }
          />
        </div>
      </div>
      {media && media.length > 0 && (
        <div className={classes.contentBox}>
          <ImageGridList media={media} handleDelete={handleFileDeleted} />
        </div>
      )}
      <div className={classes.contentBox}>
        <Chip
          className={cls(classes.chip, { disabled: media.length >= MAX_IMAGES })}
          onClick={() => {
            if (media.length < MAX_IMAGES) {
              setImageDialog(true);
            }
          }}
          clickable={media.length < MAX_IMAGES}
          icon={
            <MountainIcon
              className={cls(classes.chipIcon, { disabled: media.length >= MAX_IMAGES })}
            />
          }
          label='Insert Photos'
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={
            (!post && (!message || !sender_email)) ||
            (!postText && media.length < 1)
          }
          variant='contained'
          onClick={handlePost}
          style={{ marginRight: 8 }}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={() => onSubmit()}>
          Cancel
        </Button>
      </div>
      <Typography color='error' component='p' variant='subtitle1'>
        {postError || submitError}
      </Typography>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    postError: state.posts.error,
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      handleSubmitPost,
      handleUpdatePost,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatch)(AdminPostForm);
