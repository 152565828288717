import { makeStyles } from '@material-ui/styles';
import cls from 'classnames';
import React from 'react';

const useStyles = makeStyles(theme => ({
  groupWrapper: {
    display: 'flex', 
    flexWrap: 'wrap', 
    alignItems: 'flex-start', 
    marginBottom: 4, 
    padding: '2px 8px',
    backgroundColor: theme.palette.lightGray.main,
    borderRadius: 8,
    '&.clickable': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      }
    }
  },
}))

const WidgetElement = ({ noClick, styles, children }) => {
  const classes = useStyles()
  return (
    <div className={cls(classes.groupWrapper, {'clickable': !noClick})} style={{...styles}}>
      {children}
    </div>
    )
}

export default WidgetElement
