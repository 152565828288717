import { Typography } from '@material-ui/core/';
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import AudienceList from '../../components/audienceList';
import AutoSelect from '../../components/auto_select';
import MemberSelector from '../../components/memberSelector';
import { LOCAL_CLUBS, RESIDENTS } from '../../utils/constants';
import { getAudienceFilteredList } from '../../utils/posts';
import { handleGetLists } from '../../reducers/lists';
import ListsDialog from '../../pages/members/listDialog'
import { formatListOptions } from '../../utils/lists';

const styles = (theme) => ({
  root: {
    marginTop: 4,
    marginBottom: 8,
  },
});

const TravelAudience = ({
  attendees,
  memberList,
  userDetail,
  membersByState,
  destinationState,
  labelText,
  handleSelectedMembers,
  classes,
  lists,
  handleGetLists,
  membersVisible
}) => {
  // const [membersList, setMembersList] = React.useState([])
  const [optionsList, setOptionsList] = React.useState([]);
  const [stateResidents, setStateResidents] = React.useState([]);
  const [stateClubMembers, setStateClubMembers] = React.useState([]);
  const [individualSelection, setIndividualSelection] = React.useState(false);
  const [showLists, setShowLists] = React.useState(false);

  React.useEffect(() => {
    handleGetLists();
  }, [handleGetLists]);

  React.useEffect(() => {
    const options = [
      {
        value: 'individuals',
        label: 'Individual Members',
        type: 'individuals',
      },
    ];

    options.push({
      value: 'create-lists',
      label: `Create New List`,
      type: 'create-lists',
    });

    if (
      userDetail &&
      memberList &&
      destinationState &&
      destinationState.value
    ) {
      const resiList = memberList
        .filter(
          (x) =>
            x.address &&
            x.address.state &&
            x.email &&
            x.email !== userDetail.email &&
            destinationState &&
            x.address.state.toUpperCase() ===
              destinationState.value.toUpperCase()
        )
        .map((member) => ({
          value: member.email,
          label: member.first_name + ' ' + member.last_name,
          logo: member.profile_image,
          club_name:
            member.club_memberships && member.club_memberships.length
              ? member.club_memberships[0].name
              : '',
        }));
      setStateResidents(resiList);
      if (resiList && resiList.length > 0) {
        options.push({
          value: destinationState ? destinationState.value : '',
          label: `Members living in ${
            destinationState ? destinationState.label : ''
          }`,
          type: RESIDENTS,
        });
      }
      const stateList = membersByState.find(
        (m) =>
          m.state_abbr.toUpperCase() === destinationState.value.toUpperCase()
      );
      if (stateList && stateList.members) {
        const stateMemberList = stateList.members
          .filter((x) => x.username && x.username !== userDetail.username)
          .map((member) => ({
            value: member.member_email,
            label: member.first_name + ' ' + member.last_name,
            logo: member.profile_image,
            club_name: member.club_name || '',
          }));
        setStateClubMembers(stateMemberList);
        if (stateMemberList && stateMemberList.length > 0) {
          options.push({
            value: destinationState ? destinationState.value : '',
            label: `Members of all clubs in ${
              destinationState ? destinationState.label : ''
            }`,
            type: LOCAL_CLUBS,
          });
        }
      } else {
        setStateClubMembers([]);
      }
    }

    formatListOptions(lists, options)

    
    setOptionsList(options);
  }, [userDetail, destinationState, memberList, membersByState, lists]);
  const handleOptionSelected = (name) => (option) => {
    if (!option || !option.type) {
      return;
    }
    let members = [];
    setIndividualSelection(false);
    switch (option.type) {
      case LOCAL_CLUBS:
        members = stateClubMembers;
        break;
      case RESIDENTS:
        members = stateResidents;
        break;
      case 'create-lists':
          setShowLists(true)
          break;
      case 'list':
          if(lists && lists.length > 0){
            const list = lists.filter(l => l.id === parseInt(option.value))[0]
            if(list && list.members && list.members.length > 0){
              list.members.map(m => {
                //find user in the member lists visible
                const member = membersVisible.filter(mb => mb.email === m.member_email)[0]
  
                if(member){
                  members.push({
                    value: member.email,
                    label: `${member.first_name} ${member.last_name}`,
                    logo: member.profile_image,
                  });
                }
  
                return true
              })
            }
          }
          break;
      default:
        setIndividualSelection(true);
        break;
    }
    const ids = new Set(attendees.map((d) => d.value));
    const filteredList = [
      ...attendees,
      ...members.filter((d) => !ids.has(d.value)),
    ];
    handleSelectedMembers(filteredList);
  };
  const removeAttendee = (value) => {
    const attendee = attendees.find((x) => x.value === value);
    if (attendee) {
      const newList = attendees.filter((x) => x.value !== value);
      handleSelectedMembers(newList);
    }
  };
  const handleListChangeEvent = (item) => {
    handleSelectedMembers(getAudienceFilteredList(attendees, item));
  };
  return (
    <div className={classes.root}>
      <ListsDialog
        open={showLists}
        toggleDialog={() => setShowLists(false)} />
      <div style={{ width: '100%', textAlign: 'center', margin: 'auto' }}>
        <AutoSelect
          textFieldProps={{
            label: labelText || 'Who do you want to invite?',
            InputLabelProps: {
              shrink: true,
            },
          }}
          disabled={!destinationState}
          options={optionsList}
          selectedValue={''}
          // handleAutoSelectTextChange={searchItems}
          handleAutoSelectChange={handleOptionSelected}
        />
      </div>
      {individualSelection && (
        <div style={{ paddingTop: 16 }}>
          <MemberSelector
            isSingle
            guestList={[]}
            groupMembers={[]}
            listChangeEvent={handleListChangeEvent}
            excludeEmails={[userDetail.email].concat(
              attendees.map((x) => x.value)
            )}
          />
        </div>
      )}
      {(attendees || []).length > 0 && (
        <React.Fragment>
          <Typography variant='subtitle2' style={{ padding: 8 }}>
            Post will be visible to:
          </Typography>
          <AudienceList list={attendees || []} handleRemove={removeAttendee} />
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    memberList: state.members.memberList,
    membersByState: state.clubs.membersByState,
    lists: state.lists.lists,
    membersVisible: state.members.membersVisible,
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetLists
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(withStyles(styles)(TravelAudience));
