import { Grid, Typography } from '@material-ui/core';
// import cls from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaperMasterHoc from '../../components/paperMasterHoc';
import SearchBar from '../../components/searchBar';
import { handleArchivedGroupSearch, handleRequestGroupAccess } from '../../reducers/groups';
import GroupListBox from './groupListBox';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
  },
  filterContainer: {
    display: 'flex', 
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', 
    },
  },
  filterItem: {
    flex: 3.3,
    margin: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0
    },
  }
}))

const ArchivedGroupListContainer = ({userDetail,memberList, membersVisible, searchResults, handleArchivedGroupSearch, handleRequestGroupAccess}) => {
  const classes = useStyles();
  const [ searchText, setSearchText ] = React.useState()
  React.useEffect(() => {
    return () => {
      handleArchivedGroupSearch()
    }
  }, [handleArchivedGroupSearch]);

  React.useEffect(() => {
    handleArchivedGroupSearch({
      text: searchText,
    })
  }, [searchText, handleArchivedGroupSearch])

  if (!userDetail || !userDetail.email) { return <div /> }

  const onGroupSearch = (value) => {
    setSearchText(value)
  }
  
  return (
    <PaperMasterHoc transparent={true}>
      <div className={classes.root}>
        <Typography variant="h4" style={{marginBottom: 8}}>Archived Groups</Typography>
        <Grid container spacing={2} style={{marginBottom: 16}}>
          <Grid item xs={12} sm={6}>
            <SearchBar
              placeholderText="Type Group name here to search"
              onTextInput={onGroupSearch}
            />
          </Grid>
        </Grid>
        {searchResults.length === 0 && <Typography style={{textAlign: 'center'}} variant="h5">No groups available</Typography>}
        {membersVisible && searchResults.map((group, idx) => {
          return <GroupListBox
                    key={idx} 
                    group={group} 
                    memberList={membersVisible}
                    userDetail={userDetail}
                    handleGroupRequest={handleRequestGroupAccess} />
        })}
      </div>
    </PaperMasterHoc>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    memberList: state.members.memberList,
    membersVisible: state.members.membersVisible,
    searchResults: state.groups.searchArchivedResults,
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleArchivedGroupSearch,
    handleRequestGroupAccess
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(ArchivedGroupListContainer);
