import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import AvatarEditor from '../../components/avatorEditor';
import { handleImageSubmit } from '../../reducers/user'

function AvatarDialog ({open, toggleDialog, profile, handleImageSubmit}) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const handlePhotoSelected = (file) => {
        handleImageSubmit(profile.email, profile.role, file)
        toggleDialog(false)
    }
    
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(false)}
            aria-labelledby="edit-profile-picture"
            fullScreen={fullScreen}
        >
            <DialogTitle id="edit-profile-picture">Edit Profile Picture</DialogTitle>
            <DialogContent>
                <AvatarEditor
                    profileImage={profile && profile.profile_image ? profile.profile_image : ''}
                    handlePhotoSelected={handlePhotoSelected} />
            </DialogContent>
        </Dialog>
    );
}

const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleImageSubmit,
    }, dispatch)
}
export default connect(null, mapDispatch)(AvatarDialog);
  