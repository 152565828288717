import { IconButton, Link } from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClubDialog from '../../components/dialog_club';
import ReferrerDialog from '../../components/dialog_referrer';
import RoleDialog from '../../components/dialog_roles';
import WidgetLoading from '../../components/widgetLoading';
import { handleClearClubDetail, handleClubDetail } from '../../reducers/clubs';
import { handleClearProfile, handleDeleteUser, handleGetMemberProfile, handleReferrerChange, handleUserRoleChange } from '../../reducers/members';
import { handleSetFlagModalEditUserAdmin } from '../../reducers/user'
import { userRoles } from '../../utils/profiles';
import { getUniqueIdEmail } from '../../utils/users';
import MemberProfileDialog from './memberProfileDialog';

const useStyles = makeStyles(theme => ({
  linkButton: {
    textTransform: 'capitalize',
    cursor: 'pointer'
  },
}))

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: '11px'
      },
      root: {
        fontSize: '11px'
      }
    }
  }
})

const MemberTable = ({
  userList,
  fullMemberList,
  isLoading,
  profile,
  emailTemplates,
  clubDetail,
  adminList,
  getClubDetail,
  updateUserRole,
  handleDeleteUser,
  handleReferrerChange,
  clearClub,
  getProfile,
  clearProfile,
  flagModalEditUserAdmin,
  handleSetFlagModalEditUserAdmin }) => {
  const classes = useStyles()
  const [ userForRoleUpdate, toggleRoleDialog ] = React.useState()
  const [ userForReferrerUpdate, toggleReferrerDialog ] = React.useState()
  const [ clubChangeUser, setClubChangeUser ] = React.useState()
  const [ searchValue, setSearchValue ] = React.useState()
  const [ sortName, setSortName ] = React.useState()
  const [ sortDir, setSortDir ] = React.useState()
  const [updatedUserList, setUpdatedUserList] = useState()

  useEffect(() => {
    const newUserList = userList.map(user => {
      if(user.preferred_email){
        return { ...user, email: user.preferred_email }
      }

      return user
    })

    setUpdatedUserList(newUserList)
  }, [userList])

  const openModalEditUser = (role, email) => {
    getProfile({role, email})
    handleSetFlagModalEditUserAdmin(true)
  }

  const closeModalEditUser = () => {
    clearProfile()
    handleSetFlagModalEditUserAdmin(false)
  }

  const columns = [
    { name: "first_name", // 0
      label: "First Name", 
      options: { filter: true, sort: true } },
    { name: "last_name", label: "Last Name", options: { filter: true, sort: true, } },
    { name: "email", label: "Email", options: {  // 2
      filter: true, 
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <div style={{width: '100%'}}>
          <div>{value}</div>
          {tableMeta.rowData[10] && tableMeta.rowData[10].length > 15 && <div><img style={{height: 'auto', width: '120px'}} alt='cover' src={tableMeta.rowData[10]} /></div>}
        </div>
      }
    } },
    { name: "referrer", // 3
      label: "Referrer", 
      options: { 
        filter: true, 
        sort: true, 
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!tableMeta.rowData) { return <div /> }
          const name = tableMeta.rowData[0] + ' ' + tableMeta.rowData[1]
          const email = tableMeta.rowData[2]
          const checkedEmail = getUniqueIdEmail(userList, email)
          const referrer = value ? fullMemberList.find(x => x.username === value) : null
          return <Link 
            className={classes.linkButton}
            onClick={() => toggleReferrerDialog({name, email: checkedEmail, referrer})} 
            color="primary">
              {referrer ? referrer.username : <AddIcon style={{fontSize: 18, marginLeft: 8}} />}
            </Link>
        }
      } 
    },
    { name: "cell_number", label: "Phone", options: { filter: true, sort: true, } },
    { name: "role", // 5
      label: "Role",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!tableMeta.rowData) { return <div /> }
          const email = tableMeta.rowData[2]
          
          const checkedEmail = getUniqueIdEmail(userList, email)

          if (value && value === 'admin') {
            return <span>Admin</span>
          }
          const applied = tableMeta.rowData[11]
          if (value && value === 'guest' && !applied) {
            return <span>Guest</span>
          }
          if (value && value === 'banned') {
            return <span style={{color: 'red'}}>Banned</span>
          }
          let action = 'prospect'
          let display_name = 'prospect'
          if (!value) { value = 'prospect' }
          else if (value === 'member') {
            display_name = 'member'
            action = 'approve_final'
            if (tableMeta.rowData[8]) { display_name = 'Charter Member'; action = 'make_charter_member' }
            if (tableMeta.rowData[9]) { display_name = 'Founding Member'; action = 'make_founding_member'}
          } else if (value === 'applicant') {
            display_name = 'applicant'
            action = 'approve_initial'
          } else if (value === userRoles.memberReview) {
            display_name = 'Member Review'
            action = userRoles.memberReview
          }else {
            action = display_name = value
          }
          return <Link className={classes.linkButton} onClick={() => toggleRoleDialog({email: checkedEmail, action, role: value})} color="primary">{display_name}</Link>
        }
      }
    },
    { name: "primary_club_name", // 6
      label: "Club",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const email = tableMeta.rowData[2]
          
          const checkedEmail = getUniqueIdEmail(userList, email)
          return (
            <Link className={classes.linkButton} onClick={() => {setClubChangeUser({role: tableMeta.rowData[5], email: checkedEmail}); getClubDetail(tableMeta.rowData[7])}}>{value}</Link>
          )
        }
      }
    },
    { name: 'primary_club_id', // 7
      options: { display: 'false', viewColumns: false, filter: false }
    },
    { name: 'charter', // 8
      options: { display: 'false', viewColumns: false, filter: false }
    },
    { name: 'founder', // 9
      options: { display: 'false', viewColumns: false, filter: false }
    },
    { name: 'cover_photo',  // 10
      options: { display: 'false', viewColumns: false, filter: false }
    },
    { name: 'guest_applied',  // 11
      options: { display: 'false', viewColumns: false, filter: false }
    },
    { name: "actions",
      label: " ",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          if (!tableMeta.rowData) { return <div /> }
          const email = tableMeta.rowData[2]
          const role = tableMeta.rowData[5]
          
          const checkedEmail = getUniqueIdEmail(userList, email)

          if (role) {
            return (
            <div style={{width: '100%', textAlign: 'center'}}>
              <IconButton onClick={() => openModalEditUser(role, checkedEmail)}>
                <ViewIcon />
              </IconButton>
            </div>
            )
          }
          return <div />
        }
      }
    },
   ];
   const tableOptions = {
    filterType: 'dropdown',
    selectableRows: 'none',
    filter: false,
    rowHover: false,
    print: false,
    download: false,
    fixedHeader: false,
    searchOpen: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 25, 50],
    responsive: 'simple',
    searchText: searchValue,
    onSearchChange: (text) => {
      setSearchValue(text)
    },
    onColumnSortChange: (col, dir) => {
      setSortName(col)
      setSortDir(dir === 'descending' ? 'desc' : 'asc')
    }
  };
  if (!userList || (isLoading && userList.length === 0)) { return <WidgetLoading /> }
  const updateUserReferrer = (userEmail, referrerEmail) => {
    const user = userEmail ? fullMemberList.find(p => p.email === userEmail) : null
    if (!user) { return }
    const referrer = referrerEmail ? fullMemberList.find(x => x.email === referrerEmail) : null
    handleReferrerChange(userEmail, user.role, referrer.username)
  }
  const sortedColumns = !sortName || !sortDir ? columns : columns.map(col => {
    if (col.options && col.options.sort) {
      let newOptions = {...col.options, sortOrder: sortName === col.name ? sortDir || 'none' : 'none'}
      return {...col, options: {...newOptions}}
    } else {
      return col
    }
  })
  
  return (
    <React.Fragment>
      <ClubDialog
        open={Boolean(clubDetail)}
        toggleDialog={() => { clearClub(null); setClubChangeUser(null) }}
        clubDetail={clubDetail}
        selectedUser={clubChangeUser}
      />
      <RoleDialog
        open={Boolean(userForRoleUpdate)}
        user={userForRoleUpdate}
        adminList={adminList}
        emailTemplates={emailTemplates}
        toggleDialog={toggleRoleDialog}
        handleConfirmation={updateUserRole}
      />
      <ReferrerDialog
        open={Boolean(userForReferrerUpdate)}
        profile={userForReferrerUpdate}
        toggleDialog={toggleReferrerDialog}
        handleConfirmation={updateUserReferrer}
      />
      <MemberProfileDialog
        open={flagModalEditUserAdmin}
        toggleDialog={closeModalEditUser}
        profile={profile}
        handleDeleteUser={handleDeleteUser}
      />
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable 
          title=''
          data={updatedUserList} 
          columns={sortedColumns} 
          options={tableOptions} 
        />
      </MuiThemeProvider>
    </React.Fragment>
  )
}

const mapState = (state) => {
  return {
    profile: state.members.memberProfile,
    clubDetail: state.clubs.clubDetail,
    adminList: (state.members.memberList || []).filter(x => x.role === 'admin').map(item => ({
      value: item.email,
      label: item.first_name + ' ' + item.last_name
    })),
    flagModalEditUserAdmin: state.user.flagModalEditUserAdmin
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getClubDetail: handleClubDetail,
    clearClub: handleClearClubDetail,
    updateUserRole: handleUserRoleChange,
    handleReferrerChange,
    getProfile: handleGetMemberProfile,
    handleDeleteUser: handleDeleteUser,
    clearProfile: handleClearProfile,
    handleSetFlagModalEditUserAdmin
  }, dispatch)
}
export default connect(mapState, mapDispatchToProps)(MemberTable);
