export const LOCAL_CLUBS = 'LOCAL_CLUBS';
export const RESIDENTS = 'RESIDENTS';
export const HEADERS_USERS_CSV = [{ label: "application_date", key: "application_date" },
{ label: "date_joined", key: "date_joined" },
{ label: "role", key: "role" },
{ label: "first_name", key: "first_name" },
{ label: "last_name", key: "last_name" },
{ label: "email", key: "email" },
{ label: "cell_number", key: "cell_number" },
{ label: "venmo_username", key: "venmo_username" },
{ label: "user_whole_address", key: "user_whole_address" },
{ label: "user_address1", key: "user_address1" },
{ label: "user_address2", key: "user_address2" },
{ label: "user_city", key: "user_city" },
{ label: "user_state", key: "user_state" },
{ label: "user_zip", key: "user_zip" },
{ label: "referrer", key: "referrer" },
{ label: "rank", key: "rank" },
{ label: "name", key: "name" },
{ label: "ginn", key: "ginn" },
{ label: "handicap", key: "handicap" },
{ label: "best_gross_round", key: "best_gross_round" },
{ label: "job_title", key: "job_title" },
{ label: "retired", key: "retired" },
{ label: "company_name", key: "company_name" },
{ label: "industry", key: "industry" },
{ label: "company_website", key: "company_website" },
{ label: "social_media_links", key: "social_media_links" },
{ label: "areas_of_interest", key: "areas_of_interest" },
{ label: "about_me", key: "about_me" },
{ label: "bio", key: "bio" },
{ label: "date_of_birth", key: "date_of_birth" },
{ label: "primary_club", key: "primary_club" },
{ label: "email_token", key: "email_token" },
{ label: "rejected", key: "rejected" },
{ label: "email_confirmed", key: "email_confirmed" },
{ label: "member_email", key: "member_email" },
{ label: "favorite_courses", key: "favorite_courses" },
{ label: "other_company_urls", key: "other_company_urls" },
{ label: "privacy_setting", key: "privacy_setting" },
{ label: "charter", key: "charter" },
{ label: "founder", key: "founder" },
{ label: "terms_accepted_date", key: "terms_accepted_date" },
{ label: "profile_image", key: "profile_image" },
{ label: "cover_photo", key: "cover_photo" },
{ label: "additional_club_memberships", key: "additional_club_memberships" },
{ label: "golf_ball_brand", key: "golf_ball_brand" },
{ label: "golf_ball_description", key: "golf_ball_description" },
{ label: "driver_brand", key: "driver_brand" },
{ label: "driver_description", key: "driver_description" },
{ label: "wedge_brand", key: "wedge_brand" },
{ label: "wedge_description", key: "wedge_description" },
{ label: "iron_brand", key: "iron_brand" },
{ label: "iron_description", key: "iron_description" },
{ label: "wood_brand", key: "wood_brand" },
{ label: "wood_description", key: "wood_description" },
{ label: "putter_brand", key: "putter_brand" },
{ label: "putter_description", key: "putter_description" },
{ label: "shirt_brand", key: "shirt_brand" },
{ label: "shirt_description", key: "shirt_description" },
{ label: "shirt_size", key: "shirt_size" },
{ label: "shoe_brand", key: "shoe_brand" },
{ label: "shoe_description", key: "shoe_description" },
{ label: "shoe_size", key: "shoe_size" },
{ label: "glove_brand", key: "glove_brand" },
{ label: "glove_description", key: "glove_description" },
{ label: "glove_size", key: "glove_size" },
{ label: "polo_brand", key: "polo_brand" },
{ label: "polo_description", key: "polo_description" },
{ label: "polo_size", key: "polo_size" },
{ label: "blazer_brand", key: "blazer_brand" },
{ label: "blazer_description", key: "blazer_description" },
{ label: "blazer_size", key: "blazer_size" },
{ label: "coordinates", key: "coordinates" },
{ label: "profile_url", key: "profile_url" },
{ label: "graduate_degree", key: "graduate_degree" },
{ label: "postgraduate_degree", key: "postgraduate_degree" },
{ label: "badge_list", key: "badge_list" },
{ label: "courses_played", key: "courses_played" },
{ label: "course_wishlist", key: "course_wishlist" },
{ label: "guest_applied", key: "guest_applied" },
{ label: "username", key: "username" },
{ label: "preferred_email", key: "preferred_email" },
{ label: "id", key: "id" },
{ label: "relationship_type", key: "relationship_type" },
{ label: "club_id", key: "club_id" },
{ label: "share", key: "share" },
{ label: "membership", key: "membership" },
{ label: "number_of_holes", key: "number_of_holes" },
{ label: "phone", key: "phone" },
{ label: "fax", key: "fax" },
{ label: "website", key: "website" },
{ label: "location", key: "location" },
{ label: "contact_name", key: "contact_name" },
{ label: "contact_title", key: "contact_title" },
{ label: "email_address", key: "email_address" },
{ label: "driving_range", key: "driving_range" },
{ label: "putting_green", key: "putting_green" },
{ label: "chipping_green", key: "chipping_green" },
{ label: "practice_bunker", key: "practice_bunker" },
{ label: "motor_cart", key: "motor_cart" },
{ label: "pull_cart", key: "pull_cart" },
{ label: "golf_clubs_rental", key: "golf_clubs_rental" },
{ label: "club_fitting", key: "club_fitting" },
{ label: "pro_shop", key: "pro_shop" },
{ label: "golf_lessons", key: "golf_lessons" },
{ label: "caddie_hire", key: "caddie_hire" },
{ label: "restaurant", key: "restaurant" },
{ label: "reception_hall", key: "reception_hall" },
{ label: "changing_room", key: "changing_room" },
{ label: "lockers", key: "lockers" },
{ label: "lodging_on_site", key: "lodging_on_site" },
{ label: "logo_url", key: "logo_url" },
{ label: "description", key: "description" },
{ label: "club_logo", key: "club_logo" }]
