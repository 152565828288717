
import { parser } from '../utils/parser'
 
export const types = {
  REFERENCES_GET_REQUEST: 'REFERENCES/GET_REQUEST',
  REFERENCES_GET_SUCCESS: 'REFERENCES/GET_SUCCESS',
  REFERENCES_GET_FAILURE: 'REFERENCES/GET_FAILURE',
  REFERENCES_UPDATE_REQUEST: 'REFERENCES/UPDATE_REQUEST',
  REFERENCES_UPDATE_SUCCESS: 'REFERENCES/UPDATE_SUCCESS',
  REFERENCES_UPDATE_FAILURE: 'REFERENCES/UPDATE_FAILURE',
  SCHOOLS_GET_REQUEST: 'SCHOOLS/GET_REQUEST',
  SCHOOLS_GET_SUCCESS: 'SCHOOLS/GET_SUCCESS',
  SCHOOLS_GET_FAILURE: 'SCHOOLS/GET_FAILURE',
  CLOSE_BANNER: 'CLOSE/BANNER'
}

export const initialState = {
  areas_of_interest: [],
  glove_brands: [],
  golf_ball_brands: [],
  golf_club_brands: [],
  golf_putter_brands: [],
  introduction_template: '',
  mens_glove_sizes: [],
  mens_shirt_sizes: [],
  mens_shoe_sizes: [],
  privacy_descriptions: { full: '', basic: '', anonymous: '' },
  schools: [],
  shirt_brands: [],
  shoe_brands: [],
  blazer_brands: [],
  blazer_sizes: [],
  error: null,
  isLoading: false,
  code_of_conduct_link: '',
  sponsors_link: '',
  onboarding_intro_description: '',
  home_screen_message: '',
  banner_open: true
}

export const handleSchoolSearch = (search, skip) => (dispatch) => {
  let params = {}
  if (search) { params.search = search }
  if (skip) { params.skip = skip }
  dispatch({
    types: [types.SCHOOLS_GET_REQUEST, types.SCHOOLS_GET_SUCCESS, types.SCHOOLS_GET_FAILURE],
    url: '/schools',
    query: params
  })
}

export const handleCloseBanner = () => (dispatch) => {
  dispatch({
    type: types.CLOSE_BANNER,
    payload: null
  })
}

export const handleGetReferences = (search) => async (dispatch) => {
  let params = {}
  if (search) { params.search = search }
  dispatch({
    types: [types.REFERENCES_GET_REQUEST, types.REFERENCES_GET_SUCCESS, types.REFERENCES_GET_FAILURE],
    url: '/references',
    query: params
  })
}
export const handleUpdateReferences = (id, newValue) => dispatch => {
  if (!id || !newValue) { return }
  dispatch({
    types: [types.REFERENCES_UPDATE_REQUEST, types.REFERENCES_UPDATE_SUCCESS, types.REFERENCES_UPDATE_FAILURE],
    url: `/references/${id}`,
    method: 'put',
    data: { data: newValue },
    callback: handleGetReferences()
  })
}
const flattenReferences = (data) => {
  let obj = {}
  data.forEach(item => {
    obj[item.id] = item.data
  })
  return obj
}
const getMixedSizes = (mens, womens) => {
  let res = [].concat(mens)
  res.push(parser.list_delimiter)
  return res.concat(womens)
}
export default function (state = initialState, action) {
  switch (action.type) {
    case types.REFERENCES_GET_SUCCESS:
      const refs = flattenReferences(parser.sortBy(action.data, { prop: 'id'}))
      return {
        ...state,
        isLoading: false,
        raw_data: refs,
        areas_of_interest: refs.areas_of_interest || [],
        golf_ball_brands: (refs.golf_ball_brands || []),
        golf_club_brands: (refs.golf_club_brands || []),
        golf_putter_brands: (refs.golf_putter_brands || []),
        introduction_template: (refs.introduction_template || ''),
        code_of_conduct_link: (refs.code_of_conduct_link || ''),
        sponsors_link: (refs.sponsors_link || ''),
        home_screen_message: (refs.home_screen_message || ''),
        onboarding_intro_description: (refs.onboarding_intro_description || ''),
        shirt_brands: (refs.shirt_brands || []),
        mens_shirt_sizes: getMixedSizes(refs.mens_shirt_sizes || [], refs.womens_shirt_sizes || []),
        glove_brands: (refs.glove_brands || []),
        mens_glove_sizes: getMixedSizes(refs.mens_glove_sizes || [], refs.womens_glove_sizes || []),
        shoe_brands: (refs.shoe_brands || []),
        mens_shoe_sizes: getMixedSizes(refs.mens_shoe_sizes || [], refs.womens_shoe_sizes || []),
        blazer_brands: (refs.clothing_brands || []),
        blazer_sizes: refs.blazer_sizes,
        privacy_descriptions: {
          full: refs.privacy_descriptions_full,
          basic: refs.privacy_descriptions_basic,
          anonymous: refs.privacy_descriptions_anonymous
        }
      }
    case types.SCHOOLS_GET_SUCCESS:
        return { ...state, isLoading: false, schools: action.data }
    case types.REFERENCES_UPDATE_SUCCESS:
        return { ...state, isLoading: false }
    case types.REFERENCES_GET_FAILURE:
    case types.SCHOOLS_GET_FAILURE:
    case types.REFERENCES_UPDATE_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    case types.REFERENCES_GET_REQUEST:
    case types.SCHOOLS_GET_REQUEST:
    case types.REFERENCES_UPDATE_REQUEST:
      return { ...state, isLoading: true }
    case types.CLOSE_BANNER:
        return { ...state, banner_open: false }
    default:
      return state
  }
}
