import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import RankingsWidget from './rankingsWidget';

import ApplicantHome from './applicantHome';
import { handleGetAllPosts } from '../../reducers/posts';
import MemberHome from './memberHome';
import { isApplicantOrMemberReview } from '../../utils/profiles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    overflow: 'auto',
  },
  bannerContainer: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  leftSide: {
    paddingLeft: theme.spacing(2),
  },
  rightSide: {
    paddingRight: theme.spacing(2),
  }
}));

function isValidUser(user){
  return !!user;
}

const Home = ({ userDetail, handleGetAllPosts }) => {
  const classes = useStyles();
  
  React.useEffect(() => {
    if(!isApplicantOrMemberReview(userDetail)){
      handleGetAllPosts()
    }
  }, [handleGetAllPosts, userDetail])

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [userDetail]);
  
  if (isValidUser(userDetail) && isApplicantOrMemberReview(userDetail)) {
    return <ApplicantHome />;
  }

  return (
    <MemberHome classes={classes} userDetail={userDetail} />
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetAllPosts,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(Home);
