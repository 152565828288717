import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import MemberSelector from './memberSelector';

// const useStyles = makeStyles(theme => ({
// }));

function ReferrerDialog({open, profile, toggleDialog, handleConfirmation}) {
    const [newItem, setNewItem] = React.useState()
    React.useEffect(() => {
        if (profile && profile.referrer) {
            setNewItem({
                value: profile.referrer.email,
                label: profile.referrer.first_name + ' ' + profile.referrer.last_name,
                logo: profile.referrer.profile_image
            })
        }
        return () => {
            setNewItem(null)
        }
    }, [profile]);
    const handleListChangeEvent = (item) => {
        setNewItem(item)
      }
    const handleConfirm = () => {
        handleConfirmation(profile.email, newItem ? newItem.value : null)
        toggleDialog(null)
    }
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(null)}
            maxWidth="md"
        >
            <DialogTitle>Update Referrer {profile ? 'for ' + profile.name : ''}</DialogTitle>
            <DialogContent>
                <div style={{minWidth: 400, minHeight: 400}}>
                    <MemberSelector
                        isSingle
                        groupMembers={[newItem || '']}
                        listChangeEvent={handleListChangeEvent}
                        excludeEmails={profile ? [profile.email] : []}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleConfirm}>Save</Button>
                <Button variant="outlined" onClick={() => toggleDialog(null)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
    }
  }
  
  export default connect(mapStateToProps)(ReferrerDialog)
