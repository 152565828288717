import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import WidgetContainer from '../../components/widgetContainer';
import WidgetElement from '../../components/widgetElement';
import {
  handleSelectPostType,
  handleShowDialogPost,
} from '../../reducers/posts';
import { parser } from '../../utils/parser';

const TravelWidget = ({
  travelPosts,
  handleSelectPostType,
  handleShowDialogPost,
}) => {
  return (
    <WidgetContainer
      header='My Travel'
      action={() => {
        handleSelectPostType('travel');
        window.scroll(
          0,
          document.getElementById('feed-form-container').offsetTop
        );
      }}
      buttonLabel='+ Travel'
    >
      {travelPosts.length === 0 && (
        <Typography>
          Let other members know when and where you're planning a trip.
        </Typography>
      )}
      {travelPosts &&
        travelPosts.map((trip, idx) => (
          <div key={idx}>
            <WidgetElement noClick>
              <div style={{ flexWrap: 'nowrap' }}>
                <Typography variant='subtitle1' style={{ fontSize: 14 }}>
                  {trip.destination_city}, {trip.destination_state}
                </Typography>
                <Typography variant='subtitle1' style={{ fontSize: 14 }}>
                  {parser.shortDate(trip.arrival_date, 'MM/dd')} to{' '}
                  {parser.shortDate(trip.departure_date, 'MM/dd')}
                </Typography>
              </div>
              <Button
                onClick={() => handleShowDialogPost(trip.post_id)}
                size='small'
                variant='contained'
                style={{ marginLeft: 'auto', marginTop: 4 }}
              >
                View
              </Button>
            </WidgetElement>
          </div>
        ))}
    </WidgetContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    travelPosts: parser.sortByDate(
      state.posts.travelPosts.filter(x => x.member_email === state.user.userDetail.email),
      { prop: 'arrival_date'}
    ),
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      handleSelectPostType,
      handleShowDialogPost,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatch)(TravelWidget));
