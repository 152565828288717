import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import cls from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutoSelect from '../../components/auto_select';
import { handleSubmitTravel, handleUpdatePost } from '../../reducers/posts';
import { handleGetMembersByState } from '../../reducers/clubs';
import { parser } from '../../utils/parser';
import TravelAudience from '../dashboard/travelAudience';
import { handleGetUserDetail } from '../../reducers/user';
import CodeOfConductAlert from '../../components/codeOfConductAlert';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 16,
    backgroundColor: '#FFF',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  textField: {
    minWidth: 150,
  },
  multiLineTextBox: {
    margin: '8px 0',
    '& textarea': {
      fontFamily: theme.typography.secondaryFont,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 14px',
    },
  },
  button: {
    width: '100%',
  },
  postButton: {
    maxWidth: 300,
    width: '100%',
    margin: 'auto',
  },
  dateField: {
    marginRight: 8,
    '&.not-selected': {
      '& input': {
        color: '#FFF',
      },
    },
  },
}));

const TravelForm = ({
  post,
  closeEdit,
  userDetail,
  handleSubmitTravel,
  handleUpdatePost,
  handleGetMembersByState,
  handleGetUserDetail
}) => {
  const classes = useStyles();
  const [arrivalDate, setArrivalDate] = React.useState(); // new Date(new Date().setDate(new Date().getDate() + 1)).setHours(9, 0, 0)
  const [departureDate, setDepartureDate] = React.useState(); // new Date(new Date().setDate(new Date().getDate() + 1)).setHours(9, 0, 0)
  const [tripDescription, setTripDescription] = React.useState('');
  const [destCity, setDestCity] = React.useState('');
  const [destState, setDestState] = React.useState('');
  const [audience, setAudience] = React.useState([]);

  React.useEffect(() => {
    handleGetUserDetail({ role: '', email: post.member_email })
  }, [post.member_email, handleGetUserDetail])

  React.useEffect(() => {
    if (post && post.travel && post.travel.departure_date) {
      const travelPost = post.travel;
      setDestCity(travelPost.destination_city);
      setDestState(travelPost.destination_state);
      setArrivalDate(new Date(travelPost.arrival_date));
      setDepartureDate(new Date(travelPost.departure_date));
      setTripDescription(travelPost.description);
      setAudience(
        travelPost.audience
          ? (travelPost.audience || [])
              .filter((x) => x.member_email !== userDetail?.email)
              .map((player) => ({
                value: player.member_email,
                label: player.first_name + ' ' + player.last_name,
                logo: player.profile_image,
              }))
          : []
      );
    }
  }, [post, userDetail]);
  const setSelectedAddressState = (value) => {
    if (!value || value.label) {
      return value || '';
    }
    const stateList = parser.getStateList();
    const idx = stateList
      .map(function (e) {
        return e.value;
      })
      .indexOf(value);
    if (idx >= 0) {
      return stateList[idx] || '';
    }
    return '';
  };
  const onChangeDate = (dt, fn) => {
    fn(new Date(new Date(dt).setHours(9, 0, 0)));
  };
  const handleStateChange = (name) => (item) => {
    if (item && item.value) handleGetMembersByState(item.value);
    setDestState(item);
  };
  const submitPost = () => {
    const peeps = audience.map((x) => x.value).concat([userDetail?.email]);
    const travelPost = {
      destination_city: (destCity || '').trim(),
      destination_state:
        destState && destState.value ? destState.value : destState,
      arrival_date: arrivalDate,
      departure_date: departureDate,
      description: (tripDescription || '').trim(),
      audience: peeps,
    };
    if (post && post.id && closeEdit) {
      const newPost = { ...post, travel: travelPost };
      handleUpdatePost(newPost, null, true);
      closeEdit();
    } else {
      const userData = {
        member_email: userDetail?.email,
        username: userDetail?.username,
        first_name: userDetail?.first_name,
        last_name: userDetail?.last_name,
        profile_image: userDetail?.profile_image,
        privacy_setting: userDetail?.privacy_setting
      }

      handleSubmitTravel(travelPost, userData);
    }
    setDestCity('');
    setDestState('');
    setArrivalDate(null);
    setDepartureDate(null);
    setTripDescription('');
    setAudience([]);
  };
  return (
    <Paper className={classes.main}>
      <Typography
        component='p'
        variant='subtitle1'
        style={{ marginBottom: 12 }}
      >
        {post && post.id
          ? 'Update your travel'
          : 'Meet up. Play Golf. Let members know you will be traveling.'}
      </Typography>
      <Typography component='p' style={{ marginBottom: 12 }}>
        Where are you going?
      </Typography>
      <Grid container spacing={1}>
        <Grid item sm={4} xs={12}>
          <TextField
            id='destCity'
            name='destCity'
            label='City'
            fullWidth
            margin='none'
            variant='outlined'
            value={destCity || ''}
            onChange={(event) => setDestCity(event.target.value)}
            style={{ marginBottom: 4 }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControl fullWidth style={{ marginBottom: 4 }}>
            <AutoSelect
              name='state'
              options={parser.getStateList()}
              textFieldProps={{
                label: 'State',
              }}
              selectedValue={setSelectedAddressState(destState) || ''}
              handleAutoSelectChange={handleStateChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Typography component='p' style={{ marginTop: 12 }}>
        When are you going?
      </Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify='flex-start'>
          <DatePicker
            disablePast
            margin='normal'
            label='Arrival Date'
            className={cls(classes.dateField, { 'not-selected': !arrivalDate })}
            value={arrivalDate}
            onChange={(dt) => onChangeDate(dt, setArrivalDate)}
          />
          <DatePicker
            disablePast
            margin='normal'
            label='Departure Date'
            className={cls(classes.dateField, {
              'not-selected': !departureDate,
            })}
            value={departureDate}
            onChange={(dt) => onChangeDate(dt, setDepartureDate)}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <TextField
        id='description'
        name='description'
        label='Comments/Description'
        fullWidth
        margin='normal'
        variant='outlined'
        value={tripDescription}
        onChange={(event) => setTripDescription(event.target.value)}
        className={classes.multiLineTextBox}
        multiline
        rows='3'
        placeholder=''
        InputLabelProps={{ shrink: true }}
      />
      <TravelAudience
        destinationState={destState}
        labelText={'Who should we notify about your travel?'}
        attendees={audience}
        handleSelectedMembers={setAudience}
      />
      <CodeOfConductAlert />
      <div style={{ margin: '12px 0 4px' }}>
        {closeEdit ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={
                !destCity ||
                !destState ||
                !arrivalDate ||
                !departureDate ||
                audience.length === 0
              }
              variant='contained'
              onClick={() => submitPost()}
              style={{ marginRight: 8 }}
            >
              Save
            </Button>
            <Button variant='outlined' onClick={() => closeEdit()}>
              Cancel
            </Button>
          </div>
        ) : (
          <div className={classes.postButton}>
            <Button
              disabled={
                !destCity ||
                !destState ||
                !arrivalDate ||
                !departureDate ||
                audience.length === 0
              }
              onClick={() => submitPost()}
              className={classes.button}
              variant='contained'
            >
              Post
            </Button>
          </div>
        )}
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => {  
  return {
    userDetail: state.user.userDetail
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      handleSubmitTravel,
      handleUpdatePost,
      handleGetMembersByState,
      handleGetUserDetail
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatch)(TravelForm);
