
export default {
    control: {
      // backgroundColor: '#fff',
      color: 'inherit',
      fontSize: 14,
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      letterSpacing: 'normal',
      lineHeight: 'normal'
    },
  
    highlighter: {
      overflow: 'hidden',
    },
  
    input: {
      margin: 0,
      fontWeight: 'normal',
      letterSpacing: 'normal',
      lineHeight: 'normal'
    },
  
    '&singleLine': {
      control: {
        display: 'inline-block',
        // width: 130,
      },
  
      highlighter: {
        padding: 1,
      },
  
      input: {
        padding: 1,
      },
    },
  
    '&multiLine': {
      control: {
        // fontFamily: 'Roboto',
        // border: '1px solid silver',
      },
  
      highlighter: {
        padding: 8,
        paddingBottom: 16,
      },
  
      input: {
        padding: 8,
        paddingBottom: 16,
        // minHeight: 63,
        outline: 0,
        border: 0,
      },
    },
  
    suggestions: {
      list: {
        maxHeight: 240,
        overflowY: 'auto',
        backgroundColor: '#fff',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        position: 'absolute',
        whiteSpace: 'nowrap',
        zIndex: 9999
      },
  
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
  
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  }