import { disconnect } from '@giantmachines/redux-websocket'
// import { snackbarActions as snackbar } from 'material-ui-snackbar-redux'
import { sessionSaver } from '../utils/sessionSaver'

export const types = {
  FORGOT_PWD_REQUEST: 'AUTH/FORGOT_PWD_REQUEST',
  FORGOT_PWD_SUCCESS: 'AUTH/FORGOT_PWD_SUCCESS',
  FORGOT_PWD_FAILURE: 'AUTH/FORGOT_PWD_FAILURE',
  PWD_RESET_REQUEST: 'AUTH/PWD_RESET_REQUEST',
  PWD_RESET_SUCCESS: 'AUTH/PWD_RESET_SUCCESS',
  PWD_RESET_FAILURE: 'AUTH/PWD_RESET_FAILURE',
  APPLY_REQUEST: 'AUTH/APPLY_REQUEST',
  APPLY_SUCCESS: 'AUTH/APPLY_SUCCESS',
  APPLY_FAILURE: 'AUTH/APPLY_FAILURE',
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH/LOGIN_FAILURE',
  LOGOUT: 'AUTH/LOGOUT',
  REMEMBER_ME: 'AUTH/REMEMBER_ME',
  CLEAR_ERRORS: 'AUTH/CLEAR_ERRORS',
  REFERRER_REQUEST: 'AUTH/REFERRER_REQUEST',
  REFERRER_SUCCESS: 'AUTH/REFERRER_SUCCESS',
  REFERRER_FAILURE: 'AUTH/REFERRER_FAILURE',
  LOGIN_TESTING: 'AUTH/LOGIN_TESTING',
  LOGIN_VERIFIED: 'AUTH/LOGIN_VERIFIED',
  LOGIN_VERIFY_FAILED: 'AUTH/LOGIN_VERIFY_FAILED',
  PASSWORD_EMAIL_SENT: 'AUTH/PASSWORD_EMAIL_SENT',
  RESET_PASSWORD_SENT: 'AUTH/RESET_PASSWORD_SENT',
  GET_PASSWORD_RESET_URL_REQUEST:  'AUTH/GET_PASSWORD_RESET_URL_REQUEST',
  GET_PASSWORD_RESET_URL_SUCCESS:  'AUTH/GET_PASSWORD_RESET_URL_SUCCESS',
  GET_PASSWORD_RESET_URL_FAILURE:  'AUTH/GET_PASSWORD_RESET_URL_FAILURE'
}

export const initialState = {
  applied: false,
  error: null,
  isLoading: false,
  rememberMe: true,
  sendReset: false,
  passwordReset: false,
  passwordResetUrl: '',
  referrer: null,
  loginTesting: false,
  verifyLoginFailed: false,
  session: sessionSaver.getSessionAuthentication()
}

export const handleApply = application => dispatch => {
  dispatch({
    types: [types.APPLY_REQUEST, types.APPLY_SUCCESS, types.APPLY_FAILURE],
    url: '/membership_applications/initial',
    method: 'post',
    data: application
  })
}
export const handleLogin = (email, password, token) => (dispatch) => {
  const params = { email, password }
  if (token) {
    params.registration_token = token
  }
  dispatch({
    types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
    url: '/login/local',
    method: 'post',
    data: params
  })
}
export const handleForgotPassword = (email, isAdmin) => dispatch => {
  dispatch({
    types: [types.FORGOT_PWD_REQUEST, types.FORGOT_PWD_SUCCESS, types.FORGOT_PWD_FAILURE],
    url: '/login/local/reset/' + email,
    method: 'post',
    callback: !isAdmin ? handlePasswordEmailSent() : null
  })
}
export const handleGetPasswordResetUrl = (email) => dispatch => {
  dispatch({
    types: [types.GET_PASSWORD_RESET_URL_REQUEST, types.GET_PASSWORD_RESET_URL_SUCCESS, types.GET_PASSWORD_RESET_URL_FAILURE],
    url: '/login/local/reset/' + email,
    method: 'get'
  })
}
export const handlePasswordEmailSent = () => dispatch => {
  dispatch({ type: types.LOGOUT })
  // dispatch(snackbar.show({
  //   message: 'Thank you for requesting a password reset. An email has been sent to your email address for this account.',
  //   action: 'OK',
  //   handleAction: () => {}
  // }))
}
// export const handlePasswordChange_OLD_FUNCTION = (email, oldPassword, newPassword, token) => dispatch => {
//   dispatch({
//     types: [types.LOGIN_TESTING, types.LOGIN_VERIFIED, types.LOGIN_VERIFY_FAILED],
//     url: '/login/local',
//     method: 'post',
//     data: { email, password: oldPassword },
//     blockLogout: true,
//     callback: handlePasswordReset(email, newPassword, token)
//   })
// }
export const handlePasswordReset = (email, password, token) => dispatch => {
  dispatch({
    types: [types.PWD_RESET_REQUEST, types.PWD_RESET_SUCCESS, types.PWD_RESET_FAILURE],
    url: '/login/local/reset/' + email,
    method: 'put',
    data: { reset_token: token, new_password: password }
  })
}
export const handleGetReferrer = (referrer) => dispatch => {
  if (referrer === 'home') { return }
  dispatch({
    types: [types.REFERRER_REQUEST, types.REFERRER_SUCCESS, types.REFERRER_FAILURE],
    url: '/referrers/' + referrer
  })
}
export const handleRememberMe = (remember) => {
  return { type: types.REMEMBER_ME, data: remember }
}
export const handleLogout = () => (dispatch, getState) => {
  const oldState = getState()
  if (oldState && oldState.auth && oldState.auth.session && oldState.auth.session.role !== 'applicant') {
    dispatch(disconnect())
  }
  dispatch({ type: types.LOGOUT }) // Also handled in reducers index
}
export const resetPasswordSent = () => {
  return { type: types.RESET_PASSWORD_SENT }
}
export const clearAuthErrors = () => {
  return { type: types.CLEAR_ERRORS }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FORGOT_PWD_REQUEST:
    case types.APPLY_REQUEST:
    case types.LOGIN_REQUEST:
    case types.PWD_RESET_REQUEST:
    case types.REFERRER_REQUEST:
      return { ...state, isLoading: true, error: null }
    case types.LOGIN_TESTING:
      return { ...state, verifyLoginFailed: false, passwordReset: false, loginTesting: true }
    case types.LOGIN_VERIFY_FAILED:
      return { ...state, verifyLoginFailed: true, loginTesting: false }
    case types.LOGIN_VERIFIED:
      return { ...state, verifyLoginFailed: false, loginTesting: false }  
    case types.APPLY_SUCCESS:
      return { ...state, isLoading: false, applied: true }
    case types.FORGOT_PWD_SUCCESS:
      return { ...state, isLoading: false, sendReset: true }
    case types.LOGIN_SUCCESS:
      sessionSaver.setSessionAuthentication(action.data, state.rememberMe)
      return { ...state, isLoading: false, session: action.data }
    case types.PWD_RESET_SUCCESS:
      sessionSaver.setSessionAuthentication(action.data)
      return { ...state, isLoading: false, passwordReset: true, session: action.data }
    case types.GET_PASSWORD_RESET_URL_SUCCESS:
      return { ...state, passwordResetUrl: action.data.url || ''}
    case types.REFERRER_SUCCESS:
        return { ...state, isLoading: false, referrer: action.data }
    case types.REMEMBER_ME:
      return { ...state, rememberMe: action.data }
    case types.APPLY_FAILURE:
    case types.LOGIN_FAILURE:
    case types.FORGOT_PWD_FAILURE:
    case types.PWD_RESET_FAILURE:
      sessionSaver.logout()
      return { ...state, isLoading: false, error: action.error }
    case types.REFERRER_FAILURE:
    case types.CLEAR_ERRORS:
    case types.LOGOUT:
      sessionSaver.logout()
      return { ...state, ...initialState, session: null }    
    case types.RESET_PASSWORD_SENT:
      return { ...state, passwordReset: false, passwordResetUrl: '', sendReset: false }    
    default:
      return state
  }
}
