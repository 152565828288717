import React from 'react';
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit'
import GroupFormDialog from './groupFormDialog';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    margin: `${theme.spacing(1)}px 0`,
    padding: theme.spacing(1),
    border: `solid 1px #ccc`,
    borderRadius: theme.spacing(1),
  },
  groupTitle: {
    color: theme.palette.primary.main,
    margin: `${theme.spacing(1)} 0`,
  },
}))

const GroupHeader = ({ userDetail, group }) => {
  const classes = useStyles()
  const [editGroup, setEditGroup] = React.useState(false)
  return (
    <div className={classes.wrapper}>
      <div>
        <Typography variant="h3" className={classes.groupTitle}>{group.name}{group?.archived ? ' (Archived)' : ''}</Typography>
        <Typography variant="subtitle1" className={classes.groupTitle}>{group.description}</Typography>
      </div>
      {group.group_owner && group.group_owner === userDetail.email && <div style={{marginLeft: 'auto', alignItems: 'flex-start'}}>
        <IconButton onClick={() => setEditGroup(true)}><EditIcon /></IconButton>
      </div>}
      <GroupFormDialog open={editGroup} toggleDialog={(val) => setEditGroup(val)} group={group} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
  }
}
// const mapDispatch = (dispatch) => {
//   return bindActionCreators({
//     handleGetGroupDetail,
//     handleClearError,
//   }, dispatch)
// }

export default connect(mapStateToProps)(GroupHeader)
