import { CircularProgress, Link, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleGetMemberProfile } from '../../reducers/members';
import { paths } from '../../utils/paths';
import ProfileFooter from './footer_menu';
import ProfileView from './profileView';

function ProfileContainer({ history, getMemberProfile, match, userDetail, memberProfile, memberError }) {
  const [profileToView, setProfileToView] = React.useState()
  React.useEffect(() => {
    if (match.params.slug) {
      if (match.params.slug === userDetail.username) {
        history.push(paths.profile)
        return
      }
      
      if (memberProfile && memberProfile.email && (match.params.slug === memberProfile.username || match.params.slug === memberProfile.email)) {
        if (userDetail && userDetail.role && userDetail.role === 'guest' && userDetail.referrer !== memberProfile.username) {
          history.push(paths.root)
          return
        }
        return setProfileToView(memberProfile)
      } else {
        getMemberProfile({email: match.params.slug})
        // setTimeout(() => { getMemberProfile({role: 'member', email: match.params.slug})}, 500)
        return
      }
    }
    setProfileToView(userDetail)
  }, [match.params.slug, memberProfile, getMemberProfile, userDetail, history]);

  return (
    <React.Fragment>
      <div>
        {profileToView ? <ProfileView profile={profileToView} />
        : <div style={{width: '100%', textAlign: 'center', padding: '200px 0', minHeight: '80vh'}}>
          {memberError
            ? <React.Fragment>
                <Typography variant="h3">{memberError}</Typography>
                <Typography component="div" variant="body2" align="center" className='subtext' gutterBottom>
                  <p>
                    <Link style={{color: "#2783D4", cursor: 'pointer'}} href="http://captainsclub.golf/events">Event Registration</Link>
                  </p>
                  <p>
                    <Link style={{color: "#2783D4", cursor: 'pointer'}} onClick={() => history.push(paths.profile)}>View/Edit your Captain's Club profile</Link>
                  </p>
                </Typography>
              </React.Fragment>
            : <CircularProgress size={96} />
          }
        </div>
        }
        <ProfileFooter />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    memberProfile: state.members.memberProfile,
    memberError: state.members.error
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    getMemberProfile: handleGetMemberProfile
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)((ProfileContainer))
