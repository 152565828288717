import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cls from 'classnames';
import React from 'react';
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';


const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.textOnPrimary?.main,
    padding: theme.spacing(2),
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  secondary: {
    color: theme.palette.secondary.dark
  },
  avatar: {
    marginBottom: theme.spacing(1),
    borderRadius: '50px',
    width: '60px',
    height: '60px',
  },
  heading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 26
    },
  },
  buttonContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
  },
  button: {
    // backgroundColor: theme.palette.primary.dark,
    // color: theme.palette.textOnPrimary.main,
    // fontSize: '14px',
    // fontWeight: 300,
    // borderRadius: 20,
    // border: 'solid 1px #FFF',
    margin: '8px 8px 8px 0',
    justifyContent: 'space-around',
    width: '168px',
    '&.selected': {
      backgroundColor: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('xs')]: {
      margin: '8px auto',
      width: '100%'
    },
  }
}))

const DashboardBanner = ({ userDetail, buttonTypeList, selectedType, handleButtonClick }) => {
  const classes = useStyles()
  return (
      <Paper elevation={0} className={classes.main}>
        {userDetail && userDetail.role === 'member' &&
          <div className={classes.buttonContainer}>
            {buttonTypeList.map((item) => (
              <Button
                key={item.id} 
                id={item.id} 
                onClick={() => handleButtonClick(item.id)} 
                className={cls(classes.button, {'selected': selectedType === item.id})} 
                variant="contained"
              >{item.label}</Button>
            ))}
          </div>
        }
      </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
  }
}


export default connect(mapStateToProps)(DashboardBanner)
