import { Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import AutoSelect from '../../components/auto_select';
import ReferralLink from '../../components/referralLink';
import UserAvatar from "../../components/userAvatar";
import { handleSchoolSearch } from '../../reducers/references';
import { handleUpdateTemporaryUser, handleUpdateUser, handleCheckEmailUser } from '../../reducers/user';
import { parser } from '../../utils/parser';
import { paths } from "../../utils/paths";
import SectionTitle from './sectionTitle';
import ButtonEditEmail from "../../components/buttonEditEmail";
import MomentUTCUtils from "../../utils/MomentUTCUtils";
import VenmoLink from '../../components/venmoLink';
import { formatForAutoSelectAreasOfInterest } from '../../utils/profiles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#FFFFFF",
        color: theme.palette.profile.main,
        borderRadius: 8,
        padding: '8px 0',
        [theme.breakpoints.down('sm')]: {
          borderRadius: 0,
          padding: 8
        },
      },
    section: {
        padding: 20,
        paddingBottom: 0,
        borderBottom: "2px solid rgb(212, 219, 232)",
        [theme.breakpoints.down('md')]: {
            borderBottom: "1px solid rgb(232, 232, 232)",
        }, 
    },
    sectionHeading: {
        fontSize: 16,
        fontWeight:"bold",
    },
    itemGroup: {
    },
    item: {
        paddingBottom: 20,
        alignItems: 'center'
    },
    itemHeading: {
        fontWeight:"normal",
        color: theme.palette.profile.light
    },
    itemText: {
        fontWeight:"normal",
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }, 
    },
    multiLineTextBox: {
        '& textarea': {
          fontFamily: theme.typography.secondaryFont
        }
    },
    memberContent: {
        display: 'flex',
        width: '50%',
        minWidth: '50%',
        alignItems: 'center',
        cursor: 'pointer',
    },
    memberNameRow: {
        display: 'flex',
        
    },
    memberName: {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline'
        }
    },
}))

const BioSection = ({title, children}) => {
    const classes = useStyles()
    return (
        <div className={classes.section}>
            <Grid spacing={1} container>
                <Grid item xs={12} md={3}>
                    <Typography component='p' className={classes.sectionHeading}>{title}</Typography>
                </Grid>
                <Grid align="left" item xs={12} md={9}>
                    {children}
                </Grid>
            </Grid>
        </div>
    )
}
const BioItem = ({title, itemStyle, headingStyle, children}) => {
    const classes = useStyles()
    return (
        <Grid spacing={1} container className={classes.item} style={itemStyle || {}}>
            <Grid item xs={12} md={3}>
                <Typography component='p' className={classes.itemHeading} style={headingStyle || {}}>{title}</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography component='div' className={classes.itemText}>
                    {children}
                </Typography>
            </Grid>
        </Grid>
    )
}
// const PlaceholderText = (props) => (
//     <span style={{fontStyle: 'italic', fontWeight: 'normal', color:"#9CADBA"}}>{props.children}</span>
// )
const BioCareer = ({ history, session, profile, readonly, areasOfInterest, memberList, schools, handleSchoolSearch, updateProfile, updateTemporaryProfile, toggleEdit }) => {
    const classes = useStyles()
    const [editing, setEditing] = React.useState(false)
    const [bio, setBio] = React.useState()
    const [referrer, setReferrer] = React.useState()

    const initBio = (profile) => {
        let temp = {...profile}
        if (profile && profile.date_of_birth) {
            temp.date_of_birth = profile.date_of_birth
        }
        setBio(temp)
    }

    React.useEffect(() => {
        initBio(profile)
    }, [profile]);

    React.useEffect(() => {
        if (profile && profile.referrer && memberList) {
            const person = memberList.find(x => x.username === profile.referrer)
            setReferrer(person)
        } else {
            setReferrer(undefined)
        }
    }, [profile, memberList]);
    React.useEffect(() => {
        setEditing(!readonly)
    }, [readonly]);

    const handleNumericChange = (event) => {
        if (event.target.value && !parser.isNumeric(event.target.value)) { return }
        let num = !event.target.value ? '' : parseInt(event.target.value)
        if (num.toString().length > 3) { return}
        handleChange({target: {name: event.target.name, value: num}})
    }
    const handleRetiredChecked = (event) => {
        let temp = {...bio}
        temp['retired'] = event.target.checked
        updateProfile(normalizeValues(temp))
    }
    const handleDateChange = (date) => {
        let temp = {...bio}
        temp['date_of_birth'] = date
        updateProfile(normalizeValues(temp))
    }
    const handleAutoSelectChange = name => list => {
        let temp = {...bio}
        let oldValue = temp[name]
        let newValue = list
        let value = Array.isArray(oldValue) && !Array.isArray(newValue) ? [newValue] : newValue
        temp[name] = (value || null)
        updateProfile(normalizeValues(temp))
    }
    const handleChange = (event) => {
        let temp = {...bio}
        let oldValue = temp[event.target.name]
        let newValue = event.target.value
        let value = Array.isArray(oldValue) && !Array.isArray(newValue) ? [newValue] : newValue
        temp[event.target.name] = (value || null)
        setBio(temp)
    }
    const formatForDataModel = (data) => {
        try {
            if (!data || data.length === 0) { return [] }
            return data.filter(x => x).map((item) => {
              if (item && item.value) { return item.value }
              if (item && item.id) { return item.id }
              return item
            })                
        } catch (error) {
            console.warn('BAD DATA', data)
            console.warn('ERROR', error)
        }
    }
    const formatSchools = (schools) => {
        if (!schools || schools.length === 0) { return [] }
        return schools.filter(x => x).map(option => ({
          value: option.value || option.name,
          label: option.label || (option.name + ' (' + option.city + ', ' + option.state + ')')
        }))
    }
    const parseSelectedSchool = (school) => {
        if (!school) { return '' }
        if (school && school.value) { return school }
        try {
          return JSON.parse(school)
        } catch (e) {
          return ''
        }
    }
    const handleSchoolTextChange = name => value => {
        if (value && value.length > 0) {
          handleSchoolSearch(value)
        }
    }    

    const normalizeValues = (data) => {
        data.cell_number = parser.isValidPhone(data.cell_number) ? data.cell_number : profile.cell_number
        data.areas_of_interest = formatForDataModel(data.areas_of_interest)
        data.date_of_birth = parser.shortDateMoment(data.date_of_birth)
        data.social_media_links = data.social_media_links && !Array.isArray(data.social_media_links) ? [data.social_media_links] : data.social_media_links
        return data
    }
    const saveBio = () => {
        updateProfile(normalizeValues(bio))
        updateTemporaryProfile(normalizeValues(bio))
    }
    
    const requiredLabel = (text) => {
        return <span>{text}{editing && profile && profile.role === 'guest' ? <span style={{color: 'red'}}>*</span> : ''}</span>
    }
    if (!bio) { return <div /> }

    return (
        <div>
            <SectionTitle handleSave={() => saveBio(bio)} toggleEdit={setEditing}>Bio & Career</SectionTitle>
            <div className={classes.root}>
                <BioSection title='About'>
                    <div className={classes.itemGroup}>
                        <div className={classes.item}>
                            { editing ? <TextField
                                id="bio"
                                name="bio"
                                label={requiredLabel('Bio')}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                value={bio.bio || ''}
                                onBlur={saveBio}
                                onChange={handleChange}
                                className={classes.multiLineTextBox }
                                multiline
                                rows="4"
                                placeholder="Tell us a little about yourself"
                                InputLabelProps={{ shrink: true }} />
                            : profile.bio && <Typography component='div' className={classes.itemText}>{profile.bio}</Typography>}
                        </div>
                    </div>
                    <div className={classes.itemGroup}>
                        <BioItem title={requiredLabel('Best Round')}>
                            { editing ? <TextField
                                id="best_gross_round"
                                name="best_gross_round"
                                label={requiredLabel('Best Gross Round')}
                                fullWidth
                                value={bio.best_gross_round || ''}
                                onBlur={saveBio}
                                onChange={handleNumericChange}
                                margin="none"
                                variant="outlined"
                                className={classes.textField} />
                            : <div>{profile.best_gross_round || ''}</div>}
                        </BioItem>
                        {(session.role === 'admin' || (editing && session.email === profile.email)) && <BioItem title="GHIN">
                            { editing ? <TextField
                                id="ginn"
                                name="ginn"
                                label="GHIN"
                                fullWidth
                                value={bio.ginn || ''}
                                onBlur={saveBio}
                                onChange={handleChange}
                                margin="none"
                                variant="outlined"
                                className={classes.textField} />
                            : <div>{profile.ginn || ''}</div>}
                        </BioItem>}
                        {/* <BioItem title="LinkedIn">
                            { editing ? <TextField
                                id="social_media_links"
                                name="social_media_links"
                                label="LinkedIn Link"
                                margin="none"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={require('../../assets/images/linkedin.png')} style={{width: '24px', margin: '0', verticalAlign: 'middle'}} alt='linkedin' />
                                    </InputAdornment>
                                    ),
                                }}
                                placeholder='https://www.linkedin.com/in/yourname'
                                value={bio.social_media_links && Array.isArray(bio.social_media_links) && bio.social_media_links.length > 0 ? bio.social_media_links[0] : (bio.social_media_links || '')}
                                onChange={handleChange}
                                className={classes.textField} />
                            : profile.social_media_links && profile.social_media_links.length > 0 ? profile.social_media_links[0] :
                                <PlaceholderText>Add your LinkedIn URL</PlaceholderText>}
                        </BioItem> */}
                        <BioItem title="Retired">
                            {editing ? <FormControlLabel
                                    control={
                                    <Checkbox
                                        margin='none'
                                        style={{padding: '0 9px'}}
                                        checked={bio.retired || false}
                                        onChange={handleRetiredChecked}
                                        value="retired"
                                        color="primary"
                                    />
                                    }
                                    label="I am retired"
                                />
                            : <div>{profile.retired || 'No'}</div>}
                        </BioItem>
                        {/* <BioItem title="Short Description">
                            {editing ? <ChipInput
                                value={bio.about_me ? bio.about_me.split(',') : []}
                                label="5 words to describe me"
                                InputLabelProps={{shrink: true}}
                                newChipKeyCodes={[9, 13, 32, 188]}
                                fullWidth
                                margin="none"
                                placeholder='Example: "Entrepreneur, Father, Lawyer, Golfer, Surfer"'
                                variant="outlined"
                                onAdd={(chip) => handleAddAboutMe(chip)}
                                onDelete={(chip, index) => handleDeleteAboutMe(chip, index)}
                                />
                            : profile.about_me === null ?
                                <PlaceholderText>Describe yourself in 5 words</PlaceholderText> : profile.about_me.replace(/,/g, ', ')}
                        </BioItem> */}
                    </div> 
                </BioSection>
                <BioSection title='General'>
                    <div className={classes.itemGroup}>
                        <BioItem title={requiredLabel("Birthday")}>
                            {editing ? <MuiPickersUtilsProvider utils={MomentUTCUtils}>
                                <DatePicker
                                    disableFuture
                                    fullWidth
                                    openTo="year"
                                    format="MM/DD/YYYY"
                                    label={requiredLabel("Birthday")}
                                    views={["year", "month", "date"]}
                                    value={bio.date_of_birth || null}
                                    onChange={handleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                            : profile.date_of_birth && parser.shortDateMoment(profile.date_of_birth) }
                        </BioItem>
                        <BioItem title="Interests">
                            {editing ? <AutoSelect
                                name='areas_of_interest'
                                isMulti
                                textFieldProps={{
                                    label: 'Areas of Interest',
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                }}
                                options={formatForAutoSelectAreasOfInterest(areasOfInterest)}
                                placeholder="Select multiple items"
                                selectedValue={formatForAutoSelectAreasOfInterest(bio.areas_of_interest)}
                                handleAutoSelectChange={handleAutoSelectChange} />
                                : profile.areas_of_interest && profile.areas_of_interest.length > 0 && formatForAutoSelectAreasOfInterest(profile.areas_of_interest).map(item => item.value).join(', ')}
                        </BioItem>
                        <BioItem title="Undergrad">
                            {editing ? <AutoSelect
                                name='graduate_degree'
                                textFieldProps={{
                                    label: 'Undergraduate School'
                                }}
                                options={formatSchools(schools)}
                                selectedValue={parseSelectedSchool(bio.graduate_degree)}
                                handleAutoSelectTextChange={handleSchoolTextChange}
                                handleAutoSelectChange={handleAutoSelectChange} />
                                : profile.graduate_degree && parseSelectedSchool(profile.graduate_degree) && parseSelectedSchool(profile.graduate_degree).label}
                        </BioItem>
                        <BioItem title="Graduate">
                            {editing ? <AutoSelect
                                name='postgraduate_degree'
                                textFieldProps={{
                                    label: 'Graduate School'
                                }}
                                options={formatSchools(schools)}
                                selectedValue={parseSelectedSchool(bio.postgraduate_degree)}
                                handleAutoSelectTextChange={handleSchoolTextChange}
                                handleAutoSelectChange={handleAutoSelectChange} />
                                : profile.postgraduate_degree && parseSelectedSchool(profile.postgraduate_degree) && parseSelectedSchool(profile.postgraduate_degree).label}
                        </BioItem>
                    </div> 
                </BioSection>
                <BioSection title='Career'>
                    <div className={classes.itemGroup}>
                        <BioItem title="Job Title">
                            {editing ? <TextField
                                id="job_title"
                                name="job_title"
                                label="Job Title"
                                fullWidth
                                margin="none"
                                variant="outlined"
                                value={bio.job_title || ''}
                                onChange={handleChange}
                                onBlur={saveBio}
                                className={classes.textField} />
                            : profile.job_title || ''}
                        </BioItem>
                        <BioItem title="Company Name">
                            {editing ? <TextField
                                id="company_name"
                                name="company_name"
                                label="Company Name"
                                fullWidth
                                margin="none"
                                variant="outlined"
                                value={bio.company_name || ''}
                                onChange={handleChange}
                                onBlur={saveBio}
                                className={classes.textField} />
                            : profile.company_name || ''}
                        </BioItem>
                        <BioItem title="Industry">
                            {editing ? <TextField
                                id="industry"
                                name="industry"
                                label="Industry"
                                fullWidth
                                margin="none"
                                variant="outlined"
                                value={bio.industry || ''}
                                onChange={handleChange}
                                onBlur={saveBio}
                                className={classes.textField} />
                            : profile.industry || ''}
                        </BioItem>
                        <BioItem title="LinkedIn">
                            {editing ? <TextField
                                id="social_media_links"
                                name="social_media_links"
                                label="LinkedIn Link"
                                fullWidth
                                margin="none"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={require('../../assets/images/linkedin.png')} style={{width: '24px', margin: '0', verticalAlign: 'middle'}} alt='linkedin' />
                                    </InputAdornment>
                                    ),
                                }}
                                placeholder='https://www.linkedin.com/in/yourname'
                                value={bio.social_media_links && Array.isArray(bio.social_media_links) && bio.social_media_links.length > 0 ? bio.social_media_links[0] : (bio.social_media_links || '')}
                                onChange={handleChange}
                                onBlur={saveBio}
                                className={classes.textField} />
                            : profile.social_media_links && profile.social_media_links.length > 0 && <img onClick={() => window.open(profile.social_media_links[0])} src={require('../../assets/images/linkedin.png')} style={{cursor: 'pointer', width: '24px', margin: '0', verticalAlign: 'middle'}} alt='linkedin' />}
                        </BioItem>
                    </div> 
                </BioSection>
                {(editing || profile.privacy_setting === 'full' || profile.played_with ) && <BioSection title='Contact'>
                    <div className={classes.itemGroup}>
                        <BioItem title="Email">
                            { !profile.preferred_email ? <a href={`mailto:${profile.email}`}>{profile.email}</a> : <a href={`mailto:${profile.preferred_email}`}>{profile.preferred_email}</a> }
                            {!readonly && <ButtonEditEmail toggleEdit={toggleEdit} />}
                        </BioItem>
                        <BioItem title={requiredLabel("Phone")}>
                            {editing ? <TextField
                                id="cell_number"
                                name="cell_number"
                                label={requiredLabel("Phone")}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                value={bio.cell_number || ''}
                                onChange={handleChange}
                                onBlur={saveBio}
                                className={classes.textField} />
                            : parser.formatPhoneNumber(profile.cell_number || '')}
                        </BioItem>
                        <BioItem title={requiredLabel("Venmo")}>
                            {editing ? <TextField
                                id="venmo_username"
                                name="venmo_username"
                                label={requiredLabel("Venmo Username")}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                value={bio.venmo_username || ''}
                                onChange={handleChange}
                                onBlur={saveBio}
                                className={classes.textField} />
                            : <VenmoLink profile={profile} /> }
                        </BioItem>
                        {/* {profile.username && (session.role === 'admin' || (editing && session.email === email)) && <BioItem title="Referral Link">
                            <div style={{display: 'inline-block'}}>
                                <ReferralLink profile={profile} />
                            </div>
                        </BioItem>} */}
                    </div> 
                </BioSection>}
                {(session.role === 'admin' || session.email === profile.email || profile.referrer) ? <BioSection title="Referrals">
                    <div className={classes.itemGroup}>
                        {profile && profile.referrer ? <BioItem title="Referred By">
                            <div onClick={() => history.push(`${paths.profile}/${profile.referrer}`)} className={classes.memberContent}>
                                <div style={{marginRight: 8}}>
                                    <UserAvatar profileImage={referrer ? referrer.profile_image : null} />
                                </div>
                                <div style={{flexGrow: 1}}>
                                    <div className={classes.memberNameRow}>
                                        <Typography className={classes.memberName} variant="subtitle1">
                                        {`${referrer ? referrer.first_name : ''} ${referrer ? referrer.last_name : ''}`}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </BioItem> : <></>}
                    </div>
                    {session.role === 'admin' || session.email === profile.email 
                        ?   <div className={classes.itemGroup}>
                                <BioItem title="Referrals" itemStyle={{alignItems: 'flex-start', marginTop: 8}}>
                                    <Typography>
                                        Please help us grow the Captain's Club Membership by referring others that you know who you think would make great members.  Please use your personal referral link here so we can track any new applications back to you:
                                    </Typography>
                                    <div style={{display: 'inline-block'}}>
                                        <ReferralLink profile={profile} />
                                    </div>
                                    {profile.referrals && profile.referrals.length > 0 ? <div style={{marginTop: 16}}>
                                        <Typography>
                                            Thank you for having referred {profile.referrals.length} members so far!  Including: 
                                        </Typography>
                                        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                            {profile.referrals.map(((prof, idx) => (
                                                <div key={idx} onClick={() => history.push(`${paths.profile}/${prof.username}`)} className={classes.memberContent} style={{padding: '8px 0'}}>
                                                    <div style={{marginRight: 8}}>
                                                        <UserAvatar width={32} profileImage={prof.profile_image} />
                                                    </div>
                                                    <div style={{flexGrow: 1}}>
                                                        <div className={classes.memberNameRow}>
                                                            <Typography className={classes.memberName} variant="subtitle1" style={{fontSize: 14}}>
                                                            {`${prof.first_name || ''} ${prof.last_name || ''}`}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div> 
                                            )))}
                                        </div>

                                    </div> : <></>}
                                    {/* <div onClick={() => history.push(`${paths.profile}/${profile.referrer}`)} className={classes.memberContent}>
                                        <div style={{marginRight: 8}}>
                                            <UserAvatar profileImage={referrer ? referrer.profile_image : null} />
                                        </div>
                                        <div style={{flexGrow: 1}}>
                                            <div className={classes.memberNameRow}>
                                                <Typography className={classes.memberName} variant="subtitle1">
                                                {`${referrer ? referrer.first_name : ''} ${referrer ? referrer.last_name : ''}`}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div> */}
                                </BioItem>
                            </div>
                        : <></>
                    }
                </BioSection> : <></>}
            </div>
        </div>
    )
  }

  const mapStateToProps = (state) => {
    return {
      areasOfInterest: state.references.areas_of_interest,
      schools: state.references.schools,
      memberList: state.members.memberList,
      session: state.auth.session,
      error: state.user.error,
      isInvalidNewEmail: state.user.isInvalidNewEmail
    }
  }
  const mapDispatch = (dispatch) => {
    return bindActionCreators({
      updateProfile: handleUpdateUser,
      handleSchoolSearch: handleSchoolSearch,
      updateTemporaryProfile: handleUpdateTemporaryUser,
      handleCheckEmailUser
    }, dispatch)
  }
  export default withRouter(connect(mapStateToProps, mapDispatch)(BioCareer))
  