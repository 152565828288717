import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import cls from 'classnames';
import React from 'react';
import { parser } from '../utils/parser';

const useStyles = makeStyles((theme) => ({
  dateField: {
    marginRight: 8,
    '&.not-selected': {
      '& input': {
        color: theme.palette.common.white,
      },
    },
  },
  linkToggle: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 5,
    color: theme.palette.link.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

const DateRangeSelector = ({ startDate, endDate, onUpdate, enablePast }) => {
  const classes = useStyles();
  const [multiDate, setMultiDate] = React.useState(false);
  React.useEffect(() => {
    if (endDate) {
      setMultiDate(true);
    }
  }, [endDate]);
  const toggleMultiDate = () => {
    if (multiDate) {
      setMultiDate(false);
      onUpdate({ start_date: startDate, end_date: null });
    } else {
      setMultiDate(true);
      if (startDate) {
        onUpdate({
          start_date: startDate,
          end_date: parser.addDays(startDate, 2),
        });
      }
    }
  };
  const updateStartDate = (dt) => {
    onUpdate({ start_date: dt });
  };
  const updateEndDate = (dt) => {
    onUpdate({ end_date: dt });
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify='flex-start'>
        <DatePicker
          disablePast={!enablePast}
          required
          margin='normal'
          label={multiDate ? 'Select Start Date' : 'Select Date'}
          className={cls(classes.dateField, { 'not-selected': !startDate })}
          value={startDate}
          onChange={updateStartDate}
        />
        {multiDate ? (
          <DatePicker
            disablePast
            required
            margin='normal'
            label='Select End Date'
            className={cls(classes.dateField, { 'not-selected': !endDate })}
            value={endDate}
            onChange={updateEndDate}
          />
        ) : (
          <TimePicker
            margin='normal'
            label='Select Time'
            className={cls(classes.dateField, { 'not-selected': !startDate })}
            value={startDate}
            onChange={updateStartDate}
          />
        )}
        <div onClick={() => toggleMultiDate()} className={classes.linkToggle}>
          {multiDate ? 'Switch to Date and Time' : 'Switch to Date Range'}
        </div>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangeSelector;
