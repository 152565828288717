import { Box, CircularProgress, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@material-ui/core';
// import cls from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfiniteScroll from "react-infinite-scroll-component";

import AutoSelect from '../../components/auto_select';
import MemberBox from '../../components/memberBox';
import PaperMasterHoc from '../../components/paperMasterHoc';
import SearchBar from '../../components/searchBar';
import { handleActiveClubs } from '../../reducers/clubs';
import {
  handleFilterMembers,
  handleSearchTextChange,
  handleSelectedClubChange,
  handleLocationChange,
  handleHandicapFilterChange
} from '../../reducers/members';
import { handleWePlayedTogether } from '../../reducers/user';
import { parser } from '../../utils/parser';
import { paths } from '../../utils/paths';
import IntroductionDialog from './introductionDialog';
import { handleGetLists } from '../../reducers/lists';
import { Button } from '@material-ui/core';
import ListsDialog from './listDialog'
import HandicapListComponent from '../../components/handicapListComponent';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    // paddingTop: 24,
    // [theme.breakpoints.down('sm')]: {
    //   width: '96%',
    // }
  },
  filterContainer: {
    display: 'flex', 
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', 
    },
  },
  filterItem: {
    flex: 3.3,
    margin: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0
    },
  }
}))

const MembersContainer = ({
  history,
  userDetail,
  filteredMemberList,
  searchText,
  selectedClub,
  location,
  clubs,
  isFiltering,
  handleFilterMembers,
  handleSearchTextChange,
  handleSelectedClubChange,
  handleLocationChange,
  handleWePlayedTogether,
  handleActiveClubs,
  handleGetLists,
  handicapFilter,
  handleHandicapFilterChange
}) => {
  const classes = useStyles();
  const [ introduceMember, setIntroduceMember ] = React.useState(false)
  const [ skip, setSkip ] = React.useState(0)
  const [showLists, setShowLists] = React.useState(false);
  const [showHandicapFilter, setShowHandicapFilter] = React.useState(false);
    
  React.useEffect(() => {
    handleGetLists();
  }, [handleGetLists]);

  React.useEffect(() => {
    handleFilterMembers({
      clubId: parseFiltersForSearch(selectedClub),
      text: searchText,
      location: parseFiltersForSearch(location),
      handicapFilter
    })
  }, [handicapFilter, handleFilterMembers, selectedClub, location, searchText])

  if (!userDetail || !userDetail.email) { return <div /> }
  
  const handlePlayedTogetherClick = (evt) => {
    handleWePlayedTogether(userDetail.email, evt.target.value, evt.target.checked, {
      clubId: parseFiltersForSearch(selectedClub), 
      text: searchText,
      location: parseFiltersForSearch(location)
    })
  }
  const parseFiltersForSearch =  (list) => {
    if (list && Array.isArray(list) && list.length > 0) {
      return list.map(e => e.value).join(',')
    }
    return ''
  }
  const onMemberSearch = (value) => {
    handleSearchTextChange(value)

    handleFilterMembers({
      clubId: parseFiltersForSearch(selectedClub), 
      text: value, 
      location: parseFiltersForSearch(location),
      handicapFilter
    })
  }
  const handleIntroduce = (member) => {
    if (!member || !member.email) { return }
    setIntroduceMember(member)
  }
  const handleClubCourseChange = name => list => {
    handleSelectedClubChange(list)
    
    handleFilterMembers({
      clubId: parseFiltersForSearch(list),
      text: searchText,
      location: parseFiltersForSearch(location),
      handicapFilter
    })
  }
  const handleClubTextChange = name => value => {
    if (value && value.length > 1) {
      handleActiveClubs(value)
    }
  }

  const handleLocationListChange = name => list => {
    handleLocationChange(list)
    
    handleFilterMembers({
      clubId: parseFiltersForSearch(selectedClub), 
      text: searchText, 
      location: parseFiltersForSearch(list),
      handicapFilter
    })
  }
  const fetchMoreData = () => {
    setTimeout(() => {
      setSkip(skip + filteredMemberList - 20);

      handleFilterMembers({
        clubId: parseFiltersForSearch(selectedClub), 
        text: searchText, 
        location: parseFiltersForSearch(location),
        handicapFilter
      }, filteredMemberList.length)

    }, 1500);
  };

  function handleShowHandicapFilter(event){
    const flag = event.target.checked
    setShowHandicapFilter(flag)
    if(!flag) handleHandicapFilterChange('')
  } 

  return (
    <PaperMasterHoc transparent={true}>
      <ListsDialog
        open={showLists}
        toggleDialog={() => setShowLists(false)} />
      <div className={classes.root}>
        <Typography style={{marginBottom: 8}} variant="h4">Members</Typography>
        <IntroductionDialog open={Boolean(introduceMember)} toggleDialog={() => setIntroduceMember(false)} member={introduceMember} />
        <React.Fragment>
          <div style={{marginBottom: 8}}>
            <SearchBar onTextInput={onMemberSearch} initialText={searchText} />
          </div>
          <div className={classes.filterContainer}>
            <div className={classes.filterItem}>
              <AutoSelect
                textFieldProps={{
                  label: 'Filter by Club',
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                isMulti
                placeholder="Type Club name here to search"
                name='filter_club'
                options={parser.formatClubs(clubs)}
                selectedValue={selectedClub}
                handleAutoSelectTextChange={handleClubTextChange}
                handleAutoSelectChange={handleClubCourseChange}
                />
            </div>
            <div className={classes.filterItem}>
              <AutoSelect
                name='location'
                options={parser.getStateList()}
                isMulti
                textFieldProps={{
                  label: 'Filter by Member location',
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                placeholder="Type State here to search"
                selectedValue={location || []}
                handleAutoSelectChange={handleLocationListChange} />
            </div>
            
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Grid container spacing={3} style={{ justifyContent: 'space-between' }}>
              <Grid style={{ marginLeft: '10px' }}>
                {/* <Button onClick={() => setShowLists(true)} variant='contained'>Handicap Filter</Button> */}
                <FormGroup>
                  <FormControlLabel control={<Switch checked={showHandicapFilter} onChange={handleShowHandicapFilter} />} label="Handicap Filter" />
                </FormGroup>
              </Grid>
              <Grid>
                <Button onClick={() => setShowLists(true)} variant='contained'>View Lists</Button>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
        {filteredMemberList.length === 0 && <Typography style={{textAlign: 'center'}} variant="h5">No members available</Typography>}
              <Grid container spacing={1}>
                {showHandicapFilter && 
                  <Grid item md={2}>
                    <HandicapListComponent />
                  </Grid>
                }
                <Grid item md={showHandicapFilter ? 10 : 12}>
                  <InfiniteScroll
                    dataLength={filteredMemberList.length}
                    next={fetchMoreData}
                    hasMore={true}
                    loader={filteredMemberList.length > 0 && isFiltering && (
                      <Box marginBottom={1}>
                        <CircularProgress size={24} color="primary" />
                      </Box>
                    )}
                  >
                    {filteredMemberList.map((member, idx) => (
                      <MemberBox
                        key={idx} 
                        member={member} 
                        playerClick={() => history.push(`${paths.profile}/${member.username}`)}
                        handleIntroduce={userDetail.role === 'member' ? handleIntroduce : null}  
                        handlePlayedTogetherClick={userDetail.role === 'member' ? handlePlayedTogetherClick : null}
                      />
                    ))}
                  </InfiniteScroll>
                </Grid>
              </Grid>
          
          <div>
          
          
        </div>
      </div>
    </PaperMasterHoc>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    filteredMemberList: state.members.filteredMemberList.filter(x => x.email && x.email !== state.user.userDetail.email && x.role !== 'banned'),
    searchText: state.members.searchText,
    selectedClub: state.members.selectedClub,
    location: state.members.location,
    clubs: state.clubs.activeClubs,
    isFiltering: state.members.isFiltering,
    memberList: state.members.memberList,
    handicapFilter: state.members.handicapFilter
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleFilterMembers,
    handleSearchTextChange,
    handleSelectedClubChange,
    handleLocationChange,
    handleWePlayedTogether,
    handleActiveClubs,
    handleGetLists,
    handleHandicapFilterChange
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(MembersContainer);
