import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import WidgetContainer from '../../components/widgetContainer';
import WidgetElement from '../../components/widgetElement';
import UserAvatar from '../../components/userAvatar';
import {
  handleSelectPostType,
  handleShowDialogPost,
} from '../../reducers/posts';
import { parser } from '../../utils/parser';

const MemberTravelWidget = ({
  posts,
  travelPosts,
  handleSelectPostType,
  handleShowDialogPost,
}) => {
  return (
    <WidgetContainer
      header='Member Travel'
      action={() => {
        handleSelectPostType('travel');
        window.scroll(
          0,
          document.getElementById('feed-form-container').offsetTop
        );
      }}
    >
      {travelPosts.length === 0 && (
        <Typography>
          Let other members know when and where you're planning a trip.
        </Typography>
      )}
      {travelPosts &&
        travelPosts.map((trip, idx) => {
          const post = posts.find((x) => x.id === trip.post_id);
          return (
            <div key={idx}>
              <WidgetElement noClick>
                <div style={{ flexWrap: 'nowrap' }}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ marginRight: 8 }}>
                      <UserAvatar
                        profileImage={post.profile_image}
                        width={24}
                      />
                    </div>
                    <div>
                      <Typography variant='subtitle1' style={{ fontSize: 14 }}>
                        {post ? `${post.first_name} ${post.last_name}` : ''}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ paddingLeft: 32 }}>
                    <Typography variant='subtitle1' style={{ fontSize: 14 }}>
                      {trip.destination_city}, {trip.destination_state}
                    </Typography>
                    <Typography variant='subtitle1' style={{ fontSize: 14 }}>
                      {parser.shortDate(trip.arrival_date, 'MM/dd')} to{' '}
                      {parser.shortDate(trip.departure_date, 'MM/dd')}
                    </Typography>
                  </div>
                </div>
                <Button
                  onClick={() => handleShowDialogPost(trip.post_id)}
                  size='small'
                  variant='contained'
                  style={{ marginLeft: 'auto', marginTop: 4 }}
                >
                  View
                </Button>
              </WidgetElement>
            </div>
          );
        })}
    </WidgetContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    posts: state.posts.posts,
    travelPosts: parser.sortByDate(
      state.posts.travelPosts.filter(x => x.member_email !== state.user.userDetail.email),
      { prop: 'arrival_date'}
    ),
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      handleSelectPostType,
      handleShowDialogPost,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatch)(MemberTravelWidget)
);
