import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserAvatar from '../../components/userAvatar';
import WidgetContainer from '../../components/widgetContainer';
import WidgetElement from '../../components/widgetElement';
import { paths } from '../../utils/paths';

const useStyles = makeStyles(theme => ({
  memberWrap: {
    display: 'flex', 
    flexWrap: 'wrap', 
    alignItems: 'flex-start', 
    marginBottom: 4, 
    padding: '4px',
    backgroundColor: theme.palette.lightGray.main,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  memberName: {
    fontSize: 14,
    fontFamily: "Sentinel-Black,Roboto,sans-serif",
    fontWeight: 400,
    lineHeight: 1
  }
}))

const GuestWidget = ({ userDetail, group, history }) => {
  const classes = useStyles()
  const members = (group.group_members || [])
    .filter(x => x.role === 'guest')
  const guests = group.guest_list || []
  const guestList = members.concat(guests)
  if (guestList.length === 0) { return <div /> }
  return (
    <WidgetContainer header="Guests">
      {guestList && guestList.map((guest, idx) => (
        <div key={idx}>
          <WidgetElement noClick={!guest.accepted || userDetail.role !== 'member'}>
            <div style={{display: 'flex', width: '100%', alignItems: 'center', padding: '12px 0'}} 
              onClick={() => userDetail.role === 'member' && guest.accepted ? history.push(`${paths.profile}/${guest.username}`) : {}}>
                <div style={{marginRight: 8}}>
                  <UserAvatar profileImage={guest.profile_image} width={24} />
                </div>
                <div>
                <Typography component="div" className={classes.memberName}>
                  {`${guest.first_name} ${guest.last_name}`}
                </Typography>
                {!guest.accepted &&
                  <Typography variant="caption" style={{fontSize: 11}}>(Invited, not joined)</Typography>
                }
                </div>
            </div>
          </WidgetElement>
        </div>
      ))}
    </WidgetContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatch)(GuestWidget))
