import { Avatar, IconButton } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import { makeStyles } from '@material-ui/styles';
// import { bindActionCreators } from 'redux'
// import { connect } from 'react-redux'
import cls from 'classnames';
import React from 'react';
import PhotoEditorDialog from './photoEditorDialog';
const defaultImage = require('../assets/images/green.jpg')

const useStyles = makeStyles(theme => ({
  banner: {
    height: theme.spacing(30),
    position: 'relative',
    backgroundSize: '1300px auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: '#000',
    '&:hover .showOnHover': {
      display: 'block'
    },
  },
  imageEdit: {
    position: 'absolute',
    display: 'none',
    bottom: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
  editAvatar: {
    height: 64,
    width: 64,
    backgroundColor: theme.palette.primary.main,
  },
}))
const BannerImage = ({imagePath, isEditable, handleUpdateBannerImage}) => {
  const classes = useStyles()
  const [ showBannerImageDialog, toggleBannerImageDialog ] = React.useState(false)
  return (
    <React.Fragment>
      <PhotoEditorDialog
        open={showBannerImageDialog}
        toggleDialog={toggleBannerImageDialog}
        imagePath={imagePath}
        handleSave={handleUpdateBannerImage}
      />
      <div className={classes.banner} style={{backgroundImage: `url('${imagePath || defaultImage}')` }}>
        {isEditable && <IconButton onClick={() => toggleBannerImageDialog(true)} className={cls(classes.imageEdit, 'showOnHover')}>
          <Avatar className={classes.editAvatar}><CameraIcon style={{fontSize: 36}} /></Avatar>
        </IconButton>}
      </div>
    </React.Fragment>
  )
}

export default BannerImage
