import React from 'react';
import CopyIcon from '../assets/images/copyIcon.png'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

const ReferralLink = ({ profile }) => {
  const [ copySuccess, setCopySuccess ] = React.useState(false)
  const theme = useTheme()
  const smallPage = useMediaQuery(theme.breakpoints.down('xs'))
  // function handleTooltipClose() {
  //   setCopySuccess(false);
  // }

  function handleTooltipOpen() {
    setCopySuccess(true);
  }
  return (
    <React.Fragment>
      <CopyToClipboard text={'https://app.captains.club/' + profile.username}
        onCopy={handleTooltipOpen}>
        <Tooltip
          open={copySuccess}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied!"
          placement="top-end"
        >
          <div>
            <span  title="Copy" alt="Copy" style={{cursor: 'pointer', fontSize: (smallPage ? 16 : 18), textDecoration: 'underline'}} >{'https://app.captains.club/' + profile.username}</span>
            <img alt="Copy" title="Copy" src={CopyIcon} style={{cursor: 'pointer', height: (smallPage ? 18 : 24), width: (smallPage ? 16 : 24), marginLeft: 8, marginRight: 8, verticalAlign: 'middle'}} />
          </div>
        </Tooltip>
      </CopyToClipboard>   
    </React.Fragment>
  )
}

export default ReferralLink
