import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemAvatar, ListItemText, MenuItem, TextField } from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React from 'react';
import { connect } from 'react-redux';
import { isApplicantNotAvailableForMemberReview } from '../utils/members';
import { isApplicantOrMemberReview } from '../utils/profiles';

const useStyles = makeStyles(theme => ({
    textField: {
        margin: theme.spacing(2,0)
    },
    menu: {
        minWidth: 100,
        fontSize: '11px'
    },
    menuItemHolder: {
        width: '100%',
        display: 'flex',
        padding: 0,
        position: 'relative',
        boxSizing: 'border-box', 
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textDecoration: 'none'
    }
}));
const getMuiTheme = () => createMuiTheme({
    overrides: {
    //   MuiInputBase: {
    //     root: {
    //       fontSize: '11px'
    //     }
    //   },
    //   MuiMenuItem: {
    //     root: {
    //         fontSize: '11px'
    //     }
    //   },
        MuiListItem: {
            root: {
                padding: 0
            },
            gutters: {
                paddingLeft: 0,
                paddingRight: 0,
                padding: 0,
            }
        }
    }
})

const roleList = [
    { value: 'prospect', label: 'New Applicant', disabled: true },
    { value: 'guest', label: 'Guest', disabled: true },
    { value: 'approve_initial', label: 'Application In Progress', requires: ['prospect'] },
    { value: 'reject', label: 'Application Declined', requires: ['prospect', 'applicant', 'guest', 'member_review'] },
    { value: 'member_review', label: 'Member Review', requires: ['applicant', 'guest'] },
    { value: 'make_charter_member', label: 'Charter Membership Approved', requires: ['applicant', 'member', 'guest', 'member_review']},
    { value: 'make_founding_member', label: 'Founding Membership Approved', requires: ['applicant', 'member', 'guest', 'member_review']},
    { value: 'approve_final', label: 'Membership Approved', requires: ['applicant', 'guest', 'member_review'] },
    { value: 'rescind_membership', label: 'Membership Rescinded', requires: ['member'] }
]

function RoleDialog(props) {
    const classes = useStyles()
    const {open, user, adminList, toggleDialog, handleConfirmation, applicantList} = props
    const [ selectedRole, selectRole ] = React.useState()
    const [ sender, selectSender ] = React.useState(adminList && adminList.length > 0 ? adminList[0] : '')
    React.useEffect(() => {
        if (!user) { return }
        selectRole(user.action)
        if (adminList && adminList.length > 0) {
            selectSender(adminList[0].value)
        }
    }, [user, adminList]);
    const handleConfirm = () => {
        let options = {}
        let newStatus = selectedRole
        if (isApplicantOrMemberReview(user) &&
            (newStatus === 'make_charter_member' || newStatus === 'make_founding_member')) {
                options.charter = Boolean(newStatus === 'make_charter_member')
                options.founder = Boolean(newStatus === 'make_founding_member')
                newStatus = 'approve_final'
        }
        if (sender) { options.sender = sender }
        const roleIndex = roleList.map(e => e.value).indexOf(newStatus);
        if (roleIndex >= 0 && !roleList[roleIndex].disabled) {
            toggleDialog()
            handleConfirmation(user.email, user.role, newStatus, options)
        }
    }
    if (!user) { return <div /> }

    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog()}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">Update Status and Send</DialogTitle>
            <DialogContent>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <TextField
                        id="new_role"
                        name="new_role"
                        label="Change To"
                        select
                        fullWidth
                        className={classes.textField}
                        value={selectedRole}
                        onChange={(evt) => selectRole(evt.target.value)}
                        variant="outlined"
                        margin="none"
                        InputLabelProps={{
                            shrink: true,
                        }} 
                    >
                        {roleList.map(option => {
                            const applicantNotAvailableForMemberReview = isApplicantNotAvailableForMemberReview(option, applicantList, user)
                            return <MenuItem style={{padding: '4px 8px'}} key={option.value} disabled={option.disabled || option.requires.indexOf(user.role) < 0 || applicantNotAvailableForMemberReview} value={option.value}>
                            {option.label}{applicantNotAvailableForMemberReview ? ' (Anonymous User)' : ''}
                        </MenuItem>
                        }
                        )}
                    </TextField>
                    <TextField
                        id="sender"
                        name="sender"
                        label="Send From"
                        select
                        fullWidth
                        className={classes.textField}
                        value={sender}
                        onChange={(evt) => selectSender(evt.target.value)}
                        variant="outlined"
                        margin="none"
                        InputLabelProps={{
                            shrink: true,
                        }} 
                    >
                        {adminList.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                <div className={classes.menuItemHolder}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountCircle />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={option.label} secondary={option.value} />
                                </div>
                            </MenuItem>
                        ))}
                    </TextField>
                </MuiThemeProvider>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleConfirm}>Save</Button>
                <Button variant="outlined" onClick={() => toggleDialog()}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

const mapState = (state) => {
    return {
        applicantList: state.members.applicantList,
    } 
}

export default connect(mapState)(RoleDialog)
