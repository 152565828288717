import { Button, Typography } from '@material-ui/core';
import { default as React } from 'react';
import { connect } from 'react-redux';
import FilterBar from './filterBar';
import MemberTable from './memberTable';
import WidgetLoading from '../../components/widgetLoading';
import { userRoles } from '../../utils/profiles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVLink } from "react-csv";
import { getUserCsv } from '../../utils/users';
import moment from 'moment';
import { HEADERS_USERS_CSV } from '../../utils/constants';

const UserManagementContainer = ({ memberList, applicantList, isLoading, token }) => {
  const [ segments, setSegments ] = React.useState([])
  const [ activeSegment, setActiveSegment ] = React.useState()
  const [csvData, setCsvData] = React.useState([])
  const [csvDataApplicants, setCsvDataApplicants] = React.useState([])
  const [loadingCsv, setLoadingCsv] = React.useState(true)
  const [loadingCsvApplicants, setLoadingCsvApplicants] = React.useState(true)
  const [fileName, setFileName] = React.useState(moment(new Date()).format("MM/DD/YYYY H:mm"))

  const handleFilterClick = (filterId) => {
    setActiveSegment(segments.find(x => x.id === filterId ))
  }
  const parseClub = list =>  {
    return list.map(item => {
      return { ...item, 
        primary_club_name: item.primary_club ? item.primary_club.name : 
          (item.club_memberships && item.club_memberships.length > 0 ? item.club_memberships[0].name : '-'),
        primary_club_id: item.primary_club ? item.primary_club.id : 
          (item.club_memberships && item.club_memberships.length > 0 ? item.club_memberships[0].id : 0)
        }
    })
  }
  React.useEffect(() => {    
    if (!memberList  || !applicantList) { return }
    const groups = [
      { label: `All Users `, id: 'all', data: parseClub(memberList.concat(applicantList))},
      { label: `Prospects `, id: 'prospects', data: parseClub(applicantList.filter(x => !x.role)) },
      { label: `Applicants `, id: 'applicants', data: parseClub(applicantList.filter(x => x.role === 'applicant').concat(memberList.filter(x => x.role === 'guest' && x.guest_applied))) },
      { label: `Member Review `, id: 'member_review', data: parseClub(applicantList.filter(x => x.role === userRoles.memberReview)) },
      { label: `Members `, id: 'approved', data: parseClub(memberList.filter(x => x.role === 'member' && !x.charter && !x.founder)) },
      { label: `Charter Members `, id: 'charter', data: parseClub(memberList.filter(x => x.role === 'member' && x.charter)) },
      { label: `Founding Members `, id: 'founder', data: parseClub(memberList.filter(x => x.role === 'member' && x.founder)) },
      { label: `Guests `, id: 'guests', data: parseClub(memberList.filter(x => x.role === 'guest' && !x.guest_applied)) },
      { label: `Deactivated Members `, id:  'rescinded', data: parseClub(memberList.filter(x => x.role === 'banned')) },
    ]
    
    setSegments(groups)
    setActiveSegment(prev => groups.find(x => x.id === (prev ? prev.id : 'all') ))
  }, [memberList, applicantList]);
  
  React.useEffect(() => {
    async function getUsersForCsv() {
      await getUserCsv('csv', token, setCsvData, setLoadingCsv)
      await getUserCsv('csv-applicants', token, setCsvDataApplicants, setLoadingCsvApplicants)
    }

    getUsersForCsv()
  }, [token])

  
  return (
    <div>
      
      <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
      <Typography component='p' margin='normal' variant='h5'>Manage Users</Typography>
      { !loadingCsv && !loadingCsvApplicants && 
        <CSVLink
          headers={HEADERS_USERS_CSV}
          data={[...csvDataApplicants, ...csvData]}
          separator={","}
          filename={fileName}
          style={{ textDecoration: 'none' }}
          onClick={ () => {
              const data = moment(new Date()).format("MM/DD/YYYY H:mm")
              setFileName(`captains-all-users ${data}.csv`)
            }
          }
          >
          <Button variant='contained'><GetAppIcon /> {'User Data'}</Button>
        </CSVLink>
       }
      </div>
      <React.Fragment>
        <FilterBar segments={segments} activeId={activeSegment ? activeSegment.id : 'all'} handleFilterClick={handleFilterClick} />
        {memberList ? <MemberTable 
          userList={activeSegment ? activeSegment.data : []} 
          fullMemberList={(memberList || []).concat(applicantList || [])}
          isLoading={isLoading} /> : <WidgetLoading />
        }
      </React.Fragment>
    </div>
  )
}

const mapStateToProps = (state) => {
    return { 
      applicantList: state.members.applicantList,
      memberList: state.members.memberList,
      isLoading: state.members.isLoading,
      token: state.auth.session.token
    }
}

export default connect(mapStateToProps)(UserManagementContainer)
