import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, TextField, useMediaQuery } from '@material-ui/core';
import { handleCleanSelectedMemberLists, handleSubmitList, handleUpdateList } from '../../reducers/lists';
import { Button } from '@material-ui/core';
import ListDialogItem from './listDialogItem';
import { ArrowBack, PlaylistAdd } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MemberListItem from './memberListItem';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
      overflowY: 'unset',
      minWidth: 700,
      [theme.breakpoints.down('md')]: {
          minWidth: 200,
          width: '100%'
      },
  },
  listsContainer: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '300px',
      overflowY: 'scroll',
    },
  }
}));

const ListsDialog = ({ handleSubmitList, open, toggleDialog, lists, selectedMember, handleCleanSelectedMemberLists, handleUpdateList }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [listName, setListName] = React.useState('')
    const [selectedList, setSelectedList] = React.useState(null)

    const classes = useStyles()
  
    const handleClose = () => {
      toggleDialog(null)
      setSelectedList(null)
      setListName('')
      handleCleanSelectedMemberLists()
    }
  
    const handleText = (event) => {
      setListName(event.target.value)
    }
  
    const handleAddList = () => {
      if(listName && listName !== ''){
        handleSubmitList(listName, 1)
        setListName('')
      }
    }

    const handleUpdate = () => {
      handleUpdateList(selectedList.id, listName, 1)
      handleBack()
    }

    //update selected list if needed
    React.useEffect(() => {
      if(selectedList){
        const updatedSelectedList = lists.filter(l => l.id === selectedList.id)[0]
        setSelectedList(updatedSelectedList)
        setListName(selectedList.name)
      }
    }, [lists, selectedList])

    const handleSelectList = (list) => {
      setSelectedList(list)
    }

    const handleBack = () => {
      setSelectedList(null)
      setListName('')
      handleCleanSelectedMemberLists()
    }
  
    return <Dialog
            open={!open && selectedMember && selectedMember.email ? true : open}
            onClose={handleClose}
            fullScreen={fullScreen}
            scroll={'body'}
            classes={!fullScreen ? {paper: classes.dialogPaper} : {}}
          >
            <DialogTitle>
              <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center'
              }} >
              { selectedList && <IconButton onClick={() => handleBack()} ><ArrowBack /></IconButton> }
              { selectedList ? <div>Members of {selectedList.name} List</div> : (selectedMember ? <div>Add {selectedMember.first_name} {selectedMember.last_name} to lists below</div> : <div>My Lists</div>) }
              </div>
            </DialogTitle>
            <DialogContent style={{padding: '0 24px 8px', overflowY: 'unset'}}>
                { selectedList && <p style={{ paddingTop: 0, marginTop: 0 }}>
                  You can add new members to this list by going to <Link style={{ cursor: 'pointer', color: '#0000EE' }} onClick={() => handleClose() }>"Members"</Link> and clicking the "lists icon <PlaylistAdd style={{ color: '#757575' }} />" next to the members name. You can also click the lists icon on a member's profile to add them.
                </p> }
                { !selectedList && lists && lists.length > 0 && <div className={classes.listsContainer}>
                  { lists.map(l => <ListDialogItem key={l.id} handleSelectList={handleSelectList} list={l} open={!open && selectedMember && selectedMember.email ? true : open} />) }
                </div> }
                { !selectedList && (!lists || lists.length === 0) && <p>You don't have any lists yet</p> }
                {selectedList && selectedList.members && selectedList.members.length > 0 && 
                <div className={classes.listsContainer}>
                { selectedList.members.map(m => <MemberListItem memberData={m} />) }
                </div>
                }
            </DialogContent>
            <DialogActions>
                { open && !selectedList && !selectedMember &&
                <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: '15px',
                  paddingRight: '15px' }}>
                  <TextField
                    id="list_name"
                    name="list_name"
                    label={"Add New List"}
                    value={listName}
                    style={{
                      marginBottom: '15px'                      
                    }}
                    onChange={handleText}
                    variant="outlined" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Button
                      style={{
                        marginBottom: '15px',
                        marginRight: '15px',
                        width: '100%'
                      }}
                      variant="contained" onClick={handleAddList}>
                          Save
                      </Button>
                      <Button
                      style={{
                        marginBottom: '15px'
                      }}
                      variant="outlined" onClick={handleClose}>
                          Close
                      </Button>
                    </div>                    
                </div>}
                {open && selectedList && <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: '15px',
                  paddingRight: '15px'
                  }}>
                  <TextField
                    id="list_name"
                    name="list_name"
                    label={"Edit List Name"}
                    value={listName}
                    style={{
                      marginLeft: '15px',
                      marginBottom: '15px'                      
                    }}
                    onChange={handleText}
                    variant="outlined" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Button
                      style={{
                        marginBottom: '15px',
                        marginRight: '15px',
                        width: '100%'
                      }}
                      variant="contained" onClick={handleUpdate}>
                          Save Edit
                      </Button>
                      <Button
                      style={{
                        marginBottom: '15px'
                      }}
                      variant="outlined" onClick={handleClose}>
                          Close
                      </Button>
                    </div>                    
                </div>}
                { selectedMember &&
                <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  paddingLeft: '15px',
                  paddingRight: '15px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Button
                      style={{
                        marginBottom: '15px'
                      }}
                      variant="outlined" onClick={handleClose}>
                          Close
                      </Button>
                    </div>                    
                </div>}
            </DialogActions>
          </Dialog>
  }

const mapStateToProps = (state) => {
    return {
      lists: state.lists.lists,
      selectedMember: state.lists.selectedMember
    }
}
  
const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleSubmitList,
        handleCleanSelectedMemberLists,
        handleUpdateList
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(ListsDialog);