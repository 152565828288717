import React from 'react';
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
import AccountView from './accountView';
import Footer from '../../components/footer'

function AccountContainer({ history }) {

  return (
    <React.Fragment>
      <AccountView />
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail
  }
}

export default connect(mapStateToProps)(AccountContainer)
