import { Avatar, IconButton, Typography } from '@material-ui/core';
// import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CameraIcon from '@material-ui/icons/CameraAlt';
import ChatIcon from '@material-ui/icons/ChatSharp';
// import GroupIcon from '@material-ui/icons/Group'
// import QuestionIcon from '@material-ui/icons/QuestionAnswer'
import EditIcon from '@material-ui/icons/Edit';
import GolfIcon from '@material-ui/icons/GolfCourse';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import cls from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ToggleButton from '../../components/toggleButton';
import { handleSetSelectedMembers } from '../../reducers/notifications';
import { handleUpdateUser, handleWePlayedTogether } from '../../reducers/user';
import { paths } from '../../utils/paths';
import AvatarDialog from './avatarDialog';
import EditProfileDialog from './editProfileDialog';
import PlayerConnections from './playerConnections';
import WidgetLoading from '../../components/widgetLoading';
import { shouldShowPlayedWithToggle, userHasRole, userRoles } from '../../utils/profiles';
import { getUserMemberships } from '../../utils/users';
import { PlaylistAdd } from '@material-ui/icons';
import { handleGetLists, handleSelectMemberLists } from '../../reducers/lists'
import ListsDialog from '../../pages/members/listDialog'

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    color: '#FFF',
    width: '100%',
    padding: '0',
    margin: 'auto',
    minWidth: '550px',
    display: 'flex', 
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      flexDirection: 'column',
      paddingTop: '20px',
      width: '100%'
    },
  },
  imageBox: {
    width: '280px',
    height: 'auto',
    position: 'relative',
    textAlign: 'center',
    '&:hover .showOnHover': {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto'
    },
  },
  imageEdit: {
    position: 'absolute',
    display: 'none',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      bottom: 8,
      left: 'initial',
      right: 0,
      transform: 'unset',
    },
  },
  bannerBox: {
    position: 'relative',
    padding: '8px 16px',
    color: '#000',
    background: 'rgba(255, 255, 255, 0.7)',
    margin: '8px 16px',
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      minWidth: 280,
      padding: theme.spacing(1),
      margin: '16px 0 0',
      borderRadius: 0,
    }
  },
  bannerHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  clubMembership: {
    display: 'flex',
    marginLeft: 16,
  },
  bannerEdit: {
    position: 'absolute',
    bottom: -24,
    right: -64,
    [theme.breakpoints.down('sm')]: {
      bottom: -48,
      right: 0,
      padding: 0
    }
  },
  editAvatar: {
    height: 56,
    width: 56,
    backgroundColor: theme.palette.primary.main,
  },
  initialsBox: {
    fontSize: '128px',
    textAlign: 'center',
    backgroundColor: theme.palette.profile.main,
    borderColor: theme.palette.profile.main,
    borderRadius: '12px',
    padding: '30px 0',
  },
  playedTogetherIcon: {
    backgroundColor: '#5E99DC',
    width: '32px',
    height: '32px',
    border: 'solid 2px #ccc',
    display: 'inline-flex',
    marginRight: theme.spacing(2),
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  image: {
    border: 'solid 12px #FFF',
    borderRadius: '18px',
    minHeight: 200,
    width: '100%',
    marginTop: -1 * theme.spacing(16),
    [theme.breakpoints.down('sm')]: {
      marginTop: -1 * theme.spacing(24),
    },
  },
  description: {
    fontSize: 18,
    marginTop: 12,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      marginTop: 4
    },
  },
  location: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 12,
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
    },
  },
  handicap: {
    display: 'inline-block',
    fontSize: 14,
    fontFamily: theme.typography.secondaryFont,
    marginTop: 4,
    marginLeft: 16,
  },
  darkActionButton: {
    width: '100%',
    maxWidth: 300,
    padding: '8px 20px',
    margin: '20px 0',
    borderRadius: 50,
    color: '#FFF',
    fontWeight: 500,
    backgroundColor: theme.palette.primary.dark,
  },
}));
const ProfileBanner = ({userDetail, profile, readonly, history, updateProfile, updatePlayedTogether, setSelectedMembers, showEdit, toggleEdit, visibles, clubList, handleSelectMemberLists, handleGetLists}) => {
  const classes = useStyles()
  
  const [ showAvatarDialog, toggleAvatarDialog ] = React.useState(false)
  const [playedWithConfirmed, setStatePlayedWithConfirmed] = useState(userDetail && profile && profile.played_with)
  const [isLoading, setIsLoading] = useState(false)
  const [showLists, setShowLists] = React.useState(false);

  React.useEffect(() => {
    handleGetLists();
  }, [handleGetLists]);

  const getInitialsBox = () => {
    return (
      <div className={classes.initialsBox}>
        {(profile.first_name || ' ').charAt(0)}{(profile.last_name || '').charAt(0)}
      </div>
    )
  }
  const handlePlayedTogetherClick = (evt) => {
    setStatePlayedWithConfirmed(!playedWithConfirmed)
    setIsLoading(true)
    updatePlayedTogether(userDetail.email, evt.target.value, evt.target.checked)
  }

  const handleOpenListsDialog = () => {
    handleSelectMemberLists(profile)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [userDetail, profile])

  const handleClickChat = () => {
    setSelectedMembers([{
      value: profile.email, 
      label: `${profile.first_name} ${profile.last_name} (@${profile.username})`, 
      logo: profile.profile_image
    }])
    history.push(paths.messaging)
  }
  const primaryClub = profile?.primary_club ? clubList?.filter(club => club.id === profile?.primary_club) : null
  const myMemberships = primaryClub && primaryClub.length > 0 ? getUserMemberships(profile.email, visibles, primaryClub[0]) : getUserMemberships(profile.email, visibles)
  
  return (
    <div className={classes.content}>
      <ListsDialog
        open={showLists}
        toggleDialog={() => setShowLists(false)} />     
        <div className={classes.imageBox}>
            <React.Fragment>
              {!readonly && <IconButton onClick={() => toggleAvatarDialog(true)} className={cls(classes.imageEdit, 'showOnHover')}>
                <Avatar className={classes.editAvatar}><CameraIcon style={{fontSize: 28}} /></Avatar>
              </IconButton>}
              { profile.profile_image
                ? <img className={classes.image} src={profile.profile_image} alt="Profile" />
                : <div className={classes.image}>{getInitialsBox()}</div>
              }
            </React.Fragment>
        </div>
        <AvatarDialog
          open={showAvatarDialog}
          profile={profile}
          toggleDialog={toggleAvatarDialog}
        />
        <div className={classes.bannerBox}>
          {!readonly && <IconButton onClick={() => toggleEdit(true)} className={classes.bannerEdit}>
            <Avatar className={classes.editAvatar}><EditIcon style={{fontSize: 20}} /></Avatar>
          </IconButton>}
          <EditProfileDialog
            open={showEdit}
            profile={profile}
            toggleDialog={toggleEdit}
            handleConfirmation={updateProfile}
          />
          <div className={classes.bannerHeader}>
              {/* {readonly && (playedWithReceived || playedWithConfirmed) &&
                <Tooltip 
                  style={{cursor: 'pointer'}} 
                  onClick={() => togglePlayedConfirmation(true)}
                  title={
                    <Typography color="inherit">
                      {playedWithConfirmed ? "We've Played Together!" : `Confirm you've played with ${profile.first_name}`}
                    </Typography>
                  }
                  placement="top-start">
                    <Avatar className={classes.playedTogetherIcon}>
                      {playedWithConfirmed ? <GroupIcon style={{fontSize: 20}} /> : <QuestionIcon style={{fontSize: 20}} />}
                    </Avatar>
                </Tooltip>
              } */}
              <Typography variant="h5" align="left" style={{lineHeight: 2}}>{profile.first_name + ' ' + profile.last_name}
              {profile.handicap !== null && <div className={classes.handicap}>
                  <GolfIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />Hcp {profile.handicap}
                </div>}
              </Typography>
              {myMemberships && myMemberships.length > 0 && <div className={classes.clubMembership}>
                {myMemberships.map((item, idx) => {
                  if (!item.club_logo) { return <span key={idx} /> }
                  return <img key={idx} title={item.name} alt={item.name} src={item.club_logo} style={{width: 40, height: 40, verticalAlign: 'middle', marginRight: 16}} />
                })}
              </div>}
              {readonly && userDetail && userDetail.role === 'member' && profile && profile.role === 'member' && userDetail.email !== profile.email && 
                <div>
                <IconButton title={`Chat with ${profile.first_name}`} onClick={() => handleClickChat()} style={{marginLeft: 16}}>
                  <ChatIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => handleOpenListsDialog()}>
                  <PlaylistAdd />
                </IconButton>
                </div>
              }
          </div>
          <Typography className={classes.description} variant="subtitle2">
            {profile.about_me
              ? profile.about_me.replace(/,/g, ', ')
              : (!readonly ? <span style={{fontStyle: 'italic'}}>Add 5 words to describe yourself</span> : '')
            }
          </Typography>
          <Typography component='div' className={classes.location} variant="subtitle2">
            <div>
              {profile.address && profile.address.city && profile.address.state
                ? <React.Fragment>
                    <LocationIcon style={{verticalAlign: 'middle', marginRight: '8px', fontSize: 20}} />
                    <span>{profile.address.city + ', ' + profile.address.state}</span>
                  </React.Fragment>
                : (!readonly ? <span style={{fontStyle: 'italic'}}>Add your location</span> : '')
              }
            </div>
            {!userHasRole(profile, userRoles.memberReview) && <div style={{ height: '30px' }}>
              {isLoading ? <WidgetLoading size={15} /> : <PlayerConnections profile={profile} />}
            </div>}
          </Typography>
          {shouldShowPlayedWithToggle(readonly, userDetail, profile) && <div style={{margin: '16px auto 0', textAlign: 'center'}}>
            <ToggleButton text="Played Together" value={profile.email} checked={playedWithConfirmed || false} onClick={handlePlayedTogetherClick} />
          </div>}
        </div>
    </div>
  )
}

const mapState = (state) => {
  return {
    session: state.auth.session,
    userDetail: state.user.userDetail,
    visibles: state.members.membersVisible,
    clubList: state.clubs.clubList,
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    updateProfile: handleUpdateUser,
    updatePlayedTogether: handleWePlayedTogether,
    setSelectedMembers: handleSetSelectedMembers,
    handleSelectMemberLists,
    handleGetLists
  }, dispatch)
}
export default withRouter(connect(mapState, mapDispatch)(ProfileBanner))
