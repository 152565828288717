import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { paths } from '../utils/paths';
import Microlink from '@microlink/react'
import { getFormattedUsername } from '../utils/users';

const useStyles = makeStyles(theme => ({
  highlight: {
    color: theme.palette.link?.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

const TaggableBodyText = ({ text }) => {
  const classes = useStyles()
  // const theme = useTheme()
  // const mobile = useMediaQuery(theme.breakpoints.down('xs'))
  if (!text) { return text }

  const LinkFormatter = ({ segment }) => {
    const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const splitUrls = segment.split(urlPattern)
    if (!splitUrls || splitUrls.length <= 1) {
      return segment
    }
    return <div>
      {splitUrls.map((piece, idx) => {
        const result = piece.match(urlPattern)
        if (!result || result.length < 1) { return piece }
        if (piece.indexOf('http') < 0) { 
          piece = 'http://' + piece
        }
        if (piece.indexOf('.gif') > 0) {
          return <div key={idx}><img style={{maxWidth: '100%'}} onClick={() => window.open(piece)} src={piece} alt="Post" /></div>
        } 
        return <Microlink
          key={idx}
          url={piece}
          media={['video', 'audio', 'image', 'logo']}
          size='normal'
        />      
      })}
    </div>
  }
  
  const memberTagPattern = /@{(.*?)}}/g;
  const splitText = text.split(memberTagPattern);
  if (!splitText || splitText.length <= 1) {
    return <LinkFormatter segment={text} />
  }

  const result = splitText.map((item, idx) => {
    if (item.indexOf('{') !== 0) { return <LinkFormatter key={idx} segment={item} /> }
    const username = getFormattedUsername(item.substr(1))
    return <a key={idx} href={paths.profile + '/' + username} className={classes.highlight}>{'@' + username}</a>
  })
  return result
}

export default TaggableBodyText
