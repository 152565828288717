export const types = {
  CLUB_SEARCH_REQUEST: 'CLUBS/SEARCH_REQUEST',
  CLUB_SEARCH_SUCCESS: 'CLUBS/SEARCH_SUCCESS',
  CLUB_SEARCH_FAILURE: 'CLUBS/SEARCH_FAILURE',
  CLUB_ACTIVE_REQUEST: 'CLUBS/ACTIVE_REQUEST',
  CLUB_ACTIVE_SUCCESS: 'CLUBS/ACTIVE_SUCCESS',
  CLUB_ACTIVE_FAILURE: 'CLUBS/ACTIVE_FAILURE',
  CLUB_DETAIL_REQUEST: 'CLUBS/DETAIL_REQUEST',
  CLUB_DETAIL_SUCCESS: 'CLUBS/DETAIL_SUCCESS',
  CLUB_DETAIL_FAILURE: 'CLUBS/DETAIL_FAILURE',
  CLUB_MEMBERS_REQUEST: 'CLUBS/MEMBERS_REQUEST',
  CLUB_MEMBERS_SUCCESS: 'CLUBS/MEMBERS_SUCCESS',
  CLUB_MEMBERS_FAILURE: 'CLUBS/MEMBERS_FAILURE',
  CLUB_UPDATE_REQUEST: 'CLUBS/UPDATE_REQUEST',
  CLUB_UPDATE_SUCCESS: 'CLUBS/UPDATE_SUCCESS',
  CLUB_UPDATE_FAILURE: 'CLUBS/UPDATE_FAILURE',
  COURSE_UPDATE_REQUEST: 'COURSE/UPDATE_REQUEST',
  COURSE_UPDATE_SUCCESS: 'COURSE/UPDATE_SUCCESS',
  COURSE_UPDATE_FAILURE: 'COURSE/UPDATE_FAILURE',
  COURSE_DETAIL_REQUEST: 'COURSES/DETAIL_REQUEST',
  COURSE_DETAIL_SUCCESS: 'COURSES/DETAIL_SUCCESS',
  COURSE_DETAIL_FAILURE: 'COURSES/DETAIL_FAILURE',
  COURSE_SEARCH_REQUEST: 'COURSES/SEARCH_REQUEST',
  COURSE_SEARCH_SUCCESS: 'COURSES/SEARCH_SUCCESS',
  COURSE_SEARCH_FAILURE: 'COURSES/SEARCH_FAILURE',
  FAVORITE_COURSE_SEARCH_REQUEST: 'FAVORITE_COURSES/SEARCH_REQUEST',
  FAVORITE_COURSE_SEARCH_SUCCESS: 'FAVORITE_COURSES/SEARCH_SUCCESS',
  FAVORITE_COURSE_SEARCH_FAILURE: 'FAVORITE_COURSES/SEARCH_FAILURE',
  COURSE_TOP_US_REQUEST: 'COURSES/TOP_US_REQUEST',
  COURSE_TOP_US_SUCCESS: 'COURSES/TOP_US_SUCCESS',
  COURSE_TOP_US_FAILURE: 'COURSES/TOP_US_FAILURE',
  MEMBER_STATE_REQUEST: 'COURSES/MEMBER_STATE_REQUEST',
  MEMBER_STATE_SUCCESS: 'COURSES/MEMBER_STATE_SUCCESS',
  MEMBER_STATE_FAILURE: 'COURSES/MEMBER_STATE_FAILURE',
};

export const initialState = {
  activeClubs: [],
  clubList: [],
  courseList: [],
  favoriteCourses: [],
  coursesTopUS: [],
  clubDetail: null,
  clubMembers: {},
  membersByState: [],
  courseDetail: null,
  error: null,
  isLoading: false,
};

export const handleClubSearch = (search, limit, skip, isPublic) => (
  dispatch
) => {
  let params = {};
  if (search) {
    params.search = search;
  }
  if (limit) {
    params.limit = limit;
  }
  if (skip) {
    params.skip = skip;
  }
  if (isPublic) {
    params.public = isPublic;
  }
  dispatch({
    types: [
      types.CLUB_SEARCH_REQUEST,
      types.CLUB_SEARCH_SUCCESS,
      types.CLUB_SEARCH_FAILURE,
    ],
    url: '/clubs',
    query: params,
  });
};
export const handleActiveClubs = (search) => (dispatch) => {
  let params = {};
  if (search) {
    params.search = search;
  }
  dispatch({
    types: [
      types.CLUB_ACTIVE_REQUEST,
      types.CLUB_ACTIVE_SUCCESS,
      types.CLUB_ACTIVE_FAILURE,
    ],
    url: '/clubs/all/active',
    query: params,
  });
};
export const handleGetMembersByState = (abbr) => (dispatch) => {
  if (!abbr) {
    return;
  }
  dispatch({
    types: [
      types.MEMBER_STATE_REQUEST,
      types.MEMBER_STATE_SUCCESS,
      types.MEMBER_STATE_FAILURE,
    ],
    url: '/clubs/state/' + abbr + '/members',
  });
};


export const handleFavoriteCourseSearch = (ids = [], skip, limit=500) => (dispatch) => {
  let params = {};

  if(ids.length > 0){
    params.ids = ids

    if (skip) {
      params.skip = skip;
    }
    params.limit = limit
    dispatch({
      types: [
        types.FAVORITE_COURSE_SEARCH_REQUEST,
        types.FAVORITE_COURSE_SEARCH_SUCCESS,
        types.FAVORITE_COURSE_SEARCH_FAILURE,
      ],
      url: '/courses?public=true',
      query: params,
    });
  }
};

export const handleCourseSearch = (search, skip, limit=500) => (dispatch) => {
  let params = {};
  if (search) {
    params.search = search;
  }
  if (skip) {
    params.skip = skip;
  }
  params.limit = limit
  dispatch({
    types: [
      types.COURSE_SEARCH_REQUEST,
      types.COURSE_SEARCH_SUCCESS,
      types.COURSE_SEARCH_FAILURE,
    ],
    url: '/courses?public=true',
    query: params,
  });
};

export const handleCourseTopUS = () => (dispatch) => {
  dispatch({
    types: [
      types.COURSE_TOP_US_REQUEST,
      types.COURSE_TOP_US_SUCCESS,
      types.COURSE_TOP_US_FAILURE,
    ],
    url: '/courses/top/us',
  });
};

export const handleCourseDetail = (courseId) => (dispatch) => {
  dispatch({
    types: [
      types.COURSE_SEARCH_REQUEST,
      types.COURSE_DETAIL_SUCCESS,
      types.COURSE_SEARCH_FAILURE,
    ],
    url: '/courses/' + courseId,
  });
};
export const handleClubDetail = (clubId) => (dispatch) => {
  dispatch({
    types: [
      types.CLUB_DETAIL_REQUEST,
      types.CLUB_DETAIL_SUCCESS,
      types.CLUB_DETAIL_FAILURE,
    ],
    url: '/clubs/' + clubId,
  });
};
export const handleUpdateClub = (club, searchParams) => (dispatch) => {
  if (!club || !club.id || !club.name) {
    return;
  }
  if (!searchParams) {
    searchParams = {};
  }
  let formData = new FormData();
  formData.set('json', JSON.stringify(club));
  if (club.club_logo && typeof club.club_logo !== 'string') {
    formData.append('club_logo', club.club_logo);
  }
  dispatch({
    types: [
      types.CLUB_UPDATE_REQUEST,
      types.CLUB_UPDATE_SUCCESS,
      types.CLUB_UPDATE_FAILURE,
    ],
    url: `/clubs/${club.id}`,
    method: 'put',
    contentType: 'multipart/form-data',
    data: formData,
    timeout: 6000,
    callback: handleClubSearch(
      searchParams.search,
      searchParams.limit,
      searchParams.skip
    ),
  });
};

export const handleUpdateCourse = (course, searchParams, limit=500) => (dispatch) => {
  if (!course || !course.id) {
    return;
  }
  if (!searchParams) {
    searchParams = {};
  }

  dispatch({
    types: [
      types.COURSE_UPDATE_REQUEST,
      types.COURSE_UPDATE_SUCCESS,
      types.COURSE_UPDATE_FAILURE,
    ],
    url: `/courses/${course.id}`,
    method: 'put',
    data: {
      ...course
    },
    timeout: 6000,
    callback: handleCourseSearch(
      searchParams.search,
      null,
      limit
    ),
  });
};

export const handleAddClub = (club, searchParams) => (dispatch) => {
  if (!club || !club.name || !club.id_prefix) {
    return;
  }
  if (!searchParams) {
    searchParams = {};
  }
  // console.log('ADD CLUB', club)
  let formData = new FormData();
  formData.set('json', JSON.stringify(club));
  if (club.club_logo && typeof club.club_logo !== 'string') {
    formData.append('club_logo', club.club_logo);
  }
  dispatch({
    types: [
      types.CLUB_UPDATE_REQUEST,
      types.CLUB_UPDATE_SUCCESS,
      types.CLUB_UPDATE_FAILURE,
    ],
    url: `/clubs`,
    method: 'post',
    contentType: 'multipart/form-data',
    data: formData,
    timeout: 6000,
    // callback: handleClubSearch(searchParams.search, searchParams.limit, searchParams.skip)
  });
};
export const handleClubMembers = (clubId) => (dispatch) => {
  dispatch({
    types: [
      types.CLUB_MEMBERS_REQUEST,
      types.CLUB_MEMBERS_SUCCESS,
      types.CLUB_MEMBERS_FAILURE,
    ],
    url: '/clubs/' + clubId + '/members',
  });
};
export const handleClearClubDetail = () => (dispatch) => {
  dispatch({ type: types.CLUB_DETAIL_SUCCESS, data: null });
  dispatch({ type: types.COURSE_DETAIL_SUCCESS, data: null });
};
export default function (state = initialState, action) {
  switch (action.type) {
    case types.COURSE_TOP_US_SUCCESS:
      return { ...state, isLoading: false, coursesTopUS: action.data };
    case types.CLUB_SEARCH_SUCCESS:
      return { ...state, isLoading: false, clubList: action.data };
    case types.CLUB_ACTIVE_SUCCESS:
      return { ...state, isLoading: false, activeClubs: action.data };
    case types.CLUB_DETAIL_SUCCESS:
      return { ...state, isLoading: false, clubDetail: action.data };
    case types.COURSE_SEARCH_SUCCESS:
      return { ...state, isLoading: false, courseList: action.data };
    case types.FAVORITE_COURSE_SEARCH_SUCCESS:
      const newCourses = action.data
        .map(c => {
          const isDuplicate = state.favoriteCourses.some(f => f.id === c.id);
          return isDuplicate ? null : c;
        })
        .filter(c => c !== null);

      return { ...state, isLoading: false, favoriteCourses: [...state.favoriteCourses, ...newCourses] };
    case types.COURSE_DETAIL_SUCCESS:
      return { ...state, isLoading: false, courseDetail: action.data };
    case types.MEMBER_STATE_SUCCESS:
      const newList = state.membersByState.filter(
        (x) => x.state_abbr !== action.data.state_abbr
      );
      newList.push(action.data);
      return { ...state, isLoading: false, membersByState: newList };
    case types.COURSE_SEARCH_FAILURE:
    case types.CLUB_DETAIL_FAILURE:
    case types.CLUB_SEARCH_FAILURE:
    case types.CLUB_ACTIVE_FAILURE:
    case types.MEMBER_STATE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case types.COURSE_SEARCH_REQUEST:
    case types.CLUB_SEARCH_REQUEST:
    case types.CLUB_DETAIL_REQUEST:
    case types.CLUB_ACTIVE_REQUEST:
    case types.MEMBER_STATE_REQUEST:
      return { ...state, isLoading: true };
    default:
      return state;
  }
}
