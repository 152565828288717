import React from 'react'
import { IconButton, Typography } from '@material-ui/core';
import { Delete, Edit, ToggleOff, ToggleOn } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { handleAddMemberList, handleRemoveList, handleRemoveMemberList } from '../../reducers/lists';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
    toggleButton: {
        height: 35,
        width: 35,
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    toggleButtonActive: {
        //color: '#11274B',
        color: 'green',
        height: 35,
        width: 35,
        cursor: 'pointer'
    }
  }))

const ListDialogItem = ({list, handleSelectList, selectedMember, open, handleAddMemberList, handleRemoveMemberList, handleRemoveList}) => {
    const [isOnTheList, setIsOnTheList] = React.useState(false)
    const classes = useStyles()

    React.useEffect(() => {
        if(list.members && list.members.length > 0){
            setIsOnTheList(list.members.filter(m => m.member_email === selectedMember?.email).length > 0)
        }else{
            setIsOnTheList(false)
        }
        
    }, [list, selectedMember])

    const handleAddMember = () => {
        handleAddMemberList(list.id, selectedMember.email, 1)
    }

    const handleRemoveMember = () => {
        const listMember = list.members.filter(m => m.member_email === selectedMember.email)[0]
        handleRemoveMemberList(list.id, listMember.id)
    }

    const handleRemove = () => {
        handleRemoveList(list.id)
    }

    return <div style={{
        backgroundColor: '#F3F5F8',
        padding: '5px 15px',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px'

        }} >
        <div>
            <Typography variant='h6' style={{ fontSize: '12pt' }}>{list.name}</Typography>
            <Typography variant='caption'>Member count: {list.members &&  list.members.length ? list.members.length : 0}</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }} >
            { open && !selectedMember &&
                <>
                    <IconButton onClick={() => handleSelectList(list) } ><Edit /> </IconButton>
                    <IconButton><Delete onClick={() => handleRemove()} /> </IconButton>
                </>
            }
            { open && selectedMember ? !isOnTheList ? <ToggleOff className={classes.toggleButton} onClick={() => handleAddMember()} /> : <ToggleOn className={classes.toggleButtonActive} onClick={() => handleRemoveMember()} /> : null}
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
      selectedMember: state.lists.selectedMember
    }
}
  
const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleAddMemberList,
        handleRemoveMemberList,
        handleRemoveList
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(ListDialogItem);