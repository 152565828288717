import { userHasRole, userRoles } from "./profiles";

export function getFullListMembersWidget(applicantList, userDetail, memberList, type){
    let fullList = []

    if(type === 'Applicants'){
        fullList = applicantList
    }else{
        fullList =
        userDetail &&
        userDetail.username &&
        memberList &&
        memberList.filter(
            (x) =>  userHasRole(x, userRoles.member) && userDetail.username !== x.username
        );
    }

    return fullList
}

export function isApplicantNotAvailableForMemberReview(option, applicantList, user){
    if(option.value !== userRoles.memberReview) return false
    const applicant = applicantList.filter(applicant => applicant.email === user.email)
    
    if(applicant && applicant.length === 0) return true

    return applicant[0]?.privacy_setting === 'anonymous' ? true : false
}