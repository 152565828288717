import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import cls from 'classnames'
import { Button, Chip, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MountainIcon from '@material-ui/icons/Photo'
import {handleSubmitPost, handleUpdatePost} from '../../reducers/posts'
import PostImages from '../dashboard/postImages'
import ImageGridList from '../dashboard/imageGridList'
import TaggableInput from '../../components/taggableInput';
import SubmitPostButton from '../../components/SubmitPostButton';
import { isPostEmpty, shouldUpdatePost, submitNewPost, submitUpdatePost } from '../../utils/posts';
import { handleGetUserDetail } from '../../reducers/user';
import { MAX_IMAGES } from '../../utils/common';

const useStyles = makeStyles(theme => ({
  main: {
    padding: 16,
    backgroundColor: '#FFF',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  contentBox: {
    paddingBottom: 20,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
  },
  inputBox: {
    width: '100%',
    alignSelf: 'center'
  },
  input: {
    flex: 1,
    "&::placeholder": {
      fontSize: 14,
      color: "#999",
      fontStyle: 'italic',
    }
  },
  chip: {
    padding: 12,
    marginRight: 8,
    fontFamily: theme.typography.secondaryFont,
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&.disabled': {
      color: '#ccc'
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  chipIcon: {
    color: theme.palette.common.white,
    '&.disabled': {
      color: '#ccc'
    }
  },
  button: {
    width: '100%',
  },
  postButton: {
    maxWidth: 300,
    width: '100%',
    margin: 'auto'
  },
  tagWith: {
    border: 'solid 1px #CCC', 
    padding: 8, 
    justifyContent: 'center', 
    backgroundColor: '#e3e8f5', 
    borderRight: 0,
    fontWeight: 500,
  }
}))

const GeneralPostForm = ({ group, post, closeEdit, postError, handleSubmitPost, handleUpdatePost, isLoadingPost, userDetail, handleGetUserDetail }) => {
  const classes = useStyles()
  const [ submitError, setSubmitError ] = React.useState()
  const [ postText, setPostText ] = React.useState('')
  const [ showImageDialog, setImageDialog] = React.useState(false)
  const [media, setMedia] = React.useState([])
  
  React.useEffect(() => {
    handleGetUserDetail({ role: '', email: post.member_email })
  }, [post.member_email, handleGetUserDetail])

  React.useEffect(() => {
    if (post && !postText) {    
      setPostText(post.body_text)
      setMedia(post.photos)
    }
  }, [post, postText]);

  const handlePost = (e) => {
    if (isPostEmpty(postText, media)) { return }

    if (shouldUpdatePost(post, closeEdit)) {
      submitUpdatePost(post, postText, media, handleUpdatePost, closeEdit, true)
    } else {
      submitNewPost(postText, media, group, handleSubmitPost, userDetail)
    }
    setSubmitError('')
    setPostText('')
    setMedia([])
  }
  const handleFilesAdded = (files) => {
    setSubmitError('')
    setMedia(media?.concat(files))
  }
  const handleFileDeleted = (filename) => {
    let files = media?.filter(item => 
      (typeof item === "string" && item !== filename) ||
      (typeof item === "object" && item.name !== filename)
    )
    setSubmitError('')
    setMedia(files)
  }
  const onTextChange = (ev, value) => {
    setSubmitError('')
    setPostText(value)
  }
  return (
    <Paper elevation={closeEdit ? 0 : 2} className={classes.main}>
      <div className={classes.contentBox}>
        <PostImages
          open={showImageDialog}
          toggleDialog={setImageDialog}
          handleConfirm={handleFilesAdded}
        />
        <div className={classes.inputBox} style={{ wordBreak: 'break-word' }}>
          <TaggableInput
            value={postText}
            onChange={onTextChange}
            placeholder={"Post questions or comments for other members here. (Mention people using '@')"}
          />
        </div>
      </div>
      <div style={{maxWidth: '85vw'}}>
        {media && media?.length > 0 && <div className={classes.contentBox}>
          <ImageGridList media={media} handleDelete={handleFileDeleted} />
        </div>}
      </div>
      <div className={classes.contentBox}>
        <Chip
          className={cls(classes.chip, {'disabled': media?.length >= MAX_IMAGES})}
          onClick={() => {if (media?.length < MAX_IMAGES) {setImageDialog(true)}}} 
          clickable={media?.length < MAX_IMAGES}
          icon={<MountainIcon className={cls(classes.chipIcon, {'disabled': media?.length >= MAX_IMAGES})} />} 
          label="Insert Photos" />
      </div>
      {closeEdit ? <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button variant="contained" onClick={handlePost} style={{marginRight: 8}}>Save</Button>
        <Button variant="outlined" onClick={() => closeEdit()}>Cancel</Button>
      </div>
      :
        <SubmitPostButton handlePost={handlePost} disabled={ isLoadingPost } />
      }
      <Typography color="error" component="p" variant="subtitle1">
        {postError || submitError}
      </Typography>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    group: state.groups.groupDetail,
    postError: state.posts.error,
    isLoadingPost: state.posts.isLoadingPost,
    userDetail: state.user.userDetail,
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleSubmitPost,
    handleUpdatePost,
    handleGetUserDetail
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(GeneralPostForm)
