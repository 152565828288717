export function formatListOptions(lists, options) {
    if (lists && lists.length > 0) {
      options.push(
        ...lists.map((item) => ({
          value: item.id.toString(),
          label: item.name + ' list',
          type: 'list',
        }))
      );
    }
}