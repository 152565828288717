import { Button, Card, Collapse, makeStyles } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import bannerBackground from '../assets/images/Great_Waters_at_Reynolds_Lake_Oconee_-_Oct_2019.jpg'
import { getHomeScreenMessageData } from '../utils/common';
import HighlightOff  from '@material-ui/icons/HighlightOff';
import { handleCloseBanner } from '../reducers/references';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		backgroundColor: '#3278B3',
		display: 'flex',
		flex: 1,
		backgroundImage: `url(${bannerBackground})`,
		backgroundSize: 'cover',
		backgroundPosition: '0px -480px',
		minHeight: '170px'
	},
	internalContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		padding: '20px 30px',
		flexWrap: 'wrap'
	},
	text: {
		fontFamily: "Sentinel-Black,Roboto,sans-serif",
		fontSize: '20pt',
		fontWeight: '50 !important',
		color: '#fff',
		marginRight: '100px'
	},
	title: {
		fontSize: '30pt',
		margin: 0
	},
	subtitle: {
		margin: 0
	},
	buttonLarge: {
		padding: '3px 85px',
		fontSize: '17pt'
	},
	closeIcon: {
		color: '#fff',
		position: 'absolute',
		right: '10px',
		top: '10px',
		cursor: 'pointer'
	},
	[theme.breakpoints.down('md')]: {
		text: {
			marginRight: 0,
			marginBottom: '30px'
		},
		button: {
			flex: 1,
			display: 'flex',
			justifyContent: 'center'
		},
		container: {
			backgroundSize: 'cover',
			backgroundPosition: '0px 0px',	
		},
	},
	[theme.breakpoints.down('sm')]: {
		buttonLarge: {
			padding: '3px 25px',
		},
	}
}));

const Banner = ({ home_screen_message, handleCloseBanner, banner_open }) => {
	const classes = useStyles()

	if(!home_screen_message || home_screen_message.length === 0) return null
		
	const { text, button_text, button_url } = getHomeScreenMessageData(home_screen_message)
	
	const title = text.length > 0 ? text[0] : text
	
	return (text && text.length >= 1 && text[0] !== '') || (button_text !== '' && button_url !== '') ?
		<Collapse orientation="horizontal" in={banner_open}>
			<Card elevation={2}>		
					<div className={classes.container}>
						<HighlightOff className={classes.closeIcon}  onClick={() => handleCloseBanner() } />
						<div className={classes.internalContainer}>
							<div className={classes.text}>
								<p className={classes.title}>{title}</p>
								{text.map((text, i) => { return i !== 0 ? <p className={classes.subtitle}>{text}</p> : null })}
							</div>
							{ button_text && button_url && <div className={classes.button} >
								<Button size={'large'} className={classes.buttonLarge} variant="contained" onClick={() => window.open(button_url, '_blank', 'noopener,noreferrer')} >{button_text}</Button>
							</div>}
						</div>
					</div>
				</Card>
			</Collapse> : null
}
const mapStateToProps = (state) => {
  return {
    home_screen_message: state.references?.home_screen_message,
	banner_open: state.references?.banner_open
  }
}

const mapDispatch = (dispatch) => {
    return bindActionCreators({
		handleCloseBanner,
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatch)(Banner)