import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';
const styles = theme => ({
  root: {
    margin: theme.spacing(3, 0, 2)
  },
  filterButton: {
    marginRight: theme.spacing(1),
    '&.active': {
      fontWeight: '500',
      backgroundColor: theme.palette.primary.light
    }
  },
  buttonText: {
    fontSize: '12px',
    letterSpacing: '0.1px'
  },
})

const FilterBar = ({handleFilterClick, segments, activeId, classes}) => {
    const toggleButton = (id) => {
        handleFilterClick(id)
    }
    return (
        <div className={classes.root}>
            {segments.map((item, index) => (
              <Button 
                key={index} 
                onClick={() => toggleButton(item.id)} 
                color="primary" 
                classes={
                  {
                    label: classes.buttonText, 
                    textPrimary: classnames(classes.filterButton, {'active': activeId === item.id})
                  }
                }>
                  {item.label + `(${item.data.length})`}
              </Button>
            ))}
        </div>
    )
}

export default withStyles(styles)(FilterBar)
