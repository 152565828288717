// import { makeStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserAvatar from '../../components/userAvatar';
import WidgetContainer from '../../components/widgetContainer';
import WidgetElement from '../../components/widgetElement';
import { handleGetPlayGolfPosts, handleSelectPostType, handleShowDialogPost } from '../../reducers/posts';
import { parser } from '../../utils/parser';
import { compareDates } from '../../utils/common';

const PlayGolfWidget = ({ session, golfPosts, handleSelectPostType, handleShowDialogPost, handleGetPlayGolfPosts }) => {
  React.useEffect(() => {
    handleGetPlayGolfPosts()
  }, [handleGetPlayGolfPosts])
  return (
    <WidgetContainer header="Play Golf" action={() => {handleSelectPostType('play'); window.scroll(0, document.getElementById('feed-form-container').offsetTop);}} buttonLabel="+ Game">
      
      {golfPosts && golfPosts.sort(compareDates).map((post, idx) => {
        const game = post.id ? post.play_golf : post
        const openSpots = (game.total_seats || 99999) - (game.attendees || []).filter(x => x.accepted).length 
        return (<div key={idx}>
          <WidgetElement noClick styles={{flexWrap: 'nowrap', paddingTop: 8}}>
            <div style={{lineHeight: 2, marginLeft: 8}}>
              <Typography variant="subtitle1" style={{fontSize: 16}}>{parser.shortDate(game.date, 'MM/dd')} {(openSpots <= 0 ? ' - Full' : openSpots < 100 ? ' - ' + openSpots + (openSpots === 1 ? ' spot' : ' spots') : ' - Open')}</Typography>
              {game.club 
                  ? <Typography variant="subtitle2" style={{display: 'flex', padding: '2px 0'}}>
                      {game.club.club_logo && <div style={{marginRight: 8}}><UserAvatar profileImage={game.club.club_logo} width={24} isClub={true} /></div>}
                      <div>{game.club.name} {game.club.address && game.club.address.city ? '(' + game.club.address.city + ', ' + game.club.address.state + ')' : ''}</div>
                    </Typography>
                  : <Typography variant="subtitle2">{game.course_id}</Typography>
              }
            </div>
            <Button onClick={() => handleShowDialogPost(game.post_id)} size="small" variant="contained" style={{marginLeft: 'auto'}}>View</Button>
          </WidgetElement>
        </div>)
      })}
      {golfPosts.length === 0 && <Typography>
        {session.role === 'guest' ? 'Invitations to play golf will be listed here' : `Let other members know when and where you're planning to play.`}
      </Typography>
      }
    </WidgetContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    session: state.auth.session,
    golfPosts: parser.sortByDate(state.posts.golfPosts, { prop: 'date'})
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleSelectPostType,
    handleShowDialogPost,
    handleGetPlayGolfPosts
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatch)(PlayGolfWidget))