import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone'
import { MAX_IMAGES } from '../../utils/common';

const useStyles = makeStyles(theme => ({
    dropzoneContainer: {
        padding: '16px 32px',
        margin: '16px 0'
    },
}))

function PostImages ({open, toggleDialog, handleConfirm}) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()
    const [files, setFiles] = React.useState([])
    return (
        <Dialog
            open={open}
            onEnter={() => setFiles([])}
            onClose={() => toggleDialog(false)}
            fullScreen={fullScreen}
        >
            <DialogTitle id="edit-profile-picture">{`Select up to ${MAX_IMAGES} Files`}</DialogTitle>
            <DialogContent style={{padding: '0 30px'}}>
                <DropzoneArea 
                    onChange={setFiles}
                    showAlerts={false}
                    dropzoneText={fullScreen ? 'Click to select an Image' : 'Drag and drop an Image or click here to select an Image'}
                    filesLimit={MAX_IMAGES}
                    acceptedFiles={[
                        'image/gif',
                        'image/jpeg',   
                        'image/png', 
                        'image/tiff',  
                        'image/vnd.microsoft.icon',
                        'image/x-icon',
                        'image/vnd.djvu',   
                        'image/svg+xml',
                    ]}
                    dropzoneClass={classes.dropzoneContainer}
                    showPreviewsInDropzone={!fullScreen}
                    showFileNames={true}
                    showPreviews={fullScreen}
                    showFileNamesInPreview={false}
                    maxFileSize={50000000}
                />  
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {handleConfirm(files); toggleDialog(false)}} variant="contained">
                    Save
                </Button>
                <Button onClick={() => toggleDialog(false)} variant="outlined" color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PostImages;
  