function isPostUpdating(updatedPost, ignoreId = false) {
  return (ignoreId || !updatedPost?.id) && (updatedPost?.body_text || updatedPost?.attendees?.length > 1 || updatedPost?.photos?.length > 0) ? true : false
}

function isPostLoading(post, userDetail){
  return post && !post?.id && post.username && userDetail ? true : false
}

function isPostEmpty(postText, media){
  return (!postText || postText?.trim().length < 1) && media?.length < 1 ? true : false
}

function shouldUpdatePost(post, closeEdit){
  return post && post.id && closeEdit ? true : false
}

const submitNewPost = (postText, media, group, handleSubmitPost, userDetail) => {
  const userData = {
    member_email: userDetail.email,
    username: userDetail.username,
    first_name: userDetail.first_name,
    last_name: userDetail.last_name,
    profile_image: userDetail.profile_image,
    privacy_setting: userDetail.privacy_setting
  }

  handleSubmitPost(postText, media, group && group.id ? group.id : null, null, null, userData)
}

const submitUpdatePost = (post, postText, media, handleUpdatePost, closeEdit, isAdminUpdate = false) => {
  const newPost = { ...post, body_text: postText, photos: media?.filter(x => typeof x === 'string') }
  handleUpdatePost(newPost, media?.filter(x => typeof x !== 'string'), isAdminUpdate)
  closeEdit()
}

function arrayEquals(a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every(val => b.includes(val));
}

const ifTheAudienceListHasChanged = (list, oldList) => {
  return list && list.length > 0 && !arrayEquals(oldList, list?.map(a => a.value)) ? true : false
}

const getAudienceFilteredList = (attendees, item) => {
  const filteredAttendees = attendees?.filter((x) => x?.value !== item?.value)
  const filteredList = item ? [...filteredAttendees, item] : [...filteredAttendees]

  return filteredList
}

export { isPostUpdating, isPostLoading, isPostEmpty, shouldUpdatePost, submitNewPost, submitUpdatePost, ifTheAudienceListHasChanged, getAudienceFilteredList }