import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = theme => ({
  footermenu: {
    backgroundColor: theme.palette.profile.background,
    width: '100%',
    padding: '24px 0',
    margin: 'auto',
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.profile.main}`,
  },
  link: {
    color: theme.palette.link.main,
    textDecoration: 'none'
  },
  spacer: {
    display: 'inline',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
})

const ProfileFooter = ({ classes }) => (
  <div className={classes.footermenu}>
    <Typography variant="subtitle2" align="center" gutterBottom>
      {"All rights reserved. Captain's Club " + (new Date().getFullYear())}
    </Typography>
    <Typography variant="subtitle2" align="center">
      <a className={classes.link} rel="noopener noreferrer" href='https://docs.google.com/document/d/e/2PACX-1vSrq7A1bS98JGvZMqxw6XSBYZKCCPef4zvHqQCnaZPfYH04Yzw5dCU7slGDufrNm4EdG_bwYzb8RobF/pub' target="_blank">Terms & Conditions</a>
      <div className={classes.spacer}>&bull;</div>
      <a className={classes.link} rel="noopener noreferrer" href="https://docs.google.com/document/d/e/2PACX-1vSaNelPCPD5KEydUckCFaEOvvmZar-8iCkas78JTKUg0LGWD_sVHLmseezR9AKFWJWklcsBikVkR2jQ/pub" target="_blank">Privacy Policy</a>
    </Typography>
  </div>
);

export default withStyles(styles)(ProfileFooter);
