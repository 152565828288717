import React from 'react'
import {connect} from 'react-redux'
// import { Route, Switch} from 'react-router-dom'
import { CssBaseline } from '@material-ui/core';
import AdminLayout from './layout_admin'
import MemberLayout from './layout_member'
import GuestLayout from './layout_guest'
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
// import { SnackbarProvider } from 'material-ui-snackbar-redux'
import theme from '../assets/themes/theme';
import './app.css'

const App = ({ session }) => {
  const getLayout = () => {
    if (!session || !session.token) { return <GuestLayout /> }
    return session.role === 'admin' ? <AdminLayout /> : <MemberLayout />
  }
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {/* <SnackbarProvider SnackbarProps={{ autoHideDuration: 8000, anchorOrigin: { vertical: 'top', horizontal: 'center' } }}> */}
        <BrowserRouter>
            {getLayout()}
        </BrowserRouter>
      {/* </SnackbarProvider> */}
    </MuiThemeProvider>
  )
}

const mapState = (state) => {
  return {
    session: state.auth.session
  }
}
export default connect(mapState)(App)
