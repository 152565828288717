import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, TextField } from '@material-ui/core';
import PlainSelect from '../../components/plain_select';
import SectionTitle from './sectionTitle'
import { handleUpdateUser } from '../../reducers/user';
import { parser } from '../../utils/parser'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      padding: 8
    },
  },
  addMembershipPanel: {
    backgroundColor: "#F3F5F8",
    borderRadius: 0,
    padding: '12px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  headerRow: {
    padding: '8px 0 0 0',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center'
  },
  gear_list: {
    padding: '8px 0',
    fontSize: 14,
    color: theme.palette.profile.dark,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      padding: '2px 0'
    },
  },
  gearItem: {
    backgroundColor: "#F3F5F8",
    margin: 0,
    width: '100%',
    padding: '8px 0',
    marginBottom: '2px',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.profile.dark,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
      textAlign: 'left'
    },
  },
  mobileEditingGearItem: {
    alignItems: 'center',
    textAlign: 'center',
  },
}))

const localSizes = ["S", "M", "L", "XL", "XXL"];

const gear_preference_list = [
  { id: "golf_ball", label: "Golf Ball", brands: 'golf_ball_brands'},
  { id: "driver", label: "Driver", brands: 'golf_club_brands'},
  { id: "wood", label: "Woods", brands: 'golf_club_brands'},
  { id: "iron", label: "Irons", brands: 'golf_club_brands'},
  { id: "wedge", label: "Wedge", brands: 'golf_club_brands'},
  { id: "putter", label: "Putter", brands: 'golf_putter_brands'},
  { id: "glove", label: "Gloves", showSize: true, sizes: 'mens_glove_sizes', brands: 'glove_brands'},
  { id: "shoe", label: "Shoes", showSize: true, sizes: 'mens_shoe_sizes', brands: 'shoe_brands'},
  { id: "shirt", label: "Shirt", showSize: true, sizes: 'mens_shirt_sizes', brands: 'shirt_brands'},
  { id: "blazer", label: "Blazer", showSize: true, sizes: 'blazer_sizes', brands: 'blazer_brands'},
];

const GearListItem = ({gear, item, updateGear, references, isEditing}) => {
  const classes = useStyles()
  const [desc, setDesc] = React.useState()
  React.useEffect(() => {
    if (gear && item && gear[item.id]) {
      setDesc(gear[item.id].description)
    }
  }, [gear, item]);
  const saveDesc = () => {
    const newItem = {...gear[item.id], 'description': desc}
    const newGear = {...gear, [item.id]: newItem }
    updateGear(newGear)
  }
  const handleChange = (event) => {
    if (event.target.value === parser.list_delimiter) { return }
    const newItem = {...gear[item.id], [event.target.name]: event.target.value}
    const newGear = {...gear, [event.target.id]: newItem }
    updateGear(newGear)
  }
  if (!gear[item.id]) { return <div /> }
  return (
    <React.Fragment>
    <Hidden smDown>
      <Grid container spacing={1} className={classes.gearItem}>
        <Grid item xs={2}>{item.label}</Grid>
        <Grid item xs={3}>
          {isEditing ? <PlainSelect
            id={item.id}
            name='brand'
            options={references[item.brands]}
            value={gear[item.id].brand || ''}
            onChange={handleChange}
          />
          : gear[item.id].brand}
        </Grid>
        <Grid item xs={5}>
          {isEditing ? <TextField
            id={item.id}
            name='description'
            fullWidth
            className={classes.textField}
            value={desc || ''}
            onChange={(e) => setDesc(e.target.value)}
            onBlur={() => saveDesc()}
            margin="none"
            variant="outlined" />
          : gear[item.id].description}
          </Grid>
        <Grid item xs={2}>
          {isEditing ? (!item.showSize ? 'N/A' : <PlainSelect
            id={item.id}
            name='size'
            options={item.sizes ? references[item.sizes] : localSizes}
            value={gear[item.id].size || ''}
            onChange={handleChange}
          />)
          : gear[item.id].size }
        </Grid>
      </Grid>
    </Hidden>
    <Hidden mdUp> {/* MOBILE VIEW */}
    <div className={classes.gearItem}>
      <Grid container>
        <Grid item xs={12}>
          <div style={{fontWeight: 'bold'}}>{item.label}</div>
          { isEditing ? (
            <Grid container spacing={1} className={classes.mobileEditingGearItem}>
              <Grid item xs={5}>
                <PlainSelect
                  id={item.id}
                  name='brand'
                  options={references[item.brands]}
                  value={gear[item.id].brand || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id={item.id}
                  name='description'
                  fullWidth
                  className={classes.textField}
                  value={desc || ''}
                  onChange={(e) => setDesc(e.target.value)}
                  onBlur={() => saveDesc()}
                  margin="none"
                  variant="outlined" />
                </Grid>
              <Grid item xs={2}>
                {!item.showSize ? 'N/A' : <PlainSelect
                  id={item.id}
                  name='size'
                  options={item.sizes ? references[item.sizes] : localSizes}
                  value={gear[item.id].size || ''}
                  onChange={handleChange}
                />}
              </Grid>
            </Grid>
          ) : (
            <React.Fragment>
              <div>{gear[item.id].brand}</div>
              <div>{gear[item.id].description}</div>
              <div>{gear[item.id].size}</div>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </div>
    </Hidden>
    </React.Fragment>
  )
}

const GearPreferences = ({ profile, readonly, references, updateProfile }) => {
  const classes = useStyles()
  const [editing, setEditing] = React.useState(false)
  const [gearPrefs, setGear] = React.useState({})
  const initGear = (profile) => {
    let newGear = {}
    gear_preference_list.forEach((item) => {
      newGear[item.id] = {}
      newGear[item.id].brand = profile[item.id + '_brand']
      newGear[item.id].description = profile[item.id + '_description']
      newGear[item.id].size = item.showSize ? profile[item.id + '_size'] : ''
    })
    setGear(newGear)
  }
  React.useEffect(() => {
    initGear(profile)
  }, [profile]);
  React.useEffect(() => {
    setEditing(!readonly)
  }, [readonly]);
  const toggleEdit = (val) => {
    setEditing(val)
    if (!val) { initGear(profile) }
  }
  const saveGear = (newGear) => {
    let newProfile = {...profile}
    gear_preference_list.forEach(item => {
      newProfile[item.id + '_brand'] = newGear[item.id].brand
      newProfile[item.id + '_description'] = newGear[item.id].description
      if (item.showSize) {
        newProfile[item.id + '_size'] = newGear[item.id].size
      }
    })
    updateProfile(newProfile)
  }
  return (
    <div>
      <SectionTitle handleSave={() => saveGear()} toggleEdit={(val) => toggleEdit(val)}>My Gear Preferences</SectionTitle>
      <div className={classes.root}>
        <Hidden smDown>
          <div className={classes.headerRow}>
            <Grid container spacing={1}>
              <Grid item sm={2}>Type</Grid>
              <Grid item sm={3}>Brand</Grid>
              <Grid item sm={5}>Description</Grid>
              <Grid item sm={2}>Size</Grid>
            </Grid>
          </div>
        </Hidden>
        <div className={classes.gear_list}>
          {gear_preference_list.map((item, idx) => (
            <GearListItem
              isEditing={editing}
              gear={gearPrefs}
              item={item}
              updateGear={saveGear}
              references={references}
              key={idx} />
          ))}
        </div>
      </div>
    </div >
  )
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    updateProfile: handleUpdateUser
  }, dispatch)
}
const mapState = (state) => {
  return {
    references: state.references
  }
}

export default connect(mapState, mapDispatch)(GearPreferences)
