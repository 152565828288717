const userRoles = {
	applicant: "applicant",
  memberReview: "member_review",
  member: "member"
}

const ROLES = [
  "applicant",
  "member_review",
  "member"
]

const userHasRole = (user, role) => {
  if(user && user.role === role && ROLES.includes(role)) return true
  return false
}

const isApplicantOrMemberReview = (user) => {
  if(userHasRole(user, userRoles.applicant) || userHasRole(user, userRoles.memberReview)) return true
  return false
}

const shouldShowPlayedWithToggle = (readonly, userDetail, profile) => {
  return readonly && userDetail && userDetail.role === 'member' && profile && profile.role !== userRoles.memberReview && userDetail.email !== profile.email ? true : false
}

const shouldCountTopCoursesForProfile = (profile, courses) => {
  return profile && profile.favorite_courses && profile.favorite_courses.length > 0 && courses && courses.length > 0 ? true : false
}

const getTopUsaCourseCountForProfile = (profile, courses) => {
  if(shouldCountTopCoursesForProfile(profile, courses)){      
    let countCourses = 0      
    profile.favorite_courses.forEach(pc => {
      if(courses.filter(c => pc.id === c.id).length > 0){
        countCourses++;
      }
    })
    return countCourses
  }else{
    return 0
  }
}

function tryParseJSON(text) {
  try {
      return JSON.parse(text);
  }
  catch { 
      return undefined;
  }
}

function formatForAutoSelectAreasOfInterest(data){
  try {
      if (!data || data.length === 0) { return [] }

      const uniqueValues = {};

      return data.filter(x => x).map((item) => {
          const json = tryParseJSON(item);
          const value = json ? json.value : (item.value || item);

          if (!uniqueValues[value]) {
              uniqueValues[value] = true;
              return {
                  value: value,
                  label: json ? json.label : (item.label || item)
              };
          }

          return null; // Retorna null para excluir itens duplicados
      }).filter(item => item !== null);

  } catch (error) {
      console.warn('BAD DATA', data);
      console.warn('ERROR', error);
      return [];
  }
};

export {
  userHasRole,
  isApplicantOrMemberReview,
  shouldShowPlayedWithToggle,
  userRoles,
  shouldCountTopCoursesForProfile,
  getTopUsaCourseCountForProfile,
  formatForAutoSelectAreasOfInterest
}