import { Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { userRoles } from '../utils/profiles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  banner: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.textOnPrimary?.main,
    padding: theme.spacing(1),
    borderRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
  },
  content: {
    padding: theme.spacing(1),
    minHeight: 110,
  },
  cardAction: {
    justifyContent: 'flex-end'
  },
  cardActionMultiple: {
    justifyContent: 'space-between'
  }
}))

const WidgetContainer = ({ userDetail, header, buttonLabel, action, footer, children, type = userRoles.member }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader title={header} className={classes.banner} classes={{title: classes.header}} />
      <CardContent className={classes.content}>
        {children}
      </CardContent>
      <CardActions className={footer && type !== userRoles.memberReview ? classes.cardActionMultiple : classes.cardAction}>
        {footer}
        {userDetail && userDetail.role === 'member' && action && buttonLabel && 
          <Button variant="contained" onClick={action}>{buttonLabel}</Button>
        }
      </CardActions>
    </Card>
  )
}

const mapState = (state) => {
  return {
    userDetail: state.user.userDetail
  }
}

export default connect(mapState)(WidgetContainer)
