import { Avatar, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import EditIcon from '@material-ui/icons/Edit';
import UserAvatar from '../../components/userAvatar';
import { handleShowDialogPost, handleSubmitComment } from '../../reducers/posts';
import { paths } from '../../utils/paths';
import TravelPost from '../dashboard/travelPost';
import {CommentDisplay} from './commentDisplay';
import CommentInput from './commentInput';
import PlayGolfPost from './playGolfPost';
import PlayGolfForm from './playGolfForm';
import { GeneralPost } from './generalPost';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: '40%'
    },
    postEditIcon: {
        position: 'absolute',
        top: 4,
        right: 4,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    editAvatar: {
        height: 56,
        width: 56,
        backgroundColor: theme.palette.primary.main,
    },
    subheader: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '14px',
        fontWeight: '300',
        fontFamily: 'Roboto'
    },
    owner: {
        color: theme.palette.link?.main,
        cursor: 'pointer',
    },
}))
function PostDialog(props) {
    const {history, posts, golfPosts, userDetail, membersVisible, showDialogPostId, handleSubmitComment, handleShowDialogPost, groups} = props
    const classes = useStyles()
    const [post, setPost] = React.useState()
    const [editPost, setEditPost] = React.useState(false)
    const [comment, setComment] = React.useState();
    const group = groups?.find(x => x.id === post?.group_id)

    React.useEffect(() => {
        if (showDialogPostId) {
            let post = posts.find(x => x.id === showDialogPostId)
            if(!post){ //try to find a play golf post
                post = golfPosts.find(x => x.id === showDialogPostId)
            }

            setPost(post)
        } else {
            setPost(null)
        }
    }, [showDialogPostId, posts, golfPosts]);
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const visibleMemberList = (membersVisible || []).map(x => x.username)
    function submitComment() {
        if (!comment) { return }
        handleSubmitComment(post, comment)
        setComment('')
    }

    const openGroup = () => {
        handleShowDialogPost(null)
        history.push(`${paths.group}/${group?.url}`)
    }

    if (!post) { return <></> }
    return (
        <Dialog
            open={post && post.id ? true : false}
            onClose={() => handleShowDialogPost(null)}
            maxWidth="md"
            classes={{paper: classes.dialogPaper}}   
            fullScreen={fullScreen}>
            <DialogTitle>
                {post.is_admin || userDetail.role === 'member' ?
                    <div onClick={() => history.push(`${paths.profile}/` + post.username)}
                        style={{display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%'}}>
                        <div style={{marginRight: 8}}>
                            <UserAvatar profileImage={post.profile_image} width={24} />
                        </div>
                        <Typography variant="subtitle1">
                            <Link style={{color: theme.palette.link.main}} variant="subtitle1" onClick={() => history.push(`${paths.profile}/` + post.username)}>{post.first_name + ' ' + post.last_name}</Link>
                        </Typography>                        
                    </div>    
                : 
                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <div style={{marginRight: 8}}>
                            <UserAvatar profileImage={visibleMemberList.includes(post.username) && post.profile_image} width={24} />
                        </div>
                        <Typography variant="subtitle1">{visibleMemberList.includes(post.username) ? (post.first_name + ' ' + post.last_name) : 'Anonymous'}</Typography>
                    </div> 
                }
                <span className={classes.subheader}>{ post?.group_id && group ? <>Private Post. Only visible to <Link className={classes.owner} variant="subtitle2" onClick={openGroup}>{group?.name}</Link></> : (post?.play_golf ? 'Private Post: Only visible to those invited' : null) }</span>
            </DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                {post.member_email && userDetail && userDetail.email === post.member_email && !editPost ? <IconButton onClick={() => setEditPost(true)} className={classes.postEditIcon}>
                    <Avatar className={classes.editAvatar}><EditIcon style={{fontSize: 20}} /></Avatar>
                </IconButton> : <></>}
                {post.travel && <TravelPost post={post} />}
                {post.play_golf ? (
                    editPost 
                    ? <PlayGolfForm post={post} closeEdit={() => setEditPost(false)} />
                    : <PlayGolfPost post={post} memberList={membersVisible} userDetail={userDetail} />
                ) : <></> }
                {!post.travel && !post.play_golf 
                    ? <GeneralPost post={post} /> 
                    : <></>
                }
                { editPost ? <></> : 
                    <div>
                        {<div style={{marginTop: 16}}><CommentInput
                            value={comment}
                            placeholder='Add a comment...'
                            handleSubmit={submitComment}
                            handleUpdateText={setComment} />
                        </div>}
                        {post.comments && post.comments.map((comment, idx) => (
                            <Card key={idx} style={{marginTop: 8}}>
                                <CardContent>
                                    <CommentDisplay
                                        key={idx} 
                                        history={history} 
                                        comment={comment}
                                        post={post}
                                    />
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => handleShowDialogPost(null)}>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        posts: state.posts.allPosts,
        memberList: state.members.memberList,
        membersVisible: state.members.membersVisible,
        userDetail: state.user.userDetail,
        showDialogPostId: state.posts.showDialogPostId,
        groups: state.groups.groups,
        golfPosts: state.posts.golfPosts,
    }
  }
  const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handleShowDialogPost,
      handleSubmitComment
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatch)(withRouter(PostDialog))
