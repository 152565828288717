
// import { parser } from "../utils/parser"
// const dateFns = require('date-fns')


export const types = {
  GET_EVENTS_REQUEST: 'EVENTS/GET_EVENTS_REQUEST',
  GET_EVENTS_SUCCESS: 'EVENTS/GET_EVENTS_SUCCESS',
  GET_EVENTS_FAILURE: 'EVENTS/GET_EVENTS_FAILURE',
  SUBMIT_EVENTS_REQUEST: 'EVENTS/SUBMIT_EVENTS_REQUEST',
  SUBMIT_EVENTS_SUCCESS: 'EVENTS/SUBMIT_EVENTS_SUCCESS',
  SUBMIT_EVENTS_FAILURE: 'EVENTS/SUBMIT_EVENTS_FAILURE',
  DELETE_EVENTS_REQUEST: 'EVENTS/DELETE_EVENTS_REQUEST',
  DELETE_EVENTS_SUCCESS: 'EVENTS/DELETE_EVENTS_SUCCESS',
  DELETE_EVENTS_FAILURE: 'EVENTS/DELETE_EVENTS_FAILURE',
}

export const initialState = {
  eventList: [],
  error: null,
  isLoading: false,
}

// const generateEvents = (total) => {
//   const events = []
//   const states = parser.getStateList()
//   for (let count = 0; count < total; count++) {
//     const randValue = Math.random()
//     let category = ''
//     if (randValue >= 0.8) { category = 'captains_club' }
//     else if (randValue >= 0.6) { category = 'amateur' }
//     else if (randValue >= 0.4) { category = 'professional' }
//     else if (randValue >= 0.2) { category = 'pro_am' }
//     events.push({
//       id: count + 1,
//       name: 'Event ' + (count + 1),
//       start_date: parser.shortDateTime(dateFns.addDays(new Date(), (Math.random() >= 0.5 ? 1 : -1) * Math.floor(Math.random() * 200))),
//       city: 'Springfield',
//       state: states[count].value,
//       courses: ["USA-NJ-00181-01", "USA-NY-00552-01"],
//       category: category,
//       featured: randValue >= 0.5
//     })
//   }
//   return events
// }
export const handleGetEvents = (search, skip) => (dispatch) => {
  // return dispatch({ type: types.GET_EVENTS_SUCCESS, data: generateEvents(20) })
  // let params = {}
  // if (search) {
  //   if (typeof search === 'string') {
  //     params.search = search
  //   } else {
  //     params.search = search.text || ''
  //     params.clubId = search.clubId || ''
  //     params.location = search.location || ''
  //   }
  // }
  // if (skip) { params.skip = skip }
  dispatch({
    types: [types.GET_EVENTS_REQUEST, types.GET_EVENTS_SUCCESS, types.GET_EVENTS_FAILURE],
    url: '/events',
    // query: params
  })
}

export const handleSubmitEvent = (event) => dispatch => {
  if (!event || !event.name) { return }
  let formData = new FormData()
  formData.set('json', JSON.stringify(event))
  if (event.logo && typeof event.logo !== 'string') {
    formData.append('logo', event.logo)
  }
  dispatch({
    types: [types.SUBMIT_EVENTS_REQUEST, types.SUBMIT_EVENTS_SUCCESS, types.SUBMIT_EVENTS_FAILURE],
    url: event.id ? `/events/${event.id}` : `/events`,
    method: event.id ? 'put' : 'post',
    contentType: 'multipart/form-data',
    data: formData,
    timeout: 6000,
    callback: handleGetEvents()
  })
}
export const handleReorderEvents = (list) => dispatch => {
  dispatch({
    types: [types.SUBMIT_EVENTS_REQUEST, types.SUBMIT_EVENTS_SUCCESS, types.SUBMIT_EVENTS_FAILURE],
    url: `/events/`,
    method: 'patch',
    data: list,
    callback: handleGetEvents()
  })
}
export const handleDeleteEvent = (id) => dispatch => {
  dispatch({
    types: [types.DELETE_EVENTS_REQUEST, types.DELETE_EVENTS_SUCCESS, types.DELETE_EVENTS_FAILURE],
    url: `/events/${id}`,
    method: 'delete',
    callback: handleGetEvents()
  })
}
// export const handleShowDialogEvent = (postId) => dispatch => {
//   return dispatch({ type: types.SHOW_DIALOG_POST, data: postId })
// }
export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_EVENTS_SUCCESS:
      return { ...state, isLoading: false, eventList: action.data, error: null }
    case types.SUBMIT_EVENTS_SUCCESS:
    case types.DELETE_EVENTS_SUCCESS:
        return { ...state, isLoading: false, error: null }

    case types.GET_EVENTS_FAILURE:
    case types.SUBMIT_EVENTS_FAILURE:
    case types.DELETE_EVENTS_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    
    case types.GET_EVENTS_REQUEST:
    case types.SUBMIT_EVENTS_REQUEST: 
    case types.DELETE_EVENTS_REQUEST:
      return { ...state, isLoading: true, error: null }
    default:
      return state
  }
}
