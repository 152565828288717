import { Card, CardContent, CardActionArea, Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { parser } from '../../utils/parser'
import UserAvatar from '../../components/userAvatar';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(3),
    height: '100%',
    width: '60%',
    maxWidth: 520,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  content: {
    width: '100%',
    padding: 24,
    textAlign: 'center',

  },
  link: {
    color: theme.palette.link.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}))

const EventListItem = ({ eventItem, categoryList}) => {
  const classes = useStyles();
  if (!eventItem) { return <div /> }
  const getCourseDisplay = (course) => {
    const displayType = eventItem.course_display_type || 'club'
    switch (displayType) {
      case 'course':
        return course.course_name
      case 'both':
        return parser.formatCourseLabel(course)
      default:
        return course.club_name
    }
  }
  return (
    <Card raised={true} elevation={4} className={classes.root}>
      <CardActionArea style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100%', cursor: eventItem.url ? 'pointer' : 'default'}} onClick={() => eventItem.url ? window.open(eventItem.url) : {}}>
        <CardContent className={classes.content}>
          <Typography className={eventItem.url ? classes.link : ''} style={{textAlign: 'center', marginBottom: 16}} variant="subtitle1">{eventItem.name}</Typography>
          <Grid container spacing={2} style={{textAlign: 'center'}}>
            {eventItem.logo && <Grid item xs={12} sm={6}>
              {eventItem.logo ? <img src={eventItem.logo} width={180} height={180} alt='Event' title={eventItem.name} /> : <></>}
            </Grid>}
            <Grid item xs={12} sm={eventItem.logo ? 6 : 12}>    
              <Typography gutterBottom variant="subtitle2">
                {`${categoryList.find(x => x.value === eventItem.category).label} Event${eventItem.charity ? ' (Charity) ' : ' '}
                in ${eventItem.city}, ${eventItem.state} `}
              </Typography>
              <Typography variant="body2">{`${parser.shortDate(eventItem.start_date, 'MMM dd, yyyy')}${eventItem.end_date ? (' - ' + parser.shortDate(eventItem.end_date, 'MMM dd, yyyy')) : ''}`}</Typography>
              {eventItem.fee ? <Typography variant="body2">{`Event Cost: ${eventItem.fee}`}</Typography> : <></>}
              {eventItem.courses &&  eventItem.courses.map((course, idx) => (
                <Typography key={idx} component="div" variant="body2" style={{display: 'flex', padding: '2px 0', alignItems: 'center', justifyContent: 'center'}}>
                  {course.club_logo && <div style={{marginRight: 8}}><UserAvatar profileImage={course.club_logo} width={32} isClub={true} /></div>}
                  <div>{getCourseDisplay(course)}</div>
                </Typography>
              ))}
            </Grid>
          </Grid>
          <Typography style={{marginTop: 16, textAlign: 'justify', whiteSpace: 'pre-wrap'}} variant="subtitle2">
            {eventItem.description}
          </Typography>
          {eventItem.url && eventItem.button_text ? <Button onClick={() => window.open(eventItem.url)}  variant='contained'>{eventItem.button_text}</Button> : <></>}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default EventListItem
