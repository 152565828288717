import { IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useHistory } from "react-router";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserAvatar from '../../components/userAvatar';
import { paths } from '../../utils/paths';
import { getUserMemberships } from '../../utils/users';
import GolfIcon from '@material-ui/icons/GolfCourse';
import { handleRemoveMemberList } from '../../reducers/lists'

const useStyles = makeStyles(theme => ({
    memberWrap: {
      display: 'flex', 
      flexWrap: 'wrap', 
      alignItems: 'flex-start', 
      marginBottom: 4, 
      padding: '4px',
      backgroundColor: theme.palette.lightGray.main,
      borderRadius: 8,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      }
    },
    memberName: {
      display: 'flex',
      fontSize: 14,
      fontFamily: "Sentinel-Black,Roboto,sans-serif",
      fontWeight: 400,
      lineHeight: 1
    }
}))

const MemberListItem = ({memberData, userDetail, membersVisible, handleRemoveMemberList}) => {
    const history = useHistory();
    const classes = useStyles();
    const visibleMemberList = (membersVisible || []).map(x => x.username)
    const memberUpdated = membersVisible?.filter(m => m.email === memberData.member_email)[0]
    const myMemberships = getUserMemberships(memberUpdated.member_email, membersVisible)

    const handleDeleteMember = () => {
      handleRemoveMemberList(memberData.list_id, memberData.id)
    }

    return (
        <div key={memberUpdated.member_email}>
          <div className={classes.memberWrap} >
            <div style={{display: 'flex', width: '100%', alignItems: 'center'}} >
              <div style={{ display: 'flex' , flexDirection: 'column'}}>
                <div style={{display: 'flex', width: '100%', alignItems: 'center', padding: '6px 0'}} onClick={() => history.push(`${paths.profile}/${memberUpdated.username}`)}>
                  <div style={{marginRight: 8}}>
                    <UserAvatar profileImage={visibleMemberList.includes(memberUpdated.username) ? memberUpdated.profile_image : null} width={24} />
                  </div>
                  <div>
                    <Typography component="div" className={classes.memberName}>
                      {visibleMemberList.includes(memberUpdated.username) ? `${memberUpdated.first_name} ${memberUpdated.last_name}` : 'Anonymous'}
                      {memberUpdated.member_email === userDetail.email && userDetail.privacy_setting === 'anonymous' && 
                        <Typography variant="body2" style={{fontSize: 11, paddingLeft: 8}}><Link to={paths.profile}>(Anonymous)</Link></Typography>}
                    </Typography>
                  </div>                       
                </div>
                <div>
                  <Typography variant="body2" style={{display: 'flex', alignItems: 'center', padding: '2px 0', fontSize: 10, paddingLeft: 30}} >
                    {memberUpdated?.address && memberUpdated?.address.city ? memberUpdated?.address.city + ', ' + memberUpdated?.address.state : '' }
                    {memberUpdated?.industry ? <span style={{color: '#777'}}><span style={{margin: '0 8px'}}>&bull;</span>{memberUpdated?.industry} Industry</span> : ''}
                  </Typography>
                </div>
              </div>
                <div style={{marginLeft: 'auto'}}>
                    <IconButton onClick={() => handleDeleteMember(memberData.id)}><DeleteIcon /></IconButton>
                </div>
            </div>
            <div  style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{paddingLeft: 30}}>
              {myMemberships && myMemberships.map((item, idx) => {
                return (
                  <Typography key={idx} variant="body2" component="div" style={{display: 'flex', alignItems: 'center', padding: '2px 0', fontSize: 10}}>
                    {item.club_logo && <div style={{marginRight: 8}}><UserAvatar profileImage={item.club_logo} width={24} isClub={true} /></div>}
                    <div>{item.name} {item.address && item.address.city ? '(' + item.address.city + ', ' + item.address.state + ')' : ''}</div>
                  </Typography>
                )
              })}
              </div>
              <div style={{ paddingLeft: 30 }} >
                <Typography variant="subtitle2" style={{ display: 'flex', flexDirection: 'row', fontSize: 10, alignItems: 'center' }} >
                  {memberUpdated?.handicap !== null && <div className={classes.handicap}>
                    <GolfIcon style={{ verticalAlign: 'middle', marginRight: 4, width: 15 }} />Handicap {memberUpdated?.handicap}
                  </div>}
                  {memberUpdated?.best_gross_round && <span><span style={{margin: '0 8px'}}>&bull;</span>Best Round: {memberUpdated?.best_gross_round}</span>}
                </Typography>
              </div>
            </div>
          </div>
        </div>)
}

const mapStateToProps = (state) => {
    return {
      userDetail: state.user.userDetail,
      membersVisible: state.members.membersVisible
    }
}
  
const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handleRemoveMemberList
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(MemberListItem);