import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Card, CardMedia, ThemeProvider } from '@material-ui/core';
import WidgetLoading from './widgetLoading';

const useStyles = makeStyles(theme => ({
    centerPaper: {
        display: 'flex',
        justifyContent: 'center',
    }
}));

export default function ImageDialog({src, open, toggleDialog}) {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [isLoading, setIsLoading] = useState(true)

    const closeDialog = () => {
        toggleDialog(null)
        setIsLoading(true)
    }

    function getStyle(){
        let style = {}
        style = fullScreen ? {} : { maxHeight: 'calc(100vh - 150px)' }
        style = {...style, display: (isLoading ? 'none' : 'block') }
        return style
    }

    const getMuiTheme = () => createTheme({
        overrides: {
          MuiCardMedia: {
            media: {
              width: undefined
            }
          }
        }
      })

    if (!src) { return <span /> }
    
    return (
        <Dialog
            open={open}
            onClose={() => closeDialog()}
            fullScreen={fullScreen}
            classes={{paper: classes.centerPaper}}
            maxWidth={'xl'}
        >
            <DialogContent style={{flexGrow: 0}}>
                <Card>
                    <ThemeProvider theme={getMuiTheme()}>
                        <CardMedia
                            component="img"
                            image={src.includes("w_300") ? src.replace("w_300", "w_1.0") : src}
                            alt="Image Preview"
                            title="Image Preview"
                            style={ getStyle() }
                            onLoad={ () => setIsLoading(false) }
                        />
                        { isLoading && <WidgetLoading style={{ position: 'absolute', top: '50%', right: '50%' }} size={20} /> }
                    </ThemeProvider>
                 </Card>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={closeDialog}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
