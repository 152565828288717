import React from 'react';
import { TextField, Button } from '@material-ui/core';
import MoveIcon from '@material-ui/icons/Menu';
import { getDataListItemLabel } from '../../utils/datalists';

const DatalistItem = ({id, index, item, showMove, handleSave, handleDelete, label}) => {
  const [edit, setEdit] = React.useState(false)
  const [value, setValue] = React.useState()
  React.useEffect(() => {
    setValue(typeof item === 'object' ? item.value : item)
  }, [item]);
  const onSave = () => {
    setEdit(false)
    handleSave(index, typeof item === 'object' ? { ...item, value } : value)
  }
  const onCancel = () => {
    setEdit(false)
    setValue(typeof item === 'object' ? item.value : item)
  }

  return (
      <div style={{display: 'flex'}}>
        {showMove && <MoveIcon style={{cursor: 'grab', alignSelf: 'center', marginRight: 8}} />}
        <TextField
            id={typeof item === 'object' ? item.value : item}
            name={typeof item === 'object' ? item.value : item}
            label={getDataListItemLabel(index, id)}
            fullWidth
            disabled={!edit}
            margin="dense"
            variant="outlined"
            multiline
            value={value || ''}
            onChange={(e) => setValue(e.target.value)}
          />
        {!edit && <React.Fragment>
          <Button onClick={() => setEdit(true)}>Edit</Button>
          {handleDelete && <Button onClick={() => handleDelete(item)}>Delete</Button>}
        </React.Fragment>}
        {edit && <React.Fragment>
          <Button onClick={() => onSave()}>Save</Button>
          <Button onClick={() => onCancel()}>Cancel</Button>
        </React.Fragment>}
      </div>
  )
}

export default DatalistItem
