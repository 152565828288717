// import { snackbarReducer } from 'material-ui-snackbar-redux'
import { combineReducers } from 'redux'
import auth, { types as authTypes } from './auth'
import clubs from './clubs'
import events from './events'
import groups from './groups'
import guests from './guests'
import linkAccess from './linkAccess'
import members from './members'
import notifications from './notifications'
import posts from './posts'
import references from './references'
import user from './user'
import lists from './lists'


const appReducers = combineReducers({
  auth,
  clubs,
  events,
  groups,
  guests,
  linkAccess,
  members,
  notifications,
  posts,
  references,
  user,
  lists
  // snackbar: snackbarReducer
})

const rootReducer = (state, action) => {
  if (action.type === authTypes.LOGOUT) {
      state = undefined
  }
  return appReducers(state, action)
}
export default rootReducer
