import { IconButton } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import MUIDataTable from "mui-datatables";
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WidgetLoading from '../../components/widgetLoading';
import { handleClearClubDetail, handleClubDetail, handleClubSearch, handleUpdateClub } from '../../reducers/clubs';
import ClubFormDialog from './clubFormDialog';

// const useStyles = makeStyles(theme => ({
//   linkButton: {
//     textTransform: 'capitalize',
//     cursor: 'pointer'
//   },
// }))

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: '11px'
      },
      root: {
        fontSize: '11px'
      }
    }
  }
})

const ClubsTable = ({
  clubs,
  clubDetail,
  isLoading,
  handleClubDetail,
  handleClearClubDetail,
  handleClubSearch,
  handleUpdateClub
}) => {
  // const classes = useStyles()
  const [ clubId, setClubId ] = React.useState()
  const [ tableStore, setTableStore ] = React.useState({})
  // React.useEffect(() => {
  //   handleClubSearch(null,  10)
  // }, [handleClubSearch]);
  const columns = [
    { name: "id", label: "Id", options: { filter: false, sort: false } },
    { name: "name", label: "Club Name", options: { filter: false, sort: false, } },
    { name: "address.city", label: "City", options: { filter: true, sort: false, } },
    { name: "address.state", label: "State", options: { filter: true, sort: false, } },
    { name: "club_logo", 
      label: "Logo", 
      options: { 
        filter: false, 
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) { return <div /> }
          return <div style={{maxWidth: 48, height: 'auto'}}>
            <img style={{maxWidth: '100%'}} alt='Club Logo' src={value} />
          </div>
        }
      
      } },
    // { name: 'charter', // 8
    //   options: {
    //     display: 'false',
    //     viewColumns: false,
    //     filter: false
    //   }
    // },
    { name: "actions",
      label: " ",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!tableMeta.rowData) { return <div /> }
          const clubId = tableMeta.rowData[0]
          return (
          <div style={{width: '100%', textAlign: 'center'}}>
            <IconButton onClick={() => {setClubId(clubId); handleClubDetail(clubId)}}>
              <ViewIcon />
            </IconButton>
          </div>
          )
        }
      }
    },
   ];
  const tableOptions = {
    count: 9999,
    download: false,
    enableNestedDataAccess: '.',
    filter: false,
    // filterType: 'dropdown',
    fixedHeader: false,
    page: tableStore.page || 0,
    print: false,
    responsive: 'simple',
    rowHover: false,
    rowsPerPage: tableStore.rowsPerPage || 10,
    rowsPerPageOptions: [10, 25, 50],
    searchOpen: true,
    searchText:  tableStore.searchText || '',
    selectableRows: 'none',
    serverSide: true,
    sort: false,
    // customSearchRender: (searchText, handleSearch, hideSearch, options) => {
    //   console.log('CUSTOM SEARCH', searchText, handleSearch, hideSearch, options)
    //   return (
    //     <CustomSearch
    //       searchText={searchText}
    //       onSearch={handleSearch}
    //       onHide={hideSearch}
    //       options={options}
    //     />
    //   );
    // },
    onTableChange: (action, tableState) => {
      // console.log('TABLE CHANGE', action, tableState);
      switch (action) {
        case 'changePage':
        case 'changeRowsPerPage':
        case 'search':
          setTableStore(tableState)
          getData(tableState)
          break
        default:
          // console.log('unhandled', action)
          return
      }
    }
  };
  // const getThrottledData =  _.throttle(handleClubSearch, 250)
  const getData = (tableState) => {
    if (!tableState.searchText || tableState.searchText.length > 2) {
      handleClubSearch(tableState.searchText, tableState.rowsPerPage, Number(tableState.rowsPerPage) * Number(tableState.page), true)
      // getThrottledData(tableState.searchText, tableState.rowsPerPage, Number(tableState.rowsPerPage) * Number(tableState.page))
    }
  }

  if (!tableStore.searchText && clubs.length === 0) { return <div style={{paddingTop: 48}}><WidgetLoading /></div> }
  const clearClub = () => {
    setClubId(null)
    handleClearClubDetail()
  }
  const onUpdateClub = (newClub) => {
    handleUpdateClub(newClub, { search: tableStore.searchText, limit: tableStore.rowsPerPage, skip: Number(tableStore.rowsPerPage) * Number(tableStore.page)})
  }
  return (
    <React.Fragment>
      <ClubFormDialog
        open={Boolean(clubId)}
        toggleDialog={clearClub}
        clubDetail={clubDetail}
        isLoading={isLoading}
        saveChanges={onUpdateClub}
      />
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable 
          title=''
          data={clubs} 
          columns={columns} 
          options={tableOptions} 
        />
      </MuiThemeProvider>
    </React.Fragment>
  )
}

const mapState = (state) => {
  return {
    clubs: state.clubs.clubList,
    clubDetail: state.clubs.clubDetail,
    isLoading: state.clubs.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleClearClubDetail,
    handleClubDetail,
    handleClubSearch,
    handleUpdateClub
  }, dispatch)
}
export default connect(mapState, mapDispatchToProps)(ClubsTable);
