import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { handleDeleteGroup } from '../../reducers/groups'
import WidgetContainer from '../../components/widgetContainer';
import { Typography, TextField } from '@material-ui/core';
import { paths } from '../../utils/paths';

const DeleteGroup = ({history, group, handleDeleteGroup}) => {
  const [deleteText, setDeleteText] = React.useState([])
  const deleteClick = () => {
    if (!deleteText || !group || !group.url) { return }
    handleDeleteGroup(group.url)
    window.scroll(0, 0)
    history.push(paths.home)
  }
  return (
    <WidgetContainer header={'Delete Group'} buttonLabel={deleteText === 'DELETE' || deleteText === 'delete' ? 'SUBMIT' : ''} action={deleteClick}>
      <Typography variant="body1">
        Deleting a group is <strong>permanent</strong>.  Type DELETE to remove this group
      </Typography>
      <TextField
        id="deleteText"
        name="deleteText"
        label="DELETE"
        fullWidth
        margin="normal"
        variant="outlined"
        value={deleteText || ''}
        onChange={(event) => setDeleteText(event.target.value)}
        // className={classes.textField} 
      />
    </WidgetContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleDeleteGroup,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatch)(DeleteGroup))
