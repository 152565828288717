import React from 'react'
import { Typography } from '@material-ui/core';
import CodeOfConductLink from './codeOfConductLink';

const CodeOfConductAlert = () => {
    return <Typography component='p' variant='caption'>
        Note: Your travel post will be shown in the feed below to all members. In addition it will automatically also show on your member profile page and in the member travel section. Members you have chosen to notify above will also receive an email and a mobile app notification about your travel. Please make sure your post complies with the <CodeOfConductLink /> and you are not soliciting access to a specific club.
    </Typography>
}
  
export default CodeOfConductAlert;