import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const CodeOfConductLink = ({ color='#0000EE', hasQuotes = true, link }) => {
    return (<a style={{ textDecoration: 'none', color }}  href={link} target='_blank' rel="noopener noreferrer">{hasQuotes ? '“Code of Conduct”' : 'Code of Conduct'}</a>)
}

const mapStateToProps = (state) => {  
    return {
      link: state.references.code_of_conduct_link
    };
  };
  const mapDispatch = (dispatch) => {
    return bindActionCreators(
      {},
      dispatch
    );
  };
  
  export default connect(mapStateToProps, mapDispatch)(CodeOfConductLink);