import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { parser } from '../utils/parser';
import { paths } from '../utils/paths';
import UserAvatar from './userAvatar';

const useStyles = makeStyles(theme => ({
  notificationItem: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    borderBottom: 'solid 1px #e7e7ea',
    padding: 8,
  },
}))

const NotificationApprovedMember = ({ message, closeNotifications }) => {
  const classes = useStyles()
  return <div className={classes.notificationItem}>
    <Link onClick={() => closeNotifications()} to={`${paths.profile}/` + message.data.sender_username} style={{marginRight: 8}}>
      <UserAvatar profileImage={message.data.sender_profile_image} width={32} />
    </Link> 
    <Typography variant="caption">
      Please welcome&nbsp;
      <Link onClick={() => closeNotifications()} to={`${paths.profile}/` + message.data.sender_username}>{message.data.sender_name || `@${message.data.sender_username}`}</Link>
      &nbsp;to Captain's Club.<br />
      {message.data.sender_first_name} is a member at: {message.data.club_memberships}
      {message.read && <div style={{fontSize: 10}}>{parser.dateDistance(message.issued_at)} ago</div>}
    </Typography>
  </div>
}

export default NotificationApprovedMember
