import { Card, CardActions, CardContent, CardHeader, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserAvatar from '../../components/userAvatar';
import { handleDeletePost } from '../../reducers/posts';
import { parser } from '../../utils/parser';
import TaggableBodyText from '../../components/taggableBodyText';
import ImageGridList from '../dashboard/imageGridList';
import GeneralPostForm from './postEditForm';
import { PostGroupName } from '../../components/PostGroupName';

const useStyles = makeStyles(theme => ({
  main: {
    margin: '8px 0',
    padding: '4px 16px',
    backgroundColor: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: 8
    },
  },
  cardContent: {
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      padding: 8
    }
  },
  cardActions: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px'
    }
  },
  submitDate: {
    marginLeft: 'auto',
    color: '#777'
  },
}))

const PostItem = ({ post, handleDeletePost }) => {
  const classes = useStyles()
  const [editPost, setEditPost] = React.useState(false)
  function handleDeleteClick() {
    if (window.confirm('Are you sure you want to remove this item?')) {
      handleDeletePost(post, true)
    }
  }
  return (
    <Card elevation={2} className={classes.main}>
      <CardHeader
          style={{borderBottom: editPost ? 'none' : 'solid 1px #ccc', padding: '8px 0'}}
          avatar={<div style={{cursor: 'pointer'}}>
              <UserAvatar profileImage={post.profile_image} />
            </div>
          }
          action={
            <div>
              <IconButton onClick={() => setEditPost(!editPost)}><EditIcon /></IconButton>
              <IconButton onClick={() => handleDeleteClick()}><DeleteIcon /></IconButton>
            </div>
          }
          title={<div style={{display: 'flex'}}>
            <React.Fragment>
              <Typography variant="subtitle1">{post.first_name + ' ' + post.last_name}</Typography>
            </React.Fragment>
          </div>
          }
      />
      <PostGroupName post={post} />
      {editPost ? 
        <GeneralPostForm 
            post={post}
            closeEdit={() => setEditPost(false)}
        />
      : <>
        <CardContent className={classes.cardContent}>
        <React.Fragment>
            <div style={{width: '100%'}}>
              <Typography component="div" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}><TaggableBodyText text={post.body_text} /></Typography>
            </div>
            <div style={{maxWidth: '85vw'}}>
              <ImageGridList media={post.photos} />
            </div>            
        </React.Fragment>  
      </CardContent>
      <CardActions className={classes.cardActions}>
        <div className={classes.submitDate}>
          {post.edited ? `Edited ${parser.dateDistance(post.edited)} ago` : `Added ${parser.dateDistance(post.created_at)} ago`}
        </div>
      </CardActions>
      </>}
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleDeletePost,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatch)(PostItem))
