
import DateFnsUtils from "@date-io/date-fns";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, MenuItem, Paper, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/styles';
import cls from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutoSelect from '../../components/auto_select';
import AttendeeDialog from "../../components/dialog_attendees";
import { handleCourseSearch, handleCourseDetail } from '../../reducers/clubs';
import { handleSubmitPlayGolf, handleUpdatePost } from '../../reducers/posts';
import { parser } from '../../utils/parser';
import AudienceSelector from './audienceSelector';

import MomentUTCUtils from "../../utils/MomentUTCUtils";
import moment from 'moment';
import { ifTheAudienceListHasChanged } from "../../utils/posts";

const useStyles = makeStyles(theme => ({
  main: {
    padding: 16,
    backgroundColor: '#FFF',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  textField: {
    minWidth: 150
  },
  multiLineTextBox: {
    margin: '8px 0',
    '& textarea': {
      fontFamily: theme.typography.secondaryFont
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 14px'
    }
  },
  button: {
    width: '100%',
  },
  postButton: {
    maxWidth: 300,
    width: '100%',
    margin: 'auto'
  },
  dateField: {
    marginRight: 8,
    '&.not-selected': {
      '& input': {
        color: '#FFF'
      }
    }
  },
  linkToggle: {
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.palette.link.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  }
}))

const PlayGolfForm = ({ post, closeEdit, userDetail, groupDetail, memberList, courses, courseDetail, handleCourseDetail, handleCourseSearch, handleSubmitPlayGolf, handleUpdatePost}) => {
  const classes = useStyles()
  const [notificationOptions, setNotificationOptions] = React.useState([])
  const [notificationPreference, setNotificationPreference] = React.useState('')
  const [multiDate, setMultiDate] = React.useState(false)
  const [selectedCourse, setSelectedCourse] = React.useState()
  const [selectedDate, setSelectedDate] = React.useState() // new Date(new Date().setDate(new Date().getDate() + 1)).setHours(9, 0, 0)
  const [endDate, setEndDate] = React.useState()
  const [gameDescription, setGameDescription] = React.useState('')
  const [gameNotes, setGameNotes] = React.useState('')
  const [playerCount, setPlayerCount] = React.useState('')
  const [guestFee, setGuestFee] = React.useState('')
  const [ attendees, setAttendees ] = React.useState([])
  const [ existingAttendees, setExistingAttendees ] = React.useState([])
  const [guestList, setGuestList] = React.useState([])
  const [outOfStateAttendees, setOutOfStateAttendees] = React.useState()
  const [courceState, setCourceState] = React.useState('');

  React.useEffect(() => {
    if (post && post.play_golf && post.play_golf.club) {    
      setNotificationPreference('')
      const playGolf = post.play_golf
      setSelectedCourse({ value: playGolf.course_id, label: parser.formatCourseLabel(playGolf.club), logo: playGolf.club.club_logo })
      handleCourseDetail(playGolf.course_id)
      setSelectedDate(new Date(playGolf.date))
      if (playGolf.end_date) {
        setEndDate(new Date(playGolf.end_date))
        setMultiDate(true)
      }
      setGameDescription(playGolf.description)
      setGameNotes(playGolf.note)
      setPlayerCount(playGolf.total_seats)
      setGuestFee(playGolf.guest_fees)
      setGuestList((playGolf.guest_list || []).map(guest => {
        return {
          value: guest.email || guest,
          label: guest.first_name ? guest.first_name + ' ' + guest.last_name : guest,
          logo: guest.profile_image || '',
          email: guest.email || '',
          first_name: guest.first_name || '',
          last_name: guest.last_name || '',
          role: guest.role || 'guest',
          address: guest.address || {}
        }
      }))
      setAttendees((playGolf.attendees || []).filter(x => x.member_email !== userDetail.email).map(player => {
        return {
          value: player.member_email || player,
          label: player.first_name ? player.first_name + ' ' + player.last_name : player,
          logo: player.profile_image || '',
          email: player.member_email || '',
          first_name: player.first_name || '',
          last_name: player.last_name || '',
          role: player.role || 'member',
          address: player.address || {}
        }
      }))
      const guestEmails = (playGolf.guest_list || []).map(g => g.email || g)
      const memberEmails = (playGolf.attendees || []).map(m => m.member_email || m)
      setExistingAttendees(memberEmails.concat(guestEmails))
      setCourceState(post.play_golf.club.address.state)
    }
  }, [post, userDetail, handleCourseDetail]);

  const handleClubCourseChange = name => list => {
    // if (post && post.id && list.value && list.value !== post.play_golf.course_id) {
    //   setNotificationOptions(old => [...old, 'all', 'none'])
    // }
    setSelectedCourse(list)

    const selectedCourceState = courses.find(x => x.id === list.value).address.state;
    if (selectedCourceState) {
      setCourceState(selectedCourceState);
    }
  }

  const shouldOverrideToUTC = () => { return process.env.REACT_APP_PLAYGOLF_POST_DATETIME_UTC === 'true' && !selectedDate }

  const handleSetSelectedDate = value => {
    // if ((a.end_date || b.end_date) && (!parser.isDateEqual(a.end_date, b.end_date))) { return false }
    // if (post && post.id && value && !parser.isDateEqual(post.play_golf.date, value)) {
    //   setNotificationOptions(old => [...old, 'all', 'none'])
    // }
    if(shouldOverrideToUTC()) {
      value.utcOffset(0)
      value.set({hour:8, minute:0, second:0, millisecond:0})  
    }
    setSelectedDate(value)
  }

  const handleSetEndDate = value => {
    
    // if ((a.end_date || b.end_date) && (!parser.isDateEqual(a.end_date, b.end_date))) { return false }
    // if (post && post.id && value && !parser.isDateEqual(post.play_golf.end_date, value)) {
    //   setNotificationOptions(old => [...old, 'all', 'none'])
    // }
    setEndDate(value)
  }
  const handleCoursesTextChange = name => value => {
    if (value && value.length > 2) {
      handleCourseSearch(value)
    }
  }
  const handleNumericChange = (event) => {
    const value = event.target.value
    if (value && !parser.isNumeric(value)) { return }
    // if (post && post.id && Number(post.play_golf.guest_fees) !== Number(value)) {
    //   setNotificationOptions(old => [...old, 'all', 'none'])
    // }
    setGuestFee(value)
  }
  const handleMultiDate = () => {
    if (multiDate) {
      setMultiDate(false)
      setEndDate()
    } else {
      setMultiDate(true)
      if (selectedDate) {
        setEndDate(new Date(new Date().setDate(selectedDate.getDate() + 2)))
      }
    }
  }
  const handleGameDescriptionUpdate = (event) => {
    const value = event.target.value
    // if (post && post.id && post.play_golf.description !== value) {
    //   setNotificationOptions(old => [...old, 'all', 'none'])
    // }
    setGameDescription(value)
  }
  const handlePlayerCountUpdate = (event) => {
    const value = event.target.value
    // if (post && post.id && post.play_golf.total_seats !== value) {
    //   setNotificationOptions(old => [...old, 'all', 'none'])
    // }
    setPlayerCount(value)
  }
  const isSubmitDisabled = () => {
    return !selectedCourse || !selectedDate || (post && post.id && !notificationPreference) || 
          (!groupDetail && attendees.length === 0 && guestList.length === 0) || (multiDate && !endDate)
  }
  
  // function golfPostsEqual(a, b) {
  //   // if (a.course_id !== b.course_id) { return false }
  //   if (!parser.isDateEqual(a.date, b.date)) { return false }
  //   if ((a.end_date || b.end_date) && (!parser.isDateEqual(a.end_date, b.end_date))) { return false }
  //   if (a.description !== b.description) { return false }
  //   if (a.note !== b.note) { return false }
  //   if (Number(a.guest_fees) !== Number(b.guest_fees)) { return false }
  //   if (Number(a.total_seats) !== Number(b.total_seats)) { return false }
  //   return true
  // }
  const handleAudienceUpdate = list => { 
    const oldList = existingAttendees.filter(x => x !== userDetail.email)
    if (ifTheAudienceListHasChanged(list, oldList)) {
      setNotificationOptions(old => [...old, 'new']) 
    } else {
      const options = notificationOptions.filter(x => x !== 'new')
      setNotificationOptions(options)
    }
    setAttendees(list)
  }
  const validateAttendees = () => {
    let memberInvites = []
    if (groupDetail && groupDetail.id && groupDetail.group_members) {
        memberInvites = groupDetail.group_members.filter(m => m.accepted).map(x => x.member_email)
    } else {
      memberInvites = attendees.map(x => x.value)
    }
    const course = courseDetail && selectedCourse.value === courseDetail.id ? courseDetail : courses.find(x => x.id === selectedCourse.value)
    const allWithAddress = memberInvites.reduce((result, email) => {
      const golfer = memberList.find(x => x.email !== userDetail.email && x.email === email)
      if (golfer && golfer.address && golfer.address.state && 
          parser.getStateValue(golfer.address.state) !== parser.getStateValue(course.address.state)) {
        result.push({
          email: golfer.email,
          first_name: golfer.first_name,
          last_name: golfer.last_name,
          address: golfer.address,
          profile_image: golfer.profile_image
        })
      } else {
        result.push({
          email
        })
      }
      return result
    }, [])
    if (allWithAddress && allWithAddress.some(x => x.address)) {
      setOutOfStateAttendees(allWithAddress)
    } else {
      submitPost(memberInvites)
    }
  }
  const submitPost = (memberInvites) => {
    
    if (!memberInvites || !memberInvites.includes(userDetail.email)) { memberInvites.push(userDetail.email) }
    const golfPost = {
      note: gameNotes || '',
      description: gameDescription || '',
      guest_fees: guestFee || 0,
      total_seats: playerCount || 99999,
      date: selectedDate,
      end_date: (multiDate ? endDate : null),
      course_id: selectedCourse.value,
      attendees: memberInvites,
      guest_list: guestList,
      notification_preference: notificationPreference
    }
    if (post && post.id && closeEdit) {
      const newPost = { ...post, play_golf: golfPost }
      handleUpdatePost(newPost)
      closeEdit()
    } else {
      const userData = {
        member_email: userDetail.email,
        username: userDetail.username,
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        profile_image: userDetail.profile_image,
        privacy_setting: userDetail.privacy_setting
      }

      handleSubmitPlayGolf(golfPost, groupDetail ? groupDetail.id : null, userData)
    }
    setSelectedCourse('')
    setSelectedDate()
    setEndDate()
    setGameDescription('')
    setGameNotes('')
    setPlayerCount('')
    setGuestFee('')
    setGuestList([])
    setAttendees([])
    setNotificationPreference('')
    setCourceState('')
  }
  return (
    <Paper className={classes.main}>
      <AttendeeDialog
        open={Boolean(outOfStateAttendees && outOfStateAttendees.length > 0)}
        toggleDialog={() => setOutOfStateAttendees(null)}
        attendees={outOfStateAttendees}
        courseName={selectedCourse ? selectedCourse.label : ''}
        handleConfirmation={submitPost}
      />
      <Typography component="p" variant="subtitle1" style={{marginBottom: 12}}>
        {post && post.id
          ? "Update your game"
          : "Invite other members or guests to join you for a round of golf!"
        }
      </Typography>
      <AutoSelect
        textFieldProps={{
          label: 'What course will you be playing? Type course name here to search',
        }}
        name='play_golf_course'
        options={parser.formatCourses(courses)}
        selectedValue={selectedCourse}
        handleAutoSelectTextChange={handleCoursesTextChange}
        handleAutoSelectChange={handleClubCourseChange}
      />
      <MuiPickersUtilsProvider
        utils={process.env.REACT_APP_PLAYGOLF_POST_DATETIME_UTC === 'true' ? MomentUTCUtils: DateFnsUtils}
        moment={process.env.REACT_APP_PLAYGOLF_POST_DATETIME_UTC === 'true' ? moment : null} >
        <Grid container justify="flex-start" style={{ minHeight: 90 }}>
            <DatePicker
              disablePast
              required
              margin="normal"
              label={multiDate ? "Select Start Date" : "Select Date"}
              className={cls(classes.dateField, {'not-selected': !selectedDate})}
              value={selectedDate}
              onChange={handleSetSelectedDate}
            />
            {multiDate ? <DatePicker
                disablePast
                required
                margin="normal"
                label="Select End Date"
                className={cls(classes.dateField, {'not-selected': !endDate})}
                value={endDate}
                onChange={handleSetEndDate}
              />
            : <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TimePicker
                margin="normal"
                label="Select Time"
                className={cls(classes.dateField, {'not-selected': !selectedDate})}
                value={selectedDate}
                onChange={handleSetSelectedDate} />
                {process.env.REACT_APP_PLAYGOLF_POST_DATETIME_UTC === 'true' && <small>* Tee time is local to the course</small>}
              </div> }
            <div onClick={() => handleMultiDate()} className={classes.linkToggle} style={{ marginBottom: 23 }} >{multiDate ? 'Switch to Date and Time' : 'Switch to Date Range'}</div>
          </Grid>
        </MuiPickersUtilsProvider>
      <TextField
        id="description"
        name="description"
        label="Provide a Description"
        fullWidth
        margin="normal"
        variant="outlined"
        value={gameDescription}
        onChange={handleGameDescriptionUpdate}
        className={classes.multiLineTextBox }
        multiline
        rows="3"
        placeholder=""
        InputLabelProps={{ shrink: true }}
      />
      <Grid container justify="flex-start">
        <TextField
            id="playerCount"
            name="playerCount"
            label="# of Players (including you)"
            select
            className={classes.textField}
            value={playerCount}
            onChange={handlePlayerCountUpdate}
            variant="outlined"
            margin="normal"
            style={{marginRight: 8, minWidth: 228}}
            InputLabelProps={{
                shrink: true,
            }} 
        >
          <MenuItem value={99999}>
              Unlimited
          </MenuItem>
            {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(option => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
        <TextField
          id="guestFee"
          name="guestFee"
          label="Guest Fee"
          className={classes.textField}
          value={guestFee}
          onChange={handleNumericChange}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }} />
      </Grid>
      <TextField
        id="notes"
        name="notes"
        label="Additional Notes"
        fullWidth
        margin="normal"
        variant="outlined"
        value={gameNotes}
        onChange={(event) => setGameNotes(event.target.value)}
        className={classes.multiLineTextBox }
        multiline
        rows="3"
        placeholder="Any special notes such as where to meet, game format, etc."
        InputLabelProps={{ shrink: true }}
      />
      {!groupDetail && !(post && post.id && post.group_id) &&
        <AudienceSelector
          courceState={courceState}
          guestList={guestList}
          guestListEvent={list => setGuestList((oldValues) => [...oldValues, ...list])}
          attendees={attendees}
          memberList={memberList}
          handleSelectedMembers={handleAudienceUpdate} />
      }
      {post && post.id && 
        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
        <FormControl component="fieldset" style={{border: 'solid 1px #ccc', padding: '8px 24px', margin: '16px 0'}}>
          <FormLabel component="legend" style={{padding: '8px 0', fontWeight: 'bold'}}>Notification Options <span style={{color: '#FF0000'}}>*</span></FormLabel>
          <RadioGroup value={notificationPreference || ''} onChange={event => setNotificationPreference(event.target.value)}>
            <FormControlLabel value="all" control={<Radio />} label="Send update to ALL invitees" />
            {notificationOptions.includes('new') && <FormControlLabel value="new" control={<Radio />} label="Send update to NEW invitees only" />}
            <FormControlLabel value="none" control={<Radio />} label="Do NOT send update" />
          </RadioGroup>
        </FormControl>
        </div>
      }
      {closeEdit
      ? <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button 
            disabled={isSubmitDisabled()} 
            variant="contained" 
            onClick={() => validateAttendees()} 
            style={{marginRight: 8}}>
              Save {notificationPreference === 'none' ? '' : ` and Send`}
          </Button>
          <Button variant="outlined" onClick={() => closeEdit()}>Cancel</Button>
        </div>
      : <div className={classes.postButton}>
          <Button 
            disabled={isSubmitDisabled()} 
            onClick={() => validateAttendees()} 
            className={classes.button} 
            variant="contained">
              Post
          </Button>
        </div>
      }
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    groupDetail: state.groups.groupDetail,
    courses: state.clubs.courseList,
    courseDetail: state.clubs.courseDetail,
    memberList: state.members.memberList
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleCourseSearch,
    handleCourseDetail,
    handleSubmitPlayGolf, 
    handleUpdatePost
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(PlayGolfForm)
