import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleGetUserPosts } from '../../reducers/posts';
import { parser } from '../../utils/parser';
import SectionTitle from './sectionTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0',
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      padding: 8,
      borderRadius: 0,
    },
  },
  travelItem: {
    backgroundColor: theme.palette.lightGray.main,
    padding: '8px 0',
    alignItems: 'center',
    color: theme.palette.profile.dark,
  },
}));

const ProfileTravel = ({ profile, posts, handleGetUserPosts }) => {
  const classes = useStyles();
  React.useEffect(() => {
    handleGetUserPosts(profile.email);
  }, [handleGetUserPosts, profile]);
  const travelPosts = parser.sortByDate(
    posts
      .filter(
        (x) =>
          x.member_email === profile.email &&
          x.travel &&
          x.travel.departure_date &&
          parser.isSameOrAfterToday(x.travel.departure_date)
      )
      .map((y) => y.travel),
    { prop: 'arrival_date' }
  );
  if (!travelPosts || travelPosts.length === 0) {
    return <div />;
  }
  return (
    <div>
      <SectionTitle hideEdit>Upcoming Travel</SectionTitle>
      <Paper elevation={0} className={classes.root}>
        {travelPosts.map((trip, index) => {
          return (
            <Grid key={index} container className={classes.travelItem}>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item sm={3} xs={12}>
                <Typography variant='subtitle1' style={{ fontSize: 14 }}>
                  {parser.shortDate(trip.arrival_date, 'MM/dd')} to{' '}
                  {parser.shortDate(trip.departure_date, 'MM/dd')}
                </Typography>
              </Grid>
              <Grid item sm={8} xs={12}>
                <Typography variant='subtitle1' style={{ fontSize: 14 }}>
                  {trip.destination_city}, {trip.destination_state}
                </Typography>
                <Typography variant='subtitle2' style={{ fontSize: 14 }}>
                  {trip.description}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    posts: state.posts.userPosts,
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      handleGetUserPosts,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatch)(ProfileTravel);
