import React, { useState } from 'react';
import cls from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import ImageDialog from '../../components/dialog_images';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',  
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  imagePreview: {
    maxWidth: 300,
    height: 'auto',
  },
  deleteIcon: {
    position: 'absolute',
    top: -10,
    right: -10,
    [theme.breakpoints.down('xs')]: {
      right: 20
    },
  },
  tileItem: {
    position: 'relative',
    padding: 16,
    '&.clickable': {
      cursor: 'pointer',
    },
    '& .showOnHover': {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    '&:hover': {
      '& .showOnHover': {
        display: 'block'
      }
    }
  }
}));



export default function ImageGridList({media, handleDelete}) {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState()

  if (!media || media.length === 0) { return <span id="imageGridList" />}
  return (
    <div id="imageGridList" className={classes.root}>
      <ImageDialog
        src={imageSrc}
        open={Boolean(imageSrc)}
        toggleDialog={() => setImageSrc()} />
      <div className={classes.gridList} style={{ overflowX: media.length > 3 ? 'scroll' : 'auto'  }} >
        {media.map((item, idx) => {
          let src = item.name ? URL.createObjectURL(item) : item
          return (
              <div key={idx} onClick={handleDelete ? null : () => setImageSrc(src)} className={cls(classes.tileItem, {'clickable': !handleDelete})}>
                <img src={src} className={classes.imagePreview} alt='Event' />
                {handleDelete && <IconButton className={cls(classes.deleteIcon, 'showOnHover')} onClick={() => handleDelete(item.name || item)}><DeleteIcon /></IconButton>}
              </div>
          )
        })}
      </div>
    </div>
  );
}