import { Typography } from '@material-ui/core';
import React from 'react';
import TaggableBodyText from '../../components/taggableBodyText';
import ImageGridList from './imageGridList';

function GenPost ({post}) {
    return (
        <React.Fragment>
            <div style={{width: '100%'}}>
            <Typography component="div" style={{whiteSpace: 'pre-wrap'}}><TaggableBodyText text={post.body_text} /></Typography>
            </div>
            <ImageGridList media={post.photos} />
        </React.Fragment>
    );
}

export const GeneralPost = React.memo(GenPost)
  