import React from 'react'
import { Button } from '@material-ui/core';
import { goToHome } from '../utils/pages';

const DeclineGroupInviteButton = ({ handleVerifyMembership, groupDetail, history }) => {
  
  const declineInvite = () => {
    handleVerifyMembership(groupDetail, false)
    goToHome(history)
  }

  return <Button onClick={() => declineInvite()} variant="outlined">Decline</Button>
}

export default DeclineGroupInviteButton