import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import LoginView from './loginView'
import {
    clearAuthErrors,
    handleForgotPassword,
    handleLogin,
    handleRememberMe
  } from '../../reducers/auth'

const LoginContainer = props => {
  return <LoginView {...props} />
}

const mapStateToProps = (state) => {
    return { auth: {...state.auth} }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handleForgotPassword,
      handleLogin,
      handleRememberMe,
      clearAuthErrors
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(LoginContainer)
