import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WidgetLoading from '../../components/widgetLoading';
import { parser } from '../../utils/parser';

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          fontSize: '11px',
        },
        root: {
          fontSize: '11px',
        },
      },
    },
  });

const createColumn = (name, label, options) => ({
  name,
  label,
  options: {
    display: true,
    filter: false,
    viewColumns: true,
    sort: false,
    ...options,
  },
});
const EventTable = ({
  events,
  title,
  isLoading,
  categoryList,
  onSelectedEvent,
}) => {
  const [searchValue, setSearchValue] = React.useState();
  // const [ sortName, setSortName ] = React.useState()
  // const [ sortDir, setSortDir ] = React.useState()
  const columns = React.useMemo(() => {
    const onViewEvent = (eventId) => {
      const event = events.find((x) => x.id === eventId);
      onSelectedEvent(event);
    };
    return [
      createColumn('logo', 'Event Image', {
        filter: true,
        filterType: 'checkbox',
        filterOptions: {
          names: ['Has Image', 'No Image'],
          logic(hasImage, filterVal) {
            const show =
              (filterVal.indexOf('Has Image') >= 0 && hasImage) ||
              (filterVal.indexOf('No Image') >= 0 && !hasImage);
            return !show;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value ? (
                <img style={{ width: 64 }} src={value} alt='Event' />
              ) : (
                ''
              )}
            </div>
          );
        },
      }),
      createColumn('name', 'Event Name'),
      createColumn('start_date', 'Start Date', {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{parser.shortDate(value, 'yyyy-MM-dd')}</div>;
        },
      }),
      createColumn('end_date', 'End Date', {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return <div>{parser.shortDate(value, 'yyyy-MM-dd')}</div>;
          } else return <div />;
        },
      }),
      createColumn('city', 'City', { filter: true }),
      createColumn('state', 'State', {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {

        // }
      }),
      createColumn('courses', 'Courses', {
        // filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value &&
                value.map((course, idx) => (
                  <Typography key={idx} style={{ fontSize: 12 }}>
                    {course.club_name}
                  </Typography>
                ))}
            </div>
          );
        },
      }),
      createColumn('category', 'Category', {
        customBodyRender: (value, tableMeta, updateValue) => {
          const category = categoryList.find((x) => x.value === value);
          return <div>{category && category.label ? category.label : ''}</div>;
        },
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (data) => {
            return data.map(
              (value) => categoryList.find((y) => y.value === value).label
            );
          },
          update: (filterList, filterPos, index) => {
            console.log('update');
            console.log(filterList, filterPos, index);
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            return (
              <FormControl>
                <InputLabel htmlFor='select-multiple-chip'>Category</InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (item) =>
                          categoryList.find((x) => x.value === item).label
                      )
                      .join(', ')
                  }
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {categoryList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      <Checkbox
                        color='primary'
                        checked={filterList[index].indexOf(item.value) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      }),
      createColumn('featured', 'Featured', {
        filter: true,
        filterType: 'checkbox',
        filterOptions: {
          names: ['Featured', 'Not Featured'],
          logic(featured, filterVal) {
            const show =
              (filterVal.indexOf('Featured') >= 0 && featured) ||
              (filterVal.indexOf('Not Featured') >= 0 && !featured);
            return !show;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{value ? 'Featured' : ''}</div>;
        },
      }),
      createColumn('id', ' ', {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <IconButton onClick={() => onViewEvent(value)}>
                <ViewIcon />
              </IconButton>
            </div>
          );
        },
      }),
    ];
  }, [events, categoryList, onSelectedEvent]);
  const tableOptions = React.useMemo(
    () => ({
      filterType: 'dropdown',
      selectableRows: 'none',
      filter: true,
      viewColumns: false,
      rowHover: false,
      print: false,
      download: false,
      fixedHeader: false,
      search: true,
      // searchOpen: true,
      sort: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50],
      responsive: 'simple',
      searchText: searchValue,
      onSearchChange: (text) => {
        setSearchValue(text);
      },
      setFilterChipProps: (colIndex, colName, data) => {
        //console.log(colIndex, colName, data);
        return {
          color: 'primary',
          variant: 'outlined',
          // className: 'testClass123',
        };
      },
      // onColumnSortChange: (col, dir) => {
      //   setSortName(col)
      //   setSortDir(dir === 'descending' ? 'desc' : 'asc')
      // }
    }),
    [searchValue]
  );

  if (!events || (isLoading && events.length === 0)) {
    return <WidgetLoading />;
  }

  // const sortedColumns = !sortName || !sortDir ? columns : columns.map(col => {
  //   if (col.options && col.options.sort) {
  //     let newOptions = {...col.options, sortOrder: sortName === col.name ? sortDir || 'none' : 'none'}
  //     return {...col, options: {...newOptions}}
  //   } else {
  //     return col
  //   }
  // })
  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={title || ''}
          data={events}
          columns={columns}
          options={tableOptions}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
};

const mapState = (state) => {
  return {
    courseList: state.clubs.courseList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
export default connect(mapState, mapDispatchToProps)(EventTable);
