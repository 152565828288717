
import { Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { parser } from '../../utils/parser';

const logoIcon = require('../../assets/images/logo_short.svg')
const logoDarkIcon = require('../../assets/images/logo_short_dark.svg')

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      margin: 0
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.dark,
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logoHolder: {
    textAlign: 'center',
    margin: theme.spacing(1, 0)
  },
  logo: {
    width: '200px'
  },
  contentHolder: {
    width: '88%',
    margin: 'auto',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  applicationSent: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .subtext': {
      width: '300px',
      textAlign: 'center'
    }
  },
  textField: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  inputLabel: {
    paddingLeft: theme.spacing(2)
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  loginLoader: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
});

function GuestView(props) {
  const [ tos, setTos ] = React.useState(false)
  const { inviteToken, guest, referrer, guestError, classes, handleGuestRegistration, handleGuestInvitation } = props
  const [values, setValues] = React.useState({
    helperText: '',
    application: null,
  })
  const handleSubmit = (evt) => {
    evt.preventDefault()
    const confirmPassword = evt.target.confirmpassword.value
    const password = evt.target.password.value
    if (confirmPassword !== password) {
      return setValues(oldValues => ({ ...oldValues, 
        helperText: 'Passwords must match.'
      }))
    }
    if (!parser.isValidPassword(password)) {
      return setValues(oldValues => ({ ...oldValues, 
        helperText: 'Invalid Password. 8 character minimum including at least 1 uppercase, 1 lowercase and 1 number'
      }))
    }
    setValues(oldValues => ({ ...oldValues, helperText: ''}))
    const application = {
      email: guest ? guest.email  : evt.target.email.value,
      first_name: evt.target.first_name.value,
      last_name: evt.target.last_name.value,
      password: evt.target.password.value  
    }
    if (!tos) { return }
    if (inviteToken) {
      handleGuestRegistration(inviteToken, application)
    } else {
      if (referrer) {
        application.referrer_username = referrer.username
        handleGuestInvitation(application)
      }
    }
  }
  const onPwdChange = () => {
    if (values.helperText.length > 0) {
      setValues(oldValues => ({ ...oldValues, helperText: ''}))
    }
  }
  return (
    <main className={classes.main}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <div className={classes.logoHolder}>
            <img alt="Captain's Club" className={classes.logo} src={logoIcon} />
          </div>
          <div className={classes.contentHolder}>
            <Typography variant="h4" align="left" gutterBottom>
              Become a Guest
            </Typography>
            <Typography variant="body2" align="left" className='subtext' gutterBottom>
              Captain's Club was created to bring together golfers who want to experience great courses, establish new friendships, and build lasting relationships.
            </Typography>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper className={classes.paper}>
            {referrer && 
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '16px'}}>
                <img style={{width: '100px', height: '100px', borderRadius: '8px', marginRight: '16px'}} src={referrer.profile_image || logoDarkIcon} alt="Welcome" />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
                  <Typography variant="h6">{referrer.first_name + ' ' + referrer.last_name}</Typography>
                  <Typography gutterBottom>has invited you</Typography>
                </div>
              </div>
            }
            <Typography variant="h6" gutterBottom>
              Be our Guest
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="first_name"
                      label="First Name"
                      className={classes.textField}
                      autoFocus={Boolean(!guest)}
                      required
                      defaultValue={guest ? guest.first_name : ''}
                      margin="normal"
                      variant="outlined" />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="last_name"
                      label="Last Name"
                      className={classes.textField}
                      required
                      defaultValue={guest ? guest.last_name : ''}
                      margin="normal"
                      variant="outlined" />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      label="Email Address"
                      className={classes.textField}
                      disabled={Boolean(guest && guest.email)}
                      defaultValue={guest ? guest.email : ''}
                      margin="normal"
                      variant="outlined" />
                  </Grid>
                </Grid>
                <TextField
                  id="password"
                  label="Password"
                  className={classes.textField}
                  helperText={'Password must be minimum 8 characters, including at least 1 uppercase, 1 lowercase and 1 number'}
                  required
                  autoFocus={Boolean(guest && guest.email)}
                  onChange={onPwdChange}
                  type="password"
                  margin="normal"
                  variant="outlined" />
                <TextField
                  id="confirmpassword"
                  label="Confirm Password"
                  className={classes.textField}
                  required
                  helperText={values.helperText}
                  error={values.helperText.length > 0}
                  onChange={onPwdChange}
                  type="password"
                  margin="normal"
                  variant="outlined" />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={tos || false}
                    onChange={(event) => setTos(event.target.checked)}
                    value="tos"
                    color="primary"
                    />
                  }
                  label={<span>I agree to the <a rel="noopener noreferrer" href="https://docs.google.com/document/d/e/2PACX-1vSrq7A1bS98JGvZMqxw6XSBYZKCCPef4zvHqQCnaZPfYH04Yzw5dCU7slGDufrNm4EdG_bwYzb8RobF/pub?embedded=true" target="_blank">Terms & Conditions</a></span>}
                />
                <Typography color="error" component="div" variant="subtitle1">
                    {guestError && guestError.toLowerCase() === 'user not found' ? <div>
                      <Typography component="div" >
                        We had a problem processing your invitation.  Please have a member resend the invitation.
                      </Typography>  
                    </div>
                    : <Typography component="div">{guestError}</Typography>
                    }
                </Typography>
                <Button type="submit" disabled={Boolean(guestError) || !tos} variant="contained" className={classes.submit}>
                    Join
                </Button>
            </form>
        </Paper>
        </Grid>
      </Grid>
    </main>
  )
}

export default withStyles(styles)(GuestView)
