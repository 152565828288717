import React from 'react';
import { Grid } from '@material-ui/core';
import DashboardContainer from '../dashboard/dashboardContainer';
import GroupsWidget from './groupsWidget';
import MembersWidget from './membersWidget';
import PlayGolfWidget from './playGolfWidget';
import TravelWidget from './travelWidget';
import MemberTravelWidget from './memberTravelWidget';
import Banner from '../../components/banner';
import { connect } from 'react-redux';
import { getHomeScreenMessageData } from '../../utils/common';
import { userHasRole, userRoles } from '../../utils/profiles';

const MemberHome = ({classes, userDetail, home_screen_message, banner_open}) => {
  const { text, button_text, button_url } = getHomeScreenMessageData(home_screen_message)
  const customStyle = { maxHeight: banner_open && ((text && text.length >= 1 && text[0]) || (button_text && button_url)) ? 'calc(100vh - 254px)' : 'calc(100vh - 80px)' }

  return(
    <div data-testid="member-home" className={classes.main}>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.bannerContainer}>
          <Banner />
        </Grid>
        <Grid item xs={12} md={4} >
          <div className={`${classes.container} ${classes.leftSide}`} style={customStyle}>
            {userDetail && userHasRole(userDetail, userRoles.member) && <MembersWidget />}
            {userDetail && userHasRole(userDetail, userRoles.member) && <MembersWidget type='Applicants' />}
            {userDetail && userHasRole(userDetail, userRoles.member) && (
              <MemberTravelWidget />
            )}
            <PlayGolfWidget />
            <GroupsWidget />
            {userDetail && userHasRole(userDetail, userRoles.member) && <TravelWidget />}
            {/* {userDetail && userDetail.role === 'member' && 
              userDetail.address && userDetail.address.state && <VisitorsWidget />} */}
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div id="dashboard-container" className={`${classes.container} ${classes.rightSide}`} style={customStyle}>
            <DashboardContainer />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    home_screen_message: state.references?.home_screen_message,
    banner_open: state.references?.banner_open
  }
}

export default connect(mapStateToProps)(MemberHome)