import React from 'react';
import classNames from 'classnames'
import {
  Card,
  ButtonBase,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: '#eee',
    '&.active': {
      backgroundColor: '#fff',
      borderBottom: `solid 2px ${theme.palette.primary.dark}`,
      borderTop: `solid 2px ${theme.palette.primary.dark}`,
      '& #title': {
        color: theme.palette.primary.dark,
        fontSize: '13px'
      }
    },
  },
  cardHeight: {
    minHeight: 160,
    [theme.breakpoints.up('xl')]: {
      minHeight: 120
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 100
    },
  },
  title: {
    fontSize: '13px',
    textAlign: 'center',
  },
  subtext: {
    fontSize: '11px'
  }
}));

const cards = [
  { id: 'full', title: 'Full Profile'},
  { id: 'basic', title: 'Limited Profile'},
  { id: 'anonymous', title: 'Anonymous Profile'}
]
function PrivacySelector({text, value, handlePrivacySelected}) {
  const classes = useStyles();
  // const [active, setActive] = React.useState('full')
  // React.useEffect(() => {
  //   setActive(value)
  // }, [value]);
  const cardClicked = (value) => {
    handlePrivacySelected(value) 
    // setActive(value)
  }
  return (
    <React.Fragment>
      <Grid container justify="center" spacing={1}>
        {cards.map((card, idx) => {
          return <Grid key={idx} item xs={12} md={4}>
            <Card className={classNames(classes.card, {'active': (value || 'full') === card.id})}>
              <ButtonBase style={{width: '100%'}}
                onClick={() => cardClicked(card.id)}>
                <CardContent className={classes.cardHeight}>
                  <Typography id='title' gutterBottom variant="h6" className={classNames(classes.title)}>
                    {card.title}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.subtext} color="textSecondary" component="p">
                    { text && text[card.id] ? text[card.id] : '' }
                  </Typography>
                </CardContent>
              </ButtonBase>
            </Card>
          </Grid>
        })}
      </Grid>
    </React.Fragment>
  )
}

export default PrivacySelector;
