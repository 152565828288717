
import Button from '@material-ui/core/Button';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from '../../utils/paths';

const styles = theme => ({
  main: {
    width: 'auto',
    maxWidth: 560,
    display: 'block', // Fix IE 11 issue.
    margin: 'auto',
    color: theme.palette.primary.dark,
    padding: theme.spacing(2, 0)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    },
  },
  intro: {
    textAlign: 'left',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  logoHolder: {
    textAlign: 'center',
    marginBottom: theme.spacing(1)
  },
  logo: {
    width: '200px'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  success: {
    color: '#008000'
  },
  forgotPassword: {
    width: '100%',
    margin: 0,
    padding: 0,
    lineHeight: '14px',
    textAlign: 'right',
    float: 'right',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  submit: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }
});

function LoginView(props) {
  const { handleForgotPassword, handleLogin, clearAuthErrors, classes, auth } = props
  const [values, setValues] = React.useState({
    forgotPassword: false
  })

  const handleForgotClick = (evt) => {
    evt.preventDefault()
    clearAuthErrors()
    setValues(oldValues => ({
      ...oldValues,
      forgotPassword: true
    }))    
  }
  const handleForgotPasswordCancel = (evt) => {
    evt.preventDefault()
    clearAuthErrors()
    setValues(oldValues => ({
      ...oldValues,
      forgotPassword: false
    }))    
  }  
  const handleForgotPasswordSubmit = (evt) => {
    evt.preventDefault()
    const email = evt.target.email.value
    handleForgotPassword(email)
    handleForgotPasswordCancel(evt)
  }

  // const rememberMe = (evt, checked) => {
  //   handleRememberMe(checked)
  // }
  const handleSubmit = async (evt) => {
      evt.preventDefault()
      const email = evt.target.email.value
      const password = evt.target.password.value
      handleLogin(email.trim(), password)
  }
  
  return (
    <main className={classes.main}>
      <div className={classes.logoHolder}>
        <img alt="Captain's Club" className={classes.logo} src={require('../../assets/images/logo_short.svg')} />
      </div>
      <Paper className={classes.paper}>
        <div className={classes.intro}>
          <Typography variant="h6" gutterBottom>
            {values.forgotPassword ? "Forgot Password?" : "Welcome Back"}
          </Typography>
          {!values.forgotPassword && <Typography variant="body2">
            Type your credentials below
            </Typography>
          }
        </div>
          <form className={classes.form} onSubmit={values.forgotPassword ? handleForgotPasswordSubmit : handleSubmit}>
            <TextField
              id="email"
              label="Email Address"
              className={classes.textField}
              required
              autoFocus
              autoComplete="email"
              margin="normal"
              variant="outlined" />
          <Typography color="secondary" variant="overline" onClick={values.forgotPassword ? handleForgotPasswordCancel : handleForgotClick} className={classes.forgotPassword}>
              {values.forgotPassword ? "Login" : "Forgot"}
            </Typography>
          { !values.forgotPassword && <React.Fragment>
            <TextField
              id="password"
              label="Password"
              className={classes.textField}
              required
              type="password"
              autoComplete="current-password"
              variant="outlined" />
            {/* <FormControlLabel
              control={<Checkbox onChange={rememberMe} id='remember' name='remember' color="primary" />}
              label="Remember me" /> */}
          </React.Fragment> }
          <Button type="submit" disabled={auth.isLoading} fullWidth variant="contained" className={classes.submit}>
            {values.forgotPassword ? "SUBMIT" : "LOGIN"}
          </Button>
          <Typography color="error" component="p" variant="subtitle2" style={{textAlign: 'center', whiteSpace: 'pre-wrap'}}>
            {auth.error}
          </Typography>
          {auth.sendReset && !auth.error && <Typography className={classes.success} component="p" variant="h6">
            Thank you. We received your request.
          </Typography>}
        </form>
        {!values.forgotPassword && <Typography variant="body2">Don't have an account yet?&nbsp;&nbsp;<Link onClick={clearAuthErrors} component={RouterLink} color="secondary" to={paths.apply}>Apply</Link></Typography>}
      </Paper>
    </main>
  );
}

export default withStyles(styles)(LoginView);
