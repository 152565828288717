import React from 'react'

import Tester from '../../components/avatorEditor'

class Sandbox extends React.Component {
  render () {
    return (
      <div style={{width: '100%', backgroundColor: '#FFF'}}>
        <Tester />
      </div>
    )
  }
}

export default Sandbox
