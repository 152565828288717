import { MenuItem, TextField, Tooltip } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BannerImage from '../../components/bannerImage';
import { handleCourseTopUS } from '../../reducers/clubs';
import { handleCoverPhotoSubmit } from '../../reducers/members';
import { getTopUsaCourseCountForProfile } from '../../utils/profiles';
import BioCareer from './bio_career';
import CourseSelector from './clubCourseSelector.js';
import GearPreference from './gearPreference';
import PrivacySettings from './privacySettings.js';
import ProfileBanner from './profileBanner';
import ProfileGuestMessage from './profileGuestMessage';
import ProfileTravel from './profileTravel';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.profile.background,
  },
  content: {
    position: 'relative',
    margin: 'auto',
    padding: '0 0 32px',
    width: '72%',
    maxWidth: '1250px',
    [theme.breakpoints.down('md')]: {
      width: '96%',
      padding: 0
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    //   padding: 0
    // }
  },
  displayMode: {
    position: 'absolute',
    top: -240,
    right: 0,
  },
  displayModeSelector: {
    // backgroundColor: theme.palette.primary.main,
    // color: '#FFF',
    // borderRadius: 8,
    // padding: 0
  }
});
const getMuiTheme = () => createMuiTheme({
  overrides: {
    MuiFilledInput: {
      root: {
          borderRadius: 4,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          backgroundColor: '#11274B',
          color: '#FFF',
          '&:hover': {
            backgroundColor: '#11274B',
          },
          '&:focus': {
            backgroundColor: '#11274B',
          },
      },
      underline: {
        '&:before, &:after': {
          borderBottom: 'solid 2px #11274B'
        }
      },
      input: {
          padding: '10px 32px 10px 10px'
      },
    },
    MuiSelect: {
      select: {
        borderRadius: 4,
        '&:focus': {
          backgroundColor: '#11274B',
          borderRadius: 4,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      },
      icon: {
        color: '#FFF'
      },
      iconFilled: {
        right: 1
      }
    }
  }
})
const ProfileView = ({ profile, session, handleCoverPhotoSubmit, classes, courseList, handleCourseTopUS }) => {
  const [ readonly, setReadonly ] = React.useState(true)
  const [ isSelf, setIsSelf ] = React.useState(false)
  const [ showEdit, toggleEdit ] = React.useState(false)
  const [countCoursesInUsaRank, setCountCoursesInUsaRank] = React.useState(0)
  const courses = courseList?.filter(c => c.usa_rank && c.usa_rank <= 100)

  React.useEffect(() => {
    handleCourseTopUS()
  }, [handleCourseTopUS])

  React.useEffect(() => {
    setCountCoursesInUsaRank(getTopUsaCourseCountForProfile(profile, courses))    
  }, [profile, courses])

  
  React.useEffect(() => {
    const isSelf = (session && session.role === 'admin') || (profile && profile.email && session && session.email && session.email === profile.email)
    setReadonly(!isSelf)
    setIsSelf(isSelf)
  }, [profile, session]);
  React.useEffect(() => {
    window.scroll(0, 0)
  }, []);
  const handleUpdateCoverPhoto = (file) => {
    if (!file) { return }
    handleCoverPhotoSubmit(profile.email, profile.role, file)
  }
  if (!profile) { return <div /> }
  return (
  <div className={classes.root}>
    {!readonly && session.role !== 'admin' && profile.role === 'guest' && <ProfileGuestMessage profile={profile} /> }
    <BannerImage
      isEditable={!readonly}
      imagePath={profile.cover_photo}
      handleUpdateBannerImage={handleUpdateCoverPhoto} />
    <div className={classes.content}>
      {isSelf && <div className={classes.displayMode}>
        <MuiThemeProvider theme={getMuiTheme()} >
          <Tooltip title="View Mode is how others will see your profile" placement="left-start">
              <TextField
                  select
                  id="displayMode"
                  name="displayMode"
                  margin="none"
                  variant="filled"
                  value={readonly}
                  className={classes.displayModeSelector}
                  onChange={(evt) => setReadonly(evt.target.value)}
                  inputProps={{ name: "displayMode", id: "displayMode" }}
                >
                  <MenuItem value={false}>Edit Mode</MenuItem>
                  <MenuItem value={true}>View Mode</MenuItem>
              </TextField>
          </Tooltip>
        </MuiThemeProvider>
      </div>}
      <ProfileBanner
        profile={profile}
        readonly={readonly}
        showEdit={showEdit}
        toggleEdit={toggleEdit}
      />
      {!readonly && session.role !== 'guest' && <PrivacySettings profile={profile} />}
      <ProfileTravel profile={profile} />
      <BioCareer
        profile={profile}
        readonly={readonly}
        showEdit={showEdit}
        toggleEdit={toggleEdit} />
      <CourseSelector
        title='Club Memberships'
        field='club_memberships'
        profile={profile}
        readonly={readonly}
      />
      <CourseSelector
        title={`Favorite Courses Played`}
        field='favorite_courses'
        profile={profile}
        readonly={readonly}
        appendText={`(${countCoursesInUsaRank} of the Top 100 in the U.S.)`}
      />
      <CourseSelector
        title='Course Wishlist'
        field='course_wishlist'
        profile={profile}
        readonly={readonly}
      />
      <div style={{paddingBottom: 16}}>
        <GearPreference
          profile={profile}
          readonly={readonly}
        />
      </div>
    </div>
  </div>
  )
}
const mapStateToProps = (state) => {
  return {
    session: state.auth.session,
    courseList: state.clubs.coursesTopUS
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
      handleCoverPhotoSubmit,
      handleCourseTopUS
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(withStyles(styles)(ProfileView))
