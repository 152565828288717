import React from 'react';
import { Typography } from '@material-ui/core';
import { parser } from '../../utils/parser';

function TravelPost ({post}) {
    const travel = post.travel
    return (
        <React.Fragment>
            <Typography variant="subtitle1">{post.body_text}</Typography>
            <div style={{flexWrap: 'nowrap'}}>
                <Typography variant="subtitle1" style={{fontSize: 14, wordBreak: 'break-word'}}>
                    {travel.destination_city}, {travel.destination_state} from {parser.shortDate(travel.arrival_date, 'MM/dd')} to {parser.shortDate(travel.departure_date, 'MM/dd')}
                </Typography>
            </div>
            {travel.description && <Typography component="p" variant="subtitle2" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>{travel.description}</Typography>}
        </React.Fragment>
    )
}

export default TravelPost
  