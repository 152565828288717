import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 64px)',
    // backgroundColor: theme.palette.profile.background,
    paddingTop: 16,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 24,
      paddingRight: 24
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    },
  },
  content: {
    width: '72%',
    height: '100%',
    minHeight: 300,
    margin: 'auto',
    position: 'relative',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('md')]: {
      width: '98%'
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    },
  },
}))

const PaperMasterHoc = ({transparent, children}) => {
  const classes = useStyles();
  return <div className={classes.root}>
    <Paper elevation={transparent ? 0 : 1} style={{backgroundColor: transparent ? '#fafafa' : '#fff'}} className={classes.content}>
      {children}    
    </Paper>
  </div>
}

export default PaperMasterHoc
