

import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, TextField, Typography } from '@material-ui/core';
import DatalistItem from './datalistItem';
import { handleUpdateReferences } from '../../reducers/references'

const Datalist = ({id, list, handleUpdateReferences}) => {
  const [value, setValue] = React.useState()
  const [newItem, setNewItem] = React.useState('')
  React.useEffect(() => {
    setValue(list)
  }, [list]);
  const handleAdd = () => {
    if (!newItem || value.includes(newItem)) { return }
    let newData = [...value]
    newData.push(newItem)
    handleUpdateReferences(id, newData)
    setNewItem('')
  }
  const handleSave = (idx, item) => {
    let newData = null
    if (Array.isArray(value)) {
      newData = [...value]
      newData[idx] = item
    } else {
      newData = item
    }
    handleUpdateReferences(id, newData)
  }
  const handleDelete = (item) => {
    let newData = null
    if (Array.isArray(value)) {
      const index = value.indexOf(item);
      newData = [...value]
      if (index > -1) {
        newData.splice(index, 1);
      }
    } else {
      newData = item
    }
    handleUpdateReferences(id, newData)
  }
  const handleDragEnd = (result) => {
    if (!result.destination) { return } // dropped outside the list
    const items = reorder(
      value,
      result.source.index,
      result.destination.index
    );
    setValue(items)
    handleUpdateReferences(id, items)
  }
  const reorder = (localList, startIndex, endIndex) => {
    const result = Array.from(localList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
  
    // // change background colour if dragging
    background: isDragging ? "#DDD" : "#FFF",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
  return (
    <main id={"datalist_" + id}>
      {value && Array.isArray(value) ?
        <div>
          <div style={{display: 'flex'}}>
            <TextField
                id={id}
                name={id}
                label="Add New"
                fullWidth
                autoFocus
                margin="dense"
                variant="outlined"
                value={newItem || ''}
                onChange={(e) => setNewItem(e.target.value)}
              />
            <Button onClick={() => handleAdd()}>Add</Button>
            <Button onClick={() => setNewItem('')}>Clear</Button>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {value.length > 0 ? value.map((item, idx) => {
                    if(typeof item === 'object'){                      
                      return (
                        <Draggable key={item.value.replace(/ /g, '_')} draggableId={item.value.toString().replace(/ /g, '_')} index={idx}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <DatalistItem showMove={value.length > 1} id={id} index={idx} item={item} handleSave={handleSave} handleDelete={handleDelete} />
                            </div>
                          )}
                        </Draggable>
                      )
                    }else{
                      return (
                        <Draggable key={item.replace(/ /g, '_')} draggableId={item.toString().replace(/ /g, '_')} index={idx}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <DatalistItem showMove={value.length > 1} id={id} index={idx} item={item} handleSave={handleSave} handleDelete={handleDelete} />
                            </div>
                          )}
                        </Draggable>
                      )
                    }
                  }) : <div style={{width: '100%', textAlign: 'center'}}>
                        <Typography component='p' variant='h5'>No value defined.</Typography>
                      </div>
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        : <DatalistItem index={0} item={value} handleSave={handleSave} />
      }
    </main>
  )
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleUpdateReferences,
  }, dispatch)
}

export default connect(null, mapDispatch)(Datalist)
