function getDataListItemLabel(index, id)
{
  if(id === "home_screen_message"){
    switch (index) {
      case 0: return "Main Message";
      case 1: return "Button Text";
      case 2: return "Button URL";
      default: return '';
    }
  }

  return '';
}

export { getDataListItemLabel }