import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleAddClub } from '../../reducers/clubs';
import ClubFormDialog from './clubFormDialog';
import ClubTable from './clubsTable';

const styles = theme => ({
  root: {
    minWidth: 480
  },
  loader: {
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(20), 
  },
  loaderIcon: {
  }
})

const ClubsContainer = ({classes, handleAddClub}) => {
  const [openForm, setOpenForm] = React.useState(false)
  const onSaveClub = (club) => {
    if (!club || !club.name)  { return }
    handleAddClub(club)
  }
  return (
    <div className={classes.root}>
      <ClubFormDialog
        open={openForm}
        toggleDialog={() => setOpenForm(false)}
        saveChanges={onSaveClub}
      />
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography component='p' margin='normal' variant='h5'>Manage Clubs & Courses</Typography>
        <Button onClick={() => setOpenForm(true)} variant="contained">+ Add Club</Button>
      </div>
      <div style={{marginTop: 24}}>
        <ClubTable />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleAddClub
  }, dispatch)
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(ClubsContainer))
