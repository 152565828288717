import { IconButton, Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserAvatar from '../../components/userAvatar';
import WidgetContainer from '../../components/widgetContainer';
import WidgetElement from '../../components/widgetElement';
import { handleGroupAccessResponse } from '../../reducers/groups';
import { handleSetSelectedMembers } from '../../reducers/notifications';
import { paths } from '../../utils/paths';

const useStyles = makeStyles(theme => ({
  memberWrap: {
    display: 'flex', 
    flexWrap: 'wrap', 
    alignItems: 'flex-start', 
    marginBottom: 4, 
    padding: '4px',
    backgroundColor: theme.palette.lightGray.main,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  memberName: {
    cursor: 'pointer',
    fontSize: 14,
    fontFamily: "Sentinel-Black,Roboto,sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    '&:hover': {
      textDecoration: 'underline'
    },
  },
  success: {
    '&:hover': { color: 'green' }
  },
  warning: {
    '&:hover': { color: 'red' }
  }
}))

const GroupRequestWidget = ({ history, group, handleGroupAccessResponse, handleSetSelectedMembers }) => {
  const classes = useStyles()
  const requests = (group.group_requests || []).filter(x => !x.declined)
  const sendDecline = (member) => {
    handleGroupAccessResponse(group, member, false)
  }
  return (
    <WidgetContainer header="Group Requests">
      {requests && requests.map((member, idx) => (
        <div key={idx}>
          <WidgetElement noClick>
            <div style={{display: 'flex', width: '100%', alignItems: 'center', padding: '12px 0'}}>
                <div className={classes.memberName} style={{marginRight: 8}} onClick={() => history.push(`${paths.profile}/${member.username}`)}>
                  <UserAvatar profileImage={member.profile_image} width={24} />
                </div>
                <div>
                <Typography component="div" className={classes.memberName} onClick={() => history.push(`${paths.profile}/${member.username}`)}>
                  {`${member.first_name} ${member.last_name}`}
                </Typography>
                </div>
                <div style={{flexGrow: 1}} />
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <IconButton title='Approve' onClick={() => handleGroupAccessResponse(group, member, true)}>
                    <CheckIcon className={classes.success} />
                  </IconButton>
                  <IconButton title='Decline' onClick={() => sendDecline(member)}>
                    <BlockIcon className={classes.warning} />
                  </IconButton>
                </div>
            </div>
          </WidgetElement>
        </div>
      ))}
    </WidgetContainer>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGroupAccessResponse,
    handleSetSelectedMembers
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatch)(GroupRequestWidget))
