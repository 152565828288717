import { AppBar, Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { clearAuthErrors } from '../reducers/auth';
import { paths } from '../utils/paths';
const pack = require('../../package.json')

const styles = theme => ({
    root: {
        display: 'flex',
        height: 64
    },
    appBar: {
        width: "100%",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        width: '220px',
        cursor: 'pointer'
    },
    menuRoot: {
        zIndex: 99
    },
    menuLink: {
        color: theme.palette.primary.dark
    },
    button: {
        width: '150px',
        marginRight: theme.spacing(2),
        color: '#fff',
        borderColor: '#fff'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
    },
})


const GuestHeader = ({ history, location, match, clearAuthErrors, classes }) => {
    const [isMobileMenuOpen, openMobileMenu] = React.useState(false);
    const anchorMobileRef = React.useRef(null);
    if (location.pathname === paths.root) { return <div className={classes.root} /> }
    function handleMobileMenuOpen(event) {
        openMobileMenu(true);
    }
    function handleMenuClose() {
        openMobileMenu(false);
    }
    function handleMenuClick() {
        openMobileMenu(false)
        clearAuthErrors()
    }
    function showApply () {
        return Boolean(location.pathname !== paths.apply && location.pathname.indexOf('/guest/') < 0 && !match.params.referrer)
    }
    const renderMobileMenu = (
        <Popper open={isMobileMenuOpen} anchorEl={anchorMobileRef.current} transition disablePortal className={classes.menuRoot}>
        {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList>
                    { showApply() && <MenuItem component={Link} to={paths.apply} onClick={handleMenuClick}>Apply</MenuItem> }
                    { location.pathname !== paths.login && <MenuItem component={Link} to={paths.login} onClick={handleMenuClick}>Login</MenuItem> }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    );
    return (
    <div className={classes.root}>
        <AppBar elevation={0} position="static" className={classes.appBar}>
            <Toolbar>
                <img title={"Captain's Club v" + pack.version} alt={"Captain's Club v" + pack.version} onClick={() => history.push(paths.root)} className={classes.logo} src={require('../assets/images/logo_long.svg')} />
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    { showApply() &&  <Button variant="outlined" component={Link} to={paths.apply} onClick={clearAuthErrors} className={classes.button}>Apply</Button> }
                    { location.pathname !== paths.login && <Button variant="outlined" component={Link} to={paths.login} onClick={clearAuthErrors} className={classes.button}>Login</Button> } 
                </div>
                <div className={classes.sectionMobile}>
                    <IconButton aria-haspopup="true" ref={anchorMobileRef} onClick={handleMobileMenuOpen} color="inherit">
                        <MoreIcon />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
        {renderMobileMenu}
    </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({clearAuthErrors}, dispatch)
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(GuestHeader)))
