import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parser } from '../utils/parser';

const useStyles = makeStyles(theme => ({
    textField: {
        minWidth: 300
        // margin: 0,
        // marginBottom: theme.spacing(2)
    },
    multiLineTextBox: {
        margin: '16px 0',
        '& textarea': {
          fontFamily: theme.typography.secondaryFont
        },
        '& .MuiOutlinedInput-multiline': {
          padding: '10px 14px'
        }
    },
}));

function GuestFormDialog ({open, userDetail, toggleDialog, handleConfirm }) {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const [first_name, setFirstName] = React.useState('')
    const [last_name, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const saveClick = () => {
        if (userDetail.email === email.trim().toLowerCase()) { return }
        handleConfirm({first_name, last_name, email: email.trim().toLowerCase()})
        toggleDialog(false)
        setFirstName('')
        setLastName('')
        setEmail('')
    }
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(false)}
            fullScreen={fullScreen}
        >
            <DialogTitle>Add a guest</DialogTitle>
            <DialogContent>
                <TextField
                    id="first_name"
                    name="first_name"
                    label="Guest First Name"
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={first_name || ''}
                    onChange={(event) => setFirstName(event.target.value)}
                    className={classes.textField} />
                <TextField
                    id="last_name"
                    name="last_name"
                    label="Guest Last Name"
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={last_name || ''}
                    onChange={(event) => setLastName(event.target.value)}
                    className={classes.textField} />
                <TextField
                    id="email"
                    name="email"
                    label="Guest Email"
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={email || ''}
                    onChange={(event) => setEmail(event.target.value)}
                    className={classes.textField} />
            </DialogContent>
            <DialogActions>
                <Button disabled={!first_name || !last_name || !email || !parser.isValidEmail((email || '').trim())} onClick={saveClick} variant="contained">OK</Button>
                <Button onClick={() => toggleDialog(false)} variant="outlined">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        userDetail: state.user.userDetail,
    }
  }
  const mapDispatch = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
  }
  
export default connect(mapStateToProps, mapDispatch)(GuestFormDialog)
