import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { handleGetByToken, handleGuestRegistration, handleGuestInvitation } from '../../reducers/guests'
import { paths } from '../../utils/paths'
import GuestView from './guestView'
import { Redirect } from 'react-router-dom'
import { handleGetReferrer } from '../../reducers/auth'
import { isNull } from 'lodash'

const GuestContainer = ({location, match, referrer, guest, guestError, handleGetByToken, handleGuestRegistration, handleGuestInvitation, handleGetReferrer}) => {
  React.useEffect(() => {
    if (location.pathname.indexOf(paths.guest) >= 0) {
      handleGetByToken(match.params.inviteToken)
    }
    if (location.pathname.indexOf(paths.invitation) >= 0) {
      handleGetReferrer(match.params.referrer)
    }
  }, [match, location, handleGetReferrer, handleGetByToken]);

  if (!guestError && !guest && isNull(referrer) ) { 
    return (
      <div style={{width: '100%', textAlign: 'center', marginTop: '30vh'}}>
        <CircularProgress style={{color: '#FFF'}} size={96} />
      </div> 
    )
  }
  if ((guestError && !guest && isNull(referrer)) || referrer === '') {
    return <Redirect to={paths.root} />
  }
  return <GuestView 
    guest={guest} 
    referrer={guest ? guest.referrer : referrer}
    guestError={guestError} 
    inviteToken={match.params.inviteToken} 
    handleGuestInvitation={handleGuestInvitation}
    handleGuestRegistration={handleGuestRegistration} />
}

const mapStateToProps = (state) => {
    return {
      guest: state.guests.guestInvite,
      guestError: state.guests.error,
      referrer: state.auth.referrer
    }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handleGetByToken,
      handleGuestRegistration,
      handleGuestInvitation,
      handleGetReferrer
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(GuestContainer)
