import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WidgetLoading from '../../components/widgetLoading';
import { handleDeleteGuestInvite } from '../../reducers/guests';

// const useStyles = makeStyles(theme => ({
//   linkButton: {
//     textTransform: 'capitalize',
//     cursor: 'pointer'
//   },
// }))

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          fontSize: '11px',
        },
        root: {
          fontSize: '11px',
        },
      },
    },
  });

const GuestInvites = ({
  guestInviteList,
  isLoading,
  handleDeleteGuestInvite,
}) => {
  // const classes = useStyles()
  const [searchValue, setSearchValue] = React.useState();
  const [sortName, setSortName] = React.useState();
  const [sortDir, setSortDir] = React.useState();

  const columns = [
    {
      name: 'first_name',
      label: 'First Name',
      options: { filter: true, sort: true },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: { filter: true, sort: true },
    },
    { name: 'email', label: 'Email', options: { filter: true, sort: true } },
    {
      name: 'member_first',
      label: 'Member First Name',
      options: { filter: true, sort: true },
    },
    {
      name: 'member_last',
      label: 'Member Last Name',
      options: { filter: true, sort: true },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const email = tableMeta.rowData[2];
          return (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <IconButton onClick={() => handleDeleteGuestInvite(email)}>
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  const tableOptions = {
    filterType: 'dropdown',
    selectableRows: 'none',
    filter: false,
    rowHover: false,
    print: false,
    download: false,
    fixedHeader: false,
    searchOpen: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 25, 50],
    responsive: 'simple',
    searchText: searchValue,
    onSearchChange: (text) => {
      setSearchValue(text);
    },
    onColumnSortChange: (col, dir) => {
      setSortName(col);
      setSortDir(dir === 'descending' ? 'desc' : 'asc');
    },
  };
  if (!guestInviteList || (isLoading && guestInviteList.length === 0)) {
    return <WidgetLoading />;
  }

  const sortedColumns =
    !sortName || !sortDir
      ? columns
      : columns.map((col) => {
          if (col.options && col.options.sort) {
            let newOptions = {
              ...col.options,
              sortOrder: sortName === col.name ? sortDir || 'none' : 'none',
            };
            return { ...col, options: { ...newOptions } };
          } else {
            return col;
          }
        });
  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title=''
          data={guestInviteList}
          columns={sortedColumns}
          options={tableOptions}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
};

const mapState = (state) => {
  return {
    guestInviteList: state.guests.adminGuestInviteList,
    isLoading: state.guests.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleDeleteGuestInvite,
    },
    dispatch
  );
};
export default connect(mapState, mapDispatchToProps)(GuestInvites);
