import { parser } from "../utils/parser";
import { handleClearEverything } from "./user";

export const types = {
  GET_POSTS_REQUEST: 'POSTS/GET_POSTS_REQUEST',
  GET_POSTS_SUCCESS: 'POSTS/GET_POSTS_SUCCESS',
  GET_POSTS_FAILURE: 'POSTS/GET_POSTS_FAILURE',
  GET_USER_POSTS_REQUEST: 'POSTS/GET_USER_POSTS_REQUEST',
  GET_USER_POSTS_SUCCESS: 'POSTS/GET_USER_POSTS_SUCCESS',
  GET_USER_POSTS_FAILURE: 'POSTS/GET_USER_POSTS_FAILURE',
  SUBMIT_POSTS_REQUEST: 'POSTS/SUBMIT_POSTS_REQUEST',
  SUBMIT_POSTS_SUCCESS: 'POSTS/SUBMIT_POSTS_SUCCESS',
  SUBMIT_POSTS_FAILURE: 'POSTS/SUBMIT_POSTS_FAILURE',
  SUBMIT_COMMENT_REQUEST: 'SUBMIT_COMMENT_REQUEST',
  SUBMIT_COMMENT_SUCCESS: 'SUBMIT_COMMENT_SUCCESS',
  SUBMIT_COMMENT_FAILURE: 'SUBMIT_COMMENT_FAILURE',
  SUBMIT_REPLY_REQUEST: 'SUBMIT_REPLY_REQUEST',
  SUBMIT_REPLY_FAILURE: 'SUBMIT_REPLY_FAILURE',
  UPDATE_POSTS_REQUEST: 'UPDATE_POSTS_REQUEST',
  UPDATE_POSTS_SUCCESS: 'UPDATE_POSTS_SUCCESS',
  UPDATE_POSTS_FAILURE: 'UPDATE_POSTS_FAILURE',
  UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
  UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
  UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',
  DELETE_POSTS_REQUEST: 'POSTS/DELETE_POSTS_REQUEST',
  DELETE_POSTS_SUCCESS: 'POSTS/DELETE_POSTS_SUCCESS',
  DELETE_POSTS_FAILURE: 'POSTS/DELETE_POSTS_FAILURE',
  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',
  ATTEND_GOLF_REQUEST: 'POSTS/ATTEND_GOLF_REQUEST',
  ATTEND_GOLF_SUCCESS: 'POSTS/ATTEND_GOLF_SUCCESS',
  ATTEND_GOLF_FAILURE: 'POSTS/ATTEND_GOLF_FAILURE',
  SET_POST_TYPE_SELECTION: 'POSTS/SET_POST_TYPE',
  SHOW_DIALOG_POST: 'POSTS/SHOW_DIALOG_POST',
  GET_ALL_POSTS_REQUEST: 'GET_ALL_POSTS_REQUEST',
  GET_ALL_POSTS_SUCCESS: 'GET_ALL_POSTS_SUCCESS',
  GET_ALL_POSTS_FAILURE: 'GET_ALL_POSTS_FAILURE',
  SET_ALL_POSTS_INIT: 'SET_ALL_POSTS_INIT',
  GET_COMMENTS_REQUEST: 'GET_COMMENTS_REQUEST',
  GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
  GET_COMMENTS_FAILURE: 'GET_COMMENTS_FAILURE',
  POST_LIKE_REQUEST: 'POST_LIKE_REQUEST',
  POST_LIKE_SUCCESS: 'POST_LIKE_SUCCESS',
  POST_LIKE_FAILURE: 'POST_LIKE_FAILURE',
  COMMENT_LIKE_REQUEST: 'COMMENT_LIKE_REQUEST',
  COMMENT_LIKE_SUCCESS: 'COMMENT_LIKE_SUCCESS',
  COMMENT_LIKE_FAILURE: 'COMMENT_LIKE_FAILURE',
  GET_UPDATED_GOLF_POST: 'GET_UPDATED_GOLF_POST',
  GET_UPDATED_GENERAL_POST: 'GET_UPDATED_GENERAL_POST',
  GET_UPDATED_PLAY_GOLF_POST: 'GET_UPDATED_PLAY_GOLF_POST',
  GET_UPDATED_TRAVEL_POST: 'GET_UPDATED_TRAVEL_POST',
  GET_PLAY_GOLF_POSTS_REQUEST: 'GET_PLAY_GOLF_POSTS_REQUEST',
  GET_PLAY_GOLF_POSTS_SUCCESS: 'GET_PLAY_GOLF_POSTS_SUCCESS',
  GET_PLAY_GOLF_POSTS_FAILURE: 'GET_PLAY_GOLF_POSTS_FAILURE',
};

export const initialState = {
  posts: [],
  userPosts: [],
  postTypeSelection: '',
  showDialogPostId: null,
  error: null,
  isLoading: false,
  allPosts: [],
  allPostsLoading: false,
  nextAllPosts: false,
  initAllPosts: false,
  golfPosts: [],
  travelPosts: [],
  golfUserPosts: [],
  travelUserPosts: [],
  updatedPost: {},
  isLoadingPost: false
};

export const handleSelectPostType = (postType) => (dispatch) => {
  return dispatch({
    type: types.SET_POST_TYPE_SELECTION,
    data: postType || '',
  });
};
export const handleGetPosts = (groupId) => (dispatch, getState) => {
  const currentState = getState();
  const groupDetail = currentState.groups.groupDetail || {};
  dispatch({
    types: [
      types.GET_POSTS_REQUEST,
      types.GET_POSTS_SUCCESS,
      types.GET_POSTS_FAILURE,
    ],
    url: '/posts',
    query: {
      group_id: groupId || groupDetail.id || null,
      limit: 20
    },
    type: 'GET_POSTS'
  });
};

export const handleGetPlayGolfPosts = (groupId) => (dispatch, getState) => {
  dispatch({
    types: [
      types.GET_PLAY_GOLF_POSTS_REQUEST,
      types.GET_PLAY_GOLF_POSTS_SUCCESS,
      types.GET_PLAY_GOLF_POSTS_FAILURE,
    ],
    url: '/posts/play_golf/all',
    query: {
      limit: 20
    },
    type: 'GET_PLAY_GOLF_POSTS'
  });
};

export const handleGetAllPostsAdmin = (limit, skip) => (dispatch) => {
 
dispatch({
  type: types.SET_ALL_POSTS_INIT,
  payload: !skip ? true : false
});

const query = {};

if (skip) { query.skip = skip }
if (limit) { query.limit = limit }
query.isAdminFullPosts = true;

dispatch({
  types: [
    types.GET_ALL_POSTS_REQUEST,
    types.GET_ALL_POSTS_SUCCESS,
    types.GET_ALL_POSTS_FAILURE,
  ],
  url: '/posts',
  query,
  type: 'GET_ALL_POSTS'
});



};

export const handleGetUserPosts = (email) => (dispatch, getState) => {  
  dispatch({
    types: [
      types.GET_USER_POSTS_REQUEST,
      types.GET_USER_POSTS_SUCCESS,
      types.GET_USER_POSTS_FAILURE,
    ],
    url: `/posts/user/${email}`,
    type: 'GET_POSTS_USER'
  });
};

export const handleGetAllPosts = (groupId, skip, limit = 20) => (dispatch, getState) => {
  dispatch({
    type: types.SET_ALL_POSTS_INIT,
    payload: !skip ? true : false
  });

  const currentState = getState();
  const groupDetail = currentState.groups.groupDetail || {};

  const query = { group_id: groupId || groupDetail.id || null };

  if (skip) { query.skip = skip }
  if (limit) { query.limit = limit }

  dispatch({
    types: [
      types.GET_ALL_POSTS_REQUEST,
      types.GET_ALL_POSTS_SUCCESS,
      types.GET_ALL_POSTS_FAILURE,
    ],
    url: '/posts',
    query,
    type: 'GET_ALL_POSTS'
  });
};
export const handleSubmitPlayGolf = (golfPost, groupId, userData) => (dispatch) => {
  let formData = new FormData();
  const audience = new Set(
    (golfPost.attendees || []).concat(
      (golfPost.guest_list || []).map((x) => x.email)
    )
  );
  formData.set(
    'json',
    JSON.stringify({
      audience: Array.from(audience),
      group_id: groupId,
      body_text: 'Who wants to join me for golf?',
      play_golf: golfPost,
    })
  );
  
  //dispatch submited post
  dispatch({
    type: types.GET_UPDATED_PLAY_GOLF_POST,
    types: [ types.GET_UPDATED_PLAY_GOLF_POST, ],
    data: {
      audience: Array.from(audience),
      group_id: groupId,
      body_text: 'Who wants to join me for golf?',
      play_golf: golfPost,
      ...userData
    }
  });

  dispatch({
    types: [
      types.SUBMIT_POSTS_REQUEST,
      types.SUBMIT_POSTS_SUCCESS,
      types.SUBMIT_POSTS_FAILURE,
    ],
    url: `/posts`,
    method: 'post',
    contentType: 'multipart/form-data',
    data: formData,
  });
};
export const handleSubmitTravel = (travelPost, userData) => (dispatch) => {
  let formData = new FormData();
  formData.set(
    'json',
    JSON.stringify({
      audience: travelPost.audience || [],
      body_text: 'My upcoming travel plans',
      travel: travelPost,
    })
  );

  //dispatch submited post
  dispatch({
    type: types.GET_UPDATED_TRAVEL_POST,
    types: [ types.GET_UPDATED_TRAVEL_POST, ],
    data: {
      audience: travelPost.audience || [],
      body_text: 'My upcoming travel plans',
      travel: travelPost,
      ...userData
    }
  });

  dispatch({
    types: [
      types.SUBMIT_POSTS_REQUEST,
      types.SUBMIT_POSTS_SUCCESS,
      types.SUBMIT_POSTS_FAILURE,
    ],
    url: `/posts`,
    method: 'post',
    contentType: 'multipart/form-data',
    data: formData,
  });
};
export const handleAcceptPlayGolfInvite = (post, attending) => (dispatch) => {
  const status = attending ? 'mark_attending' : 'mark_not_attending';
  dispatch({
    type: types.GET_UPDATED_GOLF_POST,
    types: [ types.GET_POSTS_REQUEST, types.GET_UPDATED_GOLF_POST, ],
    data: post
  });
  dispatch({
    types: [
      types.ATTEND_GOLF_REQUEST,
      types.ATTEND_GOLF_SUCCESS,
      types.ATTEND_GOLF_FAILURE,
    ],
    url: `posts/${post.id}`,
    method: 'patch',
    data: { [status]: true },
    callback: handleGetAllPosts(post.group_id),
  });
};
export const handleSubmitPost = (
  text,
  media,
  groupId,
  admin_email,
  notification_message,
  userData,
  isAdminUpdate = false
) => (dispatch) => {
  let formData = new FormData();
  formData.set(
    'json',
    JSON.stringify({
      body_text: text || '',
      group_id: groupId,
      admin_email,
      notification_message,
    })
  );
  media && media.forEach((item) => {
    formData.append('post_images', item);
  });

  const post = {
    created_at: new Date().toISOString(),
    body_text: text,
    photos: media,
    group_id: groupId,
    audience: false,
    is_admin: false,
    likes: null,
    comments: null,
    comment_count: "0",
    play_golf: null,
    travel: null,
    isStatePost: true,
    ...userData
  }

  //dispatch submited post
  dispatch({
    type: types.GET_UPDATED_GENERAL_POST,
    types: [ types.GET_UPDATED_GENERAL_POST, ],
    data: post
  });

  dispatch({
    types: [
      types.SUBMIT_POSTS_REQUEST,
      types.SUBMIT_POSTS_SUCCESS,
      types.SUBMIT_POSTS_FAILURE,
    ],
    url: `/posts`,
    method: 'post',
    contentType: 'multipart/form-data',
    data: formData,
    callback: isAdminUpdate && handleGetAllPostsAdmin()
  });
};
export const handleSubmitComment = (post, comment) => (dispatch) => {
  if (!post || !post.id || !comment) {
    return;
  }
  const data = { body_text: comment };
  dispatch({
    types: [
      types.SUBMIT_COMMENT_REQUEST,
      types.SUBMIT_COMMENT_SUCCESS,
      types.SUBMIT_COMMENT_FAILURE,
    ],
    url: `/posts/${post.id}/comments`,
    method: 'post',
    data: data,
  });
};
export const handleSubmitReply = (post, commentId, reply) => (dispatch) => {
  if (!post.id || !commentId || !reply) {
    return;
  }
  const data = { body_text: reply };
  dispatch({
    types: [
      types.SUBMIT_COMMENT_REQUEST,
      types.SUBMIT_COMMENT_SUCCESS,
      types.SUBMIT_COMMENT_FAILURE,
    ],
    url: `/posts/${post.id}/comments/${commentId}/replies`,
    method: 'post',
    data: data,
  });
};


export const handleUpdatePost = (newPost, newFiles, isAdminUpdate = false) => (dispatch) => {
  let formData = new FormData();
  const audience = newPost.play_golf
    ? new Set(
        (newPost.play_golf.attendees || []).concat(
          (newPost.play_golf.guest_list || []).map((x) => x.email)
        )
      )
    : newPost.travel
    ? newPost.travel.audience
    : [];
  formData.set(
    'json',
    JSON.stringify({
      audience: Array.from(audience),
      body_text: newPost.body_text || '',
      photos: newPost.photos,
      group_id: newPost.group_id,
      member_email: newPost.member_email,
      travel: newPost.travel,
      is_admin: newPost.is_admin,
      play_golf: newPost.play_golf,
    })
  );
  if (newFiles) {
    newFiles.forEach((item) => {
      formData.append('post_images', item);
    });
  }

  let GetPostDispatchType = () => { return newPost.play_golf
    ? types.GET_UPDATED_PLAY_GOLF_POST : newPost.travel
    ? types.GET_UPDATED_TRAVEL_POST
    : types.GET_UPDATED_GENERAL_POST; };

  dispatch({
    type: GetPostDispatchType(),
    types: [ GetPostDispatchType(), ],
    data: newPost
  });

  dispatch({
    types: [
      types.UPDATE_POSTS_REQUEST,
      types.UPDATE_POSTS_SUCCESS,
      types.UPDATE_POSTS_FAILURE,
    ],
    url: `/posts/${newPost.id}`,
    method: 'put',
    contentType: 'multipart/form-data',
    data: formData,
    callback: isAdminUpdate && handleClearEverything()
  });
};

export const handleDeletePost = (post, isAdminUpdate = false) => (dispatch) => {
  dispatch({
    types: [
      types.DELETE_POSTS_REQUEST,
      types.DELETE_POSTS_SUCCESS,
      types.DELETE_POSTS_FAILURE,
    ],
    url: `/posts/${post.id}`,
    method: 'delete',
    callback: isAdminUpdate && handleGetAllPostsAdmin()
  });
};
export const handleUpdateComment = (
  memberEmail,
  text,
  post,
  commentId,
  replyId
) => (dispatch) => {
  if (!post.id || !commentId || !text || !memberEmail) {
    return;
  }
  const data = { body_text: text, member_email: memberEmail };
  const url = replyId
    ? `/posts/${post.id}/comments/${commentId}/replies/${replyId}`
    : `/posts/${post.id}/comments/${commentId}`;
  dispatch({
    types: [
      types.UPDATE_COMMENT_REQUEST,
      types.UPDATE_COMMENT_SUCCESS,
      types.UPDATE_COMMENT_FAILURE,
    ],
    url: url,
    method: 'put',
    data: data,
  });
};
export const handleDeleteComment = (post, commentId, replyId) => (dispatch) => {
  const url = replyId
    ? `/posts/${post.id}/comments/${commentId}/replies/${replyId}`
    : `/posts/${post.id}/comments/${commentId}`;
  dispatch({
    types: [
      types.DELETE_COMMENT_REQUEST,
      types.DELETE_COMMENT_SUCCESS,
      types.DELETE_COMMENT_FAILURE,
    ],
    url: url,
    method: 'delete',
  });
};
export const handleShowDialogPost = (postId) => (dispatch) => {
  return dispatch({ type: types.SHOW_DIALOG_POST, data: postId });
};
export const handleGetComments = (postId) => (dispatch) => {
  const url = `/posts/${postId}/comments`;

  dispatch({
    types: [
      types.GET_COMMENTS_REQUEST,
      types.GET_COMMENTS_SUCCESS,
      types.GET_COMMENTS_FAILURE,
    ],
    initialData: postId,
    url: url,
    method: 'get',
  });
};
export const handlePostLike = (post, like) => (dispatch) => {
  const url = `/posts/${post.id}/like`;

  dispatch({
    types: [
      types.POST_LIKE_REQUEST,
      types.POST_LIKE_SUCCESS,
      types.POST_LIKE_FAILURE,
    ],
    initialData: post,
    url: url,
    method: like ? 'post' : 'delete',
  });
}
export const handleCommentLike = (post, comment, like) => (dispatch) => {
  let url = `/posts/${post.id}/comments/${comment.id}/like`;

  if (comment.parent_comment) {
    url = `/posts/${post.id}/replies/${comment.id}/like`;
  }

  dispatch({
    types: [
      types.COMMENT_LIKE_REQUEST,
      types.COMMENT_LIKE_SUCCESS,
      types.COMMENT_LIKE_FAILURE,
    ],
    initialData: {
      post,
      comment,
    },
    url: url,
    method: like ? 'post' : 'delete',
  });
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_UPDATED_GOLF_POST: {
      return {
        ...state,
        updatedPost: action.data,
        error: null,
      }
    }

    case types.GET_UPDATED_GENERAL_POST: {
      return {
        ...state,
        updatedPost: action.data,
        isLoadingPost: true,
        error: null,
      }
    }

    case types.GET_UPDATED_PLAY_GOLF_POST: {
      return {
        ...state,
        updatedPost: action.data,
        isLoadingPost: true,
        error: null,
      }
    }

    case types.GET_UPDATED_TRAVEL_POST: {
      return {
        ...state,
        updatedPost: action.data,
        isLoadingPost: true,
        error: null,
      }
    }

    case types.GET_POSTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.GET_POSTS_SUCCESS: {
      const posts = action.data;
      const travelPosts = posts
        .filter((x) =>
          x.travel &&
          x.travel.departure_date &&
          parser.isSameOrAfterToday(x.travel.departure_date))
        .map((y) => ({
          ...y.travel,
          member_email: y.member_email,
        }));

      return {
        ...state,
        isLoading: false,
        error: null,
        posts,
        travelPosts,
      };
    }

    case types.GET_POSTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        posts: [], error:
        action.error
      };
    }

    case types.GET_PLAY_GOLF_POSTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.GET_PLAY_GOLF_POSTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        golfPosts: action.data,
      };
    }

    case types.GET_PLAY_GOLF_POSTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        golfPosts: [], error:
        action.error
      };
    }

    case types.GET_USER_POSTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.GET_USER_POSTS_SUCCESS: {
      const userPosts = action.data;
      const golfUserPosts = userPosts
        .filter(x =>
          x.play_golf && x.play_golf.date &&
          parser.isSameOrAfterToday(x.play_golf.date))
        .map(y => y.play_golf);
      const travelUserPosts = userPosts
        .filter((x) =>
          x.travel &&
          x.travel.departure_date &&
          parser.isSameOrAfterToday(x.travel.departure_date))
        .map((y) => ({
          ...y.travel,
          member_email: y.member_email,
        }));

      return {
        ...state,
        isLoading: false,
        error: null,
        userPosts,
        golfUserPosts,
        travelUserPosts,
      };
    }

    case types.GET_USER_POSTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        userPosts: [], error:
        action.error
      };
    }

    case types.SUBMIT_POSTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.SUBMIT_POSTS_SUCCESS: {
      if (action.data) {
        const newState = { ...state, isLoading: false, isLoadingPost: false, updatedPost: {}, allPosts: [action.data, ...state.allPosts]}

        if (action.data.play_golf && action.data.play_golf.post_id) {
          newState.golfPosts = [ ...newState.golfPosts, action.data.play_golf ];
        }
        
        if (action.data.travel && action.data.travel.post_id) {
          newState.travelPosts = [ ...newState.travelPosts, {...action.data.travel, member_email: action.data.member_email} ];
        }
        
        return newState;
      } else {
        return state;
      }
    }

    case types.SUBMIT_POSTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }

    case types.SUBMIT_COMMENT_REQUEST:{
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.SUBMIT_COMMENT_SUCCESS:{
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.data.post);

      if (!action.data.parent_comment) {
        if (allPosts[postIndex].comments) {
          allPosts[postIndex].comments = [...allPosts[postIndex].comments, action.data];
        } else {
          allPosts[postIndex].comments = [action.data];
        }
        allPosts[postIndex].comment_count ++;
      } else {
        const commentIndex = allPosts[postIndex].comments.findIndex(item => item.id === action.data.parent_comment);
        delete action.data.post;

        if (allPosts[postIndex].comments[commentIndex].replies) {
          allPosts[postIndex].comments[commentIndex].replies = [...allPosts[postIndex].comments[commentIndex].replies, action.data];
        } else {
          allPosts[postIndex].comments[commentIndex].replies = [action.data];
        }
      }

      return {
        ...state,
        isLoading: false,
        allPosts
      };
    }

    case types.SUBMIT_COMMENT_FAILURE:{
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    }

    case types.SUBMIT_REPLY_REQUEST:{
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.SUBMIT_REPLY_FAILURE:{
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    }

    case types.UPDATE_POSTS_REQUEST:{
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.UPDATE_POSTS_SUCCESS:{
      if (action.data && action.data.id) {
        const allPosts = [...state.allPosts];
        const index = allPosts.findIndex(item => item.id === action.data.id);
        allPosts[index] = {...action.data};
        const newState = {...state, isLoading: false, allPosts};

        if (action.data.play_golf && action.data.play_golf.post_id) {
          const golfPosts = [...state.golfPosts];
          const index = golfPosts.findIndex(item => item.post_id === action.data.play_golf.post_id);
          golfPosts[index] = {...action.data.play_golf};
          newState.golfPosts = golfPosts;
        }

        if (action.data.travel && action.data.travel.post_id) {
          const travelPosts = [...state.travelPosts];
          const index = travelPosts.findIndex(item => item.post_id === action.data.travel.post_id);
          travelPosts[index] = {...travelPosts[index], ...action.data.travel};
          newState.travelPosts = travelPosts;
        }

        return newState;
      }

      return state;
    }

    case types.UPDATE_POSTS_FAILURE:{
      return state;
    }

    case types.UPDATE_COMMENT_REQUEST:{
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.UPDATE_COMMENT_SUCCESS:{
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.data.post);

      if (!action.data.parent_comment) {
        const commentIndex = allPosts[postIndex].comments.findIndex(item => item.id === action.data.id);

        allPosts[postIndex].comments[commentIndex] = {
          ...allPosts[postIndex].comments[commentIndex],
          ...action.data
        }
      } else {
        const commentIndex = allPosts[postIndex].comments.findIndex(item => item.id === action.data.parent_comment);
        const replyIndex = allPosts[postIndex].comments[commentIndex].replies.findIndex(item => item.id === action.data.id);

        allPosts[postIndex].comments[commentIndex].replies[replyIndex] = {
          ...allPosts[postIndex].comments[commentIndex].replies[replyIndex],
          ...action.data
        }
      }

      return {
        ...state,
        isLoading: false,
        allPosts,
      }
    }

    case types.UPDATE_COMMENT_FAILURE:{
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    }

    case types.DELETE_POSTS_REQUEST: {
      return state;
    }

    case types.DELETE_POSTS_SUCCESS: {
      if (action.data && action.data.id) {
        const allPosts = state.allPosts.filter(item => item.id !== action.data.id);
        const golfPosts = state.golfPosts.filter(item => item.post_id !== action.data.id);
        const travelPosts = state.travelPosts.filter(item => item.post_id !== action.data.id);
        const newState = {...state, allPosts, golfPosts, travelPosts}

        return newState;
      }
      
      return state;
    }

    case types.DELETE_POSTS_FAILURE: {
      return state;
    }

    case types.DELETE_COMMENT_REQUEST:{
      return state;
    }

    case types.DELETE_COMMENT_SUCCESS:{
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.data.post);

      if (!action.data.parent_comment) {
        allPosts[postIndex].comments = allPosts[postIndex].comments.filter(item => item.id !== action.data.id);
      } else {
        const commentIndex = allPosts[postIndex].comments.findIndex(item => item.id === action.data.parent_comment);
        allPosts[postIndex].comments[commentIndex].replies = allPosts[postIndex].comments[commentIndex].replies.filter(item => item.id !== action.data.id);
      }

      return {
        ...state,
        allPosts
      }
    }

    case types.DELETE_COMMENT_FAILURE:{
      return state;
    }

    case types.ATTEND_GOLF_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case types.ATTEND_GOLF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
    };
    }

    case types.ATTEND_GOLF_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    }

    case types.SET_POST_TYPE_SELECTION: {
      return {
        ...state,
        postTypeSelection: action.data
      };
    }

    case types.SHOW_DIALOG_POST: {
      return {
        ...state,
        showDialogPostId: action.data
      };
    }

    case types.GET_ALL_POSTS_REQUEST:{
      return {
        ...state,
        allPostsLoading: true
      };
    }

    case types.GET_ALL_POSTS_SUCCESS:{
      const posts = (action.data || []).map(item => ({
        ...item,
        comment_count: Number(item.comment_count),
      }));

      return {
        ...state,
        allPostsLoading: false,
        allPosts: state.initAllPosts
          ? posts
          : [...state.allPosts, ...posts],
        nextAllPosts: posts.length > 0
      };
    }

    case types.GET_ALL_POSTS_FAILURE:{
      return {
        ...state,
        allPostsLoading: false,
        allPosts: []
      };
    }

    case types.SET_ALL_POSTS_INIT:{
      return {
        ...state,
        initAllPosts: action.payload
      }
    }

    case types.GET_COMMENTS_REQUEST:{
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.initialData);
      allPosts[postIndex].comment_loading = true;

      return {
        ...state,
        allPosts,
      };
    }

    case types.GET_COMMENTS_SUCCESS:{
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.initialData);
      allPosts[postIndex].comment_loading = false;

      if (action.data.length > 0) {
        allPosts[postIndex].comments = action.data;
      } 
      
      return {
        ...state,
        allPosts,
      }
    }

    case types.GET_COMMENTS_FAILURE:{
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.initialData);
      allPosts[postIndex].comment_loading = false;

      return {
        ...state,
        allPosts,
      };
    }

    case types.POST_LIKE_REQUEST: {
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.initialData.id);
      allPosts[postIndex].like_processing = true;

      return {
        ...state,
        allPosts
      };
    }

    case types.POST_LIKE_SUCCESS: {
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.data.id);
      allPosts[postIndex].likes = action.data.likes ? [...action.data.likes] : action.data.likes;
      allPosts[postIndex].like_processing = false;
      
      return {
        ...state,
        allPosts,
      };
    }

    case types.POST_LIKE_FAILURE: {
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.initialData.id);
      allPosts[postIndex].like_processing = false;

      return {
        ...state,
        allPosts
      };
    }

    case types.COMMENT_LIKE_REQUEST: {
      return { ...state }
    }

    case types.COMMENT_LIKE_SUCCESS: {
      const allPosts = [...state.allPosts];
      const postIndex = allPosts.findIndex(item => item.id === action.data.id);
      allPosts[postIndex] = {...action.data}

      return {
        ...state,
        allPosts,
      };
    }

    case types.COMMENT_LIKE_FAILURE: {
      return { ...state }
    }

    default:
      return state;
  }
}
