import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
}))

const WidgetLoading = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}><CircularProgress size={props.size ? props.size : 96} color={ props.color ? props.color : 'primary' } /></div>
    )
}

export default WidgetLoading
