import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Footer from '../components/footer';
import GuestHeader from '../components/header_guest';
import { Apply, EmailConfirmed, Guests, LinkAccess, Login, PasswordReset, Sandbox, Splash } from '../pages';
import { paths } from '../utils/paths';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.textOnPrimary.main,
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'cover',
    },
    content: {
        margin: 'auto',
        width: '80%',
        minHeight: 'calc(100vh - 144px)',
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            width: '92%'
        },
    }
}))
const DefaultLayout = ({component: Component, ...rest}) => {
    const classes = useStyles()
    return (
      <Route {...rest} render={matchProps => (
          <React.Fragment>
                <GuestHeader />
                <div className={classes.content}>
                    <Component {...matchProps} />
                </div>
                <Footer />
          </React.Fragment>
      )} />
    )
  };
const GuestLayout = () => {
    const classes = useStyles()
    const validUrls = Object.values(paths).filter(x => x.length > 1)
    return (
    <div className={classes.root}>
        <Switch>
            <DefaultLayout path={paths.root} exact component={Splash} />
            <DefaultLayout path='/sandbox' component={Sandbox} />
            <DefaultLayout path={paths.login} component={Login} />
            <DefaultLayout path={paths.apply} component={Apply} />
            <DefaultLayout path={`${paths.linkAccess}/:userToken/:accessToken`} component={LinkAccess} />
            <DefaultLayout path={`${paths.guest}/:inviteToken`} component={Guests} />
            <DefaultLayout path={`${paths.invitation}/:referrer`} component={Guests} />
            <DefaultLayout path={`${paths.setPassword}/:email`} component={PasswordReset} />
            <DefaultLayout path={`${paths.passwordReset}/:email`} component={PasswordReset} />
            <DefaultLayout path={`${paths.emailConfirmed}/:email`} component={EmailConfirmed} />
            <Route render={({location}) => {
                return validUrls.includes(location.pathname)
                        || location.pathname.substring(1).indexOf("/") > 0
                    ? <Redirect to={paths.root} />
                    : <DefaultLayout path='/:referrer' component={Apply} />
            }} />
            {/* <DefaultLayout path='/:referrer([a-zA-Z0-9]{4,100})' component={Apply} /> */}
        </Switch>        
    </div>
    )
}

export default GuestLayout
