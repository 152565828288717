import {
AppBar, Badge, Button,
  // Button,
  Card, CardContent, CardHeader,
  // CardActions,
  ClickAwayListener, Dialog, DialogActions, DialogContent, Drawer, Hidden, IconButton, MenuItem, MenuList, Paper, Popper, Toolbar, Typography, useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useTheme } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { handleLogout } from '../reducers/auth';
import { handleGetNotificationLogs, handleMarkLogsRead } from '../reducers/notifications';
import { paths } from '../utils/paths';
import NotificationPopover from './notificationPopover';
import UserAvatar from './userAvatar';
import {isMemberSession, isNonAdminUserSession, isNonApplicantSession, isAdminSession} from '../utils/users'
import CodeOfConductLink from './codeOfConductLink';
const pack = require('../../package.json')

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  sideMenu: {
    width: 250
  },
  appBar: {
    width: "100%",
    backgroundColor: theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  profileButton: {
    padding: '16px 12px',
  },
  grow: {
    flexGrow: 1,
  },
  logoBig: {
    width: '220px',
    cursor: 'pointer',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  logoSmall: {
    width: '50px',
    cursor: 'pointer',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appbarLink: {
    paddingLeft: theme.spacing(4),
    fontSize: 16,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  navMenu: {
    zIndex: 99
  },
  notificationsMenu: {
    zIndex: 2000
  },
  menuLink: {
    fontFamily: theme.typography.secondaryFont,
    color: theme.palette.primary.dark,
    padding: '4px 24px',
  },
  avatarBox: {
      display: 'flex',
      alignItems: 'center'
  },
  navDivider: {
    margin: '8px 4px',
    padding: 0,
    minWidth: 2,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  notificationMenuRoot: {
    minHeight: 50,
    flex: '1 1 0%',
    height: 'auto',
    overflowY: 'auto'
  },
}));

function AdminHeader({ 
    history,  
    newItems, 
    oldItems, 
    userDetail, 
    session,
    messageIndicator,
    handleMarkLogsRead, 
    handleGetNotificationLogs, 
    handleLogout,
    sponsorsLink }) {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const [isMenuOpen, openMenu] = React.useState(false);
  const [mobileMenuOpen, openMobileMenu] = React.useState(false);
  const anchorNotificationsRef = React.useRef(null)
  const [notificationsMenu, openNotificationsMenu] = React.useState(false);
  const [notificationsDialog, openNotificationsDialog] = React.useState(false);
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  function handleMenuLink(path) {
    openMenu(false)
    history.push(path)
  }
  function handleMenuLogout () {
    openMenu(false)
    handleLogout();
  }

  React.useEffect(() => {
    if (isMemberSession(session, userDetail)) {
      handleGetNotificationLogs(userDetail.email);
    }
  }, [userDetail, handleGetNotificationLogs, session]);

  const sideMenu = () => (
    <div
      className={classes.sideMenu}
      onClick={() => openMobileMenu(false)}
      onKeyDown={() => openMobileMenu(false)}
    >
      <MenuList>
        <MenuItem className={classes.menuLink} onClick={() => history.push(paths.home)}>Home</MenuItem>
        {isMemberSession(session, userDetail) && <MenuItem className={classes.menuLink} onClick={() => history.push(paths.members)}>Members</MenuItem>}
        {isMemberSession(session, userDetail) && <MenuItem className={classes.menuLink} onClick={() => history.push(paths.events)}>Events</MenuItem>}
        {isMemberSession(session, userDetail) && <MenuItem className={classes.menuLink} onClick={() => window.open('https://captains-club-vault.myshopify.com/', '_blank', 'noopener,noreferrer')}>Gear</MenuItem>}
        {isMemberSession(session, userDetail) && <MenuItem className={classes.menuLink} onClick={() => window.open(sponsorsLink, '_blank', 'noopener,noreferrer')}>Sponsors</MenuItem>}
        {isNonApplicantSession(session, userDetail) && <MenuItem className={classes.menuLink} onClick={() => handleMenuLink(paths.profile)}>Profile</MenuItem>}
        {isNonAdminUserSession(session, userDetail) && <MenuItem className={classes.menuLink} onClick={() => handleMenuLink(paths.account)}>Account</MenuItem>}
        {isMemberSession(session, userDetail) && <MenuItem className={classes.menuLink}><CodeOfConductLink color='#11274B' hasQuotes={false} /></MenuItem>}
        <MenuItem className={classes.menuLink} onClick={handleMenuLogout}>Sign out</MenuItem>
      </MenuList>
    </div>
  )
  function handleCloseNotifications () {
    if (newItems && newItems.length > 0) {
      handleMarkLogsRead(userDetail.email, newItems.map(x => x.id))
    }
    openNotificationsMenu(false)
    openNotificationsDialog(false)
  }
  const renderDropDownMenu = (
    <Popper open={isMenuOpen} className={classes.navMenu} anchorEl={anchorRef.current} placement="bottom-end">
      <ClickAwayListener onClickAway={() => openMenu(false)}>
        <Paper style={{position: 'relative', minWidth: 200}}>
          <MenuList>
            <MenuItem className={classes.menuLink} onClick={() => handleMenuLink(paths.root)}>Home</MenuItem>
            {isNonApplicantSession(session, userDetail) && <MenuItem className={classes.menuLink} onClick={() => handleMenuLink(paths.profile)}>Profile</MenuItem>}
            {isNonAdminUserSession(session, userDetail) && <MenuItem className={classes.menuLink} onClick={() => handleMenuLink(paths.account)}>Account</MenuItem>}
            {isMemberSession(session, userDetail) && <MenuItem className={classes.menuLink}><CodeOfConductLink color='#11274B' hasQuotes={false} /></MenuItem>}
            <MenuItem className={classes.menuLink} onClick={handleMenuLogout}>Sign out</MenuItem>
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
  const renderNotificationMenu = (
    <Popper open={notificationsMenu} className={classes.notificationsMenu} anchorEl={anchorNotificationsRef.current} placement="bottom-end">
      <ClickAwayListener onClickAway={() => handleCloseNotifications()}>
        <Paper style={{position: 'relative', minWidth: 300}}>
          <ArrowUpIcon style={{position: 'absolute', color: '#FFF', top: -22, right: 4, fontSize: 40}} />
          <Card>
            <CardHeader
              disableTypography={true}
              style={{borderBottom: 'solid 1px #e7e7ea', padding: '4px 8px'}}
              title={<Typography color="primary" variant="subtitle2" style={{fontWeight: 'bold', fontSize: 12}}>Notifications</Typography>}
            />
            <CardContent style={{padding: 0}}>
              <div className={classes.notificationMenuRoot} style={{maxHeight: 400}}>
                <NotificationPopover
                  newItems={newItems}
                  oldItems={oldItems}
                  closeNotifications={() => handleCloseNotifications()} />
              </div>
            </CardContent>
          </Card>
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
  const renderNotificationsDialog = (
    <Dialog
      open={notificationsDialog}
      onClose={() => handleCloseNotifications()}
      fullScreen={fullScreen}   
    >
    <DialogContent>
        <Card>
          <CardHeader
            disableTypography={true}
            style={{borderBottom: 'solid 1px #e7e7ea', padding: '4px 8px'}}
            title={<Typography color="primary" variant="subtitle2" style={{fontWeight: 'bold', fontSize: 12}}>Notifications</Typography>}
          />
          <CardContent style={{padding: 0}}>
            <div className={classes.notificationMenuRoot}>
              <NotificationPopover
                newItems={newItems}
                oldItems={oldItems}
                closeNotifications={() => handleCloseNotifications()} />
            </div>
          </CardContent>
        </Card>
    </DialogContent>
    <DialogActions>
        <Button variant="contained" onClick={() => handleCloseNotifications()}>
            Close
        </Button>
    </DialogActions>
</Dialog>
  )
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Hidden mdUp>
            {userDetail && userDetail.role !== 'applicant' &&
              <IconButton onClick={() => openMobileMenu(true)} edge="start" className={classes.menuButton} color="inherit">
                <MenuIcon />
              </IconButton>
            }
          </Hidden>
          <img title={"Captain's Club v" + pack.version} alt={"Captain's Club v" + pack.version} onClick={() => history.push(paths.root)} className={classes.logoBig} src={require('../assets/images/logo_long.svg')} />
          <img title={"Captain's Club v" + pack.version} alt={"Captain's Club v" + pack.version} onClick={() => history.push(paths.root)} className={classes.logoSmall} src={require('../assets/images/logo_short.svg')} />
          <Hidden smDown>          
          {isNonApplicantSession(session, userDetail) && <div style={{display: 'flex', paddingLeft: 32}}>
              <Typography className={classes.appbarLink} variant="subtitle1" onClick={() => history.push(paths.home)}>Home</Typography>
              {isMemberSession(session, userDetail) && <Typography className={classes.appbarLink} variant="subtitle1" onClick={() => history.push(paths.members)}>Members</Typography>}
              {isMemberSession(session, userDetail) && <Typography className={classes.appbarLink} variant="subtitle1" onClick={() => history.push(paths.events)}>Events</Typography>}
              {isMemberSession(session, userDetail) && <Typography className={classes.appbarLink} variant="subtitle1" onClick={() => window.open('https://captains-club-vault.myshopify.com/', '_blank', 'noopener,noreferrer') }>Gear</Typography>}
              {isMemberSession(session, userDetail) && <Typography className={classes.appbarLink} variant="subtitle1" onClick={() => window.open(sponsorsLink, '_blank', 'noopener,noreferrer') }>Sponsors</Typography>}
            </div>}
          </Hidden>
          <div className={classes.grow} />
          <div className={classes.avatarBox}>
            {!isAdminSession(session) && userDetail && <div onClick={() => {openMobileMenu(true); openNotificationsMenu(false); openMenu(true)}} style={{cursor: 'pointer'}}><UserAvatar profileImage={userDetail.profile_image} /></div> }
            <Hidden smDown>
              <IconButton
                ref={anchorRef} onClick={() => {openNotificationsMenu(false); openMenu(true)}} color="inherit" className={classes.profileButton}
                >
                {!isAdminSession(session) && userDetail && userDetail.first_name
                  ? <Typography variant="subtitle2" style={{display: 'flex', alignItems: 'center', fontSize: 16}}>{userDetail.first_name}
                      <ArrowDropDown />
                    </Typography>
                  : <AccountCircle />
                }
              </IconButton>
            </Hidden>
            {isMemberSession(session, userDetail) && <React.Fragment>
                <div className={classes.navDivider}></div>
                <IconButton title='Notifications' ref={anchorNotificationsRef} onClick={fullScreen ? () => {openMenu(false); openNotificationsMenu(false); openNotificationsDialog(true)} : () => {openMenu(false); openNotificationsMenu(true)}}>
                    <Badge color="secondary" classes={{badge: classes.badgeIcon}} variant="dot" invisible={!newItems || newItems.length === 0}>
                      <NotificationsIcon style={{ color: 'white' }} />
                    </Badge>
                </IconButton>
                <IconButton title='Messages' onClick={() => {openMenu(false); openNotificationsMenu(false); history.push(paths.messaging)}}>
                    <Badge color="secondary" classes={{badge: classes.badgeIcon}} variant="dot" invisible={messageIndicator.length === 0}>
                      <MailIcon style={{ color: 'white' }} />
                    </Badge>
                </IconButton>
            </React.Fragment>}
          </div>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Drawer open={mobileMenuOpen} onClose={() => openMobileMenu(false)}>
          {sideMenu()}
        </Drawer>
        {isMemberSession(session, userDetail) && renderNotificationsDialog}
      </Hidden>
      <Hidden smDown>
        {renderDropDownMenu}
        {isMemberSession(session, userDetail) && renderNotificationMenu}
      </Hidden>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleLogout,
    handleGetNotificationLogs,
    handleMarkLogsRead
  }, dispatch)
}
const mapState = (state) => {
  return {
    sponsorsLink: state.references.sponsors_link,
    newItems: state.notifications.logs.filter(y => !y.read),
    oldItems: state.notifications.logs.filter(x => x.read),
    userDetail: state.user.userDetail,
    messageIndicator: state.notifications.messageIndicator,
    selectedChannel: state.notifications.selectedChannel,
    session: state.auth.session
  }
}

export default withRouter(connect(mapState, mapDispatchToProps)(AdminHeader));
