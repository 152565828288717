import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  content: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .subtext': {
      maxWidth: '400px',
      textAlign: 'center'
    }
  }
});

const EmailConfirmedView = ({ classes }) => (
  <main className={classes.main}>
    <div className={classes.content}>
      <Typography variant="h4" align="center" gutterBottom>
        Email Confirmed
      </Typography>
      <Typography variant="body2" align="center" className='subtext' gutterBottom>
        Thank you for confirming your email.
        <br /><br />
      </Typography>
    </div>
  </main>
)


export default withStyles(styles)(EmailConfirmedView);
