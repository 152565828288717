export const MAX_IMAGES = 20

const isEmptyOrNotString = (value) => {
  return !value || typeof value !== 'string'
}

const getHomeScreenMessageData = (home_screen_message) => {
  let text = ''
	let button_text = ''
	let button_url = ''

  if(home_screen_message){
    text = typeof home_screen_message[0] === 'object' ? home_screen_message[0]?.value.split('\n') : home_screen_message[0]?.split('\n')
    button_text = typeof home_screen_message[1] === 'object' ? home_screen_message[1]?.value : home_screen_message[1]
    
    if((typeof home_screen_message[2] === 'string' && home_screen_message[2] !== '') || (typeof home_screen_message[2] === 'object' && home_screen_message[2]?.value !== '')){
      button_url = typeof home_screen_message[2] === 'object' ? (home_screen_message[2]?.value?.includes("http://") || home_screen_message[2]?.value?.includes("https://") ? home_screen_message[2]?.value : 'https://'+home_screen_message[2]?.value) : home_screen_message[2]?.includes("http://") || home_screen_message[2]?.includes("https://") ? home_screen_message[2] : 'https://'+home_screen_message[2]
    }
  }

  return { text, button_text, button_url }
}

const isValidDate = a => (new Date(a) instanceof Date && !isNaN(new Date(a))) ? true : false;

const compareDates = (a, b) => {
  const dateA = isValidDate(a.play_golf?.date) ? new Date(a.play_golf?.date) : (isValidDate(a.date) ? new Date(a.date) : 0)
  const dateB = isValidDate(b.play_golf?.date) ? new Date(b.play_golf?.date) : (isValidDate(b.date) ? new Date(b.date) : 0)
  return dateA - dateB;
}

export { isEmptyOrNotString, getHomeScreenMessageData, compareDates }