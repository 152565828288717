import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MemberBox from '../../components/memberBox';
import { paths } from '../../utils/paths';

const useStyles = makeStyles(theme => ({
    highlight: {
        color: theme.palette.link.main,
        textDecoration: 'underline',
        cursor: 'pointer'
      },
}))

function PlayerConnections ({history, profile, visibles}) {
    const [ openPlayerConnections, setOpenPlayerConnections ] = React.useState(false)
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))    
    const playedWith = (profile.played_with_confirmed || [])
    
    const uniq = [...new Set(playedWith)]
    
    const playerList = uniq.map(player => {
         return (visibles || []).find(x => x.email === player)
    }).filter(y => y && y.email)

    if (!playerList || playerList.length === 0) { return <div /> }
    return (      
        <React.Fragment>
            <div className={classes.highlight} onClick={() => setOpenPlayerConnections(true)}>
                {playerList.length} Player Connection{playerList.length > 1 ? 's' : ''}
            </div>
            <Dialog
                open={openPlayerConnections}
                onClose={() => setOpenPlayerConnections(false)}
                fullScreen={fullScreen}
            >
                <DialogTitle>Player Connection{playerList.length > 1 ? 's' : ''}</DialogTitle>
                <DialogContent>
                    {playerList.map((player, idx) => {
                        return <MemberBox
                                    key={idx} 
                                    playerClick={() => {setOpenPlayerConnections(false); history.push(`${paths.profile}/${player.username}`)}}
                                    member={player} />
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPlayerConnections(false)} variant="contained">
                        OK
                    </Button>
            </DialogActions>
            </Dialog>
        </React.Fragment>  
    );
}

const mapState = (state) => {
    return {
        visibles: state.members.membersVisible
    }
  }
export default withRouter(connect(mapState)(PlayerConnections))
  