import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core/'

const useStyles = makeStyles(theme => ({
    privacyTitle: {
        display: 'flex',
        padding: '24px 0 8px 16px',
        fontSize: "24px",
        fontWeight: "bold",
        [theme.breakpoints.down('sm')]: {
            padding: '32px 12px 16px',
        },
        color: theme.palette.profile.dark
    },
}))

export default function SectionTitle(props) {    
    const classes = useStyles()
    return (
        <Typography component='div' className={classes.privacyTitle}>
            {props.children}
        </Typography>
    )
}
