import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { handleGetReferrer } from '../../reducers/auth'
import { handleClubSearch } from '../../reducers/clubs'
import ApplyView from './applyView'

class ApplyContainer extends React.Component {
  componentDidMount = () => {
    if (this.props.match.params.referrer) {
      this.props.handleGetReferrer(this.props.match.params.referrer)
    }
    this.props.handleClubSearch()
  }
  render () {
    return <ApplyView {...this.props} />
  }
}

const mapStateToProps = (state) => {
    return {
      auth: {...state.auth}
    }
}

const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handleGetReferrer,
      handleClubSearch
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(ApplyContainer)
