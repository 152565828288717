import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircle';
import React from 'react';

const useStyles = makeStyles(theme => ({
  avatar: {
    borderRadius: '50px',
    width: '100%',
    height: '100%'
  },
}))


const UserAvatar = ({ profileImage, width, isClub, styles }) => {
  const classes = useStyles()
  if (isClub) {
    return <div>
      {profileImage ? <img alt="Club Logo" src={profileImage} style={{verticalAlign: 'middle', width: width || 24, height: width || 24, ...styles}} /> : ''}
    </div>
  }
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyItems: 'center', width: width || 48, height: width || 48, ...styles}}>
      {profileImage
        ? <Avatar alt="Profile Picture" src={profileImage} className={classes.avatar} /> 
        : <AccountIcon className={classes.avatar} style={{backgroundColor: '#FFF', color: '#11274B'}} />
      }
    </div>
  )
}

export default UserAvatar
