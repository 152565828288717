import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import cls from 'classnames';
import ChipInput from 'material-ui-chip-input';
import React from 'react';
import AddressForm from '../../components/addressForm';
import AutoSelect from '../../components/auto_select';
import AvatarEditor from '../../components/avatorEditor';
import PrivacySelector from '../../components/privacySelector';
import { parser } from '../../utils/parser';
import { paths } from '../../utils/paths';
import ClubCourseSelector from '../profile/clubCourseSelector';
import Partition from './partition';
import MomentUTCUtils from '../../utils/MomentUTCUtils';
import CourseSelector from '../profile/clubCourseSelector.js';
import { formatForAutoSelectAreasOfInterest, getTopUsaCourseCountForProfile } from '../../utils/profiles';


const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 0, 8),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  dialogCard: {
    width: '100% !important',
  },
  card: {
    width: '72%',
    color: theme.palette.primary.dark,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      width: '96%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  thankYouCard: {
    maxWidth: 580,
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(8),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  cardHeader: {
    padding: theme.spacing(4), // `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`,
    borderBottom: 'solid 2px #bbb',
    cursor: 'default',
    color: '#ccc',
    '&.active': {
      paddingBottom: theme.spacing(1),
      color: theme.palette.primary.dark,
      borderBottom: 'none',
      cursor: 'default',
      backgroundColor: theme.palette.primary.extraLight, // '#f6f7fc'
    },
    '&.complete': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  dob: {
    marginBottom: 8,
  },
  cardContent: {
    padding: theme.spacing(2, 4, 0), // `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`
  },
  cardActionDone: {
    padding: theme.spacing(2, 4), // `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`
  },
  cardActionNext: {
    padding: 0,
  },
  buttonNext: {
    margin: theme.spacing(3, 0), // `${theme.spacing.unit * 3}px 0`
  },
  buttonDone: {
    marginBottom: theme.spacing(4), // `${theme.spacing.unit * 4}px`,
    textAlign: 'center',
  },
  linkedin: {
    textAlign: 'center',
    '& img': {
      maxWidth: '60%',
      height: 'auto',
    },
  },
  formControl: {
    padding: 0,
  },
  cardSubmit: {
    padding: theme.spacing(3, 4), // `${theme.spacing.unit * 3}px ${theme.spacing.unit * 4}px`
  },
  avatar: {
    backgroundColor: '#ddd',
    '&.complete': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  gridBox: {},
  textField: {},
  menuItem: {
    minHeight: 36,
  },
  multiLineTextBox: {
    '& textarea': {
      fontFamily: theme.typography.secondaryFont,
    },
  },
  // clubLogo: {
  //   width: 24,
  //   height: 24,
  //   verticalAlign: 'middle',
  //   marginRight: 8
  // }
});

function OnboardingView(props) {
  const {
    handleClubSearch,
    handleSchoolSearch,
    handleGetUserDetail,
    // handleOnboardingChange,
    handleOnboardingSubmit,
    handleOnboardingSelectStage,
    handleImageSubmit,
    handleTermsAgreed,
    history,
    onboardingStage,
    session,
    userDetail,
    memberProfile,
    clubs,
    references,
    classes,
    courseList,
    handleCourseTopUS
  } = props;
  // console.log('User', profile)
  const [bioRequired, setBioRequired] = React.useState(false);
  const [photoRequired, setPhotoRequired] = React.useState(false);
  const [newProfilePic, setNewProfilePic] = React.useState();
  const [profile, setProfile] = React.useState();
  const [countCoursesInUsaRank, setCountCoursesInUsaRank] = React.useState(0)
  const courses = courseList?.filter(c => c.usa_rank && c.usa_rank <= 100)
  
  React.useEffect(() => {
    handleCourseTopUS()
  }, [handleCourseTopUS])

  React.useEffect(() => {
    setCountCoursesInUsaRank(getTopUsaCourseCountForProfile(profile, courses))    
  }, [profile, courses])

  React.useEffect(() => {
    if (session && session.role === 'admin' && memberProfile) {
      setProfile(memberProfile);
    }
    if (session && session.role !== 'admin' && userDetail) {
      setProfile(userDetail);
    }
  }, [memberProfile, userDetail, session]);
  if (!profile) {
    return <div />;
  }
  const getAvatar = (stage) => {
    if (profile.terms_accepted_date || onboardingStage > stage) {
      return (
        <Avatar className={cls([classes.avatar], 'complete')}>
          <CheckCircle />
        </Avatar>
      );
    }
    if (onboardingStage === stage) {
      return (
        <Avatar className={classes.avatar}>
          <CheckCircle />
        </Avatar>
      );
    }
    return <Avatar className={classes.avatar} />;
  };
  const changeStage = (stage) => {
    if (stage < onboardingStage || profile.terms_accepted_date) {
      handleOnboardingSelectStage(stage);
      window.scroll(0, 0);
    }
  };
  const handlePhotoNext = () => {
    if (!newProfilePic && !profile.profile_image) {
      setPhotoRequired(true);
    } else {
      return newProfilePic ? submitProfileImage() : skipImageSubmit(3);
    }
  };
  const skipImageSubmit = (stage) => {
    handleOnboardingSelectStage(stage);
    window.scroll(0, 0);
  };
  const submitProfileImage = () => {
    handleImageSubmit(profile.email, profile.role, newProfilePic);
    setNewProfilePic(null);
  };
  const formatSocialLinks = (links) => {
    if (links) {
      if (!Array.isArray(links)) {
        links = [links];
      }
      return links.map((item) => {
        if (!item) {
          item = 'https://www.linkedin.com';
        }
        if (!item.startsWith('http')) {
          return 'http://' + item;
        } else {
          return item;
        }
      });
    }
    return null;
  };
  const handleStageSubmit = () => {
    if (onboardingStage === 1 && (!profile.bio || profile.bio.length < 5)) {
      setBioRequired(true);
      return;
    }
    profile.social_media_links = formatSocialLinks(profile.social_media_links);
    profile.areas_of_interest = formatForDataModel(profile.areas_of_interest);
    profile.date_of_birth = parser.shortDateMoment(profile.date_of_birth);
    profile.courses_played = formatClubCourseForDataModel(
      profile.courses_played
    );
    profile.course_wishlist = formatClubCourseForDataModel(
      profile.course_wishlist
    );
    if (
      profile.address &&
      profile.address.state &&
      profile.address.state.label
    ) {
      profile.address.state = profile.address.state.value;
    }
    if (profile.role && profile.role === 'member') {
      profile.club_memberships = formatClubCourseForDataModel(
        profile.club_memberships
      );
    } else {
      profile.additional_club_memberships = formatClubCourseForDataModel(
        profile.additional_club_memberships
      );
    }
    handleOnboardingSubmit(profile.email, profile.role, profile);
    window.scroll(0, 0);
  };
  const agreeToTerms = (email) => {
    handleTermsAgreed(email);
  };
  const handleFinish = () => {
    handleGetUserDetail({ role: profile.role, email: profile.email });
    handleOnboardingSelectStage(7);
    window.scroll(0, 0);
  };
  const handleAutoSelectChange = (name) => (list) => {
    profile[name] = list;
    // handleOnboardingChange(profile)
    setProfile({ ...profile });
  };
  const handleClubCourseChange = (name) => (list) => {
    if (
      profile.role &&
      profile.role === 'member' &&
      name === 'additional_club_memberships'
    ) {
      name = 'club_memberships';
      list.unshift(profile.club_memberships[0]);
    }
    handleAutoSelectChange(name)(list);
  };
  const handleSchoolTextChange = (name) => (value) => {
    if (value && value.length > 0) {
      handleSchoolSearch(value);
    }
  };
  
  const handleClubsTextChange = (name) => (value) => {
    if (value && value.length > 2) {
      handleClubSearch(value);
    }
  };
  const handleChange = (event) => {
    console.log('>>>handleChange', event)
    if (event.target.value === parser.list_delimiter) {
      return;
    }
    let oldValue = profile[event.target.name];
    let value = Array.isArray(oldValue)
      ? [event.target.value]
      : event.target.value;
    profile[event.target.name] = value || null;
    // handleOnboardingChange(profile)
    setProfile({ ...profile });
  };

  const handleBioChange = (event) => {
    if (bioRequired && event.target.value && event.target.value.length > 4) {
      setBioRequired(false);
    }
    handleChange({
      target: { name: 'bio', value: event.target.value },
    });
  };
  const handleDateChange = (date) => {
    handleChange({
      target: { name: 'date_of_birth', value: parser.shortDateMoment(date) },
    });
  };
  const updateAddress = (address) => {
    handleChange({ target: { name: 'address', value: { ...address } } });
  };
  const handleNumericChange = (event) => {
    if (event.target.value && !parser.isNumeric(event.target.value)) {
      return;
    }
    let num = !event.target.value ? '' : parseInt(event.target.value);
    if (num.toString().length > 3) {
      return;
    }
    handleChange({ target: { name: event.target.name, value: num } });
  };
  const handlePrivacySelected = (value) => {
    handleChange({ target: { name: 'privacy_setting', value: value } });
  };
  const handleRetiredChecked = (event) => {
    handleChange({ target: { name: 'retired', value: event.target.checked } });
  };
  const handlePhotoSelected = (file) => {
    setPhotoRequired(false);
    setNewProfilePic(file);
  };
  const handleAddAboutMe = (chip) => {
    let oldArray =
      !profile.about_me || Array.isArray(profile.about_me)
        ? profile.about_me || []
        : profile.about_me.split(',');
    if (oldArray.length >= 5) {
      return;
    }
    if (!oldArray.length) {
      oldArray = [chip];
    } else {
      oldArray.push(chip);
    }
    profile.about_me = oldArray.join();
    // handleOnboardingChange(profile)
    setProfile({ ...profile });
  };
  const handleDeleteAboutMe = (chip, index) => {
    let oldArray = profile.about_me;
    let newArray = oldArray.split(',');
    newArray.splice(index, 1);
    profile.about_me = newArray.join();
    // handleOnboardingChange(profile)
    setProfile({ ...profile });
  };

  const handleHandicapChange = (event) => {
    if (event.target.value && !parser.isValidHandicap(event.target.value)) {
      return;
    }
    let num = !event.target.value ? '' : event.target.value;
    handleChange({ target: { name: event.target.name, value: num } });
  };
  const formatSchools = (schools) => {
    if (!schools || schools.length === 0) {
      return [];
    }
    return schools.map((option) => ({
      value: option.value || option.name,
      label:
        option.label ||
        option.name + ' (' + option.city + ', ' + option.state + ')',
    }));
  };
  const parseSelectedSchool = (school) => {
    if (!school) {
      return '';
    }
    if (school && school.value) {
      return school;
    }
    try {
      return JSON.parse(school);
    } catch (e) {
      return '';
    }
  };
  const formatClubCourseForDataModel = (data) => {
    if (!data || data.length === 0) {
      return [];
    }
    if (profile.role && profile.role === 'member') {
      return data.map((item, idx) => ({
        rank: idx,
        id: item.value || item.id,
      }));
    } else {
      return formatForDataModel(data);
    }
  };
  const formatForDataModel = (data) => {
    if (!data || data.length === 0) {
      return [];
    }
    return data.map((item) => {
      if (item && item.value) {
        return item.value;
      }
      if (item && item.id) {
        return item.id;
      }
      return item;
    });
  };
  const handleDone = () => {
    history.push(paths.root);
  };
  
  return (
    <div className={classes.root}>
      {onboardingStage > 6 ? (
        <React.Fragment>
          
          <Card className={classes.thankYouCard}>
            <CardHeader
              className={cls(classes.cardHeader, 'complete')}
              title='Thank You!'
            />
            <CardContent className={classes.cardContent}>
              Thank you for completing your profile, we will send all of your
              information to our membership committee who will make one final
              review and you'll be live very shortly!
              <br />
              <br />
            </CardContent>
            <CardActions className={classes.cardActionDone}>
              <Button
                className={classes.buttonDone}
                onClick={handleDone}
                fullWidth
                variant='contained'
              >
                Ok, Got It!
              </Button>
            </CardActions>
          </Card>
        </React.Fragment>
      ) : (
        <>
        { references.onboarding_intro_description && <Card className={cls(classes.card, {
            [classes.dialogCard]: session && session.role === 'admin',
          })} style={{ marginBottom: '15px' }} >
          <div style={{ padding: '20px' }}>
            {references.onboarding_intro_description.split("\n").map(text => <p style={{ textAlign: 'justify' }}>{text}</p>)}
          </div>
        </Card>}
        
        <Card
          className={cls(classes.card, {
            [classes.dialogCard]: session && session.role === 'admin',
          })}
          >
          <CardHeader
            className={cls({
              [classes.cardHeader]: true,
              active: onboardingStage === 0,
              complete: profile.terms_accepted_date || onboardingStage > 0,
            })}
            onClick={() => changeStage(0)}
            action={getAvatar(0)}
            title={
              profile.role === 'member' ? 'Member Detail' : 'Applicant Detail'
            }
          />
          {onboardingStage === 0 && (
            <CardContent className={classes.cardContent}>
              <Partition text='CONTACT INFO' />
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <TextField
                    id='first_name'
                    name='first_name'
                    label='First Name'
                    fullWidth
                    disabled
                    margin='none'
                    variant='outlined'
                    value={profile.first_name || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <TextField
                    id='last_name'
                    name='last_name'
                    label='Last Name'
                    fullWidth
                    disabled
                    margin='none'
                    variant='outlined'
                    value={profile.last_name || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <TextField
                    id='email'
                    name='email'
                    label='Email'
                    fullWidth
                    disabled
                    margin='none'
                    variant='outlined'
                    value={profile.email || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <TextField
                    id='cell_number'
                    name='cell_number'
                    label='Phone'
                    fullWidth
                    disabled
                    margin='none'
                    variant='outlined'
                    value={profile.cell_number || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <TextField
                    id='venmo_username'
                    name='venmo_username'
                    label='Venmo Username'
                    fullWidth
                    margin='none'
                    variant='outlined'
                    value={profile.venmo_username || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <Partition text='MAILING ADDRESS' />
              <AddressForm
                address={profile.address || {}}
                updateAddress={updateAddress}
              />
              <CardActions className={classes.cardActionNext}>
                <Button
                  className={classes.buttonNext}
                  onClick={handleStageSubmit}
                  type='submit'
                  variant='contained'
                >
                  Next
                </Button>
              </CardActions>
            </CardContent>
          )}
          <CardHeader
            className={cls({
              [classes.cardHeader]: true,
              active: onboardingStage === 1,
              complete: profile.terms_accepted_date || onboardingStage > 1,
            })}
            onClick={() => changeStage(1)}
            action={getAvatar(1)}
            title='Biography'
          />
          {onboardingStage === 1 && (
            <CardContent className={classes.cardContent}>
              <Partition text='GENERAL' />
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} xs={12}>
                  <MuiPickersUtilsProvider utils={MomentUTCUtils}>
                    <DatePicker
                      disableFuture
                      fullWidth
                      openTo='year'
                      format="MM/DD/YYYY"
                      label='Birthday'
                      views={['year', 'month', 'date']}
                      value={ profile.date_of_birth || null }
                      className={classes.dob}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <AutoSelect
                    name='areas_of_interest'
                    isMulti
                    textFieldProps={{
                      label: 'Areas of Interest',
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    options={formatForAutoSelectAreasOfInterest(references.areas_of_interest)}
                    placeholder='Select multiple items'
                    selectedValue={formatForAutoSelectAreasOfInterest(
                      profile.areas_of_interest
                    )}
                    handleAutoSelectChange={handleAutoSelectChange}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <AutoSelect
                    textFieldProps={{
                      label: 'Undergraduate school',
                    }}
                    name='graduate_degree'
                    options={formatSchools(references.schools)}
                    selectedValue={parseSelectedSchool(profile.graduate_degree)}
                    handleAutoSelectTextChange={handleSchoolTextChange}
                    handleAutoSelectChange={handleAutoSelectChange}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <AutoSelect
                    textFieldProps={{
                      label: 'Graduate school',
                    }}
                    name='postgraduate_degree'
                    options={formatSchools(references.schools)}
                    selectedValue={parseSelectedSchool(
                      profile.postgraduate_degree
                    )}
                    handleAutoSelectTextChange={handleSchoolTextChange}
                    handleAutoSelectChange={handleAutoSelectChange}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <ChipInput
                    value={profile.about_me ? profile.about_me.split(',') : []}
                    label='5 words to describe me'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    newChipKeyCodes={[9, 13, 32, 188]}
                    fullWidth
                    margin='none'
                    placeholder='Example: "Entrepreneur, Father, Lawyer, Golfer, Surfer"'
                    variant='outlined'
                    onAdd={(chip) => handleAddAboutMe(chip)}
                    onDelete={(chip, index) => handleDeleteAboutMe(chip, index)}
                  />
                </Grid>
              </Grid>
              <Partition text='GOLF' />
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} xs={12}>
                  <TextField
                    id='best_gross_round'
                    name='best_gross_round'
                    label='Best Gross Round'
                    fullWidth
                    value={profile.best_gross_round || ''}
                    onChange={handleNumericChange}
                    margin='none'
                    variant='outlined'
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <CourseSelector
                    title={`Favorite Courses Played`}
                    field='favorite_courses'
                    profile={profile}
                    readonly={false}
                    appendText={`(${countCoursesInUsaRank} of the Top 100 in the U.S.)`}
                  />
                </Grid>
              </Grid>
              <Partition text='PROFESSIONAL' />
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} xs={12}>
                  <TextField
                    id='job_title'
                    name='job_title'
                    label='Job Title'
                    // error={Boolean(values.errors.includes('title'))}
                    fullWidth
                    margin='none'
                    variant='outlined'
                    value={profile.job_title || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <TextField
                    id='company'
                    name='company_name'
                    label='Company Name'
                    // error={Boolean(values.errors.includes('company'))}
                    fullWidth
                    margin='none'
                    variant='outlined'
                    value={profile.company_name || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <TextField
                    id='industry'
                    name='industry'
                    label='Industry'
                    // error={Boolean(values.errors.includes('industry'))}
                    fullWidth
                    margin='none'
                    variant='outlined'
                    value={profile.industry || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <TextField
                    id='company_website'
                    name='company_website'
                    label='Company Website'
                    fullWidth
                    margin='none'
                    variant='outlined'
                    value={profile.company_website || ''}
                    onChange={handleChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <Typography component='span'>
                    <TextField
                      id='social_media_links'
                      name='social_media_links'
                      label='LinkedIn Link'
                      margin='none'
                      variant='outlined'
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <img
                              src={require('../../assets/images/linkedin.png')}
                              style={{
                                width: '24px',
                                margin: '0',
                                verticalAlign: 'middle',
                              }}
                              alt='linkedin'
                            />
                          </InputAdornment>
                        ),
                      }}
                      placeholder='https://www.linkedin.com/in/yourname'
                      value={
                        profile.social_media_links &&
                        Array.isArray(profile.social_media_links) &&
                        profile.social_media_links.length > 0
                          ? profile.social_media_links[0]
                          : profile.social_media_links || ''
                      }
                      onChange={handleChange}
                      className={classes.textField}
                    />
                  </Typography>
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <TextField
                    id='bio'
                    name='bio'
                    label='Bio'
                    fullWidth
                    margin='none'
                    variant='outlined'
                    value={profile.bio || ''}
                    onChange={handleBioChange}
                    className={classes.multiLineTextBox}
                    error={bioRequired}
                    multiline
                    rows='4'
                    placeholder='Tell us a little about yourself'
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={profile.retired || false}
                        onChange={handleRetiredChecked}
                        value='retired'
                        color='primary'
                      />
                    }
                    label='I am retired'
                  />
                </Grid>
              </Grid>
              <Partition text='PRIVACY' />
              <div style={{ paddingBottom: 16 }}>
                <PrivacySelector
                  text={references.privacy_descriptions}
                  value={profile.privacy_setting || 'full'}
                  handlePrivacySelected={handlePrivacySelected}
                />
                {profile.privacy_setting &&
                  profile.privacy_setting === 'anonymous' && (
                    <Paper>
                      <ClubCourseSelector
                        title='Show my profile only to members of these clubs:'
                        label='Selected Clubs'
                        field='share_clubs'
                        profile={profile}
                        readonly={Boolean(false)}
                      />
                    </Paper>
                  )}
              </div>
              <CardActions className={classes.cardActionNext}>
                <Button
                  className={classes.buttonNext}
                  onClick={handleStageSubmit}
                  type='submit'
                  variant='contained'
                >
                  Next
                </Button>
                {bioRequired ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BlockIcon style={{ color: 'red', marginRight: 4 }} />
                    <Typography
                      variant='subtitle2'
                      style={{ margin: 0, padding: 0 }}
                    >
                      Please complete your Bio before going to the next step of
                      the on boarding application
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
              </CardActions>
            </CardContent>
          )}
          <CardHeader
            className={cls({
              [classes.cardHeader]: true,
              active: onboardingStage === 2,
              complete: profile.terms_accepted_date || onboardingStage > 2,
            })}
            onClick={() => changeStage(2)}
            action={getAvatar(2)}
            title='Upload a Photo'
          />
          {onboardingStage === 2 && (
            <CardContent className={classes.cardContent}>
              <Typography variant='body1'>
                Have a favorite picture of yourself? Upload it now.
              </Typography>
              <AvatarEditor
                profileImage={newProfilePic || profile.profile_image || ''}
                handlePhotoSelected={handlePhotoSelected}
              />
              <CardActions className={classes.cardActionNext}>
                <Button
                  onClick={() => handlePhotoNext()}
                  className={classes.buttonNext}
                  type='submit'
                  variant='contained'
                >
                  Next
                </Button>
                {photoRequired ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BlockIcon style={{ color: 'red', marginRight: 4 }} />
                    <Typography
                      variant='subtitle2'
                      style={{ margin: 0, padding: 0 }}
                    >
                      Please upload a profile photo before going to the next
                      step of the on boarding application.
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
              </CardActions>
            </CardContent>
          )}
          <CardHeader
            className={cls({
              [classes.cardHeader]: true,
              active: onboardingStage === 3,
              complete: profile.terms_accepted_date || onboardingStage > 3,
            })}
            onClick={() => changeStage(3)}
            action={getAvatar(3)}
            title='Club Information'
          />
          {onboardingStage === 3 && (
            <CardContent className={classes.cardContent}>
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} xs={12}>
                  <AutoSelect
                    textFieldProps={{
                      label: 'Primary Club Membership',
                    }}
                    disabled
                    name='primary_club'
                    options={parser.formatClubs(clubs.clubList)}
                    selectedValue={parser.formatClubs(
                      profile.primary_club && profile.primary_club.id
                        ? [profile.primary_club]
                        : profile.club_memberships &&
                          profile.club_memberships.length > 0
                        ? [profile.club_memberships[0]]
                        : []
                    )}
                    handleAutoSelectTextChange={() => {}}
                    handleAutoSelectChange={() => {}}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} xs={12}>
                  <Typography
                    component='p'
                    variant='body1'
                    style={{ paddingBottom: 16 }}
                  >
                    Please select any additional club memberships that you hold
                  </Typography>
                  <AutoSelect
                    textFieldProps={{
                      label: 'Additional Clubs',
                    }}
                    isMulti
                    name='additional_club_memberships'
                    options={parser.formatClubs(clubs.clubList)}
                    selectedValue={parser.formatClubs(profile.additional_club_memberships, clubs.clubList)}
                    handleAutoSelectTextChange={handleClubsTextChange}
                    handleAutoSelectChange={handleClubCourseChange}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <TextField
                    id='handicap'
                    name='handicap'
                    label='Handicap'
                    fullWidth
                    value={profile.handicap !== null ? profile.handicap : ''}
                    onChange={handleHandicapChange}
                    margin='none'
                    variant='outlined'
                    className={classes.textField}
                  />
                </Grid>
                <Grid item className={classes.gridBox} xs={12}>
                  <TextField
                    id='ginn'
                    name='ginn'
                    label='GHIN'
                    fullWidth
                    value={profile.ginn ? profile.ginn : ''}
                    onChange={handleChange}
                    margin='none'
                    variant='outlined'
                    className={classes.textField}
                  />
                </Grid>
              </Grid>
              <CardActions className={classes.cardActionNext}>
                <Button
                  onClick={handleStageSubmit}
                  className={classes.buttonNext}
                  type='submit'
                  variant='contained'
                >
                  Next
                </Button>
              </CardActions>
            </CardContent>
          )}
          <CardHeader
            className={cls({
              [classes.cardHeader]: true,
              active: onboardingStage === 4,
              complete: profile.terms_accepted_date || onboardingStage > 4,
            })}
            onClick={() => changeStage(4)}
            action={getAvatar(4)}
            title='Gear Preferences'
          />
          {onboardingStage === 4 && (
            <CardContent className={classes.cardContent}>
              <Typography variant='body1'>
                We often send our Members free gear. Please indicate your gear
                preferences below.
              </Typography>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  select
                  id='golf_ball_brand'
                  name='golf_ball_brand'
                  label='Golf Balls'
                  // error={Boolean(values.errors.includes('golf_ball_brand'))}
                  className={classes.textField}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  value={profile.golf_ball_brand || ''}
                  onChange={handleChange}
                  inputProps={{
                    name: 'golf_ball_brand',
                    id: 'golf_ball_brand',
                  }}
                >
                  {references.golf_ball_brands &&
                    references.golf_ball_brands.map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          className={classes.menuItem}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  select
                  id='driver_brand'
                  name='driver_brand'
                  label='Driver'
                  className={classes.textField}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  value={profile.driver_brand || ''}
                  onChange={handleChange}
                  inputProps={{ name: 'driver_brand', id: 'driver_brand' }}
                >
                  {references.golf_club_brands &&
                    references.golf_club_brands.map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          className={classes.menuItem}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  select
                  id='wood_brand'
                  name='wood_brand'
                  label='Woods'
                  // error={Boolean(values.errors.includes('wood_brand'))}
                  className={classes.textField}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  value={profile.wood_brand || ''}
                  onChange={handleChange}
                  inputProps={{ name: 'wood_brand', id: 'wood_brand' }}
                >
                  {references.golf_club_brands &&
                    references.golf_club_brands.map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          className={classes.menuItem}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  select
                  id='iron_brand'
                  name='iron_brand'
                  label='Irons'
                  // error={Boolean(values.errors.includes('iron_brand'))}
                  className={classes.textField}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  value={profile.iron_brand || ''}
                  onChange={handleChange}
                  inputProps={{ name: 'iron_brand', id: 'iron_brand' }}
                >
                  {references.golf_club_brands &&
                    references.golf_club_brands.map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          className={classes.menuItem}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  select
                  id='wedge_brand'
                  name='wedge_brand'
                  label='Wedges'
                  // error={Boolean(values.errors.includes('wedge_brand'))}
                  className={classes.textField}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  value={profile.wedge_brand || ''}
                  onChange={handleChange}
                  inputProps={{ name: 'wedge_brand', id: 'wedge_brand' }}
                >
                  {references.golf_club_brands &&
                    references.golf_club_brands.map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          className={classes.menuItem}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  select
                  id='putter_brand'
                  name='putter_brand'
                  label='Putter'
                  // error={Boolean(values.errors.includes('wood_brand'))}
                  className={classes.textField}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  value={profile.putter_brand || ''}
                  onChange={handleChange}
                  inputProps={{ name: 'putter_brand', id: 'putter_brand' }}
                >
                  {references.golf_putter_brands &&
                    references.golf_putter_brands.map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          className={classes.menuItem}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </FormControl>
              <Grid
                container
                className={classes.gridBox}
                spacing={2}
                style={{ marginTop: 0 }}
              >
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      select
                      id='shirt_brand'
                      name='shirt_brand'
                      label='Shirt Brand'
                      className={classes.textField}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      value={profile.shirt_brand || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'shirt_brand', id: 'shirt_brand' }}
                    >
                      {references.shirt_brands &&
                        references.shirt_brands.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              className={classes.menuItem}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      select
                      id='shirt_size'
                      name='shirt_size'
                      label='Shirt Size'
                      // error={Boolean(values.errors.includes('shirt_size'))}
                      className={classes.textField}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      value={profile.shirt_size || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'shirt_size', id: 'shirt_size' }}
                    >
                      {references.mens_shirt_sizes &&
                        references.mens_shirt_sizes.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              className={classes.menuItem}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      select
                      id='blazer_brand'
                      name='blazer_brand'
                      label='Blazer Brand'
                      // error={Boolean(values.errors.includes('blazer_brand'))}
                      className={classes.textField}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      value={profile.blazer_brand || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'blazer_brand', id: 'blazer_brand' }}
                    >
                      {references.blazer_brands &&
                        references.blazer_brands.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              className={classes.menuItem}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      select
                      id='blazer_size'
                      name='blazer_size'
                      label='Blazer Size'
                      className={classes.textField}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      value={profile.blazer_size || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'blazer_size', id: 'blazer_size' }}
                    >
                      {references.blazer_sizes &&
                        references.blazer_sizes.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              className={classes.menuItem}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      select
                      id='shoe_brand'
                      name='shoe_brand'
                      label='Shoe Brand'
                      // error={Boolean(values.errors.includes('shoe_brand'))}
                      className={classes.textField}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      value={profile.shoe_brand || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'shoe_brand', id: 'shoe_brand' }}
                    >
                      {references.shoe_brands &&
                        references.shoe_brands.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              className={classes.menuItem}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      select
                      id='shoe_size'
                      name='shoe_size'
                      label='Shoe Size'
                      className={classes.textField}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      value={profile.shoe_size || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'shoe_size', id: 'shoe_size' }}
                    >
                      {references.mens_shoe_sizes &&
                        references.mens_shoe_sizes.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              className={classes.menuItem}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className={classes.gridBox} spacing={2}>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      select
                      id='glove_brand'
                      name='glove_brand'
                      label='Glove Brand'
                      // error={Boolean(values.errors.includes('glove_brand'))}
                      className={classes.textField}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      value={profile.glove_brand || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'glove_brand', id: 'glove_brand' }}
                    >
                      {references.glove_brands &&
                        references.glove_brands.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              className={classes.menuItem}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item className={classes.gridBox} md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      select
                      id='glove_size'
                      name='glove_size'
                      label='Glove Size'
                      // error={Boolean(values.errors.includes('glove_size'))}
                      className={classes.textField}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      value={profile.glove_size || ''}
                      onChange={handleChange}
                      inputProps={{ name: 'glove_size', id: 'glove_size' }}
                    >
                      {references.mens_glove_sizes &&
                        references.mens_glove_sizes.map((item, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              className={classes.menuItem}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
              <CardActions className={classes.cardActionNext}>
                <Button
                  onClick={handleStageSubmit}
                  className={classes.buttonNext}
                  type='submit'
                  variant='contained'
                >
                  Next
                </Button>
              </CardActions>
            </CardContent>
          )}
          <CardHeader
            className={cls({
              [classes.cardHeader]: true,
              active: onboardingStage === 5,
              complete: profile.terms_accepted_date || onboardingStage > 5,
            })}
            onClick={() => changeStage(5)}
            action={getAvatar(5)}
            title='Terms & Conditions'
          />
          {onboardingStage === 5 && (
            <CardContent className={classes.cardContent}>
              <iframe
                title='Doc'
                style={{ padding: '0 8px' }}
                width='100%'
                height='300px'
                src='https://docs.google.com/document/d/e/2PACX-1vSrq7A1bS98JGvZMqxw6XSBYZKCCPef4zvHqQCnaZPfYH04Yzw5dCU7slGDufrNm4EdG_bwYzb8RobF/pub?embedded=false'
              ></iframe>
              <CardActions className={classes.cardActionNext}>
                <Button
                  onClick={() => agreeToTerms(profile.email)}
                  className={classes.buttonNext}
                  type='submit'
                  variant='contained'
                >
                  I Agree
                </Button>
              </CardActions>
            </CardContent>
          )}
          <CardActions className={classes.cardSubmit}>
            {session && session.role !== 'admin' && (
              <Button
                onClick={() => handleFinish()}
                className={classes.buttonNext}
                disabled={!profile.terms_accepted_date && onboardingStage <= 5}
                variant='contained'
              >
                Finish
              </Button>
            )}
          </CardActions>
        </Card>
        </>
      )}
    </div>
  );
}

export default withStyles(styles)(OnboardingView);
