import { Button, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import GolfIcon from '@material-ui/icons/GolfCourse';
import QuestionIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ToggleButton from './toggleButton';
import UserAvatar from './userAvatar';
import { getUserMemberships, hasMemberPlayedWithUser } from '../utils/users';
import { userRoles } from '../utils/profiles';
import { PlaylistAdd } from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { handleSelectMemberLists } from '../reducers/lists'

const useStyles = props => makeStyles(theme => {
  return ({
    memberItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      // borderTop: `solid 1px ${theme.palette.primary.dark}`,
      // borderBottom: `solid 1px ${theme.palette.primary.dark}`,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'flex-start'
      },
      '&:hover': {
        borderBottom: props.type === userRoles.memberReview ? `none` : `solid 1px ${theme.palette.primary.main}`,
      },
    },
    memberContent: {
      padding: 16,
      display: 'flex',
      flexGrow: 1,
      width: '100%',
      alignItems: 'flex-start',
      cursor: 'pointer',
    },
    memberAction: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      paddingLeft: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 8,
        paddingLeft: 8,
        margin: 'auto',
      },
    },
    memberNameRow: {
      display: 'flex',
    },
    memberName: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    handicap: {
      display: 'inline-block',
      fontSize: 14,
      fontFamily: theme.typography.secondaryFont,
      marginTop: 4,
    },
  })
})

const MemberBoxContainer = ({type, classes, children}) => {
  return type === userRoles.memberReview ? <div className={classes}>{children}</div> : <Paper className={classes} >{children}</Paper>
}

const MemberBox = ({ userDetail, member, handlePlayedTogetherClick, handleIntroduce, playerClick, visibles, handleSelectMemberLists, type = userRoles.member, lists }) => {
  const classes = useStyles({type})();
  const [playedWithConfirmed, setPlayedWithConfirmed] = useState(hasMemberPlayedWithUser(userDetail, member));
  const [myLists, setMyLists] = React.useState([])

  React.useEffect(() => {
    if(lists && lists.length > 0){
      const selectedLists = []
      lists.map(l => {
        if(l.members && l.members.length > 0 && l.members.filter(m => m.member_email === member.email).length > 0){
          selectedLists.push(l.name)
        }
        return true
      })

      setMyLists(selectedLists)
    }
  }, [lists, member])
  
  const handleClickButtonPlayedTogether = (evt) => {
    setPlayedWithConfirmed(!playedWithConfirmed)
    handlePlayedTogetherClick(evt)
  }

  const handleOpenListsDialog = () => {
    handleSelectMemberLists(member)
  }

  useEffect(() => {
    setPlayedWithConfirmed(hasMemberPlayedWithUser(userDetail, member))
  }, [playedWithConfirmed, userDetail, member])

  const myMemberships = member.role === userRoles.member ? getUserMemberships(member.email, visibles) : [member.primary_club]
  return (
    <>
      <MemberBoxContainer classes={classes.memberItem} type={type}>
        <div onClick={playerClick} className={classes.memberContent}>
          <div style={{marginRight: 8}}>
            <UserAvatar profileImage={member.profile_image} />
          </div>
          <div style={{flexGrow: 1}}>
            <div className={classes.memberNameRow}>
            <Typography className={classes.memberName} variant="subtitle1">
              {`${member.first_name} ${member.last_name}`}
            </Typography>
            {member.role === 'guest' && member.referrer === userDetail.username && 
              <Typography variant="subtitle1" style={{fontWeight: '400', fontSize: 14, lineHeight: '32px', marginLeft: 16}}>(Guest)
                <Tooltip 
                  title={<span style={{fontSize: 14, fontFamily: 'Roboto', fontWeight: '400'}}>This is a Captain's Club guest that you invited.  No other members will see this guest listed on this page</span>}
                  placement="top-start">
                  <QuestionIcon style={{fontSize: 16}} />
                </Tooltip>
              </Typography>
            }
            </div>
            <Typography variant="subtitle2">
              {member.address && member.address.city ? member.address.city + ', ' + member.address.state : '' }
              {member.industry ? <span style={{color: '#777'}}><span style={{margin: '0 8px'}}>&bull;</span>{member.industry} Industry</span> : ''}
            </Typography>
            {myMemberships && myMemberships.map((item, idx) => {
              return (
                <Typography key={idx} variant="subtitle2" style={{display: 'flex', padding: '2px 0'}}>
                  {item?.club_logo && <div style={{marginRight: 8}}><UserAvatar profileImage={item?.club_logo} width={24} isClub={true} /></div>}
                  <div>{item?.name} {item?.address && item?.address.city ? '(' + item?.address.city + ', ' + item?.address.state + ')' : ''}</div>
                </Typography>
              )
            })}
            <Typography variant="subtitle2">
              {member.handicap !== null && <div className={classes.handicap}>
                <GolfIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />Handicap {member.handicap}
              </div>}
              {member.best_gross_round && <span><span style={{margin: '0 8px'}}>&bull;</span>Best Round: {member.best_gross_round}</span>}
            </Typography>

            { myLists && myLists.length > 0 &&
              <div >
                <div style={{ height: '2px', backgroundColor: "#cecece", marginTop: '10px', marginBottom: '10px' }} ></div>
                <Typography variant='subtitle2' >{member.first_name} {member.last_name} is added to these lists of yours:</Typography>
                { myLists.map(l => <Typography key={l} variant='caption' >  {l}<br /></Typography>) }
              </div>
            }
          </div>
        </div>
        <IconButton onClick={() => handleOpenListsDialog()}>
          <PlaylistAdd />
        </IconButton>
        {handlePlayedTogetherClick && handleIntroduce && <div className={classes.memberAction}>      
          <ToggleButton text="Played Together" value={member.email} checked={playedWithConfirmed || false} onClick={handleClickButtonPlayedTogether} />
          {playedWithConfirmed && <Button onClick={() => handleIntroduce(member)} variant="contained" style={{marginTop: 8, padding: 6}}>Introduce</Button>}
        </div>}
      </MemberBoxContainer>
  </>)
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    visibles: state.members.membersVisible,
    lists: state.lists.lists
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleSelectMemberLists
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(MemberBox);
