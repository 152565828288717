import { Grid, Typography } from '@material-ui/core';
// import cls from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MemberSelector from '../../components/memberSelector';
import PaperMasterHoc from '../../components/paperMasterHoc';
import SearchBar from '../../components/searchBar';
import { handleGroupSearch, handleRequestGroupAccess } from '../../reducers/groups';
import GroupListBox from './groupListBox';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
  },
  filterContainer: {
    display: 'flex', 
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', 
    },
  },
  filterItem: {
    flex: 3.3,
    margin: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0
    },
  }
}))

const GroupListContainer = ({userDetail,memberList, membersVisible, searchResults, handleGroupSearch, handleRequestGroupAccess}) => {
  const classes = useStyles();
  // const [ selectedClub, setSelectedClub ] = React.useState()
  const [ owner, setOwner ] = React.useState()
  const [ searchText, setSearchText ] = React.useState()
  // const [ location, setLocation ] = React.useState()
  React.useEffect(() => {
    return () => {
      handleGroupSearch()
    }
  }, [handleGroupSearch]);
  if (!userDetail || !userDetail.email) { return <div /> }

  const parseFiltersForSearch =  (list) => {
    if (list && Array.isArray(list) && list.length > 0) {
      return list.map(e => e.value).join(',')
    }
    if (list && list.value) { return list.value }
    if (!list || list.length === 0) { return '' }
    return list
  }
  const onGroupSearch = (value) => {
    setSearchText(value)
    handleGroupSearch({
      owner: parseFiltersForSearch(owner), 
      text: value, 
      // location: parseFiltersForSearch(location)
    })
  }
  // const handleLocationChange = name => list => {
  //   setLocation(list)
  //   handleGroupSearch({
  //     owner: parseFiltersForSearch(owner), 
  //     text: searchText, 
  //     location: parseFiltersForSearch(list)
  //   })
  // }
  const onMemberSearch = (value) => {
    setOwner(value)
    handleGroupSearch({
      owner: parseFiltersForSearch(value), 
      text: searchText, 
      // location: parseFiltersForSearch(location)
    })
  }
  return (
    <PaperMasterHoc transparent={true}>
      <div className={classes.root}>
        <Typography variant="h4" style={{marginBottom: 8}}>Public Groups</Typography>
        <Grid container spacing={2} style={{alignItems: 'center', justifyContent: 'center', marginBottom: 16}}>
          <Grid item xs={12} sm={6}>
            <SearchBar
              placeholderText="Type Group name here to search"
              onTextInput={onGroupSearch}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MemberSelector
                isSingle
                textFieldLabel={"Filter by Group owner"}
                groupMembers={[owner || '']}
                listChangeEvent={onMemberSearch}
                excludeEmails={[]}
            />
          </Grid>
        </Grid>
        {searchResults.length === 0 && <Typography style={{textAlign: 'center'}} variant="h5">No groups available</Typography>}
        {membersVisible && searchResults.map((group, idx) => {
          // const playedWithSent = userDetail.played_with_sent && userDetail.played_with_sent.includes(member.email)
          return <GroupListBox
                    key={idx} 
                    group={group} 
                    memberList={membersVisible}
                    userDetail={userDetail}
                    handleGroupRequest={handleRequestGroupAccess} />
        })}
      </div>
    </PaperMasterHoc>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    memberList: state.members.memberList,
    membersVisible: state.members.membersVisible,
    searchResults: state.groups.searchResults,
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGroupSearch,
    handleRequestGroupAccess
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(GroupListContainer);
