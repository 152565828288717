import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfiniteScroll from "react-infinite-scroll-component";

import { handleGetPosts, handleGetAllPosts, handleSelectPostType } from '../../reducers/posts';
import DashboardBanner from './dashboardBanner';
import DashboardFeedItem from './dashboardFeedItem';
import DashboardGuestBanner from './dashboardGuestBanner';
import GeneralPostForm from './generalPostForm';
import PlayGolfForm from './playGolfForm';
import PostDialog from './postDialog';
import TravelForm from './travelForm';
import WidgetLoading from '../../components/widgetLoading';
import { Box, CircularProgress } from '@material-ui/core';
import { isPostUpdating } from '../../utils/posts';
const buttonTypeList = [
  {id: "general", label: "General"},
  {id: "play", label: "Play Golf"},
  {id: 'travel', label: 'Travel'}
]

const DashboardContainer = ({
  userDetail,
  postTypeSelection,
  handleSelectPostType,
  posts,
  allPosts,
  nextAllPosts,
  memberList,
  handleGetPosts,
  handleGetAllPosts,
  groupId,
  updatedPost }) => {
  React.useEffect(() => {
    handleGetPosts(groupId)
  }, [handleGetPosts, groupId]);
  const handlePostTypeClick = (postType) => {
    handleSelectPostType(postType)
  }

  const[updatedPosts, setUpdatedPosts] = useState([])

  const fetchMoreData = () => {
    setTimeout(() => {
      handleGetAllPosts(groupId ? groupId : null, allPosts.length)
    }, 1500);
  };

  useEffect(() => {    
    if(isPostUpdating(updatedPost)){
      setUpdatedPosts([updatedPost, ...allPosts])
    }else{
      setUpdatedPosts(allPosts)
    }
  }, [updatedPost, allPosts])
  
  return (
    <div id="feed-form-container">
      <PostDialog />
      <DashboardBanner 
        buttonTypeList={groupId ? buttonTypeList.filter(x => x.id !== 'travel') : buttonTypeList} 
        selectedType={postTypeSelection || 'general'} 
        handleButtonClick={handlePostTypeClick} />
      {!groupId && userDetail && userDetail.role === 'guest' && <DashboardGuestBanner profile={userDetail} /> }
      {userDetail && userDetail.role === 'member' && <div>
        {(!postTypeSelection || postTypeSelection === 'general') && <GeneralPostForm />}
        {postTypeSelection === 'play' && <PlayGolfForm />}
        {postTypeSelection === 'travel' && <TravelForm />}
      </div>}
      {(!memberList || !updatedPosts)
        ? (
          <WidgetLoading />
        )
        : (
          <InfiniteScroll
            dataLength={updatedPosts.length}
            next={fetchMoreData}
            hasMore={true}
            loader={updatedPosts.length > 0 && nextAllPosts && <Box paddingTop={2} paddingBottom={1}>
              <CircularProgress size={24} color="primary" />
            </Box>}
            scrollableTarget="dashboard-container"
          >
            {updatedPosts.map((item, idx) => (
              <div key={idx}>
                <DashboardFeedItem post={{...item}} />
              </div>
            ))}
          </InfiniteScroll>
        )
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    posts: state.posts.posts,
    memberList: state.members.memberList,
    postTypeSelection: state.posts.postTypeSelection,
    allPosts: state.posts.allPosts,
    nextAllPosts: state.posts.nextAllPosts,
    updatedPost: state.posts.updatedPost,
    isLoadingPost: state.posts.isLoadingPost,
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetPosts,
    handleGetAllPosts,
    handleSelectPostType,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(DashboardContainer)
