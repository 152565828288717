import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React from 'react';

function SendInviteDialog(props) {
    const {open, toggleDialog, userDetail} = props
    const [ copySuccess, setCopySuccess ] = React.useState(false)
    const textAreaRef = React.useRef(null);
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const handleClose = () => {
        setCopySuccess(false)
        toggleDialog(null)
    }
    const copyText = (text) => {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        textAreaRef.current.appendChild(el);
        el.select();
        document.execCommand('copy');
        textAreaRef.current.removeChild(el);
        setCopySuccess(true)
    }
    const inviteTemplate = "I'd like to invite you to join me as a member of Captain's Club.   As a member of Captain's Club you will be invited to play in exclusive golf events hosted throughout the country each year and you will have the opportunity to utilize the Captain's Club Members website to customize and plan golf events, find new games to play in, manage private golf groups and find members across the country with similar interests as yourself.\n\nThere is currently no cost to join as a Charter Member.\n\nClick here to apply: "
    const referralLink = 'https://app.captains.club/' + userDetail.username
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
        >
            <DialogTitle><div>Send an invite</div>
                        <Typography component="div" variant="subtitle2">Invite a friend to join you on Captain's Club!</Typography>
                        <Typography  component="div" variant="subtitle2">Copy and paste the text and the personalized invite link below to send to them:</Typography><hr />
            </DialogTitle>
            <DialogContent ref={textAreaRef}>
                <Typography component="div" style={{fontSize: 14, fontWeight: 'normal', marginBottom: 24, whiteSpace: 'pre-wrap'}}>{inviteTemplate} <a href={referralLink}>{referralLink}</a></Typography>
            </DialogContent>
            <DialogActions>
                <Tooltip
                open={copySuccess}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied!"
                placement="top-end"
                >
                    <Button onClick={() => copyText(inviteTemplate + referralLink)} variant="contained">Copy All</Button>
                </Tooltip>
                <Button onClick={() => copyText(referralLink)} variant="contained">Copy Link</Button>
                <Button variant="contained" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SendInviteDialog
