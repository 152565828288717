import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
  }
})
const Placeholder = ({ heading, classes }) => {
  return (
    <div className={classes.root}>
      <Typography component='p' margin='normal' variant='h5'>
        {heading}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Placeholder)
