import React from 'react'
import HandicapListParent from './handicapListParent';
import { FormGroup } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleHandicapFilterChange } from '../reducers/members';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';


const HandicapListComponent = ({ memberList, handleHandicapFilterChange }) => {
    const [queryArray, setQueryArray] = React.useState([]);
    const [handicapListsCount, setHandicapListsCount] = React.useState([]);

    React.useEffect(() => {
        if(queryArray && queryArray.length > 0){
            handleHandicapFilterChange(queryArray.join(','))
        }else{
          handleHandicapFilterChange('')
        }
    }, [queryArray, handleHandicapFilterChange])

    React.useEffect(() => {
        if(memberList && memberList.length > 0){
          let greaterThanFiveToZero = 0
          let greaterThanFive = 0
          let greaterThanFour = 0
          let greaterThanThree = 0
          let greaterThanTwo = 0
          let greaterThanOne = 0
          let zero = 0
          let oneToFive = 0
          let one = 0
          let two = 0
          let three = 0
          let four = 0
          let five = 0
          let sixToTeen = 0
          let six = 0
          let seven = 0
          let eight = 0
          let nine = 0
          let ten = 0
          let elevenToFifhteen = 0
          let eleven = 0
          let twelve = 0
          let thirteen = 0
          let fourteen = 0
          let fifhteen = 0
          let sixteenToTwenty= 0
          let sixteen = 0
          let seventeen = 0
          let eightteen = 0
          let nineteen = 0
          let twenty = 0
          let greaterThenTwenty = 0
          let twentyOne = 0
          let twentyTwo = 0
          let twentyThree = 0
          let twentyFour = 0
          let twentyFive = 0
    
          memberList.forEach(member => {
            const { handicap } = member

            if (typeof handicap === 'number' && !isNaN(handicap)){
              if (handicap <= 0.5 && handicap >= -5.4) greaterThanFiveToZero++
              if (handicap < -4.5 && handicap >= -5.4) greaterThanFive++
              if (handicap < -3.5 && handicap >= -4.4) greaterThanFour++
              if (handicap < -2.5 && handicap >= -3.4) greaterThanThree++
              if (handicap < -1.5 && handicap >= -2.4) greaterThanTwo++
              if (handicap < -0.5 && handicap >= -1.4) greaterThanOne++            
              if (handicap >= -0.5 && handicap < 0.5) zero++
              
              if (handicap >= 0.5 && handicap < 5.5) oneToFive++
              if (handicap >= 0.5 && handicap < 1.5) one++
              if (handicap >= 1.5 && handicap < 2.5) two++
              if (handicap >= 2.5 && handicap < 3.5) three++
              if (handicap >= 3.5 && handicap < 4.5) four++
              if (handicap >= 4.5 && handicap < 5.5) five++

              if(handicap >= 5.5 && handicap < 10.5) sixToTeen++
              if (handicap >= 5.5 && handicap < 6.5) six++
              if (handicap >= 6.5 && handicap < 7.5) seven++
              if (handicap >= 7.5 && handicap < 8.5) eight++
              if (handicap >= 8.5 && handicap < 9.5) nine++
              if (handicap >= 9.5 && handicap < 10.5) ten++
              
              if (handicap >= 10.5 && handicap < 15.5) elevenToFifhteen++
              if (handicap >= 10.5 && handicap < 11.5) eleven++
              if (handicap >= 11.5 && handicap < 12.5) twelve++
              if (handicap >= 12.5 && handicap < 13.5) thirteen++
              if (handicap >= 13.5 && handicap < 14.5) fourteen++
              if (handicap >= 14.5 && handicap < 15.5) fifhteen++
              
              if (handicap >= 15.5 && handicap < 20.5) sixteenToTwenty++
              if (handicap >= 15.5 && handicap < 16.5) sixteen++
              if (handicap >= 16.5 && handicap < 17.5) seventeen++
              if (handicap >= 17.5 && handicap < 18.5) eightteen++
              if (handicap >= 18.5 && handicap < 19.5) nineteen++
              if (handicap >= 19.5 && handicap < 20.5) twenty++
              
              if (handicap >= 20.5 && handicap < 25.5) greaterThenTwenty++
              if (handicap >= 20.5 && handicap < 21.5) twentyOne++
              if (handicap >= 21.5 && handicap < 22.5) twentyTwo++
              if (handicap >= 22.5 && handicap < 23.5) twentyThree++
              if (handicap >= 23.5 && handicap < 24.5) twentyFour++
              if (handicap >= 24.5 && handicap < 25.5) twentyFive++
            }
          })
    
          const handicapLists = [
            // {label: '+3', value: greaterThanThree.toString()},
            // {label: '+2', value: greaterThanTwo.toString()},
            // {label: '+1', value: greaterThanOne.toString()},
            // {label: '0', value: zero.toString()},
            {
              label: '+5-0',
              value: greaterThanFiveToZero.toString(),
              children: [
                {label: '+5', value: greaterThanFive.toString()},
                {label: '+4', value: greaterThanFour.toString()},
                {label: '+3', value: greaterThanThree.toString()},
                {label: '+2', value: greaterThanTwo.toString()},
                {label: '+1', value: greaterThanOne.toString()},
                {label: '0', value: zero.toString()},
              ]
            },
            {
              label: '1-5',
              value: oneToFive.toString(),
              children: [
                {label: '1', value: one.toString()},
                {label: '2', value: two.toString()},
                {label: '3', value: three.toString()},
                {label: '4', value: four.toString()},
                {label: '5', value: five.toString()},
              ]
            },
            {
              label: '6-10',
              value: sixToTeen.toString(),
              children: [
                {label: '6', value: six.toString()},
                {label: '7', value: seven.toString()},
                {label: '8', value: eight.toString()},
                {label: '9', value: nine.toString()},
                {label: '10', value: ten.toString()},
              ]
            },   
            {
              label: '11-15',
              value: elevenToFifhteen.toString(),
              children: [
                {label: '11', value: eleven.toString()},
                {label: '12', value: twelve.toString()},
                {label: '13', value: thirteen.toString()},
                {label: '14', value: fourteen.toString()},
                {label: '15', value: fifhteen.toString()},
              ]
            },
            {
              label: '16-20',
              value: sixteenToTwenty.toString(),
              children: [
                {label: '16', value: sixteen.toString()},
                {label: '17', value: seventeen.toString()},
                {label: '18', value: eightteen.toString()},
                {label: '19', value: nineteen.toString()},
                {label: '20', value: twenty.toString()},
              ]
            },
            {
              label: '20+',
              value: greaterThenTwenty.toString(),
              children: [
                {label: '21', value: twentyOne.toString()},
                {label: '22', value: twentyTwo.toString()},
                {label: '23', value: twentyThree.toString()},
                {label: '24', value: twentyFour.toString()},
                {label: '25', value: twentyFive.toString()},
              ]
            }
          ]
          setHandicapListsCount(handicapLists)
        }
      }, [memberList])

    return <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Filter by Handicap
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            { handicapListsCount.map((handicap, index) => <HandicapListParent key={index} {...handicap} queryArray={queryArray} setQueryArray={setQueryArray} />) }
          </FormGroup>
        </AccordionDetails>
      </Accordion>
  }


  const mapStateToProps = (state) => {
    return {
      memberList: state.members.memberList
    }
  }
  
  const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handleHandicapFilterChange
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatch)(HandicapListComponent)