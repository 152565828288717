import { Grid, Typography, MenuItem, TextField } from '@material-ui/core';
// import cls from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventListItem from './eventListItem';
import PaperMasterHoc from '../../components/paperMasterHoc';
import { parser } from '../../utils/parser';
import AutoSelect from '../../components/auto_select';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 'auto',
  },
  filterContainer: {
    display: 'flex',
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  filterItem: {
    flex: 3.3,
    margin: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const EventListContainer = ({ eventList }) => {
  const classes = useStyles();
  const [filterState, setFilterState] = React.useState('');
  const [filterCategory, setFilterCategory] = React.useState('');
  const [filterMonth, setFilterMonth] = React.useState('');
  const setSelectedAddressState = (value) => {
    if (!value || value.label) {
      return value || '';
    }
    const stateList = parser.getStateList();
    const idx = stateList
      .map(function (e) {
        return e.value;
      })
      .indexOf(value);
    if (idx >= 0) {
      return stateList[idx] || '';
    }
    return '';
  };
  const handleStateChange = (name) => (list) => {
    setFilterState(list);
  };
  const categoryList = React.useMemo(
    () => [
      { value: 'captains_club', label: 'Captains Club' },
      { value: 'amateur', label: 'Amateur' },
      { value: 'professional', label: 'Professional' },
      { value: 'pro_am', label: 'Pro/Am' },
    ],
    []
  );
  const filteredEvents = React.useMemo(() => {
    if (!eventList) return [];
    let events = [...eventList];
    if (filterState) {
      events = events.filter((x) => x.state === filterState.value);
    }
    if (filterCategory) {
      events = events.filter((x) => x.category === filterCategory);
    }
    if (filterMonth || filterMonth === 0) {
      events = events.filter(
        (x) => new Date(x.start_date).getMonth() === filterMonth
      );
    }
    return events;
  }, [eventList, filterState, filterCategory, filterMonth]);

  return (
    <PaperMasterHoc transparent={true}>
      <div className={classes.root}>
        <Typography variant='h4' style={{ marginBottom: 8 }}>
          Events
        </Typography>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid style={{ textAlign: 'center' }} item xs={12} md={4}>
            <TextField
              select
              id='category'
              label='Filter By Category'
              fullWidth
              margin='none'
              variant='outlined'
              style={{ textAlign: 'left' }}
              value={filterCategory}
              onChange={(event) => setFilterCategory(event.target.value)}
            >
              <MenuItem value={''}>View All</MenuItem>
              {categoryList &&
                categoryList.map((item, idx) => {
                  return (
                    <MenuItem key={idx} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
          <Grid style={{ textAlign: 'center' }} item xs={12} md={4}>
            <AutoSelect
              name='state'
              options={parser.getStateList()}
              textFieldProps={{
                label: 'Filter By State',
                margin: 'none',
              }}
              selectedValue={setSelectedAddressState(filterState) || ''}
              handleAutoSelectChange={handleStateChange}
            />
          </Grid>
          <Grid style={{ textAlign: 'center' }} item xs={12} md={4}>
            <TextField
              select
              id='month'
              label='Filter By Month'
              fullWidth
              margin='none'
              variant='outlined'
              value={filterMonth}
              style={{ textAlign: 'left' }}
              onChange={(event) => setFilterMonth(event.target.value)}
            >
              <MenuItem value={''}>View All</MenuItem>
              {[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ].map((item, idx) => {
                return (
                  <MenuItem key={idx} value={idx}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
        {filteredEvents.length === 0 && (
          <Typography style={{ textAlign: 'center' }} variant='h5'>
            No events available
          </Typography>
        )}
        <Grid container spacing={2}>
          {filteredEvents &&
            filteredEvents.map((item, idx) => {
              return (
                <Grid key={idx} item xs={12}>
                  <EventListItem eventItem={item} categoryList={categoryList} />
                </Grid>
              );
            })}
        </Grid>
      </div>
    </PaperMasterHoc>
  );
};

const mapStateToProps = (state) => {
  return {
    // userDetail: state.user.userDetail,
    eventList: state.events.eventList || [],
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatch)(EventListContainer);
