import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DatalistsView from './datalistsView'
import { handleGetReferences } from '../../reducers/references'

class DatalistsContainer extends React.Component {
  componentDidMount = () => {
    this.props.handleGetReferences()
  } 
  render() {
    return <DatalistsView {...this.props} />
  }
}

const mapStateToProps = (state) => {
    return { 
      references: {...state.references.raw_data}
    }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({ handleGetReferences }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(DatalistsContainer)
