export const types = {
  GET_GROUPS_REQUEST: 'GROUPS/GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'GROUPS/GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE: 'GROUPS/GET_GROUPS_FAILURE',
  GET_DETAIL_REQUEST: 'GROUPS/GET_DETAIL_REQUEST',
  GET_DETAIL_SUCCESS: 'GROUPS/GET_DETAIL_SUCCESS',
  GET_DETAIL_FAILURE: 'GROUPS/GET_DETAIL_FAILURE',
  SUBMIT_GROUPS_REQUEST: 'GROUPS/SUBMIT_GROUPS_REQUEST',
  SUBMIT_GROUPS_SUCCESS: 'GROUPS/SUBMIT_GROUPS_SUCCESS',
  SUBMIT_GROUPS_FAILURE: 'GROUPS/SUBMIT_GROUPS_FAILURE',
  UPDATE_GROUPS_REQUEST: 'GROUPS/UPDATE_GROUPS_REQUEST',
  UPDATE_GROUPS_SUCCESS: 'GROUPS/UPDATE_GROUPS_SUCCESS',
  UPDATE_GROUPS_FAILURE: 'GROUPS/UPDATE_GROUPS_FAILURE',
  DELETE_GROUPS_REQUEST: 'GROUPS/DELETE_GROUPS_REQUEST',
  DELETE_GROUPS_SUCCESS: 'GROUPS/DELETE_GROUPS_SUCCESS',
  DELETE_GROUPS_FAILURE: 'GROUPS/DELETE_GROUPS_FAILURE',
  VERIFY_GROUPS_REQUEST: 'GROUPS/VERIFY_GROUPS_REQUEST',
  VERIFY_GROUPS_SUCCESS: 'GROUPS/VERIFY_GROUPS_SUCCESS',
  VERIFY_GROUPS_FAILURE: 'GROUPS/VERIFY_GROUPS_FAILURE',
  PUBLIC_GROUPS_REQUEST: 'GROUPS/PUBLIC_GROUPS_REQUEST',
  PUBLIC_GROUPS_SUCCESS: 'GROUPS/PUBLIC_GROUPS_SUCCESS',
  PUBLIC_GROUPS_FAILURE: 'GROUPS/PUBLIC_GROUPS_FAILURE',
  GROUP_SEARCH_REQUEST: 'GROUPS/SEARCH_REQUEST',
  GROUP_SEARCH_SUCCESS: 'GROUPS/SEARCH_SUCCESS',
  GROUP_SEARCH_FAILURE: 'GROUPS/SEARCH_FAILURE',
  GROUP_ARCHIVED_SEARCH_REQUEST: 'GROUPS_ARCHIVED/SEARCH_REQUEST',
  GROUP_ARCHIVED_SEARCH_SUCCESS: 'GROUPS_ARCHIVED/SEARCH_SUCCESS',
  GROUP_ARCHIVED_SEARCH_FAILURE: 'GROUPS_ARCHIVED/SEARCH_FAILURE',
  CLEAR_ERROR: 'GROUPS/CLEAR_ERROR',
}

export const initialState = {
  groups: [],
  groupDetail: null,
  searchResults: [],
  searchArchivedResults: [],
  error: null,
  isLoading: false,
  submitError: null,
  submitSuccess: false
}

export const handleClearError = () => dispatch => {
  dispatch({type: types.CLEAR_ERROR})
}
export const handleGetGroups = () => dispatch => {
  dispatch({
    types: [types.GET_GROUPS_REQUEST, types.GET_GROUPS_SUCCESS, types.GET_GROUPS_FAILURE],
    url: '/groups'
  })
}
export const handleGetGroupDetail = (slug) => dispatch => {
  if (!slug) { 
    return dispatch({ type: types.GET_DETAIL_SUCCESS, data: null })
  }
  dispatch({
    types: [types.GET_DETAIL_REQUEST, types.GET_DETAIL_SUCCESS, types.GET_DETAIL_FAILURE],
    url: `/groups/${slug}`,
    errorAction: handleGetGroups()
  })
}
export const handleGroupSearch = (search, skip) => (dispatch) => {
  let params = { public: true }
  if (search) {
    if (typeof search === 'string') {
      params.search = search
    } else {
      params.search = search.text || ''
      params.owner = search.owner || ''
      params.location = search.location || ''
    }
  }
  if (skip) { params.skip = skip }
  dispatch({
    types: [types.GROUP_SEARCH_REQUEST, types.GROUP_SEARCH_SUCCESS, types.GROUP_SEARCH_FAILURE],
    url: '/groups',
    query: params
  })
}


export const handleArchivedGroupSearch = (search, skip) => (dispatch) => {
  let params = { }
  if (search) {
    if (typeof search === 'string') {
      params.search = search
    } else {
      params.search = search.text || ''
      params.owner = search.owner || ''
      params.location = search.location || ''
    }
  }
  if (skip) { params.skip = skip }
  dispatch({
    types: [types.GROUP_ARCHIVED_SEARCH_REQUEST, types.GROUP_ARCHIVED_SEARCH_SUCCESS, types.GROUP_ARCHIVED_SEARCH_FAILURE],
    url: '/groups/archived/all',
    query: params
  })
}

export const _groupRefresh = (group) => dispatch => {
  dispatch(handleGetGroups())
  dispatch(handleGroupSearch())
  dispatch(handleGetGroupDetail(group.url))
}
export const handleSubmitGroup = (name, description, isPublic, archived, guestList, groupMembers, coverImage) => dispatch => {
  if (!name) { return }
  let formData = new FormData()
  const group = {name, description: description || '', is_public: isPublic, archived: archived, guest_list: guestList || [], group_members: groupMembers || []}  
  formData.set('json', JSON.stringify(group))
  if (coverImage) { formData.append('cover_image', coverImage) }
  dispatch({
    types: [types.SUBMIT_GROUPS_REQUEST, types.SUBMIT_GROUPS_SUCCESS, types.SUBMIT_GROUPS_FAILURE],
    url: `/groups`,
    method: 'post',
    contentType: 'multipart/form-data',
    data: formData,
    timeout: 6000,
    callback: handleGetGroups()
  })
}

export const handleUpdateGroup = (group) => dispatch => {
  if (!group || !group.url || !group.name) { return }
  let formData = new FormData()
  formData.set('json', JSON.stringify(group))
  if (group.cover_image && typeof group.cover_image !== 'string') { formData.append('cover_image', group.cover_image) }
  dispatch({
    types: [types.UPDATE_GROUPS_REQUEST, types.UPDATE_GROUPS_SUCCESS, types.UPDATE_GROUPS_FAILURE],
    url: `/groups/${group.url}`,
    method: 'put',
    contentType: 'multipart/form-data',
    data: formData,
    timeout: 6000,
    callback: _groupRefresh(group)
  })
}
export const handleUpdateGroupList = (list) => dispatch => {
  dispatch({
    types: [null, types.SUBMIT_GROUPS_SUCCESS, types.SUBMIT_GROUPS_FAILURE],
    url: `/groups`,
    method: 'patch',
    data: { sort_order_list: list.map((item, idx) => { return {url: item.url, sort_order: idx} } ) },
    callback: handleGetGroups()
  })
}
export const handleDeleteGroup = (slug) => dispatch => {
  dispatch({
    types: [types.DELETE_GROUPS_REQUEST, types.DELETE_GROUPS_SUCCESS, types.DELETE_GROUPS_FAILURE],
    url: `/groups/${slug}`,
    method: 'delete',
    callback: handleGetGroups()
  })
}
export const handleRequestGroupAccess = (group) => dispatch => {
  dispatch({
    types: [types.PUBLIC_GROUPS_REQUEST, types.PUBLIC_GROUPS_SUCCESS, types.PUBLIC_GROUPS_FAILURE],
    url: `/groups/${group.url}`,
    method: 'patch',
    data: { request_access: true },
    callback: handleGroupSearch()
  })
}
export const handleGroupAccessResponse = (group, member, approve) => dispatch => {
  dispatch({
    types: [types.PUBLIC_GROUPS_REQUEST, types.PUBLIC_GROUPS_SUCCESS, types.PUBLIC_GROUPS_FAILURE],
    url: `/groups/${group.url}`,
    method: 'patch',
    data: { 
      access_response: true,
      member_email: member.email,
      approve: approve
    },
    callback: _groupRefresh(group)
  })
}
export const handleVerifyMembership = (group, accept) => dispatch => {
  dispatch({
    types: [types.VERIFY_GROUPS_REQUEST, types.VERIFY_GROUPS_SUCCESS, types.VERIFY_GROUPS_FAILURE],
    url: `/groups/${group.url}`,
    method: 'patch',
    data: { accept_group: accept, leave_group: !accept },
    callback: _groupRefresh(group)
  })
}
export default function (state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_GROUPS_SUCCESS:
    case types.SUBMIT_GROUPS_SUCCESS:
      return { ...state, submitSuccess: true, submitError: null }
    case types.GROUP_SEARCH_SUCCESS:
      return { ...state, isLoading: false, searchResults: action.data }
    case types.GROUP_ARCHIVED_SEARCH_SUCCESS:
      return { ...state, isLoading: false, searchArchivedResults: action.data }
    case types.GET_GROUPS_SUCCESS:
      return { ...state, isLoading: false, groups: action.data }
    case types.GET_DETAIL_SUCCESS:
        return { ...state, isLoading: false, groupDetail: action.data }
    case types.GET_GROUPS_FAILURE:
    case types.GET_DETAIL_FAILURE:
    case types.GROUP_SEARCH_FAILURE:
    case types.UPDATE_GROUPS_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    case types.SUBMIT_GROUPS_FAILURE:
      return { ...state, submitSuccess: false, submitError: action.error }
    case types.GET_GROUPS_REQUEST:
    case types.GET_DETAIL_REQUEST:
      return { ...state, isLoading: true, error: null}
    case types.UPDATE_GROUPS_REQUEST:
    case types.SUBMIT_GROUPS_REQUEST:  
    case types.GROUP_SEARCH_REQUEST:
      return { ...state, submitSuccess: false, submitError: null }
    case types.CLEAR_ERROR:
      return { ...state, groupDetail: null, isLoading: false, submitError: null, submitSuccess: false, error: null}
    default:
      return state
  }
}
