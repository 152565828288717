import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import PhotoEditor from '../components/photoEditor';

function PhotoEditorDialog ({open, toggleDialog, title, imagePath, width, height, handleSave}) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const handlePhotoSelected = (file) => {
        if (!file) { return }
        handleSave(file)
        toggleDialog(false)
    }
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(false)}
            fullScreen={fullScreen}
            // fullWidth
            // maxWidth="lg"
        >
            <DialogTitle id="edit-profile-picture">{title || 'Edit Cover Photo'}</DialogTitle>
            <DialogContent>
                <PhotoEditor
                    height={height}
                    width={width}
                    profileImage={imagePath || ''}
                    handlePhotoSelected={handlePhotoSelected} />
            </DialogContent>
        </Dialog>
    );
}

export default PhotoEditorDialog;
  