import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  handleTermsAgreed,
  handleGetUserDetail,
  handleOnboardingChange,
  handleOnboardingSubmit,
  handleOnboardingSelectStage,
  handleImageSubmit } from '../../reducers/user'
import { handleCourseSearch, handleClubSearch } from '../../reducers/clubs'
import { handleSchoolSearch } from '../../reducers/references'
import OnboardingView from './onboardingView'
import { handleCourseTopUS } from '../../reducers/clubs';


class OnboardingContainer extends React.Component {
  componentDidMount = () => {
    this.props.handleOnboardingSelectStage(0)
  }
  render () {
      return <OnboardingView {...this.props} />
  }
}

const mapStateToProps = (state) => {
    return {
      session: state.auth.session,
      userDetail: state.user.userDetail,
      memberProfile: state.members.memberProfile,
      onboardingStage: state.user.onboardingStage,
      clubs: {...state.clubs},
      references: state.references,
      courseList: state.clubs.coursesTopUS
    }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handleGetUserDetail,
      handleClubSearch,
      handleCourseSearch,
      handleSchoolSearch,
      handleOnboardingChange,
      handleOnboardingSubmit,
      handleOnboardingSelectStage,
      handleImageSubmit,
      handleTermsAgreed,
      handleCourseTopUS
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(OnboardingContainer)
