import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { parser } from '../../utils/parser';
import EventFormDialog from './eventFormDialog';
import EventTable from './eventTable';
import FilterBar from './filterBar';
import EventFeaturedSort from './eventFeaturedSort';
// import Example from './exampleTable';

const EventManagement = ({ eventList, isLoading }) => {
  const [activeSegmentId, setActiveSegmentId] = React.useState('upcoming');
  const [showEventForm, setShowEventForm] = React.useState(false);
  const [openFeaturedSort, setOpenFeaturedSort] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState();
  const handleFilterClick = (filterId) => {
    setActiveSegmentId(filterId);
  };
  const handleSelectedEvent = (event) => {
    setSelectedEvent(event);
    setShowEventForm(true);
  };
  const categoryList = React.useMemo(
    () => [
      { value: 'captains_club', label: 'Captains Club' },
      { value: 'amateur', label: 'Amateur Event' },
      { value: 'professional', label: 'Professional Event' },
      { value: 'pro_am', label: 'Pro/Am Event' },
    ],
    []
  );
  const segments = React.useMemo(() => {
    if (!eventList) {
      return [];
    }
    return [
      { label: `All Events `, id: 'all', data: eventList },
      {
        label: `Upcoming Events`,
        id: 'upcoming',
        data: eventList.filter((x) =>
          x.end_date
            ? parser.isSameOrAfterToday(x.end_date)
            : parser.isSameOrAfterToday(x.start_date)
        ),
      },
      {
        label: `Past Events`,
        id: 'past',
        data: eventList.filter((x) =>
          x.end_date
            ? !parser.isSameOrAfterToday(x.end_date)
            : !parser.isSameOrAfterToday(x.start_date)
        ),
      },
    ];
  }, [eventList]);
  return (
    <div>
      <EventFormDialog
        open={showEventForm}
        toggleDialog={() => setShowEventForm(false)}
        categoryList={categoryList}
        event={selectedEvent}
      />
      <EventFeaturedSort
        open={openFeaturedSort}
        toggleDialog={() => setOpenFeaturedSort(false)}
        items={
          eventList
            ? eventList
                .filter((x) => x.featured)
                .filter((x) =>
                  x.end_date
                    ? parser.isSameOrAfterToday(x.end_date)
                    : parser.isSameOrAfterToday(x.start_date)
                )
            : []
        }
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5'>Manage Events</Typography>
        <div style={{ display: 'flex' }}>
          <Button
            style={{ marginRight: 8 }}
            onClick={() => setOpenFeaturedSort(true)}
            variant='contained'
          >
            Sort Featured Events
          </Button>
          <Button
            onClick={() => {
              setSelectedEvent(null);
              setShowEventForm(true);
            }}
            variant='contained'
          >
            + Create Event
          </Button>
        </div>
      </div>
      <FilterBar
        segments={segments}
        activeId={activeSegmentId}
        handleFilterClick={handleFilterClick}
      />
      <EventTable
        title={segments.find((x) => x.id === activeSegmentId).label}
        events={segments.find((x) => x.id === activeSegmentId).data}
        isLoading={isLoading}
        categoryList={categoryList}
        onSelectedEvent={handleSelectedEvent}
      />
      {/* <Example /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    eventList: state.events.eventList,
    isLoading: state.events.isLoading,
  };
};

export default connect(mapStateToProps)(EventManagement);
