import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MemberSelector from '../../components/memberSelector';
import { handleMakeIntroduction } from '../../reducers/notifications';
import { paths } from '../../utils/paths';

const useStyles = makeStyles(theme => ({
    textField: {
        minWidth: 300
        // margin: 0,
        // marginBottom: theme.spacing(2)
    },
    dialogPaper: {
        overflowY: 'unset',
        minWidth: 700,
        [theme.breakpoints.down('sm')]: {
            minWidth: 200,
            width: '100%'
        },
    },
    multiLineTextBox: {
        margin: '16px 0',
        '& textarea': {
          fontFamily: theme.typography.secondaryFont
        },
        '& .MuiOutlinedInput-multiline': {
          padding: '10px 14px'
        }
    },
}));

function IntroductionDialog ({userDetail, memberList, open, toggleDialog, history, member, handleMakeIntroduction}) {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const [ introducee, setIntroducee ] = React.useState(null)
    const handleIntroduce = () => {
        if (!introducee || !introducee.value || !member || !member.email) { return }
        handleMakeIntroduction(
            {
                value: member.email, 
                label: `${member.first_name} ${member.last_name} (@${member.username})`, 
                logo: member.profile_image
            },
            introducee
        )
        history.push(paths.messaging)
    }
    const cancelClick = () => {
        toggleDialog(false)
        setIntroducee(null)
    }
    const handleListChangeEvent = (list) => {
        setIntroducee(list || [])
    }
    if (!member) { return <div /> }
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(false)}
            fullScreen={fullScreen}
            scroll={'body'}
            classes={!fullScreen ? {paper: classes.dialogPaper} : {}}
        >
            <DialogTitle id="alert-dialog-title">Make an Introduction</DialogTitle>
            <DialogContent style={{padding: '0 24px 8px', overflowY: 'unset'}}>
                <div style={{border: 'solid 1px #f0f0f0', borderRadius: 4, backgroundColor: theme.palette.primary.main, padding: 16, margin: '16px 0'}}>
                    <Typography variant="subtitle1" style={{color: '#FFF'}}>Select the member to introduce to {member.first_name} {member.last_name}.<br />Then you can customize the message</Typography>
                </div>
                <MemberSelector
                    isSingle
                    groupMembers={introducee}
                    listChangeEvent={handleListChangeEvent}
                    excludeEmails={memberList.filter(x => x.email && (x.email === userDetail.email || x.email === member.email || !x.played_with)).map(x => x.email)}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={!introducee} onClick={handleIntroduce} variant="contained">Continue</Button>
                <Button onClick={cancelClick} variant="outlined">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
      userDetail: state.user.userDetail,
      memberList: (state.members.memberList || []).filter(x => x.role === 'member'),
    }
  }
  const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleMakeIntroduction,
    }, dispatch)
  }
  
export default withRouter(connect(mapStateToProps, mapDispatch)(IntroductionDialog))
