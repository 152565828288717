
export const types = {
  LINK_ACCESS_GET_REQUEST: 'LINK_ACCESS/GET_REQUEST', 
  LINK_ACCESS_GET_SUCCESS: 'LINK_ACCESS/GET_SUCCESS', 
  LINK_ACCESS_GET_FAILURE: 'LINK_ACCESS/GET_FAILURE', 
  LINK_ACCESS_UPDATE_REQUEST: 'LINK_ACCESS/UPDATE_REQUEST',
  LINK_ACCESS_UPDATE_SUCCESS: 'LINK_ACCESS/UPDATE_SUCCESS',
  LINK_ACCESS_UPDATE_FAILURE: 'LINK_ACCESS/UPDATE_FAILURE',
}

export const initialState = {
  linkDetail: {},
  error: null,
  isLoading: false
}

export const handleGetLinkAccess = (userToken, accessToken) => (dispatch) => {
  dispatch({
    types: [types.LINK_ACCESS_GET_REQUEST, types.LINK_ACCESS_GET_SUCCESS, types.LINK_ACCESS_GET_FAILURE],
    url: `/lnk/${userToken}/${accessToken}`
  })
}
export const handleUpdatePlayGolfAttendance = (postId, attending, userToken, accessToken) => dispatch => {
  const status = attending ? 'mark_attending' : 'mark_not_attending'
  dispatch({
    types: [types.LINK_ACCESS_UPDATE_REQUEST, types.LINK_ACCESS_UPDATE_SUCCESS, types.LINK_ACCESS_UPDATE_FAILURE],
    url: `lnk/${postId}/${userToken}/${accessToken}`,
    method: 'patch',
    data: { [status]: true },
    callback: handleGetLinkAccess(userToken, accessToken)
  })
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LINK_ACCESS_GET_SUCCESS:
      return { ...state, isLoading: false, error: null, linkDetail: action.data }
    case types.LINK_ACCESS_GET_FAILURE:
      return { ...state, isLoading: false, error: action.error, linkDetail: {} }
    case types.LINK_ACCESS_GET_REQUEST:
      return { ...state, isLoading: true }
    default:
      return state
  }
}
