import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import AdminSidebar from '../components/adminSidebar'
import AdminHeader from '../components/header_auth.js'
import { AdminPosts, Clubs, Datalists, EventManagement, GuestInvites, Placeholder, UserManagement, MembersPosts, Courses } from '../pages'
import { handleClubSearch, handleCourseSearch } from '../reducers/clubs'
import { handleGetEvents } from '../reducers/events'
import { handleGetGuestInvitesForAdmin } from '../reducers/guests'
import { handleGetUsers } from '../reducers/members'
import { handleGetReferences, handleSchoolSearch } from '../reducers/references'
import { paths } from '../utils/paths'


const styles = theme => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto'
    },
  },
  content: {
    padding: theme.spacing(3),
    flexGrow: 1,
    minHeight: `calc(100vh - 80px)`
  },
})

const AdminLayout = ({ classes, 
    handleClubSearch, 
    handleCourseSearch,
    handleSchoolSearch,
    handleGetReferences,
    handleGetUsers, 
    handleGetEvents, 
    handleGetGuestInvitesForAdmin}) => {
  
  React.useEffect(() => {
    handleClubSearch(null, 10, 0, true)
    handleCourseSearch()
    handleSchoolSearch()
    handleGetReferences()
    handleGetUsers()
    handleGetEvents()
    handleGetGuestInvitesForAdmin()
  }, [
    handleClubSearch,
    handleCourseSearch,
    handleSchoolSearch,
    handleGetReferences,
    handleGetUsers,
    handleGetEvents,
    handleGetGuestInvitesForAdmin
  ])
  return (
    <React.Fragment>
      <AdminHeader />
      <div className={classes.root}>
        <AdminSidebar />
        <div className={classes.content}>
          <Switch>
              <Route path={paths.manageUsers} component={UserManagement} />
              <Route path={paths.events} component={EventManagement} />
              <Route path={paths.guestInvites} component={GuestInvites} />
              <Route path={paths.dashboard} render={(props) => <Placeholder {...props} heading={'Dashboard'} />} />
              <Route path='/datalists' component={Datalists} />
              <Route path={paths.adminPosts} component={AdminPosts} />
              <Route path={paths.membersPosts} component={MembersPosts} />
              <Route path='/clubs' component={Clubs} />
              <Route path='/courses' component={Courses} />
              <Route path='/team' render={(props) => <Placeholder {...props} heading={'My Team'} />} />
              <Route path={paths.profile} render={(props) => <Placeholder {...props} heading={'My Profile'} />} />
              <Route path={paths.account} render={(props) => <Placeholder {...props} heading={'My Account'} />} />
              <Redirect to={paths.manageUsers} />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
      handleClubSearch,
      handleCourseSearch,
      handleSchoolSearch,
      handleGetReferences,
      handleGetUsers,
      handleGetEvents,
      handleGetGuestInvitesForAdmin
  }, dispatch)
}

export default connect(null, mapDispatch)(withStyles(styles)(AdminLayout))
