
import { Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutoSelect from '../../components/auto_select';
import { handleApply } from '../../reducers/auth';
import { handleClubSearch } from '../../reducers/clubs';
import { parser } from '../../utils/parser';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '100%'
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  loginLoader: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
});

function ApplyForm({ handleApply, handleClubSearch, classes, auth, userDetail, clubList }) {
  const [values, setValues] = React.useState({
    hasError: false,
    errorMessage: null,
    application: null,
    primary_club: ""
  })
  React.useEffect(() => {
    if (auth && auth.applied && values.application) {
      setValues(oldValues => ({ ...oldValues, application: null}))
    }
  }, [auth, values.application]);
  const handleSubmit = (evt) => {
    evt.preventDefault()
    if (!values.primary_club || !values.primary_club.value) {
      return setValues(oldValues => ({ ...oldValues, hasError: true, errorMessage: 'Primary Club is required' }))
    }
    const email = evt.target.email.value.trim()
    if (!email || !parser.isValidEmail(email)) {
      return setValues(oldValues => ({ ...oldValues, errorMessage: 'Email Address is not valid' }))
    }
    const phone = evt.target.cell_number.value
    if (!phone || !parser.isValidPhone(phone)) {
      return setValues(oldValues => ({ ...oldValues, errorMessage: 'Phone Number is not valid' }))
    }
    setValues({ hasError: false, errorMessage: null, primary_club: ""})
    const application = {
      email: email,
      first_name: evt.target.first_name.value,
      last_name: evt.target.last_name.value,
      cell_number: evt.target.cell_number.value,
      primary_club: values.primary_club.value
    }
    if (auth.referrer && auth.referrer.username) {
      application.referrer = auth.referrer.username
    }
    handleApply(application)
    application.club = values.primary_club
    setValues(oldValues => ({ ...oldValues, application: application}))
  }
  const handleAutoSelectTextChange = name => value => {
    setValues((oldValues) => ({ ...oldValues, errorMessage: null, hasError: false }))
    if (value && value.length > 2) {
      handleClubSearch(value)
    }
  }
  const handlePrimaryClubChange = name => value => {
    setValues(() => ({ hasError: false, errorMessage: null, primary_club: value }))
    handleClubSearch()
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {auth.referrer ? "Begin your Application Here:" : "Apply To Join Captain's Club"}              
      </Typography>
      <Typography variant="body2" gutterBottom>
        No contact information will be used for any purpose other than to contact you concerning your application. You’ll be able to add further club memberships during the onboarding process.
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                id="first_name"
                label="First Name"
                className={classes.textField}
                required
                margin="normal"
                variant="outlined" />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="last_name"
                label="Last Name"
                className={classes.textField}
                required
                margin="normal"
                variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="email"
                label="Email Address"
                className={classes.textField}
                required
                margin="normal"
                variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="cell_number"
                label="Cell Number"
                className={classes.textField}
                required
                margin="normal"
                variant="outlined" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoSelect
                textFieldProps={{
                  label: 'Primary Club',
                  InputLabelProps: {
                    shrink: true,
                  },
                  haserror: values.hasError.toString()
                }}
                isMulti={false}
                placeholder="Type Club name here to search"
                options={parser.formatClubs(clubList)}
                selectedValue={values.primary_club}
                handleAutoSelectTextChange={handleAutoSelectTextChange}
                handleAutoSelectChange={handlePrimaryClubChange} />
            </Grid>
          </Grid>
          <Typography color="error" component="p" variant="subtitle1">
              {auth.error || values.errorMessage}
          </Typography>
          {auth.isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
            ) : (<Button type="submit" disabled={auth.isLoading} variant="contained" className={classes.submit}>
              Next
          </Button>)
          }
      </form>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: {...state.auth},
    clubList: state.clubs.clubList,
    userDetail: state.user.userDetail
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleApply,
    handleClubSearch
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(withStyles(styles)(ApplyForm))
