import React from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Avatar,
	Box,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Popover,
	Typography,
} from "@material-ui/core";
// import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";
import { makeStyles } from "@material-ui/styles";

import { paths } from "../utils/paths";

const useStyles = makeStyles((theme) => ({
	owner: {
		color: theme.palette.link?.main,
		cursor: "pointer",
	},
	listItemAvatar: {
		minWidth: theme.spacing(6),
	},
	avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
}));

const LikesIndicator = ({ members, size, visibleMemberList, userDetail }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	
	const isVisible = (username) => {
    return userDetail.username === username || visibleMemberList.includes(username)
  }

	return (
		<React.Fragment>
			{(members || []).length > 0 ? (
				<Box display="flex" alignItems="center">
					{/* <FavoriteOutlinedIcon
						color="primary"
						style={{
							fontSize: size === "small" ? 16 : 20,
							marginLeft: size === "small" ? 0 : 8,
							marginRight: 8,
						}}
					/> */}
					<Typography variant={size === "small" ? "body2" : "body1"}>
						by
					</Typography>
					&nbsp;
					{ isVisible(members[0].username) ?
					<Link
						variant={size === "small" ? "body2" : "body1"}
						className={classes.owner}
						href="#"
						onClick={(e) => {
							e.preventDefault();
							history.push(
								`${paths.profile}/${members[0].username}`
							);
						}}
					>
						{members[0].name}
						{members[0].username === userDetail.username && userDetail.privacy_setting === 'anonymous' && 
							<span variant="body2" style={{fontSize: 11, paddingLeft: 8, alignSelf: 'center'}}>
								(Anonymous)
							</span>}
					</Link>
					: <span>Anonymous</span> }
					{members.length > 1 && (
						<React.Fragment>
							&nbsp;
							<Typography
								variant={size === "small" ? "body2" : "body1"}
							>
								and
							</Typography>
							&nbsp;
							<Link
								variant={size === "small" ? "body2" : "body1"}
								className={classes.owner}
								onClick={handlePopoverOpen}
							>
								{members.length - 1}
							</Link>
							&nbsp;
							<Typography
								variant={size === "small" ? "body2" : "body1"}
							>
								other{members.length - 1 > 1 && <span>s</span>}
							</Typography>
						</React.Fragment>
					)}
				</Box>
			) : null}

			{(members || []).length > 1 && (
				<Popover
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<List dense>
						{members.slice(1).map((member, index) => {
							return (
								<ListItem
									key={index}
									button
									onClick={() => {
										history.push(
											`${paths.profile}/${member.username}`
										);
										handlePopoverClose();
									}}
								>
									{ isVisible(member.username) ?
									<>
											<ListItemAvatar
											className={classes.listItemAvatar} >
											<Avatar
												className={classes.avatar}
												src={member.profile_image} />
										</ListItemAvatar>
										<ListItemText primary={member.username === userDetail.username && userDetail.privacy_setting === 'anonymous' ? `${member.name} (Anonymous)` : member.name} />
									</>
									:
									<>
										<ListItemAvatar
											className={classes.listItemAvatar} >
											<Avatar
												className={classes.avatar}
												src={null} />
										</ListItemAvatar>
										<ListItemText primary={'Anonymous'} />
									</>}
								</ListItem>
							);
						})}
					</List>
				</Popover>
			)}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {};
};
const mapDispatch = (dispatch) => {
	return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatch)(LikesIndicator);
