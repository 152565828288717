import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MemberSelector from '../../components/memberSelector';
import WidgetContainer from '../../components/widgetContainer';
import { handleUpdateGroup } from '../../reducers/groups';

const AddGroupMembers = ({userDetail, group, handleUpdateGroup}) => {
  const [newMembers, setNewMembers] = React.useState([])
  const [guestList, setGuestList] = React.useState([])
  const saveNewMembers = () => {
    if (guestList.length === 0 && newMembers.length === 0) { return }
    const newGroup = {...group,
      guest_list: (group.guest_list || []).concat(guestList.map(x => ({first_name: x.first_name, last_name: x.last_name, email: x.email.trim().toLowerCase()}))),
      group_members: group.group_members.filter(m => m.member_email !== userDetail.email).map(g => g.member_email).concat(newMembers.map(x => x.value))}
    handleUpdateGroup(newGroup)
    setNewMembers([])
    setGuestList([])
  }
  return (
    <WidgetContainer header={'Add New Members'} buttonLabel={'Save'} action={saveNewMembers}>
      <MemberSelector
        menuPortalTarget={document.body}
        groupMembers={newMembers}
        guestList={guestList}
        guestListEvent={list => setGuestList(list || [])}
        listChangeEvent={list => setNewMembers(list || [])}
        excludeEmails={group.group_members.map(x => x.member_email)}
        isGroup={true}
        />
    </WidgetContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleUpdateGroup,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(AddGroupMembers)
