
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {parser} from '../../utils/parser'

const styles = theme => ({
  main: {
    width: 'auto',
    maxWidth: '600px',
    display: 'block', // Fix IE 11 issue.
    margin: 'auto',
    color: theme.palette.primary.dark,
    padding: theme.spacing(2, 0) // `${theme.spacing.unit * 2}px 0`
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 10), // `${theme.spacing.unit * 4}px ${theme.spacing.unit * 10}px`,
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2)
    },
  },
  intro: {
    textAlign: 'left',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  logoHolder: {
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  logo: {
    width: '200px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '100%',
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
});

function PasswordReset(props) {
  const { location, auth, handlePasswordReset, email, token, classes } = props
  const [values, setValues] = React.useState({
    helperText: ''
  })
  const handleSubmit = (evt) => {
    evt.preventDefault()
    const confirmPassword = evt.target.confirmpassword.value
    const password = evt.target.password.value
    if (confirmPassword !== password) {
      return setValues(() => ({
        helperText: 'Passwords must match.'
      }))
    }
    if (!parser.isValidPassword(password)) {
      return setValues(() => ({
        helperText: 'Invalid Password. 8 character minimum including at least 1 uppercase, 1 lowercase and 1 number'
      }))
    }
    handlePasswordReset(email, password, token)
  }
  const onChange = () => {
    if (values.helperText.length > 0) {
      setValues(() => ({ helperText: '' }))
    }
  }
  return (
    <main className={classes.main}>
      <div className={classes.logoHolder}>
        <img alt="Captain's Club" className={classes.logo} src={require('../../assets/images/logo_short.svg')} />
      </div>
      <Paper className={classes.paper}>
        <div className={classes.intro}>
          <Typography variant="h6" gutterBottom>
            { location.pathname.indexOf('/set') === 0 ? 'Please set your password' : 'Reset Password' }
          </Typography>
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            id="password"
            label="Password"
            className={classes.textField}
            helperText={'Password must be minimum 8 characters, including at least 1 uppercase, 1 lowercase and 1 number'}
            required
            autoFocus
            onChange={onChange}
            type="password"
            margin="normal"
            variant="outlined" />
          <TextField
            id="confirmpassword"
            label="Confirm Password"
            className={classes.textField}
            required
            helperText={values.helperText}
            error={values.helperText.length > 0}
            onChange={onChange}
            type="password"
            margin="normal"
            variant="outlined" />
          <Button type="submit" disabled={auth.isLoading} fullWidth variant="contained" className={classes.submit}>
            SUBMIT
          </Button>
          <Typography color="error" component="p" variant="body2">
            {auth.error}
          </Typography>
        </form>
      </Paper>
    </main>
  );
}

export default withStyles(styles)(PasswordReset);
