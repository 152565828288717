import React from 'react'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

const ButtonEditEmail = ({ toggleEdit }) => {
    return <IconButton onClick={() => toggleEdit(true)}>
        <EditIcon style={{fontSize: 20}} />
    </IconButton>
}

export default ButtonEditEmail