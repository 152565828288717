
export default {
    '&multiLine': {
      tabIndex: 10,
      height: '80px',
  
      highlighter: {
        padding: 8,
        paddingTop: 20,
        paddingBottom: 16,  
        paddingLeft: 12    
      },
  
      input: {
        padding: '10px 14px',
        paddingTop: 20,
        outline: 0,
        border: 0,
        ':focus': {
          backgroundColor: 'red',
        },
      },
    }
  }