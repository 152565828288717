import { makeStyles } from '@material-ui/styles';
import { merge } from 'lodash';
import React from 'react';
import { Mention, MentionsInput } from 'react-mentions';
// import { bindActionCreators } from 'redux'
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';

import defaultInputStyle from './defaultInputStyle';
import defaultInputStyle2 from './defaultInputStyle2';
import UserAvatar from '../userAvatar';
import { useDebounce } from '../../utils/hooks';
import classNames from './style.module.css'

const useStyles = makeStyles((theme) => ({
  highlight: {
    backgroundColor: theme.palette.highlight?.main,
  },
  mentionsBox: {
    display: 'block',
    '& ul': {
      maxHeight: 180,
      overflowY: 'auto',
    },
  },
  label: {
    position: 'absolute',
    backgroundColor: '#fff',
    top: '25px',
    zIndex: 999,
    padding:'5px',
    left: '10px',
    fontSize: '12px'
  },
  title: {
    fontFamily: "Sentinel-Black,Roboto,sans-serif",
    fontWeight: 1,
    marginTop: 0
  }
}));

const TaggableInput = ({
  value,
  onChange,
  placeholder,
  placement,
  singleLine,
  session,
  members,
  groupDetail,
  textInputRef,
  isHomeFeed = false
}) => {
  const classes = useStyles();
  // const theme = useTheme()
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [memberList, setMemberList] = React.useState([]);
  const [text, setText] = React.useState(value || '')
  const [flagTextIsLoaded, setFlagTextIsLoaded] = React.useState(false)

  const style =
    placement !== 'bottom'
      ? defaultInputStyle
      : merge({}, defaultInputStyle, {
          suggestions: {
            list: {
              bottom: 14,
            },
          },
        });

  const debouncedText = useDebounce(text, 500);

  React.useEffect(() => {
    onChange(null, text)
  }, [debouncedText]) // eslint-disable-line

  React.useEffect(() => {
    if(!text && !flagTextIsLoaded && value){
      setFlagTextIsLoaded(true)
      setText(value)
    }
    if(!value){
      setText('')
    }
  }, [value]) // eslint-disable-line

  React.useEffect(() => {
    if (!session || !members) {
      return;
    }
    if (groupDetail && groupDetail.group_members) {
      const visibleMemberList = members.map((x) => x.email);
      const items = groupDetail.group_members
        .filter(
          (x) =>
            x.member_email &&
            x.member_email !== session.email &&
            visibleMemberList.includes(x.member_email) &&
            x.role !== 'banned'
        )
        .map((item) => ({
          id: item.username,
          display: `@${item.username} (${item.first_name} ${item.last_name})`,
          image: item.profile_image,
        }));
      setMemberList(items);
    } else {
      const items = members
        .filter(
          (x) => x.email && x.email !== session.email && x.role !== 'banned'
        )
        .map((item) => ({
          id: item.username,
          display: `@${item.username} (${item.first_name} ${item.last_name})`,
          image: item.profile_image,
        }));
      setMemberList(items);
    }
  }, [session, members, groupDetail]);
  // React.useEffect(() => {
  //     if (!value && !fullScreen) {
  //         textInputRef.current.focus()
  //     } else{
  //         window.scroll(0,0)
  //     }
  // }, [value, fullScreen]);
  // const onAdd = (id, display) => {
  //   console.log('Add', id, display)
  // }
  const modifyDisplay = (id, display) => {
    return '@' + id + ' ';
  };
  const renderMemberItem = (
    suggestion,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 8 }}>
          {suggestion && (
            <UserAvatar profileImage={suggestion.image} width={28} />
          )}
        </div>
        <div>{highlightedDisplay}</div>
      </div>
    );
  };

  async function fetchUsers(query, callback) {
    new Promise((resolve, reject) => {
      resolve(memberList);
    }).then(res => {
      return res
        .filter(member => member.display.includes(query))
        .slice(0, 20);
    }).then(callback);
  }

  const style2 = isHomeFeed ? merge({}, style, defaultInputStyle2) : style

  return isHomeFeed ? 
    <div className={classes.mentionsBox} style={{ position: 'relative' }}>
      <h4 className={classes.title}>Post questions or comments for other members here.&nbsp;&nbsp;(Mention people using&nbsp;&nbsp;'@')</h4>
      <span className={classes.label} >{placeholder}</span>
      <MentionsInput
        value={text}
        onChange={(evt, value) => setText(value)}
        singleLine={singleLine || false}
        style={style2}
        inputRef={textInputRef}
        classNames={classNames}
      >
        <Mention
          data={fetchUsers}
          // onAdd={onAdd}
          renderSuggestion={renderMemberItem}
          markup={'@{{__id__}}'}
          displayTransform={modifyDisplay}
          className={classes.highlight}
        />
      </MentionsInput>
    </div>
  : 
    <div className={classes.mentionsBox}>
      <MentionsInput
        value={text}
        onChange={(evt, value) => setText(value)}
        singleLine={singleLine || false}
        style={style}
        placeholder={placeholder}
        inputRef={textInputRef}
      >
        <Mention
          data={fetchUsers}
          // onAdd={onAdd}
          renderSuggestion={renderMemberItem}
          markup={'@{{__id__}}'}
          displayTransform={modifyDisplay}
          className={classes.highlight}
        />
      </MentionsInput>
    </div>
};

const mapStateToProps = (state) => {
  return {
    session: state.auth.session,
    members: state.members.memberList,
    groupDetail: state.groups.groupDetail,
  };
};

export default connect(mapStateToProps)(TaggableInput);
