import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import React from 'react';
import AudienceList from './audienceList';

function AttendeeDialog({open, toggleDialog, attendees, courseName, handleConfirmation}) {
    const [attendeeList, setAttendeeList] = React.useState([])
    React.useEffect(() => {
        setAttendeeList(attendees)
    }, [attendees]);
    const removeAttendee = email => {
        const newList = attendeeList.filter(x => x.email !== email)
        setAttendeeList(newList)
    }
    const handleConfirm = () => {
        handleConfirmation(attendeeList.map(x => x.email))
        toggleDialog(null)
    }
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(null)}
            maxWidth="sm"
        >
            <DialogTitle>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <BlockIcon style={{color: 'red', marginRight: 4}} />
                    <Typography variant="h6" color="error">Warning!</Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <Typography style={{marginBottom: 8}} component="p">We want to make sure you don’t accidentally send invites to members who are very unlikely to be able to join you for the round.</Typography>
                    <Typography style={{marginBottom: 8}} component="p">Members you have selected for this invite are located in states other than the state where {courseName} is located.  When you post this invite, all of these members will receive an email invitation to join you for this round.</Typography>
                    <Typography style={{marginBottom: 8}} component="p">You can narrow the list of out of state members below by removing out of state members individually.</Typography>
                    <AudienceList
                        fullWidth
                        list={(attendeeList || []).filter(x => x.address)}
                        handleRemove={removeAttendee}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{padding: '8px 24px', width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Button variant="outlined" onClick={() => toggleDialog(null)}>Cancel Invite</Button>
                <Button variant="contained" onClick={handleConfirm}>Send Invite</Button>
            </DialogActions>
        </Dialog>
    );
}
  
export default AttendeeDialog
