import { Box, CardContent, IconButton, Link, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LikesIndicator from '../../components/likesIndicator';
import TaggableBodyText from '../../components/taggableBodyText';
import UserAvatar from '../../components/userAvatar';
import {
  handleDeleteComment,
  handleSubmitReply,
  handleUpdateComment,
  handleCommentLike,
} from '../../reducers/posts';
import { parser } from '../../utils/parser';
import { paths } from '../../utils/paths';
import CommentInput from './commentInput';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      padding: 8
    }
  },
  avatarBox: {
    marginBottom: 0,
    marginRight: 16,
    display: 'block',
    alignSelf: 'start',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    },
  },
  owner: {
    color: theme.palette.link?.main,
    cursor: 'pointer',
  },
  commentDisplayBox: {
    padding: '8px 0',
    width: '100%',
    alignSelf: 'center',
  },
  replyActions: {
    padding: '0px 16px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
}))

const CommentDis = ({
  history,
  userDetail,
  comment,
  post,
  hideReply,
  handleSubmitReply,
  handleUpdateComment,
  handleDeleteComment,
  handleCommentLike,
  membersVisible
}) => {
  const classes = useStyles()
  const [showReply, toggleReply] = React.useState(false)
  const [reply, setReply] = React.useState('')
  const [editComment, setEditComment] = React.useState(false)
  const [newCommentText, setNewCommentText] = React.useState();
  const visibleMemberList = (membersVisible || []).map(x => x.username)
  
  const likeComment = (comment?.likes || []).map(like => like?.member_email).includes(userDetail.email)
  
  React.useEffect(() => {
    setNewCommentText(comment.body_text)
  }, [comment]);
  const submit = () => {
    if (!reply) { return }
    handleSubmitReply(post, comment.id, reply)
    setReply('')
    toggleReply(false)
  }
  const updateComment = () => {
    if (!newCommentText) { return }
    if (comment.parent_comment) {
      handleUpdateComment(comment.member_email, newCommentText, post, comment.parent_comment, comment.id)
    } else {
      handleUpdateComment(comment.member_email, newCommentText, post, comment.id)
    }
    setNewCommentText('')
    setEditComment(false)
  }
  const deleteComment = () => {
    if (window.confirm('Are you sure you want to remove this item?')) {
      if (comment.parent_comment) {
        handleDeleteComment(post, comment.parent_comment, comment.id)
      } else {
        handleDeleteComment(post, comment.id)
      }
    }
  }

  const isVisible = () => {
    return userDetail.username === comment.username || visibleMemberList.includes(comment.username)
  }
  
  return (<div className={classes.contentBox}>
    {!editComment && <div>
      { isVisible() ? 
      <div className={classes.avatarBox} style={{cursor: 'pointer'}} onClick={() => history.push(`${paths.profile}/` + comment.username)} >
        <UserAvatar profileImage={comment.profile_image} />
      </div>
      : <div className={classes.avatarBox}>
        <UserAvatar profileImage={visibleMemberList.includes(comment.username) && comment.profile_image} />
      </div>}
    </div>
    }
    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
      {editComment
        ? (
          <CommentInput
            value={newCommentText}
            placeholder='Write a comment...'
            handleSubmit={updateComment}
            handleCancelClick={() => setEditComment(false)}
            handleUpdateText={setNewCommentText}
          />
        )
        : (
          <React.Fragment>
            <div className={classes.commentDisplayBox}>
              {comment && comment.username && userDetail && (
                userDetail.username === comment.username
                  ? (
                    <div style={{float: 'right'}}>
                      <IconButton onClick={() => setEditComment(true)}><EditIcon /></IconButton>
                      <IconButton onClick={() => deleteComment()}><DeleteIcon /></IconButton>
                    </div>
                  )
                  : (
                    <div style={{float: 'right'}}>
                      <IconButton onClick={() => handleCommentLike(post, comment, !likeComment)}>
                        {likeComment
                          ? <FavoriteOutlinedIcon color="primary" />
                          : <FavoriteBorderOutlinedIcon />
                        }
                      </IconButton>
                    </div>
                  )
              )}
              { isVisible()
                ? (
                  <Link className={classes.owner} variant="body1" onClick={() => history.push(`${paths.profile}/${comment.username}`)}>
                    {comment.first_name + ' ' + comment.last_name}
                    {comment.username === userDetail.username && userDetail.privacy_setting === 'anonymous' && 
                      <span variant="body2" style={{fontSize: 11, paddingLeft: 8, alignSelf: 'center'}}>
                        (Anonymous)
                      </span>}
                  </Link>
                )
                : (
                  <Typography>Anonymous</Typography>
                )
              }
              <Typography component="div" variant="body1" style={{ paddingTop: 8, whiteSpace: 'pre-wrap' }}>
                <TaggableBodyText text={comment.body_text} />
              </Typography>
            </div>

            <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.replyActions}>
              <Box>
                {!hideReply && post && (
                  <React.Fragment>
                    <Link className={classes.owner} onClick={() => toggleReply(true)} variant="body1">Reply</Link>
                    <span>&nbsp;&bull;&nbsp;</span>
                  </React.Fragment>
                )}
                <span>
                  {comment.edited 
                    ? `Edited ${parser.dateDistance(comment.edited)} ago`
                    : `Added ${parser.dateDistance(comment.created_at)} ago`
                  }
                </span>
              </Box>

              <Box>
                <LikesIndicator
                  size="small"
                  members={(comment.likes || []).map(like => ({
                    ...like,
                    name: `${like.first_name} ${like.last_name}`
                  }))}
                  visibleMemberList={visibleMemberList}
                  userDetail={userDetail}
                />
              </Box>
            </Box>
          </React.Fragment>
        )
      }

      {showReply && <div>
        <CommentInput
          value={reply}
          placeholder='Write a reply...'
          handleSubmit={submit}
          handleCancelClick={() => toggleReply(false)}
          handleUpdateText={setReply} />
      </div>}
      {comment.replies && comment.replies.length > 0 && <CardContent className={classes.cardContent}>
        {comment.replies && comment.replies.map((reply, idx) => {
          if (!reply.body_text) { return <span /> }
          return (
            <CommentDisplay
              userDetail={userDetail}
              key={idx} 
              history={history} 
              comment={reply}
              post={post}
              hideReply
            />
          )
        })}
      </CardContent>}
    </div>
  </div>)
}

const mapStateToProps = (state) => {
    return {
      userDetail: state.user.userDetail,
      membersVisible: state.members.membersVisible,
    }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleSubmitReply,
    handleUpdateComment,
    handleDeleteComment,
    handleCommentLike,
  }, dispatch)
}
  
export const CommentDisplay = React.memo(connect(mapStateToProps, mapDispatch)(CommentDis))
  