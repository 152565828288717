import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import MemberHeader from '../components/header_auth'
import { Account, Group, GroupList, EventList, Home, Members, Messaging, Onboarding, Profile, MembersReview, TopCourses, ArchivedGroupList } from '../pages'
import { handleActiveClubs, handleClubSearch, handleCourseSearch } from '../reducers/clubs'
import { handleGetGroups, handleGroupSearch } from '../reducers/groups'
import { handleGetGuests } from '../reducers/guests'
import { handleGetMembers, handleGetMembersVisible } from '../reducers/members'
import { handleConnectNotifications, handleGetMessageChannels, handleGetNotificationSettings } from '../reducers/notifications'
import { handleGetReferences, handleSchoolSearch } from '../reducers/references'
import { handleGetUserDetail } from '../reducers/user'
import { paths } from '../utils/paths'
import { handleGetEvents } from '../reducers/events'
import { isApplicantOrMemberReview } from '../utils/profiles'

const MemberLayout = ({
    handleGetUserDetail,
    handleSchoolSearch,
    handleGetGroups,
    handleGroupSearch,
    handleGetGuests,
    handleClubSearch,
    handleActiveClubs,
    handleCourseSearch,
    handleGetReferences,
    handleGetNotificationSettings,
    handleConnectNotifications,
    handleGetMembers,
    handleGetMembersVisible,
    handleGetEvents,
    handleGetMessageChannels,
    userDetail, location,
    session, groupList}) => {
    React.useEffect(() => {
        handleGetUserDetail(session)
        handleGetNotificationSettings(session)
        handleClubSearch()
        handleActiveClubs()
        handleCourseSearch()
        handleSchoolSearch()
        handleGetReferences()
        if (session && session.role && session.role !== 'applicant') {
            handleGetMembers()
            handleGetMembersVisible()
            handleGetEvents()
            handleConnectNotifications(session.email)
            handleGetGroups()
            handleGroupSearch()
            handleGetGuests()
            handleGetMessageChannels(session.email)
        }
    }, [
        session,
        handleGetUserDetail,
        handleSchoolSearch,
        handleGetGroups,
        handleGroupSearch,
        handleGetGuests,
        handleClubSearch,
        handleActiveClubs,
        handleCourseSearch,
        handleGetReferences,
        handleGetNotificationSettings,
        handleGetMessageChannels,
        handleConnectNotifications,
        handleGetMembers,
        handleGetMembersVisible,
        handleGetEvents
    ])
    if (!session || !session.token) {
        return <Redirect to={paths.root} />
    }
    if (!userDetail || !userDetail.email) {
        return <div style={{width: '100%', marginTop: '250px', textAlign: 'center'}}><CircularProgress size={96} /></div>
    }
    if (userDetail.role === 'guest' && location.pathname && location.pathname.indexOf(paths.group) < 0 && groupList.length >= 0) {
        const groupPending = groupList.find(x => (x.guest_list && x.guest_list.find(g => g.email === userDetail.email)) 
                                                || (x.group_members && x.group_members.find(m => m.member_email === userDetail.email && !m.accepted)))
        if (groupPending && groupPending.url) {
            return <Redirect to={paths.group + '/' + groupPending.url} />
        } 
        
    }
    const applicantPath = (userDetail && userDetail.terms_accepted_date ? paths.home : paths.onboarding)
    const isMember = session.role && session.role === 'member'
    const isApplicant = session.role && isApplicantOrMemberReview(session)
    return (
        <React.Fragment>
            <MemberHeader />
            {isApplicant ? (<Switch>
                <Route path={paths.onboarding} exact component={Onboarding} />
                <Route path={paths.home} exact component={Home} />
                <Route path={paths.account} exact component={Account} />
                <Redirect to={applicantPath} />
            </Switch>) : (<Switch>
                <Route path={paths.home} exact component={Home} />
                <Route path={paths.profile} exact component={Profile} />
                <Route path={`${paths.profile}/:slug`} exact component={Profile} />
                <Route path={`${paths.group}/:slug`} exact component={Group} />
                {isMember && <Route path={`${paths.groups}`} exact component={GroupList} />}
                {isMember && <Route path={`${paths.groupsArchived}`} exact component={ArchivedGroupList} />}
                {isMember && <Route path={paths.events} exact component={EventList} />}
                <Route path={paths.account} component={Account} />
                {isMember && <Route path={paths.onboarding} exact component={Onboarding} />}
                <Route path={paths.messaging} exact render={(props) => (isMember ? <Messaging {...props} /> : <Redirect to={paths.home} />) } />
                <Route path={paths.members} exact render={(props) => (isMember ? <Members {...props} /> : <Redirect to={paths.home} />) } />
                <Route path={paths.memberReview} exact render={(props) => (isMember ? <MembersReview {...props} /> : <Redirect to={paths.home} />) } />
                <Route path={paths.topCourses} exact render={(props) => (isMember ? <TopCourses {...props} /> : <Redirect to={paths.home} />) } />
                <Redirect to={paths.home} />
            </Switch>)
            }
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
      userDetail: state.user.userDetail,
      session: state.auth.session,
      groupList: state.groups.groups
    }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleGetUserDetail,
        handleSchoolSearch,
        handleGetGroups,
        handleGroupSearch,
        handleGetGuests,
        handleClubSearch,
        handleActiveClubs,
        handleCourseSearch,
        handleGetReferences,
        handleGetNotificationSettings,
        handleGetMessageChannels,
        handleConnectNotifications,
        handleGetMembers,
        handleGetEvents,
        handleGetMembersVisible
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatch)(MemberLayout))

