
import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import ApplyForm from './applyForm';
const logoIcon = require('../../assets/images/logo_short.svg')
const logoDarkIcon = require('../../assets/images/logo_short_dark.svg')
const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      margin: 0
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.dark,
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logoHolder: {
    textAlign: 'center',
    margin: theme.spacing(1, 0)
  },
  logo: {
    width: '200px'
  },
  contentHolder: {
    width: '88%',
    margin: 'auto',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  applicationSent: {
    width: '100%',
    color: theme.palette.common.white,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .subtext': {
      width: '300px',
      textAlign: 'center'
    }
  }
});

function ApplyView({classes, auth}) {
  return (
    <main className={classes.main}>
      <Grid container>
        <Grid item md={auth.applied ? 12 : 6} xs={12}>
          <div className={classes.logoHolder}>
            <img alt="Captain's Club" className={classes.logo} src={logoIcon} />
          </div>
          {auth.applied ? (
            <div className={classes.applicationSent}>
              <Typography variant="h4" align="left" gutterBottom>
                Application Sent
              </Typography>
              <Typography variant="body2" align="left" className='subtext' gutterBottom>
                Our membership committee will review and get back to you within 48 hours.
              </Typography>
            </div>
          ) : (
            <div className={classes.contentHolder}>
              <Typography variant="h4" align="left" gutterBottom>
                Become a Member
              </Typography>
              <Typography variant="body2" align="left" className='subtext' gutterBottom>
                Captain's Club was created to bring together golfers who want to experience great courses, establish new friendships, and build lasting relationships.
                <br /><br />
                As a member of Captain's Club you will be invited to play in exclusive golf events hosted throughout the country each year and will have the opportunity to utilize the Captain's Club Members website to customize and plan golf events, find new games to play in, manage your private golf groups and find members across the country with similar interests as yourself.
                <br /><br />
                Membership is limited to golfers belonging to private golf clubs.
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item hidden={auth.applied} md={6} xs={12}>
          <Paper className={classes.paper}>
            {auth.referrer && 
              <div style={{display: 'flex', flexDirection: 'row', marginBottom: '16px'}}>
                <img style={{width: '100px', height: '100px', borderRadius: '8px', marginRight: '16px'}} src={auth.referrer.profile_image || logoDarkIcon} alt="Welcome" />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
                  <Typography variant="h6">{auth.referrer.first_name + ' ' + auth.referrer.last_name}</Typography>
                  <Typography gutterBottom>has invited you to join Captain's Club</Typography>
                </div>
              </div>
            }
            <ApplyForm />
        </Paper>
        </Grid>
      </Grid>
    </main>
  )
}

export default withStyles(styles)(ApplyView);
