import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { parser } from '../utils/parser';
import { paths } from '../utils/paths';
import { handleShowDialogPost } from '../reducers/posts';
import { SlackUtility } from '../utils/slackUtility';
import NotificationPlayGolf from './notificationPlayGolf';
import NotificationGolfUpdated from './notificationGolfUpdated';
import NotificationTravel from './notificationTravel';
import UserAvatar from './userAvatar';
import NotificationApprovedMember from './notificationApprovedMember';
import NotificationPlayGolfResponse from './NotificationPlayGolfResponse';
import NotificationTagged from './notificationTagged';

const useStyles = makeStyles((theme) => ({
  emptyBox: {
    textAlign: 'center',
    width: '100%',
    padding: '16px 0',
  },
  notificationItem: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    borderBottom: 'solid 1px #e7e7ea',
    padding: 8,
  },
  subHeader: {
    backgroundColor: '#f5f6f7',
    borderBottom: 'solid 1px #e7e7ea',
    padding: '4px 8px',
  },
  subTitle: {
    fontWeight: 'bold',
    color: '#93969d',
    fontSize: 12,
  },
  link: {
    color: theme.palette.link.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    membersVisible: state.members.membersVisible
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      handleShowDialogPost,
    },
    dispatch
  );
};
const LinkedNotificationItem = connect(
  mapStateToProps,
  mapDispatch
)(
  ({
    membersVisible,
    handleShowDialogPost,
    sender,
    message,
    closeNotifications,
  }) => {
    const classes = useStyles();
    const visibleMemberList = (membersVisible || []).map((x) => x.username);
    return (
      <div className={classes.notificationItem}>
        {visibleMemberList.includes(sender.username) ||
        (sender.role && sender.role === 'guest') ||
        message.is_admin ? (
          <Link
            onClick={() => closeNotifications()}
            to={`${paths.profile}/` + sender.username}
            style={{ marginRight: 8 }}
          >
            <UserAvatar profileImage={sender.profile_image} width={32} />
          </Link>
        ) : (
          <div style={{ marginRight: 8 }}>
            <UserAvatar width={32} />
          </div>
        )}
        <Typography variant='caption'>
          {visibleMemberList.includes(sender.username) ||
          (sender.role && sender.role === 'guest') ||
          message.is_admin ? (
            <Link
              onClick={() => closeNotifications()}
              to={`${paths.profile}/` + sender.username}
            >
              {sender.name || `@${sender.username}`}
            </Link>
          ) : (
            <span style={{ fontWeight: 500 }}>Anonymous</span>
          )}
          <span>
            {message.body}
            {message.is_admin ? (
              <span
                className={classes.link}
                onClick={() => {
                  handleShowDialogPost(message.data.post_id);
                  closeNotifications();
                }}
                style={{ paddingLeft: 4 }}
              >
                (View)
              </span>
            ) : (
              ''
            )}
          </span>
          {message.read && (
            <div style={{ fontSize: 10 }}>
              {parser.dateDistance(message.issued_at)} ago
            </div>
          )}
        </Typography>
      </div>
    );
  }
);

const NotificationList = connect(
  mapStateToProps,
  mapDispatch
)(({ items, closeNotifications }) => {
  return (
    <div>
      {items.map((item, idx) => {
        let sender = {};
        let message = {};
        switch (item.type) {
          case 'we_have_played_together':
            sender = {
              username: item.data.claimant_username,
              name: item.data.sender_name,
              profile_image: item.data.claimant_profile_image,
            };
            message = {
              ...item,
              body: <span> has indicated that you've played together</span>,
            };
            break;
          case 'tagged_in_post':
            return (
              <NotificationTagged
                key={idx}
                message={item}
                closeNotifications={() => closeNotifications()}
              />
            );
          case 'admin_message':
            sender = {
              username: item.data.sender_username,
              name: item.data.sender_name,
              profile_image: item.data.sender_profile_image,
            };
            message = {
              ...item,
              body: <span> {item.data.message}</span>,
              is_admin: true,
            };
            break;
          case 'new_group_member':
            sender = {
              username: item.data.sender_username,
              name: item.data.sender_name,
              role: item.data.sender_role,
              profile_image: item.data.sender_profile_image,
            };
            message = {
              ...item,
              body: (
                <span>
                  &nbsp;has accepted a group invite to&nbsp;
                  <Link
                    onClick={() => closeNotifications()}
                    to={`${paths.group}/` + item.data.group_url}
                  >
                    {item.data.group_name}
                  </Link>
                </span>
              ),
            };
            break;
          case 'group_invite':
            sender = {
              username: item.data.sender_username,
              name: item.data.sender_name,
              profile_image: item.data.sender_profile_image,
            };
            message = {
              ...item,
              body: (
                <span>
                  &nbsp;has invited you to join a group&nbsp;
                  <Link
                    onClick={() => closeNotifications()}
                    to={`${paths.group}/` + item.data.url}
                  >
                    ({item.data.name})
                  </Link>
                </span>
              ),
            };
            break;
          case 'group_post':
            sender = {
              username: item.data.sender_username,
              name: item.data.sender_name,
              profile_image: item.data.sender_profile_image,
            };
            message = {
              ...item,
              body: (
                <span>
                  &nbsp;added a new post in the&nbsp;
                  <Link
                    onClick={() => closeNotifications()}
                    to={`${paths.group}/` + item.data.group_url}
                  >
                    {item.data.group_name}
                  </Link>
                  &nbsp;group feed.
                </span>
              ),
            };
            break;
          case 'public_group_request':
            sender = {
              username: item.data.sender_username,
              name: item.data.sender_name,
              profile_image: item.data.sender_profile_image,
            };
            message = {
              ...item,
              body: (
                <span>
                  {' '}
                  has requested to join your group,&nbsp;
                  <Link
                    onClick={() => closeNotifications()}
                    to={`${paths.group}/` + item.data.group_url}
                  >
                    {item.data.group_name}
                  </Link>
                </span>
              ),
            };
            break;
          case 'new_approved_member':
            return (
              <NotificationApprovedMember
                key={idx}
                message={item}
                closeNotifications={() => closeNotifications()}
              />
            );
          case 'play_golf':
            return (
              <NotificationPlayGolf
                key={idx}
                message={item}
                closeNotifications={() => closeNotifications()}
              />
            );
          case 'play_golf_accepted':
          case 'play_golf_declined':
            return (
              <NotificationPlayGolfResponse
                key={idx}
                accepted={Boolean(item.type === 'play_golf_accepted')}
                message={item}
                closeNotifications={() => closeNotifications()}
              />
            );
          case 'golf_updated':
            return (
              <NotificationGolfUpdated
                key={idx}
                message={item}
                closeNotifications={() => closeNotifications()}
              />
            );
          case 'travel':
            return (
              <NotificationTravel
                key={idx}
                message={item}
                closeNotifications={() => closeNotifications()}
              />
            );
          default:
            if (!item.read) {
              SlackUtility.unhandledNotification(item);
            }
            return <React.Fragment key={idx} />;
        }
        return (
          <LinkedNotificationItem
            key={idx}
            sender={sender}
            message={message}
            closeNotifications={() => closeNotifications()}
          />
        );
      })}
    </div>
  );
});

const NotificationPopover = ({ newItems, oldItems, closeNotifications }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {!newItems || newItems.length === 0 ? (
        <div className={classes.emptyBox}>No recent notifications</div>
      ) : (
        <div>
          <div className={classes.subHeader}>
            <Typography variant='subtitle2' className={classes.subTitle}>
              NEW
            </Typography>
          </div>
          <NotificationList
            items={newItems}
            closeNotifications={closeNotifications}
          />
        </div>
      )}
      {oldItems && oldItems.length > 0 && (
        <div>
          <div className={classes.subHeader}>
            <Typography variant='subtitle2' className={classes.subTitle}>
              EARLIER
            </Typography>
          </div>
          <NotificationList
            items={oldItems}
            closeNotifications={closeNotifications}
          />
        </div>
      )}
    </React.Fragment>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     userDetail: state.user.userDetail
//   }
// }
export default NotificationPopover;
