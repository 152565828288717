
export const paths = {
  root: '/',
  account: '/account',
  manageUsers: '/manage-users',
  adminPosts: '/admin-posts',
  apply: '/apply',
  clubs: '/clubs',
  courses: '/courses',
  dashboard: '/dashboard',
  datalists: '/datalists',
  emailConfirmed: '/email-confirmed',
  events: '/events',
  group: '/group',
  groups: '/groups',
  groupsArchived: '/groups-archived',
  guest: '/guest',
  guestInvites: '/guest_invites',
  home: '/home',
  invitation: '/invitation',
  linkAccess: '/lnk',
  login: '/login',
  members: '/members',
  messaging: '/messaging',
  onboarding: '/onboarding',
  passwordReset: '/password-reset',
  profile: '/profile',
  setPassword: '/set-password',
  membersPosts: '/members-posts',
  memberReview: '/new-applicants',
  topCourses: '/top-courses'
}
