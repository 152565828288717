import { Button, Grid, Hidden, Link, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import LocationOn from '@material-ui/icons/LocationOn';
import cls from 'classnames';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutoSelect from '../../components/auto_select';
import { handleClubSearch, handleCourseSearch, handleFavoriteCourseSearch } from '../../reducers/clubs';
import { handleUpdateUser } from '../../reducers/user';
import { parser } from '../../utils/parser';
import { userRoles } from '../../utils/profiles';
import SectionTitle from './sectionTitle';
import { Link as LinkRouter} from 'react-router-dom';
import { paths } from '../../utils/paths';

const styles = theme => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      padding: 8
    },
  },
  addMembershipPanel: {
    backgroundColor: "#F3F5F8",
    borderRadius: 0,
    padding: '12px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headerRow: {
    padding: '8px 0 0 0',
    fontWeight: 'bold',
    fontSize: 18
  },
  course_list: {
    fontSize: 14,
    padding: '8px 0',
    color: theme.palette.profile.dark,
    fontWeight: 400,
    maxHeight: 300,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '2px 0'
    },
  },
  courseItem: {
    backgroundColor: theme.palette.lightGray.main,
    marginBottom: '2px',
    padding: '8px 0',
    alignItems: 'center',
    color: theme.palette.profile.dark,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
    '&.courseHover:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&.courseMove': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  deletebtn: {
    minWidth: 0,
    width: 36,
    borderRadius: "50px",
    backgroundColor: "#B5B5B5",
    color: "white",
    verticalAlign: "inherit",
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    color: theme.palette.link.main
  },
})
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250
});
const ClubCourseSelector = ({
  profile,
  readonly,
  title,
  label,
  field,
  updateUserDetail,
  handleClubSearch,
  handleCourseSearch,
  handleFavoriteCourseSearch,
  classes,
  clubs,
  courses,
  favoriteCourses,
  isTopCourses = false,
  appendText }) => {
  const [editing, setEditing] = React.useState(false)
  const [datalist, setDatalist] = React.useState([])
  const [isClubs] = React.useState(field.indexOf('club') >= 0)
  const [ fullList, setFullList ] = React.useState([])
  const [ options, setOptions ] = React.useState([])
  const toggleEdit = (val) => {
    resetItems()
    setEditing(val)
  }
  
  const column = field === 'favorite_courses' ? -1 : 1

  React.useEffect(() => {
    let data = profile[field] || []

    if(isClubs && field !== 'share_clubs'){
      if(profile.role === userRoles.memberReview){
        const primaryClub = profile?.primary_club ? clubs?.filter(club => club.id === profile?.primary_club) : null
        if(primaryClub && primaryClub.length > 0 && data.filter(item => item.id === primaryClub[0]?.id).length === 0) data.unshift(primaryClub[0])
      }
    }

    if (isClubs && field !== 'share_clubs' && data.length === 0) {
      if (profile['primary_club'] && profile['primary_club'].id) {
        data.push(profile['primary_club'])
      }
      if (profile['additional_club_memberships'] && profile.additional_club_memberships.length > 0) {
        data = data.concat(profile.additional_club_memberships)
      }
    }
    if (fullList && fullList.length === 0) {
      setFullList(isClubs ? clubs : courses)
    }
    let options = isClubs ? parser.formatClubs(clubs) : parser.formatCourses(courses)
    if (field === 'share_clubs') {
      options.unshift({ value: 'top-50', label: 'Top 50 U.S. clubs'}, { value: 'top-100', label: 'Top 100 U.S. clubs'})
    }

    const filtered = isTopCourses ? courses.filter(c => c.usa_rank && c.usa_rank > 0 && c.usa_rank <= 100) : []

    setOptions(options)
    setDatalist(isTopCourses ? filtered : data)
  }, [profile, field, clubs, courses, isClubs, fullList, isTopCourses]);
  React.useEffect(() => {
    setEditing(!readonly)
  }, [readonly]);

  React.useEffect(() => {
    handleFavoriteCourseSearch(datalist.map(c => c.id))
  }, [datalist, handleFavoriteCourseSearch])

  const searchClubs = name => value => {
    if (value && value.length > 1) {
      handleClubSearch(value)
    }
  }
  const searchCourses = name => value => {
    if (value && value.length > 1) {
      handleCourseSearch(value)
    }
  }
  const searchItems = name => value => {
    if (isClubs) { searchClubs(name)(value) } else { searchCourses(name)(value) }
  }
  const resetItems = () => {
    if (isClubs) { handleClubSearch() } else { handleCourseSearch() }
  }
  const addItem = name => value => {
    if (!value || !value.value) { return }
    const oldValue = profile[name] || []
    const existingIds = oldValue.filter(x => x && x.id).map(item => item.id)
    const newValue = oldValue.filter(x => x && x.id).map((item, idx) => ({rank: idx, id: item.id}))
    if (value.value === 'top-50' || value.value === 'top-100') {
      const rankLimit = parseInt(value.value.split('-')[1], 10)
      let index = oldValue.length
      for (let i = 0; i < fullList.length; i++) {
        const elem = fullList[i]
        if (!elem.rank || elem.rank > rankLimit) { break }
        if (!existingIds.includes(elem.id)) {
          newValue.push({rank: index, id: elem.id})
          index++
        }
      }
    } else if (!existingIds.includes(value.value)) {  
      newValue.push({rank: oldValue.length, id: value.value})
    }
    updateUserDetail({...profile, [name]: newValue})
    handleClubSearch()
    handleCourseSearch()
  }
  const removeItem = (name, id) => {
    let oldValue = profile[name]
    let idx = oldValue.map(e => e.id).indexOf(id);
    if (idx < 0) { return }
    let newValue = null
    newValue = [...oldValue]
    newValue.splice(idx, 1)
    updateUserDetail({...profile, [name]: newValue})
  }
  const handleDragEnd = (result) => {
    if (!result.destination) { return } // dropped outside the list
    const items = reorder(
      datalist,
      result.source.index,
      result.destination.index
    );
    const newList = items.map((item, idx) => ({rank: idx, id: item.id}))
    updateUserDetail({...profile, [field]: newList})
  }

  const getCourseNameColumnSize = (type) => {
    const isFavorite = field === 'favorite_courses' ? 5 + column : 3 + column
    if(type === 'item'){
      return !isClubs ? isFavorite : 4 + column
    }else{
      return isFavorite
    }
  }

  const CourseItem = ({isDragging, course, index}) => {
    const name = isTopCourses ? course.course_name : (course.name || (course.course_name ? course.course_name + ' Course' : '') || '')
    const rank = course.rank || index
    const location = course.address && course.address.city ? course.address.city + ', ' + course.address.state : (course.club_name ? course.club_name + ', ' + course.state : '')
    const logo = course.club_logo || ''
    return (
      <React.Fragment>
      <Hidden smDown>
        <Grid container className={cls(classes.courseItem, { 'courseMove': isDragging, 'courseHover': editing && datalist.length > 1 })}>
          {!isClubs && !isTopCourses ? <Grid style={{paddingLeft: '0', textAlign: 'center'}} item xs={2}>
            {<span>#{(Number(rank) + 1)}</span>}
          </Grid> : (!isTopCourses ? <Grid item xs={1}>&nbsp;</Grid> : null)}
          {(field === 'favorite_courses' || isTopCourses) && <Grid item xs={2} style={{ textAlign: 'center' }} >
           {course.usa_rank && course.usa_rank > 0 ? `#${course.usa_rank}` : '-'}
          </Grid>}
          
          <Grid item xs={getCourseNameColumnSize('item')} style={{ paddingLeft: field === 'favorite_courses' ? '66px' : 0 }}>
            { logo && <img alt={name} src={logo} style={{width: 24, height: 24, verticalAlign: 'middle', marginRight: 8}} /> }
            { course.website ? <Link className={classes.link} target="_blank" rel="noreferrer" href={course.website}>{name}</Link> : name}
          </Grid>
          <Grid item xs={4 + column}>
            <LocationOn style={{ verticalAlign: "middle", marginRight: '4px' }} />
            {location}
            <div className={classes.grow} />
          </Grid>
          <Grid item xs={1} style={{textAlign: 'center'}}>
            {editing && <Button onClick={() => removeItem(field, course.id)} className={classes.deletebtn}>
                <Clear />
            </Button>}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
      <div className={classes.courseItem}>
        <Grid container>
          <Grid item xs={10}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>{ logo && <img alt={name} src={logo} style={{width: 24, height: 24, verticalAlign: 'middle', marginRight: 8}} /> }</div>
              <div>
                <div>
                  {course.website ? <Link className={classes.link} target="_blank" rel="noreferrer" href={course.website}>{name}</Link> : name}
                </div>
                {!isClubs && !isTopCourses && <div>My Rank : {(Number(rank) + 1)}</div>}
                {(field === 'favorite_courses' || isTopCourses) && <div>{isTopCourses ? 'U.S. Rank:' : <LinkRouter style={{ color: '#000' }} to={paths.topCourses}>Top 100 Rank (U.S.):</LinkRouter>} {course.usa_rank && course.usa_rank > 0 ? `#${course.usa_rank}` : '-'}</div>}
                <div>{location}</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={2} style={{alignSelf: 'center'}}>
            {editing && <Button onClick={() => removeItem(field, course.id)} className={classes.deletebtn}>
                <Clear />
            </Button>}
          </Grid>
        </Grid>
      </div>
      </Hidden>
      </React.Fragment>
    );
  };
  return (
    <div>
      <SectionTitle toggleEdit={(val) => toggleEdit(val)} hideSave>{title}{appendText ? ` ${appendText}` : ''}</SectionTitle>
      <div className={classes.root}>
        {editing && <div className={classes.addMembershipPanel}>
          <div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
            <AutoSelect
                textFieldProps={{
                  label: 'ADD TO ' + (label || title).toUpperCase(),
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                placeholder={(isClubs ? "Type Club name" : "Type Course name") + ' here to search'}
                name={field}
                options={options}
                selectedValue={''}
                handleAutoSelectTextChange={searchItems}
                handleAutoSelectChange={addItem} />
          </div>
        </div>}
        <Hidden smDown>
          { !isClubs && datalist && datalist.length > 0 && <div className={classes.headerRow}>
            <Grid container>
              {!isTopCourses ? <Grid style={{paddingLeft: '0', textAlign: 'center'}} item sm={2}>My Rank</Grid> : (!isTopCourses ? <Grid item sm={1}>&nbsp;</Grid> : null)}
              {(field === 'favorite_courses' || isTopCourses) && <Grid style={{ textAlign: 'center' }} item sm={2}>{isTopCourses ? 'U.S. Rank' : <LinkRouter style={{ color: '#000' }} to={paths.topCourses}>Top 100 Rank (U.S.)</LinkRouter>}</Grid>}
              <Grid item sm={getCourseNameColumnSize('header')} style={{ paddingLeft: field === 'favorite_courses' ? '66px' : 0 }} >Course</Grid>
              <Grid item sm={4 + column}>Location</Grid>
              <Grid item sm={1}></Grid>
            </Grid>
          </div> }
        </Hidden> 
        <div className={classes.course_list} style={{ maxHeight: isTopCourses ? 430 : 300 }} >
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {datalist && datalist.map((course, index) => {
                    const foundCourse = courses.filter(c => c.id === course.id)[0]                    
                    //if not find the course search in favorite courses list
                    const myCourse = !isClubs ? (foundCourse ? foundCourse : favoriteCourses.filter(c => c.id === course.id)[0]) : course
                    
                    if (!myCourse || !myCourse.id) { return <div key={index} /> }
                    return <Draggable isDragDisabled={!editing || datalist.length <= 1} key={myCourse.id} draggableId={myCourse.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <CourseItem isDragging={snapshot.isDragging} course={myCourse} index={index} />
                        </div>
                      )}
                    </Draggable>
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {datalist.length === 0 && <div style={{width: '100%', textAlign: 'center'}}>
            <Typography variant='subtitle2' component='p'>Add to your {label || title}</Typography>
          </div>}
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    clubs: state.clubs.clubList,
    courses: state.clubs.courseList,
    favoriteCourses: state.clubs.favoriteCourses
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleClubSearch,
    handleCourseSearch,
    handleFavoriteCourseSearch,
    updateUserDetail: handleUpdateUser
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(withStyles(styles)(ClubCourseSelector))
