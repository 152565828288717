
import { isApplicantOrMemberReview } from "../utils/profiles"
import { resetPasswordSent } from "./auth"
import { handleGetUserDetail } from "./user"

export const types = {
  GET_APPLICANTS_REQUEST: 'MEMBERS/GET_APPLICANTS_REQUEST',
  GET_APPLICANTS_SUCCESS: 'MEMBERS/GET_APPLICANTS_SUCCESS',
  GET_APPLICANTS_FAILURE: 'MEMBERS/GET_APPLICANTS_FAILURE',
  GET_MEMBERS_REQUEST: 'MEMBERS/GET_MEMBERS_REQUEST',
  GET_MEMBERS_SUCCESS: 'MEMBERS/GET_MEMBERS_SUCCESS',
  GET_MEMBERS_VISIBLE_SUCCESS: 'MEMBERS/GET_MEMBERS_VISIBLE_SUCCESS',
  GET_MEMBERS_FAILURE: 'MEMBERS/GET_MEMBERS_FAILURE',
  GET_PROFILE_REQUEST: 'MEMBERS/GET_MEMBERPROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'MEMBERS/GET_MEMBERPROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'MEMBERS/GET_MEMBERPROFILE_FAILURE',
  PROFILE_SEARCH_REQUEST: 'MEMBERS/PROFILE_SEARCH_REQUEST',
  PROFILE_SEARCH_SUCCESS: 'MEMBERS/PROFILE_SEARCH_SUCCESS',
  PROFILE_SEARCH_FAILURE: 'MEMBERS/PROFILE_SEARCH_FAILURE',
  COVER_PHOTO_REQUEST: 'MEMBERS/COVER_PHOTO_REQUEST',
  COVER_PHOTO_SUCCESS: 'MEMBERS/COVER_PHOTO_SUCCESS',
  COVER_PHOTO_FAILURE: 'MEMBERS/COVER_PHOTO_FAILURE',
  STATUS_CHANGE_SUCCESS: 'MEMBERS/STATUS_CHANGE_SUCCESS',
  STATUS_CHANGE_FAILURE: 'MEMBERS/STATUS_CHANGE_FAILURE',
  CLEAR_EVERYTHING: 'MEMBERS/CLEAR',
  FILTER_MEMBERS_REQUEST: 'MEMBERS/FILTER_MEMBERS_REQUEST',
  FILTER_MEMBERS_SUCCESS: 'MEMBERS/FILTER_MEMBERS_SUCCESS',
  FILTER_MEMBERS_FAILURE: 'MEMBERS/FILTER_MEMBERS_FAILURE',
  SET_FILTERED_MEMBER_LIST_INIT: 'MEMBERS/SET_FILTERED_MEMBER_LIST_INIT',
  SET_SEARCH_TEXT: 'MEMBERS/SET_SEARCH_TEXT',
  SET_HANDICAP_FILTER: 'MEMBERS/SET_HANDICAP_FILTER',
  SET_SELECTED_CLUB: 'MEMBERS/SET_SELECTED_CLUB',
  SET_LOCATION: 'MEMBERS/SET_LOCATION',
  GET_MEMBERS_REVIEW_REQUEST: 'MEMBERS/GET_MEMBERS_REVIEW_REQUEST',
  GET_MEMBERS_REVIEW_SUCCESS: 'MEMBERS/GET_MEMBERS_REVIEW_SUCCESS',
  GET_MEMBERS_REVIEW_FAILURE: 'MEMBERS/GET_MEMBERS_REVIEW_FAILURE',
  GET_MEMBERS_RANK_REQUEST: 'MEMBERS/GET_MEMBERS_RANK_REQUEST',
  GET_MEMBERS_RANK_SUCCESS: 'MEMBERS/GET_MEMBERS_RANK_SUCCESS',
  GET_MEMBERS_RANK_FAILURE: 'MEMBERS/GET_MEMBERS_RANK_FAILURE',
}

export const initialState = {
  applicantList: [],
  memberList: null,
  membersVisible: null,
  memberProfile: null,
  membersRank: [],
  searchResults: [],
  filteredMemberList: [],
  filteredMemberListInit: false,
  searchText: '',
  error: null,
  isLoading: false,
  isFiltering: false,
  flagModalEditUserAdmin: false,
  handicapFilter: ''
}

const handleGetApplicants = () => {
  return {
    types: [types.GET_APPLICANTS_REQUEST, types.GET_APPLICANTS_SUCCESS, types.GET_APPLICANTS_FAILURE],
    url: '/membership_applications?limit=50000'
  }
}

export const handleGetApplicantsHome = () => (dispatch) => {
  dispatch({
    types: [types.GET_MEMBERS_REVIEW_REQUEST, types.GET_MEMBERS_REVIEW_SUCCESS, types.GET_MEMBERS_REVIEW_FAILURE],
    url: '/membership_applications/?limit=50000&type=member_review'
  })
}

export const handleGetMembers = () => {
  return {
    types: [types.GET_MEMBERS_REQUEST, types.GET_MEMBERS_SUCCESS, types.GET_MEMBERS_FAILURE],
    url: '/profiles?limit=50000&useShortQuery=true'
  }
}

export const handleGetMembersVisible = () => {
  return {
    types: [types.GET_MEMBERS_REQUEST, types.GET_MEMBERS_VISIBLE_SUCCESS],
    url: '/profiles/visible?useShortQuery=true'
  }
}

export const handleGetMembersRank = () => {
  return {
    types: [types.GET_MEMBERS_RANK_REQUEST, types.GET_MEMBERS_RANK_SUCCESS],
    url: '/profiles/rank/top-100'
  }
}

export const handleGetUsers = () => dispatch => {
  dispatch(handleGetApplicants())
  dispatch(handleGetMembers())
}
export const handleProfileSearch = (search, skip) => (dispatch) => {
  if (!search && !skip) { dispatch(handleGetMembers()); return }
  let params = {}
  if (search) {
    if (typeof search === 'string') {
      params.search = search
    } else {
      params.search = search.text || ''
      params.clubId = search.clubId || ''
      params.location = search.location || ''
    }
  }
  if (skip) { params.skip = skip }
  dispatch({
    types: [types.PROFILE_SEARCH_REQUEST, types.PROFILE_SEARCH_SUCCESS, types.PROFILE_SEARCH_FAILURE],
    url: `profiles/username/${params.search}`,
    query: params
  })
}
export const handleSearchTextChange = (payload) => (dispatch) => {
  dispatch({
    type: types.SET_SEARCH_TEXT,
    payload
  })
}
export const handleSelectedClubChange = (payload) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_CLUB,
    payload
  })
}
export const handleLocationChange = (payload) => (dispatch) => {
  dispatch({
    type: types.SET_LOCATION,
    payload
  })
}
export const handleHandicapFilterChange = (payload) => (dispatch) => {
  dispatch({
    type: types.SET_HANDICAP_FILTER,
    payload
  })
}
export const handleFilterMembers = (search, skip, limit = 20) => (dispatch) => {
  dispatch({
    type: types.SET_FILTERED_MEMBER_LIST_INIT,
    payload: !skip ? true : false
  })

  if (!search && !skip) { dispatch(handleGetMembers()); return }

  let params = {}

  if (search) {
    if (typeof search === 'string') {
      params.search = search
    } else {
      params.search = search.text || ''
      params.clubId = search.clubId || ''
      params.location = search.location || ''
      params.handicapFilter = search.handicapFilter || ''
    }
  }

  if (skip) { params.skip = skip }
  if (limit) { params.limit = limit }

  dispatch({
    types: [types.FILTER_MEMBERS_REQUEST, types.FILTER_MEMBERS_SUCCESS, types.FILTER_MEMBERS_FAILURE],
    url: `/profiles`,
    query: params
  })
}
export const handleGetMemberProfile = (user) => dispatch => {
  const url = isApplicantOrMemberReview(user) ? '/membership_applications' : '/profiles'
  dispatch({
    types: [types.GET_PROFILE_REQUEST, types.GET_PROFILE_SUCCESS, types.GET_PROFILE_FAILURE],
    url: `${url}/${encodeURIComponent(user.email)}`
  })
}
export const handleClearProfile = () => dispatch => {
  dispatch({ type: types.GET_PROFILE_SUCCESS, data: null })
  dispatch(resetPasswordSent())
  dispatch(handleGetUsers())
}
export const handleClearEverything = () => {
  return { type: types.CLEAR_EVERYTHING }
}
export const handleCoverPhotoSubmit = (email, role, file) => (dispatch, getState) => {
  const oldState = getState()
  const session = oldState.auth.session
  const adminChange = session.role === 'admin' && email !== session.email
  if (!email || !role || !file) { return }
  const url = isApplicantOrMemberReview(role ? { role } : null) ? '/membership_applications' : '/profiles'
  const data = new FormData()
  data.append('cover_photo', file, file.name)
  dispatch({
    types: [types.COVER_PHOTO_REQUEST, types.COVER_PHOTO_SUCCESS, types.COVER_PHOTO_FAILURE],
    url: `${url}/${encodeURIComponent(email)}/cover_photo`,
    method: 'put',
    contentType: 'multipart/form-data',
    data: data,
    timeout: 6000,
    callback: adminChange ? handleGetMemberProfile({role: role, email: email}) : handleGetUserDetail({role: role, email: email})
  })
}
export const handleReferrerChange = (email, role, referrer)  => (dispatch) => {
  const path = isApplicantOrMemberReview(role ? { role } : null) ? '/membership_applications' : '/profiles'
  dispatch({
    types: [null, types.STATUS_CHANGE_SUCCESS, types.STATUS_CHANGE_FAILURE],
    url: `${path}/${encodeURIComponent(email)}/referrer`,
    method: 'put',
    data: { referrer: referrer },
    callback: handleGetUsers()
  })
}
export const handleUserRoleChange = (email, role, status, options) => (dispatch) => {
  const path = (role && role === 'member') ? '/profiles' : (role && role === 'guest' ? '/guests'  : '/membership_applications')
  dispatch({
    types: [null, types.STATUS_CHANGE_SUCCESS, types.STATUS_CHANGE_FAILURE],
    url: `${path}/${encodeURIComponent(email)}`,
    method: 'patch',
    data: { [status]: options },
    callback: handleGetUsers()
  })
}
export const handleDeleteUser = (email) => (dispatch) => {
  dispatch({
    types: [null, types.STATUS_CHANGE_SUCCESS, types.STATUS_CHANGE_FAILURE],
    url: `/membership_applications/${encodeURIComponent(email)}`,
    method: 'delete',
    callback: handleGetUsers()
  })
}
export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_MEMBERS_REVIEW_SUCCESS:
      return { ...state, isLoading: false, applicantList:  action.data}
    case types.GET_APPLICANTS_SUCCESS:
      return { ...state, isLoading: false, applicantList:  action.data}
    case types.GET_MEMBERS_SUCCESS:
      return { ...state, isLoading: false, memberList: action.data, searchResults: action.data }
    case types.GET_MEMBERS_VISIBLE_SUCCESS:
        return { ...state, isLoading: false, membersVisible: action.data }
    case types.GET_MEMBERS_RANK_SUCCESS:
          return { ...state, isLoading: false, membersRank: action.data }
    case types.PROFILE_SEARCH_SUCCESS:
      return { ...state, isLoading: false, searchResults: action.data}
    case types.SET_FILTERED_MEMBER_LIST_INIT:
      return { ...state, filteredMemberListInit: action.payload}
    case types.FILTER_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        filteredMemberList: state.filteredMemberListInit
          ? (action.data || [])
          : [ ...state.filteredMemberList, ...(action.data || []) ],
        isFiltering: action.data && action.data.length > 0
      }
    case types.SET_SEARCH_TEXT:
      return { ...state, isLoading: false, searchText: action.payload}
    case types.SET_HANDICAP_FILTER:
        return { ...state, isLoading: false, handicapFilter: action.payload}
    case types.SET_SELECTED_CLUB:
      return { ...state, isLoading: false, selectedClub: action.payload}
    case types.SET_LOCATION:
      return { ...state, isLoading: false, location: action.payload}
    case types.GET_PROFILE_SUCCESS:
      const profile = action.data
      if (profile) {
        profile.date_of_birth = profile.date_of_birth || ''
      }
      return { ...state, isLoading: false, memberProfile:  profile}
    case types.COVER_PHOTO_SUCCESS:
      return { ...state, isLoading: false }
    case types.GET_MEMBERS_FAILURE:
      return { ...state, isLoading: false, memberList: [], visibleMemberList: [], error: action.error }

    case types.GET_APPLICANTS_FAILURE:
    case types.STATUS_CHANGE_FAILURE:
    case types.PROFILE_SEARCH_FAILURE:
    case types.FILTER_MEMBERS_FAILURE:
    case types.GET_PROFILE_FAILURE:
    case types.COVER_PHOTO_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    case types.GET_APPLICANTS_REQUEST:
    case types.GET_MEMBERS_REQUEST:
    case types.GET_PROFILE_REQUEST:
    case types.PROFILE_SEARCH_REQUEST:  
    case types.COVER_PHOTO_REQUEST:
      return { ...state, isLoading: true }
    case types.CLEAR_EVERYTHING:
      return initialState
    default:
      return state
  }
}
