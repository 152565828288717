import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserAvatar from '../../components/userAvatar';
import { handleGetLinkAccess, handleUpdatePlayGolfAttendance } from '../../reducers/linkAccess';
import { paths } from '../../utils/paths';
import PlayGolfPost from '../dashboard/playGolfPost';


const styles = theme => ({
  logoHolder: {
    textAlign: 'center',
    margin: 'auto',
  },
  logo: {
    width: '300px'
  },
})

const LinkAccessContainer = ({classes, history, match, error, isLoading, linkDetail, handleGetLinkAccess, handleUpdatePlayGolfAttendance}) => {
  useEffect(() => {
    if (match.params.userToken && match.params.userToken.length > 20 
        && match.params.accessToken && match.params.accessToken.length > 20) {
      if (!error && !linkDetail.userDetail) {
        handleGetLinkAccess(match.params.userToken, match.params.accessToken) 
      }
    }
    else {
      history.push(paths.root)
    }
  }, [history, error, match.params, linkDetail, handleGetLinkAccess]);

  const onAccept = (post, attending) => {
    const userToken = match.params.userToken
    const accessToken = match.params.accessToken
    handleUpdatePlayGolfAttendance(post.id, attending, userToken, accessToken)
  }
  
  return (
    <div>
        <div className={classes.logoHolder}>
          <img alt="Captain's Club" className={classes.logo} src={require('../../assets/images/splash.svg')} />
          <Typography style={{maxWidth: 400, margin: 'auto'}}>
            To see or make comments regarding this invite, please <Link style={{color: '#FFF'}} to={paths.login}>login</Link>.
          </Typography>
        </div>
        {error && <div style={{width: '100%', textAlign: 'center', margin: 'auto'}}><Typography variant="h6">This event is no longer available.</Typography></div>}
        
        {!error && linkDetail && linkDetail.post && linkDetail.post.play_golf && 
          <div style={{width: '100%', maxWidth: 432, margin: 'auto', border: 'solid 1px #fff', borderRadius: 8, padding: 16}}>
            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
              <div style={{marginRight: 8}}>
                  <UserAvatar profileImage={linkDetail.post.profile_image} width={24} />
              </div>
              <Typography variant="subtitle1">
                  {linkDetail.post.first_name + ' ' + linkDetail.post.last_name}
              </Typography>
            </div> 
            <PlayGolfPost 
                post={linkDetail.post}
                memberList={linkDetail.memberList}
                userDetail={linkDetail.userDetail}
                onAccept={onAccept} />
          </div>
        }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    linkDetail: state.linkAccess.linkDetail,
    error: state.linkAccess.error,
    isLoading: state.linkAccess.isLoading,
    isLoadingPost: state.linkAccess.isLoading,
  }
}
const mapDispatch = (dispatch) => {
    return bindActionCreators({
      handleGetLinkAccess, 
      handleUpdatePlayGolfAttendance
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(withStyles(styles)(LinkAccessContainer))
