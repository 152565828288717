import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GuestFormDialog from '../components/guestFormDialog';
import { handleProfileSearch } from '../reducers/members';
import { isEmptyOrNotString } from '../utils/common';
import AutoSelect from './auto_select';
import { handleGetLists } from '../reducers/lists';

const MemberSelector = ({
  excludeEmails,
  isLoading,
  members,
  guestList,
  groupMembers,
  guestListEvent,
  placeholderText,
  textFieldLabel,
  listChangeEvent,
  handleProfileSearch,
  menuPortalTarget,
  isSingle,
  visibles,
  lists,
  handleGetLists,
  isGroup = false
}) => {
  const [openForm, setOpenForm] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState();
  const [currentMembers, setCurrentMembers] = React.useState();

  React.useEffect(() => {
    handleGetLists();
  }, [handleGetLists]);

  React.useEffect(() => {
    setCurrentMembers(members)
  }, [members])

  const formatMembers = (memberList) => {
    if (!memberList || memberList.length === 0) {
      return [];
    }
    if (!Array.isArray(memberList)) {
      memberList = [memberList];
    }
    const list = [];
    if (guestListEvent) {
      list.push({ value: 'guests', label: 'Invite a Guest', logo: null });
    }
    
    if(isGroup && lists && lists.length > 0){
      lists.forEach(l => {
        list.push({
          value: l.name + ' list',
          label: l.name + ' list',
          type: 'list',
          id: l.id
        })
        return true
      })
    }

    return list.concat(
      memberList
        .filter(
          (x) =>
            x.email &&
            x.role !== 'admin' &&
            x.role !== 'banned' &&
            !excludeEmails.includes(x.email)
        )
        .map((option) => ({
          value: option.value || option.email,
          label: option.label || option.first_name + ' ' + option.last_name,
          logo: option.profile_image || '',
          email: option.email || '',
          first_name: option.first_name || '',
          last_name: option.last_name || '',
          club_name:
            option.club_name ||
            (option.club_memberships && option.club_memberships.length
              ? option.club_memberships[0].name
              : ''),
          role: 'member',
        }))
    );
  };
  
  const formatForAutoSelect = (data) => {
    if (!data || data.length === 0) {
      return [];
    }
    if (!Array.isArray(data)) {
      data = [data];
    }
    return data.map((item) => ({
      value: item.value || item,
      label: item.label || item,
      logo: item.logo,
      email: item.email || '',
      first_name: item.first_name || '',
      last_name: item.last_name || '',
      club_name:
        item.club_name ||
        (item.club_memberships && item.club_memberships.length
          ? item.club_memberships[0].name
          : ''),
      role: item.role,
    }));
  };
  const getSelectedValues = (memberList, guestList) => {
    const formattedList = formatForAutoSelect(memberList);
    const guests = formatForAutoSelect(guestList);
    const ids = new Set(guests.map((d) => d.value));
    const filteredList = [
      ...guests,
      ...formattedList.filter((d) => !ids.has(d.value)),
    ];
    return filteredList;
  };
  const handleTextChange = (name) => (value) => {
    if(isEmptyOrNotString(value)){
      setCurrentMembers(visibles)
      return
    }
    if (value.target || isLoading) {
      return;
    }
    if (value !== searchValue) {
      setSearchValue(value);
      handleProfileSearch(value);
    }
  };
  const handleAutoSelectChange = (name) => (list) => {
    const listItem = list && list.length > 0 ? list?.filter(l => l.type === 'list')[0] : null
    
    if(listItem){
      const selectedList = lists.filter(l => l.id === listItem.id)[0]
      const selectedListMembersFormatted = []
      selectedList
        .members
        .map(m => visibles.filter(v => v.email === m.member_email)[0])
        .map(m => {
          if(list.filter(l => l.email === m.email).length === 0){
            selectedListMembersFormatted.push({
              value: m.email,
              label: m.first_name + ' ' + m.last_name,
              logo: m.profile_image || '',
              email: m.email || '',
              first_name: m.first_name || '',
              last_name: m.last_name || '',
              club_name:
                m.club_name ||
                (m.club_memberships && m.club_memberships.length
                  ? m.club_memberships[0].name
                  : ''),
              role: 'member',
            })
          }
          return true
        })
      listChangeEvent([...list.filter(l => !l.type || l.type !== 'list'), ...selectedListMembersFormatted])
    }else{
      const selection =
      Array.isArray(list) && list.length > 0 ? list[list.length - 1] : list;
      if (selection && selection.value === 'guests') {
        setOpenForm(true);
        return;
      }
      if (Array.isArray(list)) {
        const mems = (list || []).filter((x) => x.role === 'member');
        const gsts = (list || []).filter((y) => y.role === 'guest');
        listChangeEvent(mems);
        guestListEvent(gsts);
      } else {
        listChangeEvent(list);
      }
    }
  };
  const handleAddGuest = (guest) => {
    guest.value = guest.email;
    guest.label = guest.first_name + ' ' + guest.last_name;
    guest.role = 'guest';
    const newList = [...guestList, guest];
    guestListEvent(newList);
  };
  return (
    <React.Fragment>
      <GuestFormDialog
        open={openForm}
        toggleDialog={() => setOpenForm(false)}
        handleConfirm={handleAddGuest}
      />
      <AutoSelect
        isMulti={!isSingle}
        name='members'
        menuPortalTarget={menuPortalTarget}
        textFieldProps={{
          label: textFieldLabel || 'Type Member name here to search',
        }}
        placeholder={placeholderText || ''}
        options={formatMembers(currentMembers)}
        selectedValue={getSelectedValues(groupMembers, guestList)}
        handleAutoSelectTextChange={handleTextChange}
        handleAutoSelectChange={handleAutoSelectChange}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    members: state.members.searchResults,
    isLoading: state.members.isLoading,
    visibles: state.members.memberList,
    lists: state.lists.lists
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      handleProfileSearch,
      handleGetLists
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatch)(MemberSelector);
