import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddressForm from '../../components/addressForm';
import { parser } from '../../utils/parser';
import { resetIsInvalidNewEmail } from '../../reducers/user';
import { closeModalEditProfile } from '../../utils/users';

const useStyles = makeStyles(theme => ({
    textField: {
        margin: 0,
        marginBottom: theme.spacing(2)
    },
    textFieldEnd: {
        margin: 0,
        marginBottom: 0
    },
    button: {
        fontSize: '13px'
    },
}));

function EditProfileDialog ({open, toggleDialog, handleConfirmation, profile, isInvalidNewEmail, isLoading, isUpdatedSuccessFull, session, resetIsInvalidNewEmail}) {
    const classes = useStyles()
    const [bio, setBio] = React.useState({})
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [currentEmail, setCurrentEmail] = useState()

    const initBio = (profile) => {
        let temp = {...profile}
        if(profile.preferred_email){
            setCurrentEmail(profile.preferred_email)
            setBio({...temp, preferred_email: profile.preferred_email})
        }else{
            setCurrentEmail(profile.email)
            setBio({...temp, preferred_email: profile.email})
        }        
    }
    
    React.useEffect(() => {
        initBio(profile)
    }, [profile]);

    useEffect(() => {
        if(isUpdatedSuccessFull){
            toggleDialog(false)
        }
    }, [isUpdatedSuccessFull, session.role, toggleDialog])

    const handleChangeEmail = (event) => {        
        const newEmail = event.target.value.replace(/\s+/g,'')        
        if(currentEmail !== newEmail){
            setCurrentEmail(newEmail)
            setBio({ ...bio, preferred_email:  newEmail})
        }
    }

    const handleConfirm = () => {
        bio.social_media_links = formatSocialLinks(bio.social_media_links)
        if (bio.address && bio.address.state && bio.address.state.label) {
            bio.address.state = bio.address.state.value
        }
        handleConfirmation({...bio})        
    }
    const updateAddress = (address) => {
        let temp = {...bio}
        temp.address = {...address}
        setBio(temp)
    }
    const handleChange = (event) => {
        let temp = {...bio}
        let oldValue = temp[event.target.name]
        let newValue = event.target.value
        let value = Array.isArray(oldValue) && !Array.isArray(newValue) ? [newValue] : newValue
        temp[event.target.name] = (value || null)
        setBio(temp)
    }
    const handleHandicapChange = (event) => {
        if (event.target.value && !parser.isValidHandicap(event.target.value)) { return }
        handleChange({target: {name: event.target.name, value: event.target.value}})
    }
    const handleAddAboutMe = (chip) => {
        let temp = {...bio}
        let oldArray = (!temp.about_me || Array.isArray(temp.about_me)) 
                          ? (temp.about_me || [])
                          : temp.about_me.split(',') 
        if (oldArray.length >= 5) { return }
        if (!oldArray.length) {
          oldArray = [chip]
        } else { oldArray.push(chip) }
        temp.about_me = oldArray.join()
        setBio(temp)
    }
    const handleDeleteAboutMe = (chip, index) => {
        let temp = {...bio}
        let oldArray = temp.about_me
        let newArray = oldArray.split(',')
        newArray.splice(index, 1)
        temp.about_me = newArray.join()
        setBio(temp)
    }
    const formatSocialLinks = (links) => {
        if (links) {
          if (!Array.isArray(links)) {
            links = [links]
          }
          return links.map((item) => {
            if (!item) { return 'http://www.linkedin.com/' }
            if (!item.startsWith('http')) {
              return 'http://' + item
            } else { return item }
          })
        }
        return null
    }
    return (
        <Dialog
            open={open}
            onClose={() => closeModalEditProfile(initBio, toggleDialog, resetIsInvalidNewEmail, profile)}
            fullScreen={fullScreen}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">Edit Profile</DialogTitle>
            <DialogContent style={{overflowX: 'hidden'}}>
                <TextField
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    fullWidth
                    margin="none"
                    variant="outlined"
                    value={bio.first_name || ''}
                    onChange={handleChange}
                    className={classes.textField} />                
                <TextField
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    fullWidth
                    margin="none"
                    variant="outlined"
                    value={bio.last_name || ''}
                    onChange={handleChange}
                    className={classes.textField} />
                <FormControl className={classes.textField} style={{marginTop: 0}}>
                    <AddressForm address={bio.address || {}} updateAddress={updateAddress} />
                </FormControl>
                <TextField 
                    id="handicap"
                    name="handicap"
                    label="Handicap"
                    fullWidth
                    value={bio.handicap !== null ? bio.handicap : ''}
                    onChange={handleHandicapChange}
                    margin="normal"
                    variant="outlined"
                    className={classes.textField} />
                <TextField
                    id="preferred_email"
                    name="preferred_email"
                    label="Email"
                    fullWidth
                    margin="none"
                    variant="outlined"
                    value={currentEmail}
                    onChange={handleChangeEmail}
                    className={classes.textFieldEnd} />
                <div style={{ height: 40 }}>
                    <span style={{ color: 'red'}}>{ isInvalidNewEmail }</span>
                </div>
                <ChipInput
                    value={bio.about_me ? bio.about_me.split(',') : []}
                    label="5 words to describe me"
                    InputLabelProps={{shrink: true}}
                    newChipKeyCodes={[9, 13, 32, 188]}
                    fullWidth
                    margin="none"
                    placeholder='Example: "Entrepreneur, Father, Lawyer, Golfer, Surfer"'
                    variant="outlined"
                    onAdd={(chip) => handleAddAboutMe(chip)}
                    onDelete={(chip, index) => handleDeleteAboutMe(chip, index)}
                    />
                
                {profile.username && <div style={{marginTop: '16px'}}>
                    <TextField
                        id="referral"
                        name="referral"
                        label="Referral Link"
                        fullWidth
                        margin="none"
                        variant="outlined"
                        style={{backgroundColor: theme.palette.lightGray?.main}}
                        value={'https://app.captains.club/' + profile.username}
                        onChange={() => {}}
                        className={classes.textField} />
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} variant="contained" disabled={ isLoading ? true : false }>
                    Save
                </Button>
                <Button onClick={() => closeModalEditProfile(initBio, toggleDialog, resetIsInvalidNewEmail, profile)} variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
      isInvalidNewEmail: state.user.isInvalidNewEmail,
      isLoading: state.user.isLoading,
      isUpdatedSuccessFull: state.user.isUpdatedSuccessFull,
      session: state.auth.session,
    }
  }

const mapDispatch = (dispatch) => {
    return bindActionCreators({
        resetIsInvalidNewEmail
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatch)(EditProfileDialog)
