import { Typography } from '@material-ui/core/';
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import AudienceList from '../../components/audienceList';
import AutoSelect from '../../components/auto_select';
import MemberSelector from '../../components/memberSelector';
import { RESIDENTS } from '../../utils/constants';
import { parser } from '../../utils/parser';
import { getAudienceFilteredList } from '../../utils/posts';
import { handleGetLists } from '../../reducers/lists';
import ListsDialog from '../../pages/members/listDialog'
import { formatListOptions } from '../../utils/lists';


const styles = (theme) => ({
  root: {
    marginTop: 4,
    marginBottom: 8,
  },
});

const AudienceSelector = ({
  attendees,
  groups,
  membersVisible,
  userDetail,
  labelText,
  guestList,
  guestListEvent,
  courceState,
  handleSelectedMembers,
  classes,
  lists,
  handleGetLists
}) => {
  // const [membersList, setMembersList] = React.useState([])
  const [optionsList, setOptionsList] = React.useState([]);
  const [individualSelection, setIndividualSelection] = React.useState(false);
  const [showLists, setShowLists] = React.useState(false);

  const visibleMemberList = (membersVisible || []).map((x) => x.email);
  React.useEffect(() => {
    handleGetLists();
  }, [handleGetLists]);

  React.useEffect(() => {
    let topLabel = 'Individual Members';
    if (guestListEvent) {
      topLabel = topLabel + ' and/or Guests';
    }
    let options = [
      { value: 'individuals', label: topLabel, type: 'individuals' },
    ];

    options = options.concat({
      value: 'create-list',
      label: `Create New List`,
      type: 'create-list',
    })

    if (courceState) {
      options = options.concat({
        value: courceState,
        label: `Members living in ${courceState}`,
        type: RESIDENTS,
      })
    }
    options = options.concat(
      groups.map((item) => {
        return {
          value: item.url,
          label: 'Group Members of ' + item.name,
          type: 'group',
        };
      })
    );
    if (userDetail && userDetail.club_memberships) {
      options = options.concat(
        userDetail.club_memberships.map((item) => {
          return {
            value: item.name,
            label: 'Club Members of ' + item.name,
            type: 'club',
          };
        })
      );
    }

    formatListOptions(lists, options)

    // options = options.concat([{
    //     value: 'all',
    //     label: 'Select All Members',
    //     type: 'all'
    // }])
    setOptionsList(options);
  }, [groups, userDetail, guestListEvent, courceState, lists]);
  const handleOptionSelected = (name) => (option) => {
    if (!option || !option.type) {
      return;
    }
    let members = [];
    setIndividualSelection(false);
    switch (option.type) {
      case 'group':
        const foundGroup = groups.find((x) => x.url === option.value);
        foundGroup &&
          foundGroup.group_members
            .filter(
              (x) =>
                x.username !== userDetail.username &&
                visibleMemberList.includes(x.member_email)
            )
            .map((member) => {
              if(members.filter(m => m.value === member.member_email).length === 0){
                members.push({
                  value: member.member_email,
                  label: member.first_name + ' ' + member.last_name,
                  logo: member.profile_image,
                  // club_name:
                  //   member.club_name ||
                  //   (member.club_memberships && member.club_memberships.length
                  //     ? member.club_memberships[0].name
                  //     : ''),
                })
              }
              return true;
            });
        break;
      case 'club':
        const club = userDetail.club_memberships.find(
          (x) => x.id === option.value
        );
        club &&
          club.club_members
            .filter(
              (x) =>
                x.username !== userDetail.username &&
                visibleMemberList.includes(x.email)
            )
            .map((member) => {
              if(members.filter(m => m.value === member.email).length === 0){
                members.push({
                  value: member.email,
                  label: member.first_name + ' ' + member.last_name,
                  logo: member.profile_image,
                  // club_name: club.name,
                })
              }
              return true;
            });
        break;
      case RESIDENTS:
        const courceStateValule = parser
          .getStateList()
          .find(item => item.label === courceState)?.value || '';
        
        if (courceStateValule) {
          membersVisible
            .filter(member => member.address?.state === courceStateValule)
            .map((member) => {
              if(members.filter(m => m.value === member.email).length === 0){
                members.push({
                  value: member.email,
                  label: `${member.first_name} ${member.last_name} (${member.address.city}, ${member.address.state})`,
                  logo: member.profile_image,
                });
              }
              return true
            })
        }

        break;

      case 'list':
        if(lists && lists.length > 0){
          const list = lists.filter(l => l.id === parseInt(option.value))[0]
          if(list && list.members && list.members.length > 0){
            list.members.map(m => {
              //find user in the member lists visible
              const member = membersVisible.filter(mb => mb.email === m.member_email)[0]

              if(member){
                members.push({
                  value: member.email,
                  label: `${member.first_name} ${member.last_name}`,
                  logo: member.profile_image,
                });
              }

              return true
            })
          }
        }
        break;      
      case 'create-list':
        setShowLists(true)
        break;

      // case 'all':
      //   memberList.map(member => {
      //     if (member.username !== userDetail.username) {
      //       members.push({
      //         value: member.email,
      //         label: member.first_name + ' ' + member.last_name,
      //         logo: member.profile_image
      //       })
      //     }
      //     return true
      //   })
      //   setIndividualSelection(false)
      //   break
      default:
        setIndividualSelection(true);
        break;
    }
    const ids = new Set(attendees.map((d) => d.value));
    const filteredList = [
      ...attendees,
      ...members.filter((d) => !ids.has(d.value)),
    ];
    handleSelectedMembers(filteredList);
  };
  const removeAttendee = (email) => {
    const attendee = attendees.find((x) => x.value === email);
    if (attendee) {
      const newList = attendees.filter((x) => x.value !== email);
      handleSelectedMembers(newList);
    } else {
      const guests = (guestList || []).filter((x) => x.value !== email);
      guestListEvent(guests);
    }
  };
  const handleListChangeEvent = (item) => {
    handleSelectedMembers(getAudienceFilteredList(attendees, item));
  };
  return (
    <div className={classes.root}>
      <ListsDialog
        open={showLists}
        toggleDialog={() => setShowLists(false)} />
      <div style={{ width: '100%', textAlign: 'center', margin: 'auto' }}>
        <AutoSelect
          textFieldProps={{
            label: labelText || 'Who do you want to invite?',
            InputLabelProps: {
              shrink: true,
            },
          }}
          options={optionsList}
          selectedValue={''}
          // handleAutoSelectTextChange={searchItems}
          handleAutoSelectChange={handleOptionSelected}
        />
      </div>
      {individualSelection && (
        <div style={{ paddingTop: 16 }}>
          <MemberSelector
            isSingle
            guestList={[]}
            guestListEvent={guestListEvent}
            groupMembers={[]}
            listChangeEvent={handleListChangeEvent}
            excludeEmails={[userDetail.email].concat(
              attendees.map((x) => x.value)
            )}
          />
        </div>
      )}
      {(attendees || []).concat(guestList || []).length > 0 && (
        <React.Fragment>
          <Typography variant='subtitle2' style={{ padding: 8 }}>
            Post will only be visible to:
          </Typography>
          <AudienceList
            list={(attendees || []).concat(guestList || [])}
            handleRemove={removeAttendee}
          />
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    membersVisible: state.members.membersVisible,
    groups: state.groups.groups,
    lists: state.lists.lists
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetLists
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(withStyles(styles)(AudienceSelector));
