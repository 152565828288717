import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleGuestApplication } from '../../reducers/guests';

const useStyles = makeStyles(theme => ({
  root:{
    margin: 'auto',
    padding: 16,
    borderRadius: 8,
    maxWidth: 1300,
  },
  message: {
    color: theme.palette.profile.dark,
    fontSize: 18
  }
}))

const requiredFields = [
  {id: 'bio', label: 'Bio field is required'},
  {id: 'best_gross_round', label: 'Best Round is required'},
  {id: 'date_of_birth', label: 'Birthday is required'},
  {id: 'cell_number', label: 'Phone is required'},
  {id: 'address', label: 'Your location (city, state) is required'},
  {id: 'profile_image', label: 'Profile picture is required'},
  {id: 'club_memberships', label: 'Club Membership is required'},
  {id: 'about_me', label: '5 words to describe yourself is required'}
]
const ProfileGuestMessage = ({profile, handleGuestApplication}) => {
  const classes = useStyles();
  const [ errors, setErrors] = React.useState([])
  const [ success, setSuccess ] = React.useState(false)
  React.useEffect(() => {
      setErrors(e => e.filter(x => !profile[x.id]))
  }, [profile]);
  const handleFinish = () => {
    const newProfile = { ...profile }
    const newErrors = []
    requiredFields.forEach(item => {
      if (!newProfile[item.id]) {
        newErrors.push(item)
      }
    })
    if (newErrors.length > 0) { return setErrors(newErrors) }
    // updateUserDetail(newProfile)
    handleGuestApplication(newProfile.email)
    setSuccess(true)
  }
  return (
    <div style={{padding: 16, backgroundColor: '#000'}}>
      <Paper elevation={0} className={classes.root}>
        <Typography className={classes.message} variant="subtitle2">
          {success || profile.guest_applied ? 'Your application will be reviewed by the membership committee.  We will follow up with you via email once it has been reviewed.' :
          'Please complete your Guest Profile.  Other members will be able to view your profile from group pages and golf invites. If you would like to become a Captain’s Club Member, once your profile is completed, you can apply.'
          }
        </Typography>
        <div style={{paddingTop: 24}}>
          <Button disabled={success || profile.guest_applied} onClick={() => handleFinish()} variant="contained">{success || profile.guest_applied ? 'Application Pending' : 'Submit Application'}</Button>
          <ul>
          {errors && errors.map(item => (
            <li key={item.id}><Typography color='error'>{item.label}</Typography></li>
          ))}
          </ul>
        </div>
      </Paper>
    </div>
  )
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGuestApplication
  }, dispatch)
}
const mapState = (state) => {
  return {
  }
}

export default connect(mapState, mapDispatch)(ProfileGuestMessage)
