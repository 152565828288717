import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleGetPosts } from '../../reducers/posts';
import PostItem from './postItem';
import PostDialog from './postDialog';
import WidgetLoading from '../../components/widgetLoading';
import { Button, Typography } from '@material-ui/core';

const PostContainer = ({ posts, memberList, handleGetPosts }) => {
  const [showDialog, setShowDialog] = React.useState(false)
  React.useEffect(() => {
    handleGetPosts()
  }, [handleGetPosts]);
  return (
    <div>
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h5'>Admin Posts</Typography>
        <div style={{display: 'flex'}}>
          <Button onClick={() => setShowDialog(true)} variant="contained">+ Create Post</Button>
        </div>
      </div>
      <PostDialog
        open={showDialog}
        toggleDialog={setShowDialog}
      /> 
      {!memberList || !posts ? <WidgetLoading /> : posts.map((item, idx) => {
        return <div key={idx}>
          <PostItem post={item} />
        </div>
      })}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    posts: state.posts.posts.filter(x => x.is_admin),
    memberList: state.members.memberList
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetPosts,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(PostContainer)
