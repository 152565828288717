import { Button, Grid, Hidden, IconButton, Link, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import cls from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import shave from 'shave'
import AutoSelect from '../../components/auto_select'
import CodeOfConductLink from '../../components/codeOfConductLink'
import PaperMasterHoc from '../../components/paperMasterHoc'
import TaggableBodyText from '../../components/taggableBodyText'
import TaggableInput from '../../components/taggableInput'
import UserAvatar from '../../components/userAvatar'
import { handleProfileSearch } from '../../reducers/members'
import { handleCreateNewChannel, handleGetChannelDetail, handleGetMessageChannels, handleHideMessageChannels, handleSendNewMessage, handleSetSelectedChannel, handleSetSelectedMembers } from '../../reducers/notifications'
import { parser } from '../../utils/parser'
import { handleGetLists } from '../../reducers/lists';
import { formatListOptions } from '../../utils/lists'
import { paths } from '../../utils/paths'

const useStyles = makeStyles(theme => ({
  gridItem: {
    position: 'relative',
    padding: '0px 8px',
  },
  rightSide: {
    borderLeft: 'solid 1px #ccc',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      borderLeft: 0
    },
  },
  avatarBox: {
    marginBottom: 0,
    marginRight: 16,
    display: 'block',
    alignSelf: 'start',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  inputBox: {
    width: '100%',
    alignSelf: 'center',
    padding: '8px 20px 0',
    backgroundColor: theme.palette.primary.light,
    border: 'solid 1px #ccc',
    // borderColor: theme.palette.primary.main,
    borderRadius: 32
  },
  channelBox: {
    display: 'flex',
    cursor: 'pointer',
    padding: '12px 8px',
    borderBottom: 'solid 1px #ccc',
    '& .showOnHover': {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      '& .showOnHover': {
        display: 'block'
      }
    },
    '&.selected': {
      borderLeft: `solid 2px ${theme.palette.primary.main}`,
      backgroundColor: 'rgb(236, 242, 251)',
    },
    '&.pending': {
      borderRight: `solid 4px ${theme.palette.secondary.main}`,
    }
  },
  channelItem: {
    width: '100%',
    padding: '0 4px',
    alignSelf: 'center',
  },
  chatItem: {
    width: '100%',
    alignSelf: 'center',    
    flexGrow: 1,
  },
  actionButtons: {
    marginTop: 12,
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  messagesContainer: {
    height: 'calc(100vh - 80px)', 
    minHeight: 300, 
    display: 'flex', 
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    }
  },
  messagesBox: {
    minHeight: 16, 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'flex-start', 
    justifyContent: 'flex-start', 
    overflowY: 'auto',
  },
  grow: {
    flexGrow: 1
  }
}))

const MessagingHeader = ({title, icon, handleClick}) => {
  return (
    <div style={{borderBottom: 'solid 1px #ccc', padding: '18px 0', margin: '0', }}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography>
        {icon && <div style={{position: 'absolute', right: 0}}>
          <IconButton onClick={handleClick}>{icon}</IconButton>
        </div>}
      </div>
    </div>
  )
}
const MessagingContainer = ({
    userDetail,
    members,
    channels,
    channelDetail,
    messageIndicator,
    initialChatMessage,
    selectedMembers,
    selectedChannel,
    createNewChannel,
    getMessageChannels,
    hideMessageChannels,
    getChannelDetail,
    sendNewMessage,
    setSelectedMembers,
    setSelectedChannel,
    lists,
    handleGetLists,
    membersVisible,
    history
  }) => {
  
  const classes = useStyles();
  
  const [ message, setMessage ] = React.useState()
  const [ messageMembers, setMessageMembers ] = React.useState([])
  const [ showChats, setShowChats ] = React.useState(false)
  
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const messagesEnd = React.useRef(null)
  const scrollDown = () => { messagesEnd.current.scrollIntoView({ behavior: 'smooth' }) }

  React.useEffect(() => {
    handleGetLists();
  }, [handleGetLists]);

  React.useEffect(() => {
    return () => {
      setSelectedChannel()
    }
  }, [setSelectedChannel]);
  React.useEffect(() => {
    if (userDetail && userDetail.email && selectedMembers.length > 0) {
      setMessageMembers(selectedMembers)
      if (initialChatMessage) {
        setMessage(initialChatMessage)
      }
      let foundChannel
      for (let channel of channels) {
        const channelMembers = channel.channel_members.map(m => m.member_email).filter(x => x !== userDetail.email)
        if (selectedMembers.length === channelMembers.length && selectedMembers.map(x => x.value).sort().every((item, idx) => { return item === channelMembers.sort()[idx] })) {
          foundChannel = channel
          break
        }
      }
      setSelectedChannel()
      setSelectedMembers([])
      if (foundChannel) {
        getChannelDetail(userDetail.email, foundChannel)
      }
    }
  }, [selectedMembers, setSelectedMembers, setSelectedChannel, initialChatMessage, userDetail, channels, getChannelDetail]);
  React.useEffect(() => {
    shave('.message-text', 40)
    // shave('.message-members', 40)
  });
  React.useEffect(() => {
    if (selectedChannel && selectedChannel.id && !selectedChannel.channel_owner) {
      const channelList = channels.filter(x => x.id === selectedChannel.id)
      if (channelList && channelList.length > 0) {        
        setSelectedChannel(userDetail.email, channelList[0])
      }
    }
  }, [selectedChannel, channels, setSelectedChannel, userDetail, getChannelDetail]);
  React.useEffect(() => {
    scrollDown()
  }, [messagesEnd, channelDetail, messageIndicator]);

  const handleChannelClick = (channel) => {
    setSelectedChannel(userDetail.email, channel)
    setMessage('')
    if (fullScreen) { setShowChats(true) }
  } 
  const formatMembers = () => {
    const formattedMembers = []
    if (!members || members.length === 0) { return [] }

    formatListOptions(lists, formattedMembers)

    members.filter(x => x.email && x.email !== userDetail.email && x.role !== 'banned').map(option => {
      formattedMembers.push({
        value: option.value || option.email,
        label: option.label || (option.first_name + ' ' + option.last_name + ' (@' + option.username + ')'),
        logo: option.profile_image || '',
        type: 'member'
      })
      return true
    })

    return formattedMembers
  }

  const getListMembers = (data) => {
    const selectedList = data.filter(d => d.type === 'list')[0]
    const members = []
    
    if(selectedList){
      if(lists && lists.length > 0){
        const list = lists.filter(l => l.id === parseInt(selectedList.value))[0]
        if(list && list.members && list.members.length > 0){
          list.members.forEach(m => {
            //find user in the member lists visible
            const member = membersVisible.filter(mb => mb.email === m.member_email)[0]
            if(member && messageMembers.filter(m => m.value === member.email).length === 0){
              const newMember = {
                value: member.email,
                label: `${member.first_name} ${member.last_name}`,
                logo: member.profile_image,
              }
              members.push(newMember);
            }

            return true
          })
        }
      }
    }

    return members;
  }
    
  const formatForAutoSelect = (data) => {
    if (!data || data.length === 0) { return [] }

    let members = []

    const selectedListMembers = getListMembers(data)

    if(selectedListMembers && selectedListMembers.length > 0){
      members = selectedListMembers
    }
    data.forEach((item) =>{
      if(item.type !== 'list'){
        members.push({
          value: item.value || item,
          label: item.label || item,
          logo: item.logo
        })
      }
      return true
    });
    return members
  }
  const handleTextChange = name => value => {
    handleProfileSearch(value)
  }
  const handleAutoSelectChange = name => list => {
    setMessageMembers(list)
    if (list && list.length > 0) {
      const channel = matchChannel(channels, list)
      if (channel) {
        getChannelDetail(userDetail.email, channel)
        return
      }
      getChannelDetail()
    } else {
      resetMessagePage()
    }
  }
  const matchChannel = (channelList, newList) => {
    for (let channel of channelList) {
      const channelMembers = channel.channel_members.map(m => m.member_email).filter(x => x !== userDetail.email)
      const selectedListMembers = getListMembers(newList)
      if (selectedListMembers.length === channelMembers.length && 
        selectedListMembers.map(x => x.value).sort().every((item, idx) => { return item === channelMembers.sort()[idx] })) {
        return channel
      }
    }
    return null
  }
  const submitMessage = () => {
    if (!message || message.trim() === '') { return }
    if (!channelDetail || !channelDetail.messages || channelDetail.messages.length === 0) {
      if (!messageMembers || messageMembers.length === 0) { return }

      let membersFiltered = []
      if(messageMembers.filter(m => m.type === 'list').length > 0){
        const selectedListMembers = getListMembers(messageMembers)
        membersFiltered = [...messageMembers.filter(m => m.type !== 'list'), ...selectedListMembers]
      }
      
      createNewChannel(userDetail.email,
        membersFiltered.length > 0 ? membersFiltered.map(x => x.value) : messageMembers.map(x => x.value),
        message)
    } else {
      sendNewMessage(channelDetail.id, message)
      if (!selectedChannel) {
        const channelList = channels.filter(x => x.id === channelDetail.id)
        if (channelList.length > 0) { handleChannelClick(channelList[0]) }
      }
    }
    setMessage('')
  }
  const resetMessagePage = () => {
    setMessageMembers([])
    setMessage('')
    setShowChats(false)
    setSelectedChannel()
    getMessageChannels(userDetail.email)
    getChannelDetail()
  }
  const getChannelName = (channel) => {
    if (!channel || !channel.channel_members) { return 'Message' }
    // if (channel.name && channel.name.length > 0) { return channel.name }
    const participants = channel.channel_members.filter(user => user.username !== userDetail.username)
    if (!participants || participants.length === 0) { return 'Message' }
    const images = participants.map((x, idx) => {
      return <div key={idx} style={{marginLeft: (idx > 0 ? '-16px' : 0), zIndex: (idx + 1)}} >
        <UserAvatar profileImage={x.profile_image} width={36} />
        </div>
    })
    const names = participants.map(x => {
      return x.first_name ? x.first_name + ' ' + x.last_name : '@' + x.username
    })
    return (<div style={{display: 'flex', flexWrap: 'wrap'}}>
        <div style={{display: 'flex', alignItems: 'center', marginRight: 8}}>{images}</div>
        <div style={{display: 'flex', alignItems: 'center'}}>{names.join(', ')}</div>
      </div>
    )
  }
  const cleanUpTags = (text) => {
    return text.replace(/@{{/g, '@').replace(/}}/g, '')
  }
  const handleDeleteChannel = (item) => {
    if (window.confirm('Would you like to remove this conversation?')) {
      hideMessageChannels(userDetail.email, item)
      if (selectedChannel && selectedChannel.id === item.id) {  resetMessagePage() }
    }
  }
  return (
  <PaperMasterHoc>
    <Grid container style={{height: '100%'}}>
      <Grid hidden={fullScreen && showChats} item xs={12} sm={4} md={3} style={{position: 'relative', height: '100%', overflow: 'auto'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <MessagingHeader title="Messages" icon={<AddIcon />} handleClick={() => {resetMessagePage(); setShowChats(true)}} />
          {channels.map((item, idx) => {
            return (
              <div key={idx} onClick={(evt) => {if (evt.target.id !== 'close') { handleChannelClick(item)}}} className={cls(classes.channelBox, {'pending': messageIndicator && messageIndicator.includes(item.id)}, {'selected': selectedChannel && selectedChannel.id === item.id})}>
                <div className={classes.channelItem}>
                  <div className='message-members' style={{fontWeight: 500}}>{getChannelName(item)}</div>
                  <div className='message-text' style={{margin: 4}}>{item.last_message ? cleanUpTags(item.last_message.message) : ''}</div>
                </div>
                <div style={{fontSize: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', textAlign: 'center'}}>
                  {item.last_message ? parser.shortDate(item.last_message.issued_at, 'MM/dd') : ''}
                  <IconButton style={{padding: 4}} onClick={(evt) => {evt.stopPropagation(); handleDeleteChannel(item)}}><CloseIcon style={{height: 18, width: 18}} id="close" /></IconButton>
                </div>
              </div>
            )
          })}
        </div>
      </Grid>
      <Grid item hidden={fullScreen && !showChats} xs={12} sm={8} md={9} className={cls(classes.gridItem, classes.rightSide)}>
        <div className={classes.messagesContainer}>
            {!selectedChannel
              ? <div style={{borderBottom: 'solid 1px #ccc', padding: '8px 0', margin: '0', }}>
                  <AutoSelect
                    isMulti
                    name='messageMembers'
                    placeholder='Select members to chat'
                    options={formatMembers(members)}
                    selectedValue={formatForAutoSelect(messageMembers)}
                    handleAutoSelectTextChange={handleTextChange}
                    handleAutoSelectChange={handleAutoSelectChange} />
                  </div>
              : <MessagingHeader title={getChannelName(selectedChannel)} /> 
              // ${selectedChannel.name}
            }
          <div className={classes.messagesBox}>
            <div style={{ padding: '5px 20px' }}>
              <p>Please make sure your message complies with the <CodeOfConductLink /> and you are not soliciting access to a specific club.</p>
            </div>
            {channelDetail && channelDetail.messages && channelDetail.messages.map((item, idx) => {
              return (<div key={idx} style={{display: 'flex', width: '100%', padding: '16px 4px', borderBottom: 'solid 1px #ccc',}}>
                <div className={classes.avatarBox}>
                  <Link style={{cursor: 'pointer'}} onClick={() => history.push(`${paths.profile}/` + item.sender_username)}>
                    <UserAvatar profileImage={item.sender_profile_image} />
                  </Link>
                </div>
                <div className={classes.chatItem}>
                  <div style={{fontWeight: 'bold'}}>
                    <Link style={{cursor: 'pointer', color: '#2783D4'}} onClick={() => history.push(`${paths.profile}/` + item.sender_username)}>
                        {item.sender_first_name ? item.sender_first_name + ' ' + item.sender_last_name : `@${item.sender_username}`}
                    </Link>
                  </div>
                  <div style={{whiteSpace: 'pre-wrap'}}><TaggableBodyText text={item.message} /></div>
                </div>
                <div style={{fontSize: 10, whiteSpace: 'nowrap', alignSelf: 'flex-end'}}>
                  {parser.dateDistance(item.issued_at)}
                </div>
              </div>)
            })}
            <div style={{float: 'left', clear: 'both'}} ref={messagesEnd}></div>
          </div>
          {(selectedChannel || messageMembers.length > 0) ? <div style={{padding: '8px 0'}}>
            <div style={{display: 'flex'}}>
              <div className={classes.avatarBox}>
                <UserAvatar profileImage={userDetail.profile_image} />
              </div>
              <div className={classes.inputBox}>
                <TaggableInput
                  value={message}
                  placement={channelDetail && channelDetail.messages && channelDetail.messages.length > 0 ? 'bottom' : ''}
                  onChange={(evt, value) => setMessage(value)}
                  placeholder={"Add your message. Mention people using '@'"}
                />
              </div>
            </div>
            <div className={classes.actionButtons}>
              <Button onClick={() => submitMessage()} variant="contained" style={{marginRight: 8}}>Send</Button>
              <Button onClick={() => resetMessagePage()} variant="outlined">Close</Button>
            </div>
          </div> : <Hidden smUp>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button onClick={() => resetMessagePage()} variant="contained">Close</Button>
            </div>
          </Hidden>
          }
        </div>
      </Grid>
    </Grid>
  </PaperMasterHoc>
  )
}
const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    channels: state.notifications.channels,
    channelDetail: state.notifications.channelDetail,
    selectedMembers: state.notifications.selectedMembers,
    selectedChannel: state.notifications.selectedChannel,
    messageIndicator: state.notifications.messageIndicator,
    initialChatMessage: state.notifications.initialChatMessage,
    members: state.members.searchResults.filter(x => x.role === 'member'),
    lists: state.lists.lists,
    membersVisible: state.members.membersVisible,
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    createNewChannel: handleCreateNewChannel,
    getMessageChannels: handleGetMessageChannels,
    hideMessageChannels: handleHideMessageChannels,
    getChannelDetail: handleGetChannelDetail,
    setSelectedMembers: handleSetSelectedMembers,
    setSelectedChannel: handleSetSelectedChannel,
    sendNewMessage: handleSendNewMessage,
    handleProfileSearch: handleProfileSearch,
    handleGetLists
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(MessagingContainer)
