import { Typography } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/styles';
import cls from 'classnames';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import AcceptButton from '../../components/AcceptPlayGolfInviteButton';
import DeclineButton from '../../components/DeclinePlayGolfInviteButton';
import UserAvatar from '../../components/userAvatar';
import WidgetLoading from '../../components/widgetLoading';
import { handleAcceptPlayGolfInvite } from '../../reducers/posts';
import { parser } from '../../utils/parser';
import { paths } from '../../utils/paths';
import { sortAttendedByUsername, sortAttendesByAcceptanceState, sortAttendesByCurUserFirst } from '../../utils/users';

const useStyles = makeStyles((theme) => ({
    iconStatus: { color: '#ddd' },
    link: {
        color: theme.palette.link?.main,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    hoverGreen: {
        '&:hover': { backgroundColor: 'green', color: 'white' }
    },
    hoverRed: {
        '&:hover': { backgroundColor: 'red' }
    },
    accept: { color: 'green', '&.mine:hover': { color: 'white' } },
    reject: { color: 'red', '&.mine:hover': { color: 'white' } },
  }))

function PlayGolfPost ({session, post, userDetail, memberList, onAccept, acceptPlayGolfInvite, updatedPost, isLoading, isLoadingPost}) {
    const classes = useStyles()
    const golf = post.play_golf
    const attendeeCount = (golf.attendees || []).filter(x => x.accepted).length
    const players = (golf.attendees || []).concat(golf.guest_list || [])

    const sortedAttendes = players
            .sort(sortAttendedByUsername)
            .sort(sortAttendesByAcceptanceState)
            .sort(sortAttendesByCurUserFirst(userDetail));
    
    const playerAttended = post?.play_golf?.attendees?.filter(player => player?.username === userDetail?.username)[0]
    const [inviteStatus, setInviteStatus] = useState(playerAttended?.accepted)
    const [isLoadingLink, setIsLoadingLink] = useState(false)

    const setStatesLinkEmail = (post, status) => {
        setInviteStatus(status)
        setIsLoadingLink(true)
        onAccept(post, status)
    }

    const updatePostStateForComponentVisibility = (post, status) => {
        setInviteStatus(status)
        const remainingPlayers = post?.play_golf?.attendees?.filter(player => player.username !== userDetail?.username)
        let newPost = post
        let newPlayerAttended = playerAttended
        newPlayerAttended.accepted = status
        newPost.play_golf.attendees = [...remainingPlayers, newPlayerAttended]
        acceptPlayGolfInvite(newPost, status)
    }
    
    const updateStatus = (post, status) => {
        if(status !== inviteStatus) {
            if (onAccept && !isLoadingLink) {
                setStatesLinkEmail(post, status)
            } else {
                if(!isLoading && !onAccept){
                    updatePostStateForComponentVisibility(post, status)
                }
            }    
        }
    }
    
    const shouldNotShowLoadingWidget = () => {
        return !isLoadingPost > 0 ? true : false
    }
    
    // useEffect(() => {
    //     if(post.id === updatedPost.id){
    //         console.log('updatedPost', updatedPost)            
    //         const currentPlayerAttendedUpdatedPost = updatedPost?.play_golf?.attendees?.filter(player => player === userDetail?.username)[0]
    //         console.log('here', currentPlayerAttendedUpdatedPost)
    //         setInviteStatus(currentPlayerAttendedUpdatedPost?.accepted) 
    //     }else{
    //         const currentPlayerAttended = post?.play_golf?.attendees?.filter(player => player.username === userDetail?.username)[0]
    //         setInviteStatus(currentPlayerAttended?.accepted)
    //         setIsLoadingLink(false)
    //     }
      
    // }, [post, userDetail, updatedPost])

    // console.log('inviteStatus', inviteStatus, playerAttended)
    return (
        <React.Fragment>
            <Typography variant="subtitle1">{post.body_text}</Typography>
            {golf.club 
                ? <Typography variant="subtitle2" style={{display: 'flex', padding: '2px 0'}}>
                    {golf.club.club_logo && <div style={{marginRight: 8}}><UserAvatar profileImage={golf.club.club_logo} width={24} isClub={true} /></div>}
                    <div>{golf.club.name} {golf.club.address && golf.club.address.city ? '(' + golf.club.address.city + ', ' + golf.club.address.state + ')' : ''}</div>
                  </Typography>
                : <Typography variant="subtitle2">{golf.course_id}</Typography>
            }
            <div style={{display: 'flex'}}>
                {golf.end_date ? <Typography variant="subtitle2">Dates: {parser.shortDate(golf.date) + ' - ' + parser.shortDate(golf.end_date)}</Typography>
                : <Typography variant="subtitle2">
                    Date: <span style={{ textTransform: 'uppercase' }}>{parser.shortDateTime(golf.date)}</span> {process.env.REACT_APP_PLAYGOLF_POST_DATETIME_UTC === 'true' && '(Tee time is local to the course)'}
                </Typography>}
                {!parser.isFutureDate(golf.date) && <Typography component="span" variant="subtitle2" color="error">*</Typography>}
            </div>
            {!parser.isFutureDate(golf.date) && <Typography variant="subtitle2" color="error">*The start time for this round has already passed.</Typography>}
            {golf.description && <Typography variant="subtitle2" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>{golf.description}</Typography>}
            <Typography variant="body2">Guest Fees: ${golf.guest_fees}</Typography>
            {golf.total_seats && golf.total_seats < 100 && <Typography variant="body2">Spots Available: {(golf.total_seats - attendeeCount) > 0 ? golf.total_seats - attendeeCount : <span style={{color: 'red'}}>None</span>}</Typography>}
            {golf.note && <Typography variant="body2" style={{wordBreak: 'break-word'}}>Notes: {golf.note}</Typography>}
            <hr />
            <Typography variant="subtitle1">Invited:</Typography>
            {sortedAttendes && sortedAttendes.map((player, idx) => {                
                return (
                    <div key={idx} style={{borderBottom: 'solid 1px #aaa', display: 'flex', alignItems: 'center', maxWidth: 400, width: '100%'}}>
                        <div>
                            {player.username || player?.role === 'guest' || player.email === userDetail?.email || player.referrer === userDetail?.email ?
                                <div style={{display: 'flex', width: '100%'}}>
                                    <div style={{marginRight: 8}}>
                                        {session && session.token && player.username && (userDetail?.role === 'member' || userDetail?.referrer === player.username) ? 
                                            <Link to={`${paths.profile}/` + player.username}>
                                                <UserAvatar profileImage={player.profile_image} width={24} />
                                            </Link>
                                            : <UserAvatar profileImage={player.profile_image} width={24} />
                                        }
                                    </div>
                                    <Typography style={{fontSize: 14}} variant="subtitle1">
                                        {session && session.token && player.username && (userDetail?.role === 'member' || userDetail?.referrer === player.username) ? 
                                            <Link className={classes.link} to={`${paths.profile}` + (player.username !== userDetail?.username ? `/${player.username}` : '')}>
                                                {`${player.first_name} ${player.last_name} ${player.role === 'guest' ? '(Guest)' : ''}`}
                                            </Link>
                                            : `${player.first_name} ${player.last_name} ${player.role === 'guest' ? '(Guest)' : ''}`
                                        }
                                    </Typography>
                                    {player.username === userDetail?.username && userDetail?.privacy_setting === 'anonymous' && userDetail?.role !== 'guest' &&
                                        <Typography variant="body2" style={{fontSize: 11, paddingLeft: 8, alignSelf: 'center'}}><Link to={paths.profile}>(Anonymous)</Link></Typography>}
                                </div>
                            :   <div style={{display: 'flex', width: '100%'}}>
                                    <div style={{marginRight: 8}}>
                                        <UserAvatar width={24} />
                                    </div>
                                    { shouldNotShowLoadingWidget() ? <Typography style={{fontSize: 14}} variant="subtitle1">
                                        Anonymous                                        
                                    </Typography> : <WidgetLoading size={20} /> }                                    
                                </div>
                            }
                        </div>

                        {userDetail && parser.isFutureDate(golf.date) && (player.username === userDetail?.username || player.email === userDetail?.email) && (player.accepted !== null || ((golf.total_seats - attendeeCount) > 0)) ?
                        <div style={{marginLeft: 'auto'}}>
                            <AcceptButton onClick={ () => updateStatus(post, true) } inviteStatus={inviteStatus} />
                            <DeclineButton onClick={ () => updateStatus(post, false) } inviteStatus={inviteStatus} />
                        </div>
                        : <div style={{padding: '12px 0', marginLeft: 'auto'}}>
                            <CheckIcon style={{marginRight: 24}} className={cls(classes.iconStatus, {[classes.accept]: player.accepted}) } />
                            <BlockIcon style={{marginRight: 12}} className={cls(classes.iconStatus, {[classes.reject]: player.username && !player.accepted && player.accepted !== null && player.accepted !== undefined}) } />
                        </div>
                        }   
                    </div>
                )
            })}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        updatedPost: state.posts.updatedPost,
        error: state.posts.error,
        session: state.auth.session,
        isLoading: state.posts.isLoading,
        isLoadingPost: state.posts.isLoadingPost
    }
}

const mapDispatch = (dispatch, ownProps) => {
    return bindActionCreators({
        acceptPlayGolfInvite: handleAcceptPlayGolfInvite
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(PlayGolfPost)
  