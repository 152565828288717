import { TextField } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WidgetLoading from '../../components/widgetLoading';
import { handleCourseSearch, handleUpdateCourse } from '../../reducers/clubs';

const MAX_LIMIT = 201;
const TOTAL_COURSES = 50000

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: '11px'
      },
      root: {
        fontSize: '11px'
      }
    }
  }
})

const CourseTable = ({
  courses,
  handleUpdateCourse,
  handleCourseSearch
}) => {
  const [tempRank, setTempRank] = React.useState()
  const [tempCourseId, setTempCourseId] = React.useState()
  const handleRankChange = (course_id, event) => {
    setTempRank(event.target.value)
    setTempCourseId(course_id)
  }

  const handleRankUpdate = () => {
    if(tempCourseId){
      handleUpdateCourse({ id: tempCourseId, rank: tempRank }, null, TOTAL_COURSES)
      setTempRank(undefined)
      setTempCourseId(undefined)
    }
  }

  React.useEffect(() => {
    handleCourseSearch(null, null, TOTAL_COURSES)
  }, [handleCourseSearch])

  const columns = [
    { name: "id", label: "Id", options: { filter: false, sort: true } },
    { name: "course_name", label: "Course Name", options: { filter: false, sort: true } },
    { name: "address.city", label: "City", options: { filter: true, sort: true } },
    { name: "address.state", label: "State", options: { filter: true, sort: true } },
    { name: "usa_rank",
      label: "Top 100 U.S. Courses",
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!tableMeta.rowData) { return <div /> }
          const rank = tableMeta.rowData[4]
          const course_id = tableMeta.rowData[0]
          return (
          <div style={{width: '100%', textAlign: 'center'}}>
            <NumericFormat
              value={tempCourseId === course_id ? tempRank : (rank || '')}
              onChange={(e) => handleRankChange(course_id, e)}
              onBlur={handleRankUpdate}
              customInput={TextField}
              id="usa_rank"
              name="usa_rank"
              fullWidth
              margin="none"
              variant="outlined"
              allowNegative={false}
              decimalScale={0}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue < MAX_LIMIT || !floatValue;
              }}
              />
          </div>
          )
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data ? obj1.data : (Number.MAX_VALUE * (order === 'asc' ? 1 : -1));
            let val2 = obj2.data ? obj2.data : (Number.MAX_VALUE * (order === 'asc' ? 1 : -1));
            
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        }
      }
    },
   ];
  const tableOptions = {
    count: courses.length,
    download: false,
    enableNestedDataAccess: '.',
    print: false,
    selectableRows: 'none'
  };
  
  if (courses.length === 0) { return <div style={{paddingTop: 48}}><WidgetLoading /></div> }
  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable 
          title=''
          data={courses} 
          columns={columns} 
          options={tableOptions} 
        />
      </MuiThemeProvider>
    </React.Fragment>
  )
}

const mapState = (state) => {
  return {
    courses: state.clubs.courseList
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleCourseSearch,
    handleUpdateCourse
  }, dispatch)
}
export default connect(mapState, mapDispatchToProps)(CourseTable);
