import axios from "axios";

function hasMemberPlayedWithUser(userDetail, member) {
  return userDetail?.played_with_confirmed?.includes(member?.email) ? true : false
}

const isPreferredEmailEdit = (temporaryUser, profile) => {
  return temporaryUser && temporaryUser.preferred_email !== profile.preferred_email ? true : false
}

const getUniqueIdEmail = (userList, email) => {
  const userWithEmailChanged = userList.find(user => user.preferred_email === email)          
  return userWithEmailChanged && userWithEmailChanged.email ? userWithEmailChanged.email : email
}

const closeModalEditProfile = (initBio, toggleDialog, resetIsInvalidNewEmail, profile) => {
  toggleDialog(false)
  resetIsInvalidNewEmail()
  initBio(profile)
}

const getUserMemberships = (email, visibles, primary = null) => {
  const memberships = visibles
    ?.filter(x => x.email === email)
    ?.map(x => {
      if(!primary || primary !== x.id){
        return {
          club_logo: x.club_logo,
          name: x.club_name,
          address: x.club_address
        }  
      }
      return []
    })
  if(memberships && memberships.length > 0){
    return primary ? [primary, ...memberships] : memberships
  }
  return primary ? [primary] : []
}

const getFormattedUsername = (username) => username.toLowerCase();

const isAdminSession = (session) => { return session.role === 'admin' }
const isNonAdminUserSession = (session, userDetail) => { return !isAdminSession(session) && userDetail && userDetail.email }
const isMemberSession = (session, userDetail) =>  { return isNonAdminUserSession(session, userDetail) && userDetail.role === 'member' }
const isNonApplicantSession = (session, userDetail) => { return isNonAdminUserSession(session, userDetail) && userDetail.role !== 'applicant' }

const getFormattedVenmoUsername = (venmo_username) => venmo_username?.[0] === '@' ? venmo_username : '@'+venmo_username
const getVenmoLink = (venmo_username) => venmo_username?.[0] === '@' ? venmo_username.substring(1) : venmo_username
const getUserAddressString = (user) => {
  let address = user?.address?.address_1 ? user?.address?.address_1 : ''
  address = address + (user?.address?.address_2 ? ', '+user?.address?.address_2 : '')
  address = address + (user?.address?.city ? ', '+user?.address?.city : '')
  address = address + (user?.address?.state ? ', '+user?.address?.state : '')
  address = address + (user?.address?.zip ? ', '+user?.address?.zip : '')
  address = address + (user?.address?.country ? ', '+user?.address?.country : '')
  return address
}

const getUserCsv = async (type, token, setData,setLoading) => {
  await axios.get(`${process.env.REACT_APP_BASE_URL}/profiles/export/${type}`, {
    headers: { Authorization: token }
  }).then((response) => {
    const data = response?.data?.map(user => {
      const address = getUserAddressString(user)      
      return ({
        ...user,
        user_whole_address: address,
        user_address1:user?.address?.address_1 ? user?.address?.address_1:'',
        user_address2:user?.address?.address_2 ? user?.address?.address_2:'',
        user_city:user?.address?.city ? user?.address?.city:'',
        user_state:user?.address?.state ? user?.address?.state:'',
        user_zip:user?.address?.zip ? user?.address?.zip:'',
        graduate_degree: user?.graduate_degree?.label,
        postgraduate_degree: user?.graduate_degree?.label
      })
    })
    setData(data)
    setLoading(false)
    return data
  }).catch(error => {
    return null
  })
}

const sortAttendedByUsername = (a, b) => {
  const usernameA = (a.username || '').toLowerCase();
  const usernameB = (b.username || '').toLowerCase();
  return usernameA.localeCompare(usernameB, 'en', { sensitivity: 'base' });
};

const sortAttendesByAcceptanceState = (a, b) =>
attendeeToAcceptanceWeight(a) - attendeeToAcceptanceWeight(b);

const attendeeToAcceptanceWeight = (itm) => {
  if (itm.accepted === true) {
    return 0;
  }
  if (itm.accepted === false) {
    return 1;
  }
  return 2;
};

const sortAttendesByCurUserFirst = (curUser) => (a, b) => {
  if(curUser){
    const { email, username } = curUser;
    if (a.member_email === email || a.username === username) {
      return -1;
    }
    if (b.member_email === email || b.username === username) {
      return 1;
    }
  }
  return 0;
};

export {
  sortAttendedByUsername,
  sortAttendesByAcceptanceState,
  sortAttendesByCurUserFirst,
  hasMemberPlayedWithUser,
  isPreferredEmailEdit,
  getUniqueIdEmail,
  closeModalEditProfile,
  isAdminSession,
  isMemberSession,
  isNonApplicantSession,isNonAdminUserSession,
  getUserMemberships,
  getFormattedUsername,
  getFormattedVenmoUsername,
  getVenmoLink,
  getUserAddressString,
  getUserCsv
};