import { isApplicantOrMemberReview } from "../utils/profiles"
import { handleGetMemberProfile, handleGetMembers, handleGetUsers, handleProfileSearch, handleGetMembersVisible } from "./members"

export const types = {
  ONBOARDING_CHANGE: 'USER/ONBOARDING_CHANGE',
  ONBOARDING_SELECT_STAGE: 'USER/ONBOARDING_SELECT_STAGE',
  ONBOARDING_SUBMIT_REQUEST: 'USER/ONBOARDING_SUBMIT_REQUEST',
  ONBOARDING_SUBMIT_SUCCESS: 'USER/ONBOARDING_SUBMIT_SUCCESS',
  ONBOARDING_SUBMIT_FAILURE: 'USER/ONBOARDING_SUBMIT_FAILURE',
  IMAGE_SUBMIT_REQUEST: 'USER/IMAGE_SUBMIT_REQUEST',
  IMAGE_SUBMIT_SUCCESS: 'USER/IMAGE_SUBMIT_SUCCESS', 
  IMAGE_SUBMIT_FAILURE: 'USER/IMAGE_SUBMIT_FAILURE',
  GET_USER_REQUEST: 'USER/GET_USER_REQUEST',
  GET_USER_SUCCESS: 'USER/GET_USER_SUCCESS',
  GET_USER_FAILURE: 'USER/GET_USER_FAILURE',
  UPDATE_USER_REQUEST: 'USER/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'USER/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'USER/UPDATE_USER_FAILURE',
  UPDATE_PLAYED_REQUEST: 'USER/UPDATE_PLAYED_REQUEST',
  UPDATE_PLAYED_SUCCESS: 'USER/UPDATE_PLAYED_SUCCESS',
  UPDATE_PLAYED_FAILURE: 'USER/UPDATE_PLAYED_FAILURE',
  STATUS_CHANGE_SUCCESS: 'USER/STATUS_CHANGE_SUCCESS',
  STATUS_CHANGE_FAILURE: 'USER/STATUS_CHANGE_FAILURE',
  CLEAR_EVERYTHING: 'USER/CLEAR',
  UPDATE_TEMPORARY_USER: 'UPDATE_TEMPORARY_USER',
  CHECK_EMAIL_USER_REQUEST: 'CHECK_EMAIL_USER_REQUEST',
  CHECK_EMAIL_USER_SUCCESS: 'CHECK_EMAIL_USER_SUCCESS',
  CHECK_EMAIL_USER_FAILURE: 'CHECK_EMAIL_USER_FAILURE',
  CHECK_EMAIL_USER: 'CHECK_EMAIL_USER',
  SET_FLAG_MODAL_EDIT_USER_ADMIN: 'SET_FLAG_MODAL_EDIT_USER_ADMIN',
  RESET_IS_VALID_NEW_EMAIL: 'RESET_IS_VALID_NEW_EMAIL'
}

export const initialState = {
  userDetail: null,
  onboardingStage: 0,
  error: null,
  isLoading: false,
  imageLoading: false,
  temporaryUser: null,
  isInvalidNewEmail: null,
  isUpdatedSuccessFull: false
}

export const handleGetUserDetail = ({role, email}) => dispatch => {
  const url = isApplicantOrMemberReview(role ? { role } : null) ? '/membership_applications' : '/profiles'
  dispatch({
    types: [types.GET_USER_REQUEST, types.GET_USER_SUCCESS, types.GET_USER_FAILURE],
    url: `${url}/${encodeURIComponent(email)}`
  })
}
export const _updateUser = ({role, email, member}, flagLoadVisibleUsers) => dispatch => {
  if (role && email) { dispatch(handleGetUserDetail({role: role, email: email})) }
  if (member) {
    dispatch(handleGetMemberProfile(member))
    dispatch(handleProfileSearch(member.search || {}))

    if(flagLoadVisibleUsers){
      dispatch(handleGetMembersVisible())
    }
    
  } else if (role && role !== 'applicant') {
    dispatch(handleGetMembers())
  }
}
export const handleClearEverything = () => {
  return { type: types.CLEAR_EVERYTHING }
}
export const handleOnboardingChange = (data) => {
  return { type: types.ONBOARDING_CHANGE, data: data }
}
export const handleOnboardingSelectStage = (stage) => {
  return { type: types.ONBOARDING_SELECT_STAGE, data: stage }
}
export const handleOnboardingSubmit = (email, role, application) => (dispatch, getState) => {
  const oldState = getState()
  const session = oldState.auth.session
  const adminChange = session.role === 'admin' && email !== session.email
  const url = isApplicantOrMemberReview(role ? { role } : null) ? '/membership_applications/onboarding' : '/profiles'
  dispatch({
    types: [types.ONBOARDING_SUBMIT_REQUEST, types.ONBOARDING_SUBMIT_SUCCESS, types.ONBOARDING_SUBMIT_FAILURE],
    url: `${url}/${encodeURIComponent(email)}`,
    method: 'put',
    data: { ...scrubData(application) },
    callback: adminChange ? handleGetMemberProfile({role: role, email: email}) : _updateUser({role: role, email: email})
  })
}
const scrubData = (data) => {
  if (!data.privacy_setting) { data.privacy_setting = 'full' }
  if (!data.date_of_birth) { data.date_of_birth = null }
  if (data.primary_club_name) { delete data.primary_club_name }
  if (data.primary_club_id) { delete data.primary_club_id }
  if (data.email_token) { delete data.email_token }
  return data
}

export const resetIsInvalidNewEmail = () => (dispatch) => {
  dispatch({
    type: types.RESET_IS_VALID_NEW_EMAIL,
    payload: null
  })
}

export const handleSetFlagModalEditUserAdmin = (payload) => (dispatch) => {
  dispatch({
    type: types.SET_FLAG_MODAL_EDIT_USER_ADMIN,
    payload
  })
}

export const handleCheckEmailUser = (email) => (dispatch, getState) => {
  dispatch({
    types: [types.CHECK_EMAIL_USER_REQUEST, types.CHECK_EMAIL_USER_SUCCESS, types.CHECK_EMAIL_USER_FAILURE],
    url: `/profiles/email/check/${encodeURIComponent(email ? email : '-')}`,
  })
}

export const handleUpdateTemporaryUser = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_TEMPORARY_USER,
    payload: data
  });
}

export const handleUpdateUser = (data, silent, closeAfterSave = false) => (dispatch, getState) => {
  const oldState = getState()
  const session = oldState.auth.session
  const adminChange = session.role === 'admin' && data.email !== session.email
  const url =  isApplicantOrMemberReview(data) ? '/membership_applications/onboarding' : '/profiles'
  dispatch({
    types: [types.UPDATE_USER_REQUEST, types.UPDATE_USER_SUCCESS, types.UPDATE_USER_FAILURE],
    url: `${url}/${encodeURIComponent(data.email)}`,
    method: 'put',
    data: { ...scrubData(data) },
    callback: adminChange && !silent ? handleGetMemberProfile({role: data.role, email: data.email}) : _updateUser({role: data.role, email: data.email})
  })
}
export const handleWePlayedTogether = (myEmail, memberEmail, playedTogether, searchParams) => dispatch => {
  const data = playedTogether ? { we_have_played_together: memberEmail } : { we_have_not_played_together: memberEmail }
  dispatch({
    types: [types.UPDATE_PLAYED_REQUEST, types.UPDATE_PLAYED_SUCCESS, types.UPDATE_PLAYED_FAILURE],
    url: `/profiles/${encodeURIComponent(myEmail)}`,
    method: 'patch',
    data: data,
    callback: _updateUser({role: 'member', email: myEmail, member: { role: 'member', email: memberEmail, search: searchParams}}, true)
  })
}
export const handleImageSubmit = (email, role, file) => (dispatch, getState) => {
  const oldState = getState()
  const session = oldState.auth.session
  const adminChange = session.role === 'admin' && email !== session.email
  const url = isApplicantOrMemberReview(role ? { role } : null) ? '/membership_applications' : '/profiles'
  dispatch({
    types: [types.IMAGE_SUBMIT_REQUEST, types.IMAGE_SUBMIT_SUCCESS, types.IMAGE_SUBMIT_FAILURE],
    url: `${url}/${encodeURIComponent(email)}/profile_image`,
    method: 'put',
    contentType: 'multipart/form-data',
    data: file,
    timeout: 6000,
    callback: adminChange ? handleGetMemberProfile({role: role, email: email}) : handleGetUserDetail({role: role, email: email})
  })
}
export const handleProspectClubChange = (email, clubId) => dispatch => {
  dispatch({
    types: [types.ONBOARDING_SUBMIT_REQUEST, types.ONBOARDING_SUBMIT_SUCCESS, types.ONBOARDING_SUBMIT_FAILURE],
    url: `/membership_applications/${encodeURIComponent(email)}/club`,
    method: 'put',
    data: { primary_club: clubId },
    callback: handleGetUsers()
  })
}

// APPLICANTS ONLY.  CANNOT BE USED FOR GUESTS!!!!!
export const handleTermsAgreed = (email) => dispatch => {
  dispatch({
    types: [types.ONBOARDING_SUBMIT_REQUEST, types.ONBOARDING_SUBMIT_SUCCESS, types.ONBOARDING_SUBMIT_FAILURE],
    url: `/membership_applications/${encodeURIComponent(email)}/tos`,
    method: 'put'
  })
}
export default function (state = initialState, action) {
  switch (action.type) {    
    case types.RESET_IS_VALID_NEW_EMAIL:
      return {
        ...state,
        isInvalidNewEmail: null
    }
    case types.SET_FLAG_MODAL_EDIT_USER_ADMIN:
      return {
        ...state,
        flagModalEditUserAdmin: action.payload,
        isInvalidNewEmail: null
    }
    case types.CHECK_EMAIL_USER_SUCCESS:{
      return {
        ...state,
        isInvalidNewEmail: false
      }
    }
    case types.CHECK_EMAIL_USER_FAILURE:{
      return {
        ...state,
        isInvalidNewEmail: action.error
      }
    }
    case types.UPDATE_TEMPORARY_USER:{
      return {
        ...state,
        temporaryUser: action.payload
      }
    }
    case types.UPDATE_USER_SUCCESS:
      return { ...state, isLoading: false, isInvalidNewEmail: null, isUpdatedSuccessFull: true}
    case types.UPDATE_PLAYED_SUCCESS:
        return { ...state, isLoading: false }
    case types.GET_USER_SUCCESS:
      const userDetail = action.data
      if (userDetail) {
        userDetail.date_of_birth = userDetail.date_of_birth || ''
      }
      return { ...state, isLoading: false, imageLoading: false, userDetail:  userDetail, isUpdatedSuccessFull: true }
    case types.ONBOARDING_CHANGE:
      return { ...state, userDetail: action.data }
    case types.ONBOARDING_SELECT_STAGE:
      return { ...state, isLoading: false, onboardingStage: action.data }
    case types.ONBOARDING_SUBMIT_SUCCESS:
    case types.IMAGE_SUBMIT_SUCCESS:
      const currentStage = state.onboardingStage
      return { ...state, onboardingStage: currentStage + 1 }
    case types.ONBOARDING_SUBMIT_FAILURE:
    case types.GET_USER_FAILURE:
    case types.UPDATE_USER_FAILURE:
      return { ...state, isLoading: false, isInvalidNewEmail: action.error, isUpdatedSuccessFull: false}
    case types.UPDATE_PLAYED_FAILURE:
    case types.IMAGE_SUBMIT_FAILURE:
      return { ...state, isLoading: false, imageLoading: false, error: action.error }
    case types.ONBOARDING_SUBMIT_REQUEST:
    case types.GET_USER_REQUEST:
    case types.UPDATE_PLAYED_REQUEST:
    case types.UPDATE_USER_REQUEST:
      return { ...state, isLoading: true, isInvalidNewEmail: null, isUpdatedSuccessFull: false }
    case types.IMAGE_SUBMIT_REQUEST:
        return { ...state, imageLoading: true }
    case types.CLEAR_EVERYTHING:
      return initialState
    default:
      return state
  }
}
