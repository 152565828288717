import { Box, ButtonBase, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react'
import HandicapListChildren from './handicapListChildren';
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons';

const HandicapListParent = (props) => {
    const { children, label, value, queryArray, setQueryArray } = props
    const [checked, setChecked] = React.useState(false);
    const [countChildreChecked, setCountChildreChecked] = React.useState(0);
    const [visible, setVisible] = React.useState(false);
    

    const handleChange = (event) => {
      if(event.target.checked){
        setCountChildreChecked(children?.length);

        //add all children to array
        if(children.length > 0){
          const labelsToAdd = []
          children.forEach(ch => {
            if(!queryArray.includes(ch.label)){
              labelsToAdd.push(ch.label)
            }
          })
          setQueryArray([...queryArray, ...labelsToAdd])
        }
      }else{
        setCountChildreChecked(0)
        setVisible(false)

        //remove all children to array
        if(children.length > 0){
          const labelsToRemove = children.map(ch => {
            if(queryArray.includes(ch.label)){
              return ch.label
            }
            return true
          })
          setQueryArray([...queryArray.filter(label => !labelsToRemove.includes(label))])
        }
      }
      setChecked(event.target.checked)
    };

    React.useEffect(() => {
      if(countChildreChecked === 0 && checked) setChecked(false)
      if(countChildreChecked === children?.length){
        setChecked(true)
        setVisible(true)
      }
    }, [countChildreChecked, checked, children])

    const updateCounterChildrenCheck = (value, label) => {
      setCountChildreChecked(countChildreChecked + value)

      if(value > 0){ //add to array
        addItemToQueryArray(label)
      }else{ //remove from array
        removeItemFromQueryArray(label)
      }
    }
    
    function addItemToQueryArray(label){
      if(!queryArray.includes(label)){
        setQueryArray([...queryArray, label])
      }
    }

    function removeItemFromQueryArray(label){
      if(queryArray.includes(label)){
        setQueryArray([...queryArray.filter(a => a !== label)])
      }
    }

    function handleVisible(){
      setVisible(!visible)
    }

    return (
        <Grid container>
          <Grid item xs={11}>
            <FormControlLabel
              label={`${label} (${value})`}
              control={
                <Checkbox
                  checked={checked}
                  indeterminate={countChildreChecked > 0 && countChildreChecked !== children.length ? true : false}
                  onChange={handleChange}
                />
              }
            />
            <Box sx={{ display: visible ? 'flex' : 'none', flexDirection: 'column', ml: 3 }}>
            { children && children.length > 0 && children.map((ch, index) => <HandicapListChildren
              key={index}
              label={`${ch.label} (${ch.value})`}
              childrenLabel={ch.label}
              handleCount={updateCounterChildrenCheck}
              parentLabel={label}
              parentState={checked} />)}
            </Box>
          </Grid>
          <Grid item xs={1} style={{ paddingTop: '10px' }}>
            <ButtonBase onClick={handleVisible} >
              { visible ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            </ButtonBase>
          </Grid>
        </Grid>
    );
}

export default HandicapListParent