import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddLogoIcon from '@material-ui/icons/AddToPhotos';
import AutoSelect from '../../components/auto_select';
import DateRangeSelector from '../../components/dateRangeSelector';
import PhotoEditorDialog from '../../components/photoEditorDialog';
import { handleCourseSearch } from '../../reducers/clubs';
import { parser } from '../../utils/parser';
import { handleSubmitEvent, handleDeleteEvent } from '../../reducers/events';

const useStyles = makeStyles((theme) => ({
  multiLineTextBox: {
    margin: '8px 0',
    '& textarea': {
      fontFamily: theme.typography.secondaryFont,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 14px',
    },
  },
}));
const EventFormDialog = ({
  open,
  toggleDialog,
  event,
  isLoading,
  clubs,
  categoryList,
  handleCourseSearch,
  handleSubmitEvent,
  handleDeleteEvent,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [errors, setErrors] = React.useState({});
  const [newEvent, setNewEvent] = React.useState({});
  const [tempLogo, setTempLogo] = React.useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showPhotoEditor, setShowPhotoEditor] = React.useState(false);
  React.useEffect(() => {
    setTempLogo(null);
    setErrors({});
    if (event && event.id) {
      setNewEvent({ ...event });
    } else {
      setNewEvent({});
    }
    return () => {
      setNewEvent({});
      setTempLogo();
      setErrors({});
    };
  }, [event, open]);
  const onChange = (event) => {
    if (event && event.preventDefault) event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    setNewEvent((oldValues) => ({ ...oldValues, [name]: value }));
    const errorList = { ...errors };
    delete errorList[name];
    setErrors(errorList);
  };
  const onChecked = (event) => {
    // if (event && event.preventDefault) event.preventDefault()
    const name = event.target.name;
    const value = event.target.checked;
    setNewEvent((oldValues) => ({ ...oldValues, [name]: value }));
  };
  const handleCoursesTextChange = (name) => (value) => {
    if (value && value.length > 2) {
      handleCourseSearch(value);
    }
    if (!value || value.length === 0) {
      handleCourseSearch(value);
    }
  };
  const handleCourseSelection = (name) => (list) => {
    console.log('course', list);
    setNewEvent((oldValues) => ({ ...oldValues, courses: list }));
    if (list && list.length > 0) {
      const errorList = { ...errors };
      delete errorList[name];
      setErrors(errorList);
    }
  };
  const handleAutoSelectChange = (name) => (list) => {
    onChange({ target: { name: name, value: list } });
  };
  const handleDateChange = (dates) => {
    setNewEvent((oldValues) => ({ ...oldValues, ...dates }));
    const errorList = { ...errors };
    delete errorList['start_date'];
    setErrors(errorList);
  };
  const setSelectedAddressState = (value) => {
    if (!value || value.label) {
      return value || '';
    }
    const stateList = parser.getStateList();
    const st = stateList.find((x) => x.label === value || x.value === value);
    return st || value;
  };
  const onSubmit = () => {
    // console.log('Submit EVENT', {...newEvent})
    const errorList = {};
    if (!newEvent.name) {
      errorList.name = 'Event name is required';
    }
    if (!newEvent.city) {
      errorList.city = 'Event city is required';
    }
    if (!newEvent.state) {
      errorList.state = 'Event state is required';
    }
    if (!newEvent.start_date) {
      errorList.start_date = 'Event date is required';
    }
    if (!newEvent.courses) {
      errorList.courses = 'Course is required';
    }
    if (newEvent.url && newEvent.url.indexOf('http') !== 0) {
      errorList.url = 'URL must start with http or https';
    }
    setErrors(errorList);
    // console.log('ERRORS', errorList)
    if (errorList && Object.keys(errorList).length > 0) {
      return;
    }
    const courses = newEvent.courses.map((course) => course.value || course.id);
    const submitEvent = {
      ...newEvent,
      courses,
      state: newEvent.state.value || newEvent.state,
    };
    // console.log('UPDATED:', {...submitEvent})
    handleSubmitEvent({ ...submitEvent });
    onClose();
  };
  const onDelete = () => {
    handleDeleteEvent(event.id);
    onClose();
  };
  const onClose = () => {
    setTempLogo(null);
    setNewEvent({});
    setErrors({});
    toggleDialog(null);
  };
  const handleEventImage = (file) => {
    if (!file) {
      return;
    }
    getTempPath(file);
    setNewEvent({ ...newEvent, logo: file });
  };
  // const onFileChange = e => {
  //     if (e.target.files.length > 0) {
  //         const file = e.target.files[0]
  //         getTempPath(file)
  //         setNewEvent({...newClub, logo: file})
  //     }
  // }
  const getTempPath = (logo) => {
    if (!logo || typeof logo === 'string') {
      return logo;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setTempLogo(e.target.result);
    };
    reader.readAsDataURL(logo);
  };
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        {isLoading ? (
          <div />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>{event && event.id ? 'Edit Event' : 'New Event'}</div>
            {tempLogo || (newEvent && newEvent.logo) ? (
              <div style={{ maxWidth: 120 }}>
                <IconButton onClick={() => setShowPhotoEditor(true)}>
                  <img
                    style={{ maxWidth: '100%' }}
                    src={tempLogo || newEvent.logo}
                    alt={newEvent.name || 'Logo'}
                  />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={() => setShowPhotoEditor(true)}>
                <AddLogoIcon style={{ width: 50, height: 'auto' }} />
              </IconButton>
            )}
          </div>
        )}
      </DialogTitle>
      <DialogContent style={{ minWidth: '80%' }}>
        <PhotoEditorDialog
          open={showPhotoEditor}
          title={'Select Event Image'}
          toggleDialog={() => setShowPhotoEditor(false)}
          imagePath={event && event.logo ? event.logo : ''}
          height={300}
          width={300}
          handleSave={handleEventImage}
        />
        <div style={{ display: 'flex', margin: '16px 0' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                name='name'
                required
                value={newEvent.name || ''}
                onChange={onChange}
                label='Event Name'
                margin='none'
                fullWidth
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <DateRangeSelector
                enablePast={true}
                startDate={newEvent.start_date}
                endDate={newEvent.end_date}
                onUpdate={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: 4 }}>
              <TextField
                name='city'
                required
                value={newEvent.city || ''}
                onChange={onChange}
                label='City'
                margin='none'
                fullWidth
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: 4 }}>
              <FormControl fullWidth>
                <AutoSelect
                  name='state'
                  options={parser.getStateList()}
                  textFieldProps={{
                    id: 'state',
                    // inputStyle: {
                    //     margin: 80
                    // },
                    InputLabelProps: { shrink: true },
                    label: (
                      <div style={{ color: '#777', marginTop: -4 }}>
                        State<sup>*</sup>
                      </div>
                    ),
                  }}
                  selectedValue={setSelectedAddressState(newEvent.state) || ''}
                  handleAutoSelectChange={handleAutoSelectChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name='url'
                value={newEvent.url || ''}
                onChange={onChange}
                label='Event Url'
                margin='none'
                fullWidth
                style={{ marginTop: 4 }}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{ marginTop: 4 }}
                name='fee'
                label='Event Fee'
                margin='none'
                value={newEvent.fee || ''}
                onChange={onChange}
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <AutoSelect
                textFieldProps={{
                  label: 'Course',
                  required: true,
                  InputLabelProps: { shrink: true },
                }}
                name='courses'
                isMulti
                options={parser.formatCourses(clubs.courseList)}
                selectedValue={parser.formatCourses(newEvent.courses)}
                handleAutoSelectTextChange={handleCoursesTextChange}
                handleAutoSelectChange={handleCourseSelection}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name='course_display_type'
                label='Course Display Type'
                select
                required
                style={{ marginTop: 4 }}
                fullWidth
                margin='none'
                value={newEvent.course_display_type || 'club'}
                onChange={onChange}
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem style={{ padding: '4px 8px' }} value={'club'}>
                  Club Only
                </MenuItem>
                <MenuItem style={{ padding: '4px 8px' }} value={'course'}>
                  Course Only
                </MenuItem>
                <MenuItem style={{ padding: '4px 8px' }} value={'both'}>
                  Club and Course
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name='category'
                label='Event Category'
                select
                required
                style={{ marginTop: 4 }}
                fullWidth
                margin='none'
                value={newEvent.category || ''}
                onChange={onChange}
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {categoryList.map((category) => (
                  <MenuItem
                    style={{ padding: '4px 8px' }}
                    key={category.value}
                    value={category.value}
                  >
                    {category.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{ marginTop: 4 }}
                name='button_text'
                label='Optional Button Text'
                margin='none'
                value={newEvent.button_text || ''}
                onChange={onChange}
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEvent.featured || false}
                    onChange={onChecked}
                    name='featured'
                    color='primary'
                  />
                }
                label='Featured'
                style={{ padding: 8 }}
              />
            </Grid>
            <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEvent.charity || false}
                    onChange={onChecked}
                    name='charity'
                    color='primary'
                  />
                }
                label='Charity Event'
                style={{ padding: 8 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='description'
                label='Provide a Description'
                fullWidth
                variant='outlined'
                style={{ marginTop: 4 }}
                value={newEvent.description || ''}
                onChange={onChange}
                className={classes.multiLineTextBox}
                multiline
                rows='12'
                placeholder=''
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </div>
        <Typography
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          component='div'
          variant='subtitle2'
          color='error'
        >
          {errors &&
            Object.keys(errors).length > 0 &&
            Object.keys(errors).map((key) => (
              <div key={key}>{errors[key]}</div>
            ))}
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          width: '100%',
          padding: '8px 24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {event && event.id ? (
            <Button
              variant='outlined'
              style={{ backgroundColor: '#FF0000', color: '#FFF' }}
              onClick={() => onDelete()}
            >
              DELETE
            </Button>
          ) : (
            <></>
          )}
        </div>
        <div>
          <Button
            variant='outlined'
            style={{ marginRight: 32 }}
            onClick={() => onClose()}
          >
            CLOSE
          </Button>
          <Button variant='contained' onClick={onSubmit}>
            SAVE
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    clubs: { ...state.clubs },
  };
};
const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      handleCourseSearch,
      handleSubmitEvent,
      handleDeleteEvent,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatch)(EventFormDialog);
