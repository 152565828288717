import { Button, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserAvatar from '../../components/userAvatar';
import WidgetContainer from '../../components/widgetContainer';
import WidgetElement from '../../components/widgetElement';
import { handleGetApplicantsHome } from '../../reducers/members';
import { getFullListMembersWidget } from '../../utils/members';
import { parser } from '../../utils/parser';
import { paths } from '../../utils/paths';
import { userRoles } from '../../utils/profiles';
import { getUserMemberships } from '../../utils/users';
import SendInviteDialog from './sendInviteDialog';

const APPLICANTS = 'Applicants'
const MEMBERS = 'Members'

const MembersWidget = ({ history, userDetail, memberList, visibles, applicantList, handleGetApplicantsHome, type = MEMBERS }) => {
  const [showInvite, setShowInvite] = React.useState(false);
  
  const fullList = getFullListMembersWidget(applicantList, userDetail, memberList, type)
  
  const newMembers = parser
    .sortBy(fullList || [], { prop: 'date_joined', desc: true })
    .slice(0, 3);
  
  const newMembersWithMembership = newMembers.map(member => {
    const club_memberships = getUserMemberships(member.email, visibles)
    return { ...member, club_memberships }
  })

  useEffect(() => {
    if(type === APPLICANTS) handleGetApplicantsHome()
  }, [handleGetApplicantsHome, type])

  return (
    fullList && fullList.length > 0 ? <WidgetContainer
      header={`New ${type}`}
      footer={<Button variant='contained' onClick={() => type === APPLICANTS ? history.push(paths.memberReview) : history.push(paths.members)}>
          View All
        </Button>}
      action={() => type === MEMBERS && setShowInvite(true)}
      buttonLabel={type === MEMBERS && '+ Invite'}
      type={type === MEMBERS ? userRoles.member : userRoles.memberReview}
    >
      {type === MEMBERS && <SendInviteDialog
        userDetail={userDetail}
        open={showInvite}
        toggleDialog={() => setShowInvite(false)}
      />}
      {type === APPLICANTS && <Typography style={{ marginBottom: '5px' }} >Please email <a href={`mailto:membership+applicants@captains.club`} style={{ color: 'rgb(0, 0, 238)', textDecoration: 'none' }} >membership+applicants@captains.club</a> with any feedback on the applicants below.</Typography>}
      {newMembersWithMembership.map((member, idx) => {
        const referrer = memberList?.filter(m => m.username === member.referrer)[0]
        const club_memberships = type === APPLICANTS ? [member.primary_club] : member.club_memberships
        return (
        <div
          key={idx}
          onClick={() => history.push(`${paths.profile}/${type === APPLICANTS ? member.email : member.username}`)}
        >
          <WidgetElement>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ marginRight: 8 }}>
                <UserAvatar profileImage={member.profile_image} width={24} />
              </div>
              <Typography style={{ fontSize: 14 }} variant='subtitle1'>
                {`${member.first_name} ${member.last_name}`}{referrer && type === APPLICANTS && <Typography variant='caption'> - Referred by <a href={`${paths.profile}/${referrer.username}`}>{referrer.first_name} {referrer.last_name}</a></Typography>}
              </Typography>
            </div>
            <div style={{ paddingLeft: 32 }}>
              {club_memberships &&
                club_memberships?.map((item, idx) => {
                  return (
                    <Typography
                      key={idx}
                      variant='body2'
                      component='div'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 10,
                      }}
                    >
                      {item.club_logo && (
                        <div style={{ marginRight: 8 }}>
                          <UserAvatar
                            profileImage={item.club_logo}
                            width={24}
                            isClub={true}
                          />
                        </div>
                      )}
                      <div>
                        {item.name}{' '}
                        {item.address && item.address.city
                          ? '(' +
                            item.address.city +
                            ', ' +
                            item.address.state +
                            ')'
                          : ''}
                      </div>
                    </Typography>
                  );
                })}
            </div>
          </WidgetElement>
        </div>
      )})}
    </WidgetContainer> : null
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    memberList: state.members.memberList,
    visibles: state.members.membersVisible,
    applicantList: state.members.applicantList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleGetApplicantsHome
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersWidget));
