//import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaperMasterHoc from '../../components/paperMasterHoc';
import CourseSelector from '../profile/clubCourseSelector.js';
import { handleGetMembersRank } from '../../reducers/members';
import MembersRank from './membersRank';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
  }
}))

const TopCoursesContainer = ({
  userDetail,
  handleGetMembersRank,
  membersRank
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    handleGetMembersRank()
  }, [handleGetMembersRank])

  if (!userDetail || !userDetail.email) { return <div /> }

  return (
    <PaperMasterHoc transparent={true}>
      <div className={classes.root}>
        {/* <Typography style={{marginBottom: '25px'}} variant="h4">Top 100 courses</Typography> */}
        <CourseSelector
            title='Top 100 U.S. Courses'
            field=''
            profile={userDetail}
            readonly={true}
            isTopCourses={true} />
        <MembersRank
          title='Members That Have Played the Most Top 100 U.S. Courses'
          field=''
          profile={userDetail}
          readonly={true}
          isTopCourses={true}
          members={membersRank} />
      </div>
    </PaperMasterHoc>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    membersRank: state.members.membersRank
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetMembersRank
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(TopCoursesContainer);
