import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfiniteScroll from "react-infinite-scroll-component";
import MemberBox from '../../components/memberBox';
import PaperMasterHoc from '../../components/paperMasterHoc';
import { paths } from '../../utils/paths';
import { handleGetApplicantsHome } from '../../reducers/members';
import moment from 'moment';
import { userRoles } from '../../utils/profiles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 'auto',
  },
  filterContainer: {
    display: 'flex', 
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column', 
    },
  },
  filterItem: {
    flex: 3.3,
    margin: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0
    },
  },
  tableHeader: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }
}))

const MembersReviewContainer = ({
  history,
  userDetail,
  applicantList,
  handleGetApplicantsHome,
  memberList
}) => {
  const classes = useStyles();

  useEffect(() => {
    handleGetApplicantsHome()
  }, [handleGetApplicantsHome])

  if (!userDetail || !userDetail.email) { return <div /> }

  return (
    <PaperMasterHoc transparent={true}>
      <div className={classes.root}>
        <Typography style={{marginBottom: '25px'}} variant="h4">New Applicants</Typography>
        { applicantList.length > 0 &&  
        <React.Fragment>
          <Typography style={{ marginBottom: '15px' }}>The following have been proposed to join us as Captain’s Club members and are expected to become members within the next month or so pending member feedback. If you have any feedback about the applicants below that you’d like to share with the membership committee, please let us know by emailing <a href={`mailto:membership+applicants@captains.club`} style={{ color: 'rgb(0, 0, 238)', textDecoration: 'none' }} >membership+applicants@captains.club</a>. </Typography>
        </React.Fragment> }
        {applicantList.length === 0 && <Typography style={{marginTop: '65px', textAlign: 'center'}} variant="h5">No new applicants to review</Typography>}
        <InfiniteScroll
          dataLength={applicantList.length}
          style={{ overflow: 'inherit' }}
        >
          {applicantList.length > 0 && 
          <Grid className={classes.tableHeader} container spacing={2}>
            <Grid item xs={2} style={{ paddingLeft: '15px' }} >
              <h4>Application Date</h4>
            </Grid>
            <Grid item xs={5}  style={{ paddingLeft: '45px' }}>
              <h4>Applicant</h4>
            </Grid>
            <Grid item xs={5}  style={{ paddingLeft: '45px' }}>
              <h4>Referred By</h4>
            </Grid>
          </Grid> }

          {applicantList.map((member, idx) => {
            const referredUser = memberList?.filter(item => item.username === member?.referrer)[0]
            return (
              <Paper key={member.email}>
                <Grid container spacing={1} style={{ marginBottom: '15px', }}>
                  <Grid item md={2} sm={12} style={{ padding: '15px' }}>
                    <h4>{moment(member.application_date).format("MM/DD/YYYY")}</h4>
                  </Grid>
                  <Grid item md={5} sm={6}>
                    <MemberBox
                      type={userRoles.memberReview}
                      key={idx} 
                      member={member} 
                      playerClick={() => history.push(`${paths.profile}/${member.email}`)}
                    />
                  </Grid>
                  <Grid item md={5} sm={6}>
                    { referredUser && 
                      <MemberBox
                        type={userRoles.memberReview}
                        key={referredUser.email} 
                        member={referredUser} 
                        playerClick={() => history.push(`${paths.profile}/${referredUser.username}`)}
                    /> }
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
        </InfiniteScroll>
      </div>
    </PaperMasterHoc>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    applicantList: state.members.applicantList,
    memberList: state.members.memberList,
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetApplicantsHome
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(MembersReviewContainer);
