import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { handleForgotPassword } from '../../reducers/auth'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.profile.background,
    minHeight: 'calc(100vh - 80px - 64px)',
  },
  content: {
    margin: 'auto',
    padding: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 0
    }
  },
  section: {
    padding: 20
  },
  accountTitle: {
    display: 'flex',
    padding: '16px',
    fontSize: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
        padding: '32px 12px 16px',
    },
    color: theme.palette.profile.dark
  },
  buttonSection: {
    paddingTop: 16
  },
}))

const AccountView = ({ email, handleForgotPassword }) => {
  const classes = useStyles()
  const submitPasswordUpdate = (evt) => {
    if (evt.preventDefault) { evt.preventDefault() }
    handleForgotPassword(email)
  }
  return (
  <React.Fragment>
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography component='div' className={classes.accountTitle}>Change Password</Typography>
        <Paper style={{padding: 24}}>
          <Typography>To change your password, click the Send button below.  <strong>You will be immediately logged out</strong> and an email will be sent to update your password.  Use the password link in your email to update your password.</Typography>
          <div className={classes.buttonSection}>
            <Button variant="contained" onClick={submitPasswordUpdate}>Send Password Reset</Button>
          </div>
        </Paper>
      </div>
    </div>
  </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    email: state.auth.session.email
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleForgotPassword: handleForgotPassword
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(AccountView)
