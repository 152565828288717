import { Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { paths } from '../../utils/paths';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  link: {
    color: theme.palette.link?.main,
    cursor: 'pointer'
  },
  content: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .subtext': {
      maxWidth: '500px',
      textAlign: 'center'
    }
  }
});

const ApplicantHome = ({ classes, history }) => (
  <main data-testid="applicant-home" className={classes?.main}>
    <div className={classes?.content}>
      <Typography variant="h4" align="center" gutterBottom>
        Thank you for applying to join Captain's Club
      </Typography>
      <Typography component="div" variant="body1" align="center" className='subtext' gutterBottom>
        We will notify you regarding next steps.
        <div style={{marginTop: 16}}>In the meantime, we’d love to have you join us for our upcoming golf events.</div>
        <p>
          <Link className={classes?.link} href="http://captainsclub.golf/events">Event Registration</Link>
        </p>
        <p>
          <Link className={classes?.link} onClick={() => history.push(paths.onboarding)}>View/Edit your Captain's Club application</Link>
        </p>
      </Typography>
    </div>
  </main>
)


export default withRouter(withStyles(styles)(ApplicantHome))
