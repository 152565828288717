import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, DialogContent, TextField, Typography } from '@material-ui/core';
import { handleProfileSearch } from '../../reducers/members'
import AutoSelect from '../../components/auto_select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AdminPostForm from './postForm';

const useStyles = makeStyles(theme => ({
    centerPaper: {
        display: 'flex',
        justifyContent: 'center',
    }
}));

const PostDialog = ({members, handleProfileSearch, open, toggleDialog}) => {
    const [searchValue, setSearchValue] = React.useState()
    const [selectedMember, setSelectedMember] = React.useState()
    const [message, setMessage] = React.useState('')
    const dialogRef = React.useRef(null)
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const formatMembers = (memberList) => {    
        if (!memberList || memberList.length === 0) { return [] }
        if (!Array.isArray(memberList)) { memberList = [memberList] }
        return memberList.filter(x => x.email && x.role !== 'banned').map(option => ({
            value: option.value || option.email,
            label: option.label || (option.first_name + ' ' + option.last_name),
            logo: option.profile_image || '',
            email: option.email || '',
            first_name: option.first_name || '',
            last_name: option.last_name || '',
            role: option.role || 'member'
        }))
    }
    const formatForAutoSelect = (data) => {
        if (!data || data.length === 0) { return [] }
        if (!Array.isArray(data)) { data = [data] }
        return data.map((item) => ({
            value: item.value || item,
            label: item.label || item,
            logo: item.logo,
            email: item.email || '',
            first_name: item.first_name || '',
            last_name: item.last_name || '',
            role: item.role
        }));
    }
    const getSelectedValues = (memberList) => {
      return formatForAutoSelect(memberList)
    }
    const handleTextChange = name => value => {
        if (value.target) { return }
        if (value !== searchValue) {
          setSearchValue(value)
          handleProfileSearch(value)
        }   
    }
    const handleAutoSelectChange = name => item => {  
        setSelectedMember(item)
    }
    const onSubmit = () => {
        setSelectedMember(null)
        setMessage('')
        toggleDialog(false)
    }
    return (
        <Dialog
            ref={dialogRef}
            open={open}
            maxWidth="md"
            fullWidth
            onClose={() => toggleDialog(false)}
            fullScreen={fullScreen}
            classes={{paper: classes.centerPaper}}    
        >
            <DialogContent style={{marginBottom: 16, height: '80vh', display: 'flex', flexDirection: 'column'}}>
                <Typography variant="subtitle1">Create Post</Typography>
                <AutoSelect
                    rootStyle={{flexGrow: 'initial'}}
                    isMulti={false}
                    name='members'
                    menuPortalTarget={dialogRef.current}
                    textFieldProps={{
                        label: 'Member to post from'
                    }}
                    placeholder={''}
                    options={formatMembers(members)}
                    selectedValue={getSelectedValues(selectedMember)}
                    handleAutoSelectTextChange={handleTextChange}
                    handleAutoSelectChange={handleAutoSelectChange} />
                <TextField
                    label="Notification Message"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={message || ''}
                    onChange={(event) => setMessage(event.target.value)}
                />
                <AdminPostForm 
                    sender_email={selectedMember ? selectedMember.email : ''} 
                    message={message}
                    onSubmit={onSubmit}
                />
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        members: state.members.searchResults,
    }
  }
  const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleProfileSearch
    }, dispatch)
  }
  
  export default connect(mapStateToProps, mapDispatch)(PostDialog)
