import React from "react";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import UserAvatar from "./userAvatar";
import { handleShowDialogPost } from "../reducers/posts";
import { parser } from "../utils/parser";
import { paths } from "../utils/paths";

const useStyles = makeStyles((theme) => ({
	notificationItem: {
		display: "flex",
		flexGrow: 1,
		alignItems: "center",
		borderBottom: "solid 1px #e7e7ea",
		padding: 8,
	},
}));

const NotificationTagged = ({
	posts,
	message,
	handleShowDialogPost,
	closeNotifications,
}) => {
	const classes = useStyles();

	const post = posts.find(
		(x) =>
			message.data.post_id &&
			Number(x.id) === Number(message.data.post_id)
	);

	const onPostClick = () => {
		handleShowDialogPost(post.id);
		closeNotifications();
	};

	if (!post) {
		return <div />;
	}

	return (
		<div className={classes.notificationItem}>
			<Link
				onClick={() => closeNotifications()}
				to={`${paths.profile}/` + message.data.sender_username}
				style={{ marginRight: 8 }}
			>
				<UserAvatar
					profileImage={message.data.sender_profile_image}
					width={32}
				/>
			</Link>

			<Typography variant="caption">
				<Link
					onClick={() => closeNotifications()}
					to={`${paths.profile}/` + message.data.sender_username}
				>
					{message.data.sender_name ||
						`@${message.data.sender_username}`}
				</Link>

				<span> has tagged you in a </span>

				<Link onClick={() => onPostClick()} to={paths.home}>
					post
				</Link>

				{message.read && (
					<div style={{ fontSize: 10 }}>
						{parser.dateDistance(message.issued_at)} ago
					</div>
				)}
			</Typography>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		posts: state.posts.posts,
	};
};
const mapDispatch = (dispatch) => {
	return bindActionCreators(
		{
			handleShowDialogPost,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatch)(NotificationTagged);
