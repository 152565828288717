import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AcceptGroupInviteButton from '../../components/AcceptGroupInviteButton';
import BannerImage from '../../components/bannerImage';
import DeclineGroupInviteButton from '../../components/DeclineGroupInviteButton';
import { handleClearError, handleGetGroupDetail, handleUpdateGroup, handleVerifyMembership } from '../../reducers/groups';
import { handleGetAllPosts } from '../../reducers/posts';
import { paths } from '../../utils/paths';
import DashboardContainer from '../dashboard/dashboardContainer';
import AddNewMembers from './addGroupMembers';
import DeleteGroup from './deleteGroup';
import GroupHeader from './groupHeader';
import GroupMembers from './groupMembers';
import GroupRequestWidget from './groupRequestWidget';
import GuestWidget from './guestWidget';

const useStyles = makeStyles(theme => ({
  flexColumn: {
    display: 'flex', 
    flexDirection: 'column'
  },
  verifyBox: {
    padding: '40px 20px',
    width: theme.spacing(50),
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '40px 16px',
    },
  }
}))
const GroupContainer = (
  {
    userDetail,
    handleUpdateGroup,
    handleGetGroupDetail,
    handleClearError, 
    handleVerifyMembership,
    handleGetAllPosts,
    groupLoading, 
    groupDetail, 
    groupError, 
    match, 
    history}) => {
  const classes = useStyles()
  React.useEffect(() => {
    if (groupLoading) { return }
    if (groupError) {
      handleClearError()
      history.push(paths.home)
      return
    }
    if (!groupDetail || match.params.slug !== groupDetail.url) {
      handleGetGroupDetail(match.params.slug)
      return
    }
  }, [handleGetGroupDetail, handleClearError, match.params.slug, groupDetail, groupLoading, groupError, history]);
  React.useEffect(() => {
    return () => {
      handleGetGroupDetail()
    }
  }, [handleGetGroupDetail]);
  React.useEffect(() => {
    if (groupDetail) {
      handleGetAllPosts(groupDetail.id)
    }
  }, [handleGetAllPosts, groupDetail])
  const updateBannerImage = (file) => {
    const newGroup = {...groupDetail, group_members: groupDetail.group_members.filter(x => x.username !== userDetail.username).map(g => g.member_email), cover_image: file}
    handleUpdateGroup(newGroup)
  }
  if (!groupDetail || !groupDetail.id) { return <div /> }
  const isGroupOwner = userDetail && groupDetail && userDetail.email === groupDetail.group_owner
  const membership = groupDetail.group_members.find(x => x.member_email === userDetail.email)
  const accepted = membership && membership.accepted

  return (
  // <React.Fragment>
  //   {!groupDetail || match.params.slug !== groupDetail.url || groupLoading ? <div style={{display: 'flex', padding: '200px 0', justifyContent: 'center', alignItems: 'center'}}>
  //     <CircularProgress size={96} /></div> :
    <React.Fragment>
      <BannerImage
        isEditable={isGroupOwner}
        handleUpdateBannerImage={updateBannerImage}
        imagePath={groupDetail.cover_image} />
      <div style={{padding: 8}}>
        <Typography variant="subtitle1" style={{display: 'flex', justifyContent: 'flex-end'}}>{(groupDetail.is_public ? 'Public' : 'Private') + ' group'}</Typography>
        <GroupHeader group={groupDetail} />
        {accepted && <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.flexColumn}>
              {isGroupOwner && <AddNewMembers group={groupDetail} />}
              {isGroupOwner && groupDetail.group_requests && 
                groupDetail.group_requests.filter(x => !x.declined).length > 0 && <GroupRequestWidget group={groupDetail} />}
              <GroupMembers group={groupDetail} />
              <GuestWidget group={groupDetail} />
              {isGroupOwner && <DeleteGroup group={groupDetail} />}
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <DashboardContainer groupId={groupDetail.id} />
          </Grid>
        </Grid>}
        {!accepted && <div className={classes.verifyBox}>
          <Typography variant="subtitle1">You have been invited to join this group</Typography>
          <div style={{display: 'flex', justifyContent: 'space-between', padding: 30}}>
            <AcceptGroupInviteButton handleVerifyMembership={handleVerifyMembership} groupDetail={groupDetail} />
            <DeclineGroupInviteButton handleVerifyMembership={handleVerifyMembership} groupDetail={groupDetail} history={history} />
          </div>
        </div>}
      </div>
    </React.Fragment>
  //   }
  // </React.Fragment>
    
  )
}
const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    groupDetail: state.groups.groupDetail,
    groupLoading: state.groups.isLoading,
    groupError: state.groups.error
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetGroupDetail,
    handleVerifyMembership,
    handleUpdateGroup,
    handleClearError,
    handleGetAllPosts,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(GroupContainer)
