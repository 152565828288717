import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CourseTable from './courseTable';

const styles = theme => ({
  root: {
    minWidth: 480
  },
  loader: {
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(20), 
  },
  loaderIcon: {
  }
})

const CoursesContainer = ({classes, handleAddClub}) => {
  return (
    <div className={classes.root}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography component='p' margin='normal' variant='h5'>Manage Courses Rank</Typography>
      </div>
      <div style={{marginTop: 24}}>
        <CourseTable />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(CoursesContainer))
