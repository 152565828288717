// import cls from 'classnames';
import { FormControl, Grid, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { parser } from '../utils/parser';
import AutoSelect from './auto_select';

const styles = theme => ({
  // gridBox: {
  //   paddingTop: '0 !important',
  //   paddingBottom: '0 !important'
  // },
  formControl: {
    // padding: 0,
    // marginTop: 0
  },
  textField: {
    // marginTop: 16
  }
});

const AddressForm = ({ address, classes, updateAddress }) => {
  const setSelectedAddressState = (value) => {
    if (!value || value.label) { return value || '' }
    const stateList = parser.getStateList()
    const st = stateList.find(x => x.label === value || x.value === value);
    return st || value
  }
  const validateZip = (event) => {
    const zip = event.target.value || ''
    if (parser.isValidZip(zip)) {
      handleAddressChange({target: {name: 'zip', value: zip}})  
    }
  }
  const handleAutoSelectChange = name => list => {
    handleAddressChange({target: {name: name, value: list}}) 
  }
  const handleAddressChange = (event) => {
    let newAddress = {...address, [event.target.name]: event.target.value}
    updateAddress({...newAddress})
  }
  return (
    <Grid container className={classes.gridBox} spacing={2}>
      <Grid item className={classes.gridBox} md={6} xs={12}>
        <TextField
          id="address_1"
          name="address_1"
          label="Street Address"
          fullWidth
          margin="none"
          variant="outlined"
          value={address.address_1 || ''}
          onChange={handleAddressChange}
          className={classes.textField}
        />
      </Grid>
      <Grid item className={classes.gridBox} md={6} xs={12}>
        <TextField
            id="city"
            name="city"
            label="City"
            fullWidth
            margin="none"
            variant="outlined"
            value={address.city || ''}
            onChange={handleAddressChange}
            className={classes.textField}
          />
      </Grid>
      <Grid item className={classes.gridBox} md={6} xs={12}>
        <FormControl fullWidth className={classes.formControl}>
          <AutoSelect
            name='state'
            options={parser.getStateList()}
            placeholder="State"
            selectedValue={setSelectedAddressState(address.state) || ''}
            handleAutoSelectChange={handleAutoSelectChange} />
        </FormControl>
      </Grid>
      <Grid item className={classes.gridBox} md={6} xs={12}>
        <TextField
          id="zip"
          name="zip"
          label="Zip"
          fullWidth
          margin="none"
          variant="outlined"
          value={address.zip || ''}
          onChange={validateZip}
        />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(AddressForm);
