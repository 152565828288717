

import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import MoveIcon from '@material-ui/icons/Menu'
import { handleReorderEvents } from '../../reducers/events'

const EventFeaturedSort = ({open, toggleDialog, items, handleReorderEvents}) => {
  const [list, setList] = React.useState([])
  React.useEffect(() => {
    setList(items)
  }, [items]);
  const handleConfirm = () => {
    const orderedList = list.map((item, idx) => {
      return  {
        id: item.id,
        sort_order: idx
      }
    })
    handleReorderEvents(orderedList)
    toggleDialog()
  }
  const handleDragEnd = (result) => {
    if (!result.destination) { return } // dropped outside the list
    const newList = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    setList(newList)
  }
  const reorder = (localList, startIndex, endIndex) => {
    const result = Array.from(localList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
  
    // // change background colour if dragging
    background: isDragging ? "#DDD" : "#FFF",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
  return (
      <Dialog
          open={open}
          onClose={() => toggleDialog()}
          fullWidth
          maxWidth={'sm'}
      >
        <DialogTitle style={{paddingBottom: 0}}>Sort Featured Events</DialogTitle>
        <DialogContent>
          <div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {list && list.map((item, idx) => (
                    <Draggable key={item.id} draggableId={item.id} index={idx}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div style={{display: 'flex', padding: 16,  border: 'solid 1px #ccc', marginBottom: 8}}>
                            <MoveIcon style={{cursor: 'grab', alignSelf: 'center', marginRight: 8}} />
                            <Typography>{item.name}</Typography>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          </div>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleConfirm}>Save</Button>
            <Button variant="outlined" onClick={() => toggleDialog()}>Cancel</Button>
        </DialogActions>
    </Dialog>
  )
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleReorderEvents
  }, dispatch)
}

export default connect(null, mapDispatch)(EventFeaturedSort)
