export const types = {
    GET_LISTS_REQUEST: 'LISTS/GET_LISTS_REQUEST',
    GET_LISTS_SUCCESS: 'LISTS/GET_LISTS_SUCCESS',
    GET_LISTS_FAILURE: 'LISTS/GET_LISTS_FAILURE',
    SUBMIT_LISTS_REQUEST: 'LISTS/SUBMIT_LISTS_REQUEST',
    SUBMIT_LISTS_SUCCESS: 'LISTS/SUBMIT_LISTS_SUCCESS',
    SUBMIT_LISTS_FAILURE: 'LISTS/SUBMIT_LISTS_FAILURE',
    UPDATE_LISTS_REQUEST: 'LISTS/UPDATE_LISTS_REQUEST',
    UPDATE_LISTS_SUCCESS: 'LISTS/UPDATE_LISTS_SUCCESS',
    UPDATE_LISTS_FAILURE: 'LISTS/UPDATE_LISTS_FAILURE',
    DELETE_LISTS_REQUEST: 'LISTS/DELETE_LISTS_REQUEST',
    DELETE_LISTS_SUCCESS: 'LISTS/DELETE_LISTS_SUCCESS',
    DELETE_LISTS_FAILURE: 'LISTS/DELETE_LISTS_FAILURE',

    ADD_MEMBER_REQUEST: 'LISTS/ADD_MEMBER_REQUEST',
    ADD_MEMBER_SUCCESS: 'LISTS/ADD_MEMBER_SUCCESS',
    ADD_MEMBER_FAILURE: 'LISTS/ADD_MEMBER_FAILURE',

    REMOVE_MEMBER_REQUEST: 'LISTS/REMOVE_MEMBER_REQUEST',
    REMOVE_MEMBER_SUCCESS: 'LISTS/REMOVE_MEMBER_SUCCESS',
    REMOVE_MEMBER_FAILURE: 'LISTS/REMOVE_MEMBER_FAILURE',

    SELECT_MEMBER_SUCCESS: 'LISTS/SELECT_MEMBER_SUCCESS',
    SELECT_MEMBER_CLEAN: 'LISTS/SELECT_MEMBER_CLEAN',
    SELECT_MEMBER_FAILURE: 'LISTS/SELECT_MEMBER_FAILURE',
};
  
export const initialState = {
    lists: [],
    selectedMember: null,
    isLoading: false,
    error: {}
};

export const handleSelectMemberLists = (member) => (dispatch, getState) => {
    dispatch({
        type: types.SELECT_MEMBER_SUCCESS,
        data: member
    });
};

export const handleCleanSelectedMemberLists = () => (dispatch, getState) => {
    dispatch({
        type: types.SELECT_MEMBER_CLEAN,
        data: null
    });
};

export const handleGetLists = (limit, skip) => (dispatch, getState) => {
    dispatch({
    types: [
        types.GET_LISTS_REQUEST,
        types.GET_LISTS_SUCCESS,
        types.GET_LISTS_FAILURE,
    ],
    url: '/lists',
    query: {
        skip,
        limit
    },
    type: 'GET_LISTS'
    });
};

export const handleSubmitList = (
    list_name,
    rank
  ) => (dispatch) => {
    const requestData = {
        name: list_name || '',
        rank: Number(rank),
    };
  
    dispatch({
      types: [
        types.SUBMIT_LISTS_REQUEST,
        types.SUBMIT_LISTS_SUCCESS,
        types.SUBMIT_LISTS_FAILURE,
      ],
      url: `/lists`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(requestData),
      callback: handleGetLists()
    });
};

export const handleAddMemberList = (
    list_id,
    member_email,
    rank
  ) => (dispatch) => {
    const requestData = {
        member_email: member_email || '',
        rank: Number(rank),
    };
  
    dispatch({
      types: [
        types.ADD_MEMBER_REQUEST,
        types.ADD_MEMBER_SUCCESS,
        types.ADD_MEMBER_FAILURE,
      ],
      url: `/lists/${list_id}/items`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(requestData),
      callback: handleGetLists()
    });
};

export const handleRemoveMemberList = (
    list_id,
    list_users_id,
  ) => (dispatch) => {  
    dispatch({
      types: [
        types.REMOVE_MEMBER_REQUEST,
        types.REMOVE_MEMBER_SUCCESS,
        types.REMOVE_MEMBER_FAILURE,
      ],
      url: `/lists/${list_id}/items/${list_users_id}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {},
      callback: handleGetLists()
    });
};

export const handleRemoveList = (
    list_id,
  ) => (dispatch) => {  
    dispatch({
      types: [
        types.DELETE_LISTS_REQUEST,
        types.DELETE_LISTS_SUCCESS,
        types.DELETE_LISTS_FAILURE,
      ],
      url: `/lists/${list_id}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {},
      callback: handleGetLists()
    });
};

export const handleUpdateList = (
    list_id,
    list_name,
    rank
  ) => (dispatch) => {
    const requestData = {
        name: list_name || '',
        rank: Number(rank),
    };
  
    dispatch({
      types: [
        types.UPDATE_LISTS_REQUEST,
        types.UPDATE_LISTS_SUCCESS,
        types.UPDATE_LISTS_FAILURE,
      ],
      url: `/lists/${list_id}`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(requestData),
      callback: handleGetLists()
    });
};

export default function (state = initialState, action) {
    switch (action.type) {  
        case types.GET_LISTS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }
        
        case types.GET_LISTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                lists: action.data,
            };
        }

        case types.GET_LISTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                lists: [],
                error: action.error
            };
        }

        case types.SUBMIT_LISTS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case types.SUBMIT_LISTS_SUCCESS: {
            return { ...state, isLoading: false };
        }
      
        case types.SUBMIT_LISTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }

        case types.ADD_MEMBER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case types.ADD_MEMBER_SUCCESS: {
            return { ...state, isLoading: false };
        }
      
        case types.ADD_MEMBER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }

        case types.REMOVE_MEMBER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case types.REMOVE_MEMBER_SUCCESS: {
            return { ...state, isLoading: false };
        }
      
        case types.REMOVE_MEMBER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }

        case types.SELECT_MEMBER_SUCCESS: {
            return {
                ...state,
                selectedMember: action.data,
                error: null,
            }
        }

        case types.SELECT_MEMBER_CLEAN: {
            return {
                ...state,
                selectedMember: null,
                error: null,
            }
        }

        default:
            return state;
    }
}