import { IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UserAvatar from '../../components/userAvatar';
import WidgetContainer from '../../components/widgetContainer';
import WidgetElement from '../../components/widgetElement';
import { handleUpdateGroup, handleVerifyMembership } from '../../reducers/groups';
import { paths } from '../../utils/paths';
import { getUserMemberships } from '../../utils/users';
import GolfIcon from '@material-ui/icons/GolfCourse';

const useStyles = makeStyles(theme => ({
  memberWrap: {
    display: 'flex', 
    flexWrap: 'wrap', 
    alignItems: 'flex-start', 
    marginBottom: 4, 
    padding: '4px',
    backgroundColor: theme.palette.lightGray.main,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  memberName: {
    display: 'flex',
    fontSize: 14,
    fontFamily: "Sentinel-Black,Roboto,sans-serif",
    fontWeight: 400,
    lineHeight: 1
  }
}))

const GroupMembers = ({ userDetail, membersVisible, group, history, handleUpdateGroup, handleVerifyMembership }) => {
  const classes = useStyles()
  const members = (group.group_members || []).filter(x => x.role === 'member')
  const owner = group.group_owner
  const visibleMemberList = (membersVisible || []).map(x => x.email)
  const handleDeleteMember = (member) => {
    if (member.member_email === userDetail.email && window.confirm(`Are you sure you want to remove yourself from this group?`)) {
      history.push(paths.home)
      handleVerifyMembership(group, false)
    } else if (window.confirm(`Are you sure about deleting ${member.first_name} ${member.last_name} from this group?`)) {
      const newGroup = {...group, group_members: group.group_members.filter(m => m.member_email !== userDetail.email && m.member_email !== member.member_email).map(x => x.member_email)}
      handleUpdateGroup(newGroup)
    }
  }
  
  return (
    <WidgetContainer header="Members">
      {members && members.map((member, idx) => {
        const memberUpdated = membersVisible?.filter(m => m.email === member.member_email)[0]
        const myMemberships = getUserMemberships(member.member_email, membersVisible)
        const allowClick = Boolean((userDetail.role === 'member' || userDetail.referrer === member.username) && visibleMemberList.includes(member.member_email))
        return (<div key={idx}>
          <WidgetElement noClick={!allowClick}>
            <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
              <div style={{ display: 'flex' , flexDirection: 'column'}}>
                <div style={{display: 'flex', width: '100%', alignItems: 'center', padding: '6px 0'}} 
                  onClick={() => allowClick ? history.push(`${paths.profile}/${member.username}`) : {}}>
                  <div style={{marginRight: 8}}>
                    <UserAvatar profileImage={visibleMemberList.includes(member.member_email) ? member.profile_image : null} width={24} />
                  </div>
                  <div>
                    <Typography component="div" className={classes.memberName}>
                      {visibleMemberList.includes(member.member_email) ? `${member.first_name} ${member.last_name}` : 'Anonymous'}
                      {member.member_email === userDetail.email && userDetail.privacy_setting === 'anonymous' && 
                        <Typography variant="body2" style={{fontSize: 11, paddingLeft: 8}}><Link to={paths.profile}>(Anonymous)</Link></Typography>}
                    </Typography>                
                    {!member.accepted &&
                      <Typography variant="caption" style={{fontSize: 11}}>(Invited, not joined)</Typography>
                    }
                    {owner === member.member_email &&
                      <Typography variant="caption" style={{fontSize: 11}}>(Group Owner)</Typography>
                    }
                  </div>                       
                </div>
                <div>
                  <Typography variant="body2" style={{display: 'flex', alignItems: 'center', padding: '2px 0', fontSize: 10, paddingLeft: 30}} >
                    {memberUpdated?.address && memberUpdated?.address.city ? memberUpdated?.address.city + ', ' + memberUpdated?.address.state : '' }
                    {memberUpdated?.industry ? <span style={{color: '#777'}}><span style={{margin: '0 8px'}}>&bull;</span>{memberUpdated?.industry} Industry</span> : ''}
                  </Typography>
                </div>
              </div>
              {(member.member_email === userDetail.email || owner === userDetail.email) && owner !== member.member_email &&
                <div style={{marginLeft: 'auto'}}>
                  <IconButton onClick={() => handleDeleteMember(member)}><DeleteIcon /></IconButton>
                </div>
              }
            </div>
            <div  style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{paddingLeft: 30}}>
              {myMemberships && myMemberships.map((item, idx) => {
                return (
                  <Typography key={idx} variant="body2" component="div" style={{display: 'flex', alignItems: 'center', padding: '2px 0', fontSize: 10}}>
                    {item.club_logo && <div style={{marginRight: 8}}><UserAvatar profileImage={item.club_logo} width={24} isClub={true} /></div>}
                    <div>{item.name} {item.address && item.address.city ? '(' + item.address.city + ', ' + item.address.state + ')' : ''}</div>
                  </Typography>
                )
              })}
              </div>
              <div style={{ paddingLeft: 30 }} >
                <Typography variant="subtitle2" style={{ display: 'flex', flexDirection: 'row', fontSize: 10, alignItems: 'center' }} >
                  {memberUpdated?.handicap !== null && <div className={classes.handicap}>
                    <GolfIcon style={{ verticalAlign: 'middle', marginRight: 4, width: 15 }} />Handicap {memberUpdated?.handicap}
                  </div>}
                  {memberUpdated?.best_gross_round && <span><span style={{margin: '0 8px'}}>&bull;</span>Best Round: {memberUpdated?.best_gross_round}</span>}
                </Typography>
              </div>
            </div>
          </WidgetElement>
        </div>)
      })}
    </WidgetContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    membersVisible: state.members.membersVisible
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleUpdateGroup,
    handleVerifyMembership
  }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatch)(GroupMembers))
