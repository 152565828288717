import React from 'react';
import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import cls from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  iconStatus: { color: '#ddd' },
  hoverGreen: {
      '&:hover': { backgroundColor: 'green', color: 'white' }
  },
  accept: { color: 'green', '&.mine:hover': { color: 'white' } },
}))

const AcceptButton = (props) => {
  const { inviteStatus } = props
  const classes = useStyles()
  
  return (
    <>
      <IconButton onClick={props.onClick} className={classes.hoverGreen}>
        <CheckIcon className={cls(classes.iconStatus, 'mine', {[classes.accept]: inviteStatus}) } />
      </IconButton>
    </>)
}

export default AcceptButton