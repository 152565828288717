import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import QuestionIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MemberSelector from '../../components/memberSelector';
import { handleClearError, handleSubmitGroup, handleUpdateGroup } from '../../reducers/groups';

const useStyles = makeStyles(theme => ({
    textField: {
        minWidth: 300
        // margin: 0,
        // marginBottom: theme.spacing(2)
    },
    dialogPaper: {
        overflowY: 'unset',
        minWidth: 700,
        [theme.breakpoints.down('sm')]: {
            minWidth: 200,
            width: '100%'
        },
    },
    multiLineTextBox: {
        margin: '16px 0',
        '& textarea': {
          fontFamily: theme.typography.secondaryFont
        },
        '& .MuiOutlinedInput-multiline': {
          padding: '10px 14px'
        }
    },
}));

function GroupFormDialog ({userDetail, open, toggleDialog, group, submitSuccess, submitError, handleClearError, handleSubmitGroup, handleUpdateGroup}) {
    const classes = useStyles()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const [isPublic, setPublic] = React.useState(false)
    const [groupName, setGroupName] = React.useState('')
    const [groupDescription, setGroupDescription] = React.useState('')
    const [groupMembers, setGroupMembers] = React.useState([])
    const [guestList, setGuestList] = React.useState([])
    const [archived, setArchived] = React.useState(false)
    React.useEffect(() => {
        if (group && group.id) {
            setGroupName(group.name)
            setGroupDescription(group.description)
            setPublic(group.is_public)
            setArchived(group.archived)
            setGroupMembers(group.group_members.filter(x => x.username !== userDetail.username).map(item => (
                {
                    value: item.member_email,
                    label: item.member_email + ' (@' + item.username + ')',
                    logo: item.profile_image || '',
                    role: 'member'
                }
            )))
            setGuestList((group.guest_list || []).map(item => (
                {
                    first_name: item.first_name,
                    last_name: item.last_name,
                    email: item.email,
                    value: item.email,
                    label: item.first_name + ' ' + item.last_name,
                    role: 'guest'
                }
            )))
        }
    }, [userDetail, group, open]);
    React.useEffect(() => {
        if (submitSuccess) {
            cancelClick()
            handleClearError()
        }
    });
    const saveClick = () => {
        if (group && group.id) { 
            const newGroup = {...group, name: groupName, description: groupDescription, is_public: isPublic, archived, guest_list: guestList, group_members: groupMembers.map(x => x.value)}
            handleUpdateGroup(newGroup)
        } else {
            handleSubmitGroup(
                groupName, 
                groupDescription, 
                isPublic,
                archived,
                guestList.map(x => ({first_name: x.first_name, last_name: x.last_name, email: x.email.trim().toLowerCase()})),
                groupMembers.filter(x => x.role === 'member').map(y => y.value)
            )
        }
    }
    const cancelClick = () => {
        toggleDialog(false)
        setGroupName('')
        setGroupDescription('')
        setGroupMembers([])
        setGuestList([])
        setPublic(false)
    }
    const handleNameChange = (event) => {
        // ^ for start
        // match alphanumeric with a \w, which is the same as [A-Za-z0-9_]
        // \- is for hyphen
        // ' is just an apostrophe
        // \s is for space
        // * means zero or more characters
        // + means at least one character
        // {0,40} means any number of characters between 0 and 40. Need 0 to clear the textbox
        // $ for end
        const pattern = new RegExp(/^[\w\-'\s]{0,40}$/)
        const value = event.target.value
        if (pattern.test(value)) {
            setGroupName(value)
            if (submitError) {
                handleClearError()
            }
        }
    }
    return (
        <Dialog
            open={open}
            onClose={() => toggleDialog(false)}
            fullScreen={fullScreen}
            scroll={'body'}
            classes={!fullScreen ? {paper: classes.dialogPaper} : {}}
        >
            <DialogTitle id="alert-dialog-title">{group ? 'Modify Group' : 'Create New Group'}</DialogTitle>
            <DialogContent style={{padding: '0 24px 8px', overflowY: 'unset'}}>
                {!group && <div style={{border: 'solid 1px #f0f0f0', borderRadius: 4, backgroundColor: theme.palette.primary.main, padding: 16}}>
                    <Typography variant="subtitle1" style={{color: '#FFF'}}>Groups are great for coordinating golf games, planning trips, or managing private communications.</Typography>
                </div>}
                <TextField
                    id="name"
                    name="name"
                    label="Name your group"
                    required
                    fullWidth
                    disabled={Boolean(group && group.url)}
                    margin="normal"
                    variant="outlined"
                    value={groupName || ''}
                    onChange={handleNameChange}
                    className={classes.textField} />
                <TextField
                    id="description"
                    name="description"
                    label="Provide a brief description"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={groupDescription}
                    onChange={(event) => setGroupDescription(event.target.value)}
                    className={classes.multiLineTextBox }
                    multiline
                    rows="3"
                    placeholder=""
                    // InputLabelProps={{ shrink: true }}
                    />
                <MemberSelector
                    groupMembers={groupMembers}
                    guestList={guestList}
                    guestListEvent={list => setGuestList(list || [])}
                    listChangeEvent={(list) => setGroupMembers(list || [])}
                    excludeEmails={group ? group.group_members.map(x => x.member_email) : [userDetail.email]}
                    isGroup={true}
                    />
                <FormControlLabel
                    control={<Checkbox checked={isPublic} onChange={(event => setPublic(event.target.checked))}  name='isPublic' color="primary" />}
                    label="Make this Group Public" 
                    style={{padding: '16px 0', marginRight: 4}} />
                
                <Tooltip 
                    title={
                        <Typography style={{fontSize: 14}}>
                            Captain's Club members can find public groups and request to join them.  As the group creator, you can choose who you allow to join.
                        </Typography>
                    }
                    placement="top-start"
                    style={{ marginRight: 15 }}>
                    <QuestionIcon style={{fontSize: 20, marginRight: 15}} />
                </Tooltip>
                <FormControlLabel
                    control={<Checkbox checked={archived} onChange={(event => setArchived(event.target.checked))}  name='archived' color="primary" />}
                    label="Archive Group" 
                    style={{padding: '16px 0', marginRight: 4}} />
                {submitError && <Typography variant="subtitle1" color="error">{submitError}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button disabled={!groupName} onClick={saveClick} variant="contained">{group ? 'Save' : 'Create'}</Button>
                <Button onClick={cancelClick} variant="outlined">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
      userDetail: state.user.userDetail,
      isLoading: state.groups.isLoading,
      submitSuccess: state.groups.submitSuccess,
      submitError: state.groups.submitError
    }
  }
  const mapDispatch = (dispatch) => {
    return bindActionCreators({
        handleSubmitGroup,
        handleUpdateGroup,
        handleClearError
    }, dispatch)
  }
  
export default connect(mapStateToProps, mapDispatch)(GroupFormDialog)
