import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import SectionTitle from './sectionTitle';
import { handleUpdateUser } from '../../reducers/user'
import PrivacySelector from '../../components/privacySelector'
import ClubCourseSelector from './clubCourseSelector'

const useStyles = makeStyles(theme => ({
  root:{
    padding: '16px 0',
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      padding: 8,
      borderRadius: 0
    },
  },
  topPart: {
    width: '100%',
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  }
}));

function PrivacySettings({ references, profile, updateUserDetail }) {
  const classes = useStyles();
  const handlePrivacySelected = value => {
    const newProfile = { ...profile, privacy_setting: value }
    updateUserDetail(newProfile)
  }
  return (
    <div>
      <SectionTitle hideEdit>Privacy Settings</SectionTitle>
      <Paper elevation={0} className={classes.root}>
        <div className={classes.topPart}>
          <PrivacySelector
            text={references.privacy_descriptions}
            value={profile.privacy_setting || 'full'}
            handlePrivacySelected={handlePrivacySelected} />
        </div>
        {profile.privacy_setting && profile.privacy_setting === 'anonymous' && <div>
          <ClubCourseSelector
            title='Show my profile only to members of these clubs:'
            label='Selected Clubs'
            field='share_clubs'
            profile={profile}
            readonly={Boolean(false)}
          />
        </div>}
      </Paper>
    </div>
  );
}

const mapDispatch = (dispatch) => {
  return bindActionCreators({
    updateUserDetail: handleUpdateUser
  }, dispatch)
}
const mapState = (state) => {
  return {
    references: state.references
  }
}

export default connect(mapState, mapDispatch)(PrivacySettings)