import React from 'react'
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Typography, Card, CardContent, CardHeader } from '@material-ui/core';
import Datalist from './datalist';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
  },
  card: {
    width: '90%',
    maxWidth: 980,
    color: theme.palette.primary.dark,
    margin: '24px auto 16px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  cardHeader: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    borderBottom: 'solid 1px #ccc',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ccc',
    }
  }
})
const getMuiTheme = () => createMuiTheme({
  overrides: {
    MuiCardHeader: {
      action: {
        marginTop: 0,
        marginRight: 0
      }
    }
  }
})
const DatalistsView = ({classes, references}) => {
  const [selected, setSelected] = React.useState('')
  return (
    <div className={classes.root}>
      <Typography component='p' margin='normal' variant='h5'>Data Lists</Typography>
      <MuiThemeProvider theme={getMuiTheme()}>
        <Card className={classes.card}>
          {references && Object.keys(references).map((keyValue, idx) => {
            if (keyValue.indexOf('_') < 0) { return <span key={idx} /> }
            return (<React.Fragment key={idx}>
              <CardHeader key={idx}
                onClick={() => {selected === keyValue ? setSelected('') : setSelected(keyValue)}}
                action={selected === keyValue ? <ExpandLess /> : <ExpandMore />}
                className={classes.cardHeader}
                title={keyValue.replace(/_/g, ' ')} />
              {selected === keyValue && <CardContent>
                <Datalist id={keyValue} list={references[keyValue]} />
              </CardContent>}
            </React.Fragment>)
          })}
        </Card>
      </MuiThemeProvider>
    </div>
  )
}

export default withStyles(styles)(DatalistsView)
