import React from 'react'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
// import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
// import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined'
import { isPostLoading } from '../utils/posts'
import WidgetLoading from './widgetLoading'


const PostCardActions = ({ post, userDetail, likedPost, setEditPost, handlePostLike, handleDeleteClick }) => {
  return isPostLoading(post, userDetail)
  ? <WidgetLoading size={20} />
  :  (
    userDetail.username === post.username && <div>
      <IconButton onClick={() => setEditPost(true)}><EditIcon /></IconButton>
      <IconButton onClick={() => handleDeleteClick()}><DeleteIcon /></IconButton>
    </div>
  )
  // :  (
  //   userDetail.username === post.username
  //     ? (
  //       <div>
  //         <IconButton onClick={() => setEditPost(true)}><EditIcon /></IconButton>
  //         <IconButton onClick={() => handleDeleteClick()}><DeleteIcon /></IconButton>
  //       </div>
  //     )
  //     : (
  //       <IconButton onClick={() => handlePostLike(post, !likedPost)}>
  //         {likedPost
  //           ? <FavoriteOutlinedIcon color="primary" />
  //           : <FavoriteBorderOutlinedIcon />
  //         }
  //       </IconButton>
  //     )
  // )
}

export default PostCardActions