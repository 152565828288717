import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleGetAllPostsAdmin } from '../../reducers/posts';
import PostItem from './postItem';
import PostDialog from './postDialog';
import WidgetLoading from '../../components/widgetLoading';
import PlayGolfPostItem from './playGolfPostItem';
import TravelPostItem from './travelPostItem';
import { Button, Typography } from '@material-ui/core';
import { isPostUpdating } from '../../utils/posts';
import { Box, CircularProgress } from '@material-ui/core';
import InfiniteScroll from "react-infinite-scroll-component";

const PostMembersContainer = ({ posts, memberList, handleGetAllPostsAdmin, userDetail, updatedPost, allPosts }) => {
  
  const [showDialog, setShowDialog] = React.useState(false)
  const[updatedPosts, setUpdatedPosts] = useState([])

 React.useEffect(() => {
   handleGetAllPostsAdmin()
 }, [handleGetAllPostsAdmin]);

  const fetchMoreData = () => {
    setTimeout(() => {
      handleGetAllPostsAdmin(null, allPosts.length)
    }, 1500);
  };

  useEffect(() => {    
    if(isPostUpdating(updatedPost, true)){
      const newUpdatedPosts = allPosts.map(obj => {
        if(obj.id === updatedPost.id) {
          return updatedPost;
        }
        return obj;
      });

      setUpdatedPosts(newUpdatedPosts);
    }
  }, [updatedPost, allPosts])


  useEffect(() => {    
      setUpdatedPosts(allPosts)
  }, [allPosts])
  
  return (
    <div id="memberpost-container">
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h5'>Posts</Typography>
        <div style={{display: 'flex'}}>
          <Button onClick={() => setShowDialog(true)} variant="contained">+ Create Post</Button>
        </div>
      </div>
      <PostDialog
        open={showDialog}
        toggleDialog={setShowDialog}
      /> 
      <PostDialog
        open={showDialog}
        toggleDialog={setShowDialog}
      />
      {!memberList || !updatedPosts ? (<WidgetLoading />) : 
      (
      <InfiniteScroll
      dataLength={updatedPosts.length}
      next={fetchMoreData}
      hasMore={true}
      loader={
        updatedPosts.length > 0 && 
          <Box paddingTop={2} paddingBottom={1}>
            <CircularProgress size={24} color="primary" />
          </Box>
      }
        > {
        updatedPosts.map((item, idx) => 
          {
            if(item.play_golf){
              return <div key={idx}>
                <PlayGolfPostItem post={item} />
              </div>
            }

            if(item.travel){
              return <div key={idx}>
                <TravelPostItem post={item} />
              </div>
            }

            return <div key={idx}>
              <PostItem post={item} />
            </div>
          })
        }
      </InfiniteScroll> 
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    posts: state.posts.posts,
    memberList: state.members.memberList,
    userDetail: state.user.userDetail,
    updatedPost: state.posts.updatedPost,
    allPosts: state.posts.allPosts,
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleGetAllPostsAdmin,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(PostMembersContainer)
