import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react'

const HandicapListChildren = ({ label, handleCount, parentLabel, parentState, childrenLabel }) => {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
      setChecked(event.target.checked);
      handleCount(event.target.checked ? 1 : -1, childrenLabel)
    };

    React.useEffect(() => {
      setChecked(parentState)
    }, [parentState])

    return <FormControlLabel
          label={label}
          control={<Checkbox checked={checked} onChange={handleChange} />}
        />;
}

export default HandicapListChildren