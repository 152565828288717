import { Avatar, Grid, Hidden } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import LocationOn from '@material-ui/icons/LocationOn';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SectionTitle from '../profile/sectionTitle';
import { Link as LinkRouter} from 'react-router-dom';
import { paths } from '../../utils/paths';
import AccountIcon from '@material-ui/icons/AccountCircle';
import cls from 'classnames';

const styles = theme => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      padding: 8
    },
  },
  headerRow: {
    padding: '8px 0 0 0',
    fontWeight: 'bold',
    fontSize: 18
  },
  course_list: {
    fontSize: 14,
    padding: '8px 0',
    color: theme.palette.profile.dark,
    fontWeight: 400,
    maxHeight: 300,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '2px 0'
    },
  },
  courseItem: {
    backgroundColor: theme.palette.lightGray.main,
    marginBottom: '2px',
    padding: '8px 0',
    alignItems: 'center',
    color: theme.palette.profile.dark,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
    '&.courseHover:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&.courseMove': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  grow: {
    flexGrow: 1,
  },
  mb5: {
    marginBottom: '5px'
  },
  avatar: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    maxWidth: '34px',
    maxHeight: '34px',
  },
  displayFlexAlignItemsCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textCenterAlignCenter: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  link: {
    textDecoration: 'none',
    color: '#162649'
  },
  mL10: {
    marginLeft: '10px'
  },
  accountIcon: {
    backgroundColor: '#FFF',
    color: '#11274B'
  }
})

const MembersRank = ({
  title,
  classes,
  members}) => {
  const [customDataList, setCustomDataList] = React.useState([])
  const [customDataListFiltered, setCustomDataListFiltered] = React.useState([])
  
  React.useEffect(() => {
    setCustomDataList(members.map(member => ({...member, custom_rank: null})))
  }, [members]);
  
  React.useEffect(() => {
    let auxMemberList = []
    if(customDataList.length > 0){
      for (let index = 0; index < customDataList.length; index++) {
        const member = customDataList[index];
        if(auxMemberList.length > 0 && auxMemberList.filter(m => m.email === member.email).length > 0){
          continue;
        }
        const tied = customDataList
          .filter(m => m.courses_quantity === member.courses_quantity && !m.custom_rank)
          .map(m => ({ ...m, custom_rank: (index+1) }))

        auxMemberList = [...auxMemberList, ...tied]
      }
    }

    if(auxMemberList.length > 0){
      setCustomDataListFiltered(auxMemberList)
    }
  }, [customDataList])
  
  const MemberItem = ({rank, member}) => {
    const name = `${member.first_name} ${member.last_name}`
    const { courses_quantity, profile_image } = member
    const location = member.address && member.address.city ? member.address.city + ', ' + member.address.state : (member.club_name ? member.club_name + ', ' + member.state : '')

    return (
      <React.Fragment>
      <Hidden smDown>
        <Grid container className={classes.courseItem}>
          <Grid style={{paddingLeft: '0', textAlign: 'center'}} item xs={2}>
            {<span>#{rank}</span>}
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }} >
            {courses_quantity}
          </Grid>
          <Grid item xs={4} className={classes.displayFlexAlignItemsCenter} style={{ paddingLeft: '66px' }}>
            <LinkRouter to={`${paths.profile}/${member.username}`} >
              {profile_image
                ? <Avatar alt="Profile Picture" src={profile_image} className={classes.avatar} />
                : <AccountIcon className={cls(classes.avatar, classes.accountIcon)} />
              }
            </LinkRouter>
            <LinkRouter to={`${paths.profile}/${member.username}`} className={classes.link} ><span className={classes.mL10} >{ name }</span></LinkRouter>
          </Grid>
          <Grid item xs={4}>            
            {location &&
            <>
              <LocationOn style={{ verticalAlign: "middle", marginRight: '4px' }} />
              {location}
              <div className={classes.grow} />
            </>}
            
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
      <div className={classes.courseItem}>
        <Grid container>
          <Grid className={classes.mb5} item xs={12}>
            Member Rank {<span>#{rank}</span>}
          </Grid>
          <Grid className={classes.mb5} item xs={12}>
            Courses Played: {courses_quantity}
          </Grid>
          <Grid item className={cls(classes.mb5, classes.displayFlexAlignItemsCenter)} xs={12} >
            <LinkRouter to={`${paths.profile}/${member.username}`} >
              {profile_image
                ? <Avatar alt="Profile Picture" src={profile_image} className={classes.avatar} />
                : <AccountIcon className={cls(classes.avatar, classes.accountIcon)} />
              }
            </LinkRouter>
            <LinkRouter to={`${paths.profile}/${member.username}`} className={classes.link} ><span className={classes.mL10} >{ name }</span></LinkRouter>
          </Grid>
          <Grid className={classes.mb5} item xs={12}>
            {location &&
              <>
                <LocationOn style={{ verticalAlign: "middle", marginRight: '4px' }} />
                {location}
                <div className={classes.grow} />
              </>}
          </Grid>
        </Grid>
      </div>
      </Hidden>
      </React.Fragment>
    );
  };

  return (
    <div style={{ paddingTop: '16px', paddingBottom: '40px' }} >
      <SectionTitle>{title}</SectionTitle>
      <div className={classes.root}>
        <Hidden smDown>
        { customDataListFiltered && customDataListFiltered.length > 0 && <div className={classes.headerRow}>
            <Grid container>
              <Grid className={classes.textCenterAlignCenter} item sm={2}>Member Rank</Grid>
              <Grid className={classes.textCenterAlignCenter} item sm={2}>Top 100 U.S.<br />Courses Played:</Grid>
              <Grid style={{ paddingLeft: '66px', display: 'flex', alignItems: 'center'}} item sm={4}>Member Name</Grid>
              <Grid style={{ display: 'flex', alignItems: 'center'}} item sm={4}>Location</Grid>
            </Grid>
          </div> }
          </Hidden>
        <div className={classes.course_list} style={{ maxHeight: 530 }} >
          {customDataListFiltered && customDataListFiltered.map((member, index) => {
            if (!member) { return <div key={index} /> }
            
            return <MemberItem member={member} rank={member.custom_rank} />
          })}
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    courses: state.clubs.courseList
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({ }, dispatch)
}
export default connect(mapStateToProps, mapDispatch)(withStyles(styles)(MembersRank))
