import React from 'react'
import { isPostLoading } from '../utils/posts';
import { Box, Button, CardActions, CardContent, CircularProgress, Collapse, IconButton } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubbleOutline';
import { makeStyles } from '@material-ui/styles';
import { CommentDisplay } from '../pages/dashboard/commentDisplay';
import CommentInput from '../pages/dashboard/commentInput';
import LikesIndicator from './likesIndicator';
import WidgetLoading from './widgetLoading';
import { parser } from '../utils/parser';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined'

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      padding: 8
    }
  },
  cardActions: {
    padding: 0,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
  submitDate: {
    marginLeft: 'auto',
    color: '#777'
  },
}))

const PostCommentActions = ({ post, userDetail, expanded, visibleMemberList, history, handleExpandClick, handleSubmitComment, setExpanded, likedPost, handlePostLike }) => {
  const classes = useStyles()

  const [comment, setComment] = React.useState();

  function submitComment() {
    if (!comment) { return }
    handleSubmitComment(post, comment)
    setComment('')
  }

  return isPostLoading(post, userDetail) ? <WidgetLoading size={20} />
    : <>
      <CardActions className={classes.cardActions} >
        <Box display="flex" alignItems="center">
          <Button color="primary" onClick={handleExpandClick}>
            <ChatBubbleIcon style={{ marginRight: 8, fontSize: 20}} /> {`${post.comment_count} Comment${post.comment_count > 1 ? 's' : ''}`}
          </Button>
          <div className={classes.submitDate}>
            {post.edited ? `Edited ${parser.dateDistance(post.edited)} ago` : `Added ${parser.dateDistance(post.created_at)} ago`}
          </div>
        </Box>

        <Box style={{ marginLeft: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
          {
            userDetail.username === post.username && post.likes && post.likes.length > 0 && <FavoriteBorderOutlinedIcon color="primary" style={{ marginRight: '12px' }} />
          }
          {userDetail.username !== post.username && 
          <IconButton onClick={() => handlePostLike(post, !likedPost)}>
            {likedPost
              ? <FavoriteOutlinedIcon color="primary" />
              : <FavoriteBorderOutlinedIcon />
            }
          </IconButton> }
          <LikesIndicator
            members={(post.likes || []).map(like => ({
              ...like,
              name: `${like.first_name} ${like.last_name}`,
            }))}
            visibleMemberList={visibleMemberList}
            userDetail={userDetail}
          />
          </div>
        </Box>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContent}>
          {post.comment_loading === true
            ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={120}
                paddingBottom={4}
              >
                <CircularProgress size={24} color="primary" />
              </Box>
            )
            : post.comments
            ? post.comments.map((item, idx) => (
                <CommentDisplay
                  key={idx}
                  history={history}
                  comment={item}
                  post={post}
                />
              ))
            : null
          }
        </CardContent>
        
          <CardActions className={classes.cardActions}>
            <CommentInput
              value={comment}
              placeholder='Write a comment...'
              handleSubmit={submitComment}
              handleCancelClick={() => setExpanded(false)}
              handleUpdateText={setComment} />
          
          </CardActions>
      </Collapse>
    </>
}

export default PostCommentActions