import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
  postButton: {
    maxWidth: 300,
    width: '100%',
    margin: 'auto'
  },
}))

const SubmitPostButton = ({handlePost, disabled}) => {
  const classes = useStyles()

  return (
    <div onClick={handlePost} className={classes.postButton}>
      <Button  className={classes.button} variant="contained" disabled={ disabled } >Post</Button>
    </div>
  )
}

export default SubmitPostButton