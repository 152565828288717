import React from 'react';
import { IconButton } from '@material-ui/core';
import cls from 'classnames';
import { makeStyles } from '@material-ui/styles';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
  iconStatus: { color: '#ddd' },
  hoverRed: {
      '&:hover': { backgroundColor: 'red' }
  },
  reject: { color: 'red', '&.mine:hover': { color: 'white' } },
}))

const DeclineButton = (props) => {
  const { inviteStatus } = props
  const classes = useStyles()
  
  return (
    <>
      <IconButton onClick={props.onClick} className={classes.hoverRed}>
        <BlockIcon className={cls(classes.iconStatus, 'mine', {[classes.reject]: !inviteStatus && inviteStatus !== null && inviteStatus !== undefined}) } />
      </IconButton>
    </>)
}

export default DeclineButton