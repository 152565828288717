import React from 'react'
import { Redirect } from 'react-router-dom'
import EmailConfirmedView from './emailConfirmedView'
import { paths } from '../../utils/paths'
const qs = require('query-string')

class EmailConfirmedContainer extends React.Component {
  render () {
    try {
      const queryString = this.props.location.search
      const token = qs.parse(queryString).token
      const email = this.props.match.params.email
      if (!token || !email) { throw new Error('Bad Path') }
      return <EmailConfirmedView {...this.props} email={email} token={token} />
    } catch (error) {
      console.log('Email Confirmed ERROR', error.message)
      return <Redirect to={paths.root} />
    }
  }
}

export default EmailConfirmedContainer
