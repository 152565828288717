import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import UserAvatar from '../../components/userAvatar';
import { paths } from '../../utils/paths';

const useStyles = makeStyles(theme => ({
  groupName: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 22
    },
  },
  groupItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // borderTop: `solid 1px ${theme.palette.primary.dark}`,
    // borderBottom: `solid 1px ${theme.palette.primary.dark}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyItems: 'center',
    },
  },
  groupContent: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  groupAction: {
    paddingRight: 16,
    paddingLeft: 0,
    minWidth: 180,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 8,
      paddingLeft: 8,
      margin: 'auto',
      marginTop: 16
    },
  }
}))

const GroupListBox = ({ history, group, memberList, userDetail, handleGroupRequest}) => {
  const classes = useStyles();
  if (!group || !group.group_owner) { return <div /> }
  const creator = group.group_members.find(x => x.member_email === group.group_owner)
  const currentMember = group.group_members.find(x => x.member_email === userDetail.email)
  const pending = group.group_requests ? group.group_requests.filter(x => !x.declined).find(x => x.email === userDetail.email) : null
  const visibleMemberList = (memberList || []).map(x => x.username)
  return (
  <Paper style={{marginBottom: 16, padding: 16}}>
    <Typography className={classes.groupName} variant="subtitle1">{group.name}</Typography>
    <div className={classes.groupItem}>
      <div className={classes.groupContent}>
        <div style={{flexGrow: 1}}>
          <Typography component="div" style={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <UserAvatar width={24} profileImage={visibleMemberList.includes(creator.username) && creator.profile_image} />
            <div style={{marginLeft: 8}}>{visibleMemberList.includes(creator.username) ? (creator.first_name + ' ' + creator.last_name) : 'Anonymous'}</div></Typography>
          <Typography>Member Count: {group.group_members.length}</Typography>
          <Typography>Description: {`${group.description}`}</Typography>
        </div>
      </div>
      <div className={classes.groupAction}>
        {currentMember && currentMember.username ?
          <Button onClick={() => history.push(`${paths.group}/${group.url}`)} variant="contained" style={{width: '100%'}}>View Group</Button>
        : ( pending && pending.username ?
              <Button disabled variant="contained" style={{width: '100%'}}>Pending</Button>
            : <Button onClick={() => handleGroupRequest(group)} variant="contained" style={{width: '100%'}}>Request Access</Button>
          )
        }
      </div>
    </div>
  </Paper>)
}

export default withRouter(GroupListBox)
