import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddLogoIcon from '@material-ui/icons/AddToPhotos';
import React from 'react';
import AddressForm from '../../components/addressForm';
import WidgetLoading from '../../components/widgetLoading';

const useStyles = makeStyles(theme => ({
    textField: {
        margin: 0,
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    button: {
        fontSize: '13px'
    },
    deletebtn: {
        minWidth: 0,
        width: 36,
        height: 'auto',
        borderRadius: "50px",
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        color: "white",
        verticalAlign: "inherit",
    },
}));

function ClubFormDialog ({open, toggleDialog, clubDetail, isLoading, saveChanges}) {
    const classes = useStyles()
    const [newClub, setNewClub] = React.useState({})
    const [tempLogo, setTempLogo] = React.useState()
    const [newCourse, setNewCourse] = React.useState()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    React.useEffect(() => {
        setTempLogo(null)
        setNewCourse(null)
        if (clubDetail) {
            // console.log('DETAIL', clubDetail)
            setNewClub({...clubDetail})
        } else {
            setNewClub({})
        }
    }, [clubDetail]);
    const handleConfirm = () => {
        if (!newClub.address) { newClub.address = {}}
        if (!newClub.address.state) { newClub.address = {...newClub.address, state: ''}}
        if (!newClub.address.city) { newClub.address = {...newClub.address, city: ''}}
        if (!newClub.id_prefix)  { newClub.id_prefix = 'INT-CC' }
        if (newCourse) { 
            const courses = newClub.courses ? [...newClub.courses] : []
            courses.push({ course_name: newCourse })
            newClub.courses = courses
        }
        saveChanges({...newClub})
        onClose()
    }
    const updateAddress = (address) => {
        const locality = {...address}
        if (locality.state && locality.state.label) {
            newClub.id_prefix = 'USA-' + locality.state.value
            address.state = locality.state.label
        }
        setNewClub({...newClub, address: {...address }})
    }
    const handleChange = (event) => {
        let key = event.target.name
        let newValue = event.target.value
        setNewClub({...newClub, [key]: newValue })
    }
    const handleCourseChange = (event) => {
        let courseId = event.target.name
        let newValue = event.target.value
        const course = (newClub.courses || []).find(x => x.id === courseId)
        if (course && course.id) {
            course.course_name = newValue
            setNewClub({...newClub, courses: [...newClub.courses]})
        }
    }
    const onClose = () => {
        setTempLogo(null)
        setNewCourse(null)
        setNewClub({})
        toggleDialog(null)
    }
    const onFileChange = e => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            getTempPath(file)
            setNewClub({...newClub, club_logo: file})
        }
    }
    const getTempPath = logo => {
        if (!logo || typeof logo === 'string') { return logo }
        let reader = new FileReader()
        reader.onload = (e) => {
            setTempLogo(e.target.result)
        }
        reader.readAsDataURL(logo)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {isLoading ? <div /> :
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div>{clubDetail ? 'Edit ' + (clubDetail.name || 'Club')  : 'Add New'}<div style={{fontSize: 10}}>*Name required</div></div>
                    {tempLogo || (newClub && newClub.club_logo) ? <div style={{maxWidth: 80}}>
                        <IconButton component="label">
                            <img style={{maxWidth: '100%'}} src={tempLogo || newClub.club_logo} alt={newClub.name || 'Logo'} />
                            <input
                                type="file"
                                onChange={onFileChange}
                                style={{ display: "none" }}
                            />
                        </IconButton>
                    </div>
                    : <IconButton component="label">
                        <AddLogoIcon style={{width: 50, height: 'auto'}} />
                        <input
                            type="file"
                            onChange={onFileChange}
                            style={{ display: "none" }}
                        />
                    </IconButton>
                    }
                </div>}
            </DialogTitle>
            {isLoading ? <DialogContent>
                    <div style={{ padding: 40, minHeight: 400, minWidth: 300}}>
                        <WidgetLoading />
                    </div>
                </DialogContent>
            : <DialogContent style={{overflowX: 'hidden', minHeight: '60vh'}}>
                <TextField
                    id="name"
                    name="name"
                    label="Club Name"
                    fullWidth
                    margin="none"
                    variant="outlined"
                    value={newClub.name || ''}
                    onChange={handleChange}
                    className={classes.textField} />
                <FormControl className={classes.textField} style={{marginTop: 0}}>
                    <AddressForm address={newClub.address || {}} updateAddress={updateAddress} />
                </FormControl>
                <TextField
                    id="website"
                    name="website"
                    label="Website"
                    fullWidth
                    margin="none"
                    variant="outlined"
                    value={newClub.website || ''}
                    onChange={handleChange}
                    className={classes.textField} />
                <div style={{paddingTop: 24}}>
                    <Typography variant="subtitle1">Courses:</Typography>
                    {isLoading ? <div /> : <div style={{paddingTop: 24}}>
                        {newClub && newClub.courses && newClub.courses.length > 0 && newClub.courses.map((course, idx) => {
                            return <Grid container key={idx}>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    name={course.id}
                                    fullWidth
                                    margin="none"
                                    variant="outlined"
                                    value={course.course_name}
                                    onChange={handleCourseChange}
                                    className={classes.textField}
                                    style={{color: '#FFF'}} />
                            </Grid>
                            <Grid item md={6} xs={false} style={{textAlign: 'center'}}>
                                {/* <IconButton onClick={() => window.alert('REMOVE ME')}>
                                    <ClearIcon className={classes.deletebtn} />
                                </IconButton> */}
                            </Grid>
                        </Grid>})}
                        <Grid container style={{display: 'flex', alignItems: 'center'}}>
                            <Grid item md={6} xs={12}>
                            <TextField
                                id="course"
                                name="course"
                                label="Add a new Course"
                                placeholder="Course Name"
                                fullWidth
                                margin="none"
                                variant="outlined"
                                value={newCourse || ''}
                                onChange={(e) => setNewCourse(e.target.value)}
                                className={classes.textField} />
                            </Grid>
                            <Grid item md={6} xs={false} style={{textAlign: 'center', paddingBottom: 16}}>
                                {/* <IconButton onClick={() => window.alert('ADD ME')}>
                                    <AddCourseIcon className={classes.deletebtn} />
                                </IconButton> */}
                            </Grid>
                        </Grid>
                    </div>
                    }
                </div>
            </DialogContent> }
            <DialogActions>
                {newClub && <Button disabled={!newClub.name} onClick={handleConfirm} variant="contained">Save</Button>}
                <Button onClick={onClose} variant="outlined">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ClubFormDialog
  