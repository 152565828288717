import { handleLogin } from './auth';
import { handleGetUserDetail } from './user';

export const types = {
  ADMIN_INVITES_REQUEST: 'GUESTS/ADMIN_INVITES_REQUEST',
  ADMIN_INVITES_SUCCESS: 'GUESTS/ADMIN_INVITES_SUCCESS',
  ADMIN_INVITES_FAILURE: 'GUESTS/ADMIN_INVITES_FAILURE',
  ADMIN_INVITE_DELETE_REQUEST: 'GUESTS/ADMIN_INVITE_DELETE_REQUEST',
  ADMIN_INVITE_DELETE_SUCCESS: 'GUESTS/ADMIN_INVITE_DELETE_SUCCESS',
  ADMIN_INVITE_DELETE_FAILURE: 'GUESTS/ADMIN_INVITE_DELETE_FAILURE',
  GET_REQUEST: 'GUESTS/GET_REQUEST',
  GET_SUCCESS: 'GUESTS/GET_SUCCESS',
  GET_FAILURE: 'GUESTS/GET_FAILURE',
  TOKEN_REQUEST: 'GUESTS/TOKEN_REQUEST',
  TOKEN_SUCCESS: 'GUESTS/TOKEN_SUCCESS',
  TOKEN_FAILURE: 'GUESTS/TOKEN_FAILURE',
  SEND_INVITE_REQUEST: 'GUESTS/SEND_INVITE_REQUEST',
  SEND_INVITE_SUCCESS: 'GUESTS/SEND_INVITE_SUCCESS',
  SEND_INVITE_FAILURE: 'GUESTS/SEND_INVITE_FAILURE',
  REGISTRATION_REQUEST: 'GUESTS/REGISTRATION_REQUEST',
  REGISTRATION_SUCCESS: 'GUESTS/REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'GUESTS/REGISTRATION_FAILURE',
  APPLICATION_REQUEST: 'GUESTS/APPLICATION_REQUEST',
  APPLICATION_SUCCESS: 'GUESTS/APPLICATION_SUCCESS',
  APPLICATION_FAILURE: 'GUESTS/APPLICATION_FAILURE',
  CLEAR_ERROR: 'GUESTS/CLEAR_ERROR',
};

export const initialState = {
  adminGuestInviteList: [],
  guestInvite: null,
  guestList: [],
  error: null,
  isLoading: false,
};

export const handleGetGuests = () => (dispatch) => {
  dispatch({
    types: [types.GET_REQUEST, types.GET_SUCCESS, types.GET_FAILURE],
    url: `/guests/`,
  });
};
export const handleGetGuestInvitesForAdmin = () => (dispatch) => {
  dispatch({
    types: [
      types.ADMIN_INVITES_REQUEST,
      types.ADMIN_INVITES_SUCCESS,
      types.ADMIN_INVITES_FAILURE,
    ],
    url: `/guests/invites`,
  });
};

export const handleDeleteGuestInvite = (email) => (dispatch) => {
  dispatch({
    types: [
      types.ADMIN_INVITE_DELETE_REQUEST,
      types.ADMIN_INVITE_DELETE_SUCCESS,
      types.ADMIN_INVITE_DELETE_FAILURE,
    ],
    url: `/guests/${email}`,
    method: 'delete',
    callback: handleGetGuestInvitesForAdmin(),
  });
};

export const handleGuestInvitation = (application) => (dispatch) => {
  dispatch({
    types: [
      types.SEND_INVITE_REQUEST,
      types.SEND_INVITE_SUCCESS,
      types.SEND_INVITE_FAILURE,
    ],
    url: `/guests/invitation`,
    method: 'post',
    data: { ...application },
    callback: handleLogin(application.email, application.password),
  });
};
export const handleGetByToken = (inviteToken) => (dispatch) => {
  dispatch({
    types: [types.TOKEN_REQUEST, types.TOKEN_SUCCESS, types.TOKEN_FAILURE],
    url: `/guests/apply/${inviteToken}`,
  });
};
export const handleGuestRegistration = (inviteToken, application) => (
  dispatch
) => {
  if (!application || !application.email || !application.password) {
    return;
  }
  dispatch({
    types: [
      types.REGISTRATION_REQUEST,
      types.REGISTRATION_SUCCESS,
      types.REGISTRATION_FAILURE,
    ],
    url: `/guests/apply/${inviteToken}`,
    method: 'post',
    data: { ...application },
    callback: handleLogin(application.email, application.password),
  });
};
export const handleGuestApplication = (email) => (dispatch) => {
  dispatch({
    types: [
      types.APPLICATION_REQUEST,
      types.APPLICATION_SUCCESS,
      types.APPLICATION_FAILURE,
    ],
    url: `/guests/${encodeURIComponent(email)}`,
    method: 'put',
    callback: handleGetUserDetail({ role: 'guest', email }),
  });
};
export const handleClearError = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ERROR });
};
export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        guestList: action.data,
      };
    case types.GET_FAILURE:
      return { ...state, isLoading: false, error: action.error, guestList: [] };

    case types.ADMIN_INVITES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        adminGuestInviteList: action.data,
      };
    case types.ADMIN_INVITES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        adminGuestInviteList: [],
      };

    case types.TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        guestInvite: action.data,
      };
    case types.TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        guestInvite: null,
      };

    case types.ADMIN_INVITE_DELETE_SUCCESS:
    case types.SEND_INVITE_SUCCESS:
    case types.REGISTRATION_SUCCESS:
      return { ...state, isLoading: false, error: null };
    case types.ADMIN_INVITE_DELETE_FAILURE:
    case types.SEND_INVITE_FAILURE:
    case types.REGISTRATION_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case types.ADMIN_INVITES_REQUEST:
    case types.SEND_INVITE_REQUEST:
    case types.REGISTRATION_REQUEST:
    case types.ADMIN_INVITE_DELETE_REQUEST:
    case types.TOKEN_REQUEST:
    case types.GET_REQUEST:
      return { ...state, isLoading: true };
    case types.CLEAR_ERROR:
      return { ...state, guestInvite: null, isLoading: false, error: null };
    default:
      return state;
  }
}
