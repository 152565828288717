import { Paper, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../utils/paths';


const useStyles = makeStyles(theme => ({
  main: {
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  secondary: {
    color: theme.palette.secondary.dark
  },
  avatar: {
    marginBottom: theme.spacing(1),
    borderRadius: '50px',
    width: '60px',
    height: '60px',
  },
  heading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 26
    },
  },
  buttonContainer: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
  },
  button: {
    // backgroundColor: theme.palette.primary.dark,
    // color: theme.palette.textOnPrimary.main,
    // fontSize: '14px',
    // fontWeight: 300,
    // borderRadius: 20,
    // border: 'solid 1px #FFF',
    margin: '8px 8px 8px 0',
    justifyContent: 'space-around',
    width: '168px',
    '&.selected': {
      backgroundColor: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('xs')]: {
      margin: '8px auto',
      width: '100%'
    },
  }
}))

export default function DashboardGuestBanner ({profile}) {
  const classes = useStyles()
  const theme = useTheme()
  return (
      <Paper elevation={2} className={classes.main}>
        <React.Fragment>
          <Typography variant="h6" className={classes.heading}>Guest Information</Typography>
          <Typography component={'div'} variant="body2" style={{fontWeight: 400}}>
            <p>Captain's Club is a private club that was created to bring together golfers who want to experience great courses, establish new friendships, and build lasting relationships.</p>
            <p>Members of Captain's Club are invited to play in exclusive golf events hosted throughout the country each year and have the opportunity to utilize the Captain's Club Members website to customize and plan golf events, find new games to play in, manage private golf groups and find members across the country with similar interests.</p>
            <p>Membership is limited to golfers belonging to private golf clubs.</p>
            {profile.guest_applied ? <p style={{margin: '8px auto', border: 'solid 1px #ccc', padding: 8, textAlign: 'center', color: theme.palette.primary.main, fontSize: 16}}>Thank you for applying.  We will get back to you shortly.<br />To make changes to your application, <Link to={paths.profile}>click here</Link>.</p> :
              <React.Fragment>
                <p>If you are interested in becoming a Captain's Club member, you can complete your profile and apply to join.</p>
                <p><Link style={{color: theme.palette.primary.main, fontSize: 16}} to={paths.profile}>Click here to apply</Link></p>
                <hr />
              </React.Fragment>
            }
            <p>As a Guest of a member, you'll be able to interact with member groups you have been invited to and you can be invited to play golf with other members.</p>
            <p>While Guests do not have access to full member profiles, other online services and events, we do recommend that you complete your guest profile that other members of your groups or invited golf rounds will be able to see.</p>
            <p>We hope that you enjoy being a Captain's Club Guest.  If you have any questions about the club or suggestions about how we can improve your guest experience, please let us know by clicking the "Feedback" button on the left.</p>
          </Typography>
        </React.Fragment>
      </Paper>
  )
}
