import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { handleShowDialogPost } from '../reducers/posts';
import { parser } from '../utils/parser';
import { paths } from '../utils/paths';
import UserAvatar from './userAvatar';

const useStyles = makeStyles(theme => ({
  emptyBox: {
    textAlign: 'center',
    width: '100%',
    padding: '16px 0',
  },
  notificationItem: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    borderBottom: 'solid 1px #e7e7ea',
    padding: 8,
  },
  subHeader: {
    backgroundColor: '#f5f6f7',
    borderBottom: 'solid 1px #e7e7ea',
    padding: '4px 8px'
  },
  subTitle: {
    fontWeight: 'bold',
    color: '#93969d',
    fontSize: 12
  },
}))

const NotificationGolfUpdated = ({ posts, membersVisible, message, handleShowDialogPost, closeNotifications }) => {
  const classes = useStyles()
  const post = posts.find(x => message.data.pgp_id && Number(x.id) === Number(message.data.pgp_id))
  const visibleMemberList = (membersVisible || []).map(x => x.username)
  
  if (!post) { return <div /> }
  const onPostClick = () => {
    handleShowDialogPost(post.id)
    closeNotifications()
  }
  return <div className={classes.notificationItem}>
    {visibleMemberList.includes(message.data.sender_username) ?
    <Link onClick={() => closeNotifications()} to={`${paths.profile}/` + message.data.sender_username} style={{marginRight: 8}}>
      <UserAvatar profileImage={message.data.sender_profile_image} width={32} />
    </Link> :
      <div style={{marginRight: 8}}>
        <UserAvatar width={32} />
      </div>
    }
    <Typography variant="caption">
      {visibleMemberList.includes(message.data.sender_username) ?
        <Link onClick={() => closeNotifications()} to={`${paths.profile}/` + message.data.sender_username}>{message.data.sender_name || `@${message.data.sender_username}`}</Link>
        : <span style={{fontWeight: 500}}>Anonymous</span>
      } 
      <span> has updated a </span><Link onClick={() => onPostClick()} to={paths.home}>golf invite</Link>
      {message.read && <div style={{fontSize: 10}}>{parser.dateDistance(message.issued_at)} ago</div>}
    </Typography>
  </div>
}

const mapStateToProps = (state) => {
  return {
    posts: state.posts.posts,
    membersVisible: state.members.membersVisible
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleShowDialogPost,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatch)(NotificationGolfUpdated)
