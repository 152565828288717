import { Badge, Button, Typography } from '@material-ui/core';
import MoveIcon from '@material-ui/icons/DragIndicator';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import WidgetContainer from '../../components/widgetContainer';
import WidgetElement from '../../components/widgetElement';
import WidgetLoading from '../../components/widgetLoading';
import { handleUpdateGroupList } from '../../reducers/groups';
import { paths } from '../../utils/paths';
import GroupFormDialog from '../groups/groupFormDialog';

const useStyles = makeStyles(theme => ({
  groupName: {
    fontSize: 14,
  },
  badgeIcon: {
    top: 6,
    right: -1 * theme.spacing(0.5)
  }
}))

const GroupsWidget = ({ history, userDetail, groupList, groupsLoading, handleUpdateGroupList }) => {
  const classes = useStyles()
  const [ openForm, setOpenForm ] = React.useState(false)
  const [orderableList, setOrderableList] = React.useState([])
  React.useEffect(() => {
    setOrderableList(groupList)
  }, [groupList]);
  const handleDragEnd = (result) => {
    if (!result.destination) { return } // dropped outside the list
    const items = reorder(
      orderableList,
      result.source.index,
      result.destination.index
    );
    setOrderableList(items)
    handleUpdateGroupList(items)
  }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
  
    // // change background colour if dragging
    background: isDragging ? "#DDD" : "#FFF",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });
  const footer = userDetail && userDetail.role === 'member' ? 
  <>
  <Button variant="contained" onClick={() => history.push(paths.groups)}>Find Groups</Button> 
  <Button variant="contained" onClick={() => history.push(paths.groupsArchived)}>Archived Groups</Button> 
  </>
  : <span />
  if (userDetail && userDetail.role === 'guest' && groupList && groupList.length === 0) { 
    return <div />
  }
  return (
    <WidgetContainer header="My Groups" footer={footer} action={() => setOpenForm(true)} buttonLabel="+ Group">
      {groupsLoading && <WidgetLoading />} 
      {!groupsLoading && <React.Fragment> 
        <GroupFormDialog open={openForm} toggleDialog={(val) => setOpenForm(val)} />
        {orderableList.length === 0 && <Typography>
            Groups are great for coordinating golf games, planning trips, or managing private communications.
          </Typography>
        }
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {orderableList.length > 0 && orderableList.map((group, idx) => {
                  const membership = group.group_members.find(x => x.member_email === userDetail.email)
                  const accepted = membership && membership.accepted
                  const ownerRequests = group.group_owner === userDetail.email && group.group_requests && group.group_requests.filter(x => !x.declined).length > 0
                  return (
                    <Draggable key={group.id} draggableId={group.id.toString()} index={idx}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div onClick={() => history.push(`${paths.group}/${group.url}`)}>
                          <WidgetElement>
                            <div style={{display: 'flex', width: '100%'}}>
                              <div style={{cursor: 'grab', width: '10px', marginRight: 4}}><MoveIcon style={{height: '100%', width: '100%'}} /></div>
                              <Badge color="error" classes={{badge: classes.badgeIcon}} variant="dot" invisible={accepted && !ownerRequests}>
                                <Typography className={classes.groupName} variant="subtitle1">
                                  {group.name}
                                </Typography>
                              </Badge>
                            </div>
                            <div style={{paddingLeft: 30, fontSize: 10}}>
                              Member Count: {group.group_members.filter(x => x.accepted).length }
                            </div>
                            <div style={{flexGrow: 1}} />
                            <Typography variant="body2" style={{fontSize: 10}}>
                              {group.is_public ? 'Public' : 'Private'}
                            </Typography>
                          </WidgetElement>
                        </div>
                      </div>
                    )}
                    </Draggable>
                  )})
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </React.Fragment>
      }
    </WidgetContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    groupList: state.groups.groups,
    groupsLoading: state.groups.isLoading
  }
}
const mapDispatch = (dispatch) => {
  return bindActionCreators({
    handleUpdateGroupList
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatch)(GroupsWidget))
