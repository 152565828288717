import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import cls from 'classnames';
import React from 'react';

const styles = theme => ({
  footer: {
    zIndex: 10,
    backgroundColor: theme.palette.primary.dark,
    height: theme.spacing(10),
    paddingTop: theme.spacing(2),
    '& .content': {
      textAlign: 'center',
    },
    '& h6': {
      color: theme.palette.textOnPrimary.main,
    },
  },
  link: {
    color: '#fff',
    textDecoration: 'none'
  },
  spacer: {
    display: 'inline',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

const Footer = ({ classes }) => (
  <footer className={cls(classes.footer)}>
    <Typography variant="subtitle2" align="center" gutterBottom>
      {"All rights reserved. Captain's Club " + (new Date().getFullYear())}
    </Typography>
    <Typography variant="subtitle2" align="center">
      <a className={classes.link} rel="noopener noreferrer" href='https://docs.google.com/document/d/e/2PACX-1vSrq7A1bS98JGvZMqxw6XSBYZKCCPef4zvHqQCnaZPfYH04Yzw5dCU7slGDufrNm4EdG_bwYzb8RobF/pub' target="_blank">Terms & Conditions</a>
      <div className={classes.spacer}>&bull;</div>
      <a className={classes.link} rel="noopener noreferrer" href="https://docs.google.com/document/d/e/2PACX-1vSaNelPCPD5KEydUckCFaEOvvmZar-8iCkas78JTKUg0LGWD_sVHLmseezR9AKFWJWklcsBikVkR2jQ/pub" target="_blank">Privacy Policy</a>
    </Typography>
  </footer>
);

export default withStyles(styles)(Footer);
