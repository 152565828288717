import { Button } from '@material-ui/core';
// import { bindActionCreators } from 'redux'
import { makeStyles } from '@material-ui/styles';
import cls from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import TaggableInput from '../../components/taggableInput';
import UserAvatar from '../../components/userAvatar';


const useStyles = makeStyles(theme => ({
    avatarBox: {
      marginBottom: 0,
      marginRight: 16,
      display: 'block',
      alignSelf: 'start',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },
    contentBox: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between'
      },
    },
    inputBox: {
        width: '100%',
        alignSelf: 'center',
        padding: '4px 20px 0',
        backgroundColor: theme.palette.primary.light,
        border: 'solid 1px #ccc',
        borderRadius: 32
    },
    commentButtons: {
        justifyContent: 'flex-end',
        padding: '16px 0',
        [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
        },
    },
}))

const CommentInput = ({userDetail, placeholder, value, handleSubmit, handleUpdateText, handleCancelClick}) => {
    const classes = useStyles()
    return (
      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <div className={classes.contentBox}>
          <div className={classes.avatarBox}>
            <UserAvatar profileImage={userDetail.profile_image} />
          </div>
          <div className={classes.inputBox}>
            <TaggableInput
              value={value || ''}
              placement="bottom"
              onChange={(event, value) => handleUpdateText(value)}
              placeholder={placeholder}
            />
          </div>
        </div>
        <div className={cls(classes.contentBox, classes.commentButtons)}>
          <Button onClick={() => handleSubmit()} variant="contained" style={{marginRight: 8, padding: '6px 32px'}}>Send</Button>
          {handleCancelClick && <Button onClick={() => handleCancelClick()} style={{padding: '6px 24px'}} variant="outlined">Cancel</Button>}
        </div>
      </div>
    )
}

const mapStateToProps = (state) => {
    return {
      userDetail: state.user.userDetail,
    }
  }
  
export default connect(mapStateToProps)(CommentInput)
  